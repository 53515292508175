/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelBillingCostFormValuesDto
 */
export interface ModelBillingCostFormValuesDto {
    /**
     * 
     * @type {Date}
     * @memberof ModelBillingCostFormValuesDto
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof ModelBillingCostFormValuesDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelBillingCostFormValuesDto
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelBillingCostFormValuesDto
     */
    cost?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelBillingCostFormValuesDto
     */
    booker?: string;
}

/**
 * Check if a given object implements the ModelBillingCostFormValuesDto interface.
 */
export function instanceOfModelBillingCostFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelBillingCostFormValuesDtoFromJSON(json: any): ModelBillingCostFormValuesDto {
    return ModelBillingCostFormValuesDtoFromJSONTyped(json, false);
}

export function ModelBillingCostFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelBillingCostFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'booker': !exists(json, 'booker') ? undefined : json['booker'],
    };
}

export function ModelBillingCostFormValuesDtoToJSON(value?: ModelBillingCostFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'amount': value.amount,
        'reason': value.reason,
        'cost': value.cost,
        'booker': value.booker,
    };
}

