/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelCurrentSelectionCalendarDay } from './ProjectModelCurrentSelectionCalendarDay';
import {
    ProjectModelCurrentSelectionCalendarDayFromJSON,
    ProjectModelCurrentSelectionCalendarDayFromJSONTyped,
    ProjectModelCurrentSelectionCalendarDayToJSON,
} from './ProjectModelCurrentSelectionCalendarDay';

/**
 * 
 * @export
 * @interface CalendarMonths
 */
export interface CalendarMonths {
    /**
     * 
     * @type {string}
     * @memberof CalendarMonths
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarMonths
     */
    year?: string;
    /**
     * 
     * @type {Array<ProjectModelCurrentSelectionCalendarDay>}
     * @memberof CalendarMonths
     */
    days?: Array<ProjectModelCurrentSelectionCalendarDay>;
}

/**
 * Check if a given object implements the CalendarMonths interface.
 */
export function instanceOfCalendarMonths(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarMonthsFromJSON(json: any): CalendarMonths {
    return CalendarMonthsFromJSONTyped(json, false);
}

export function CalendarMonthsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarMonths {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': !exists(json, 'month') ? undefined : json['month'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'days': !exists(json, 'days') ? undefined : ((json['days'] as Array<any>).map(ProjectModelCurrentSelectionCalendarDayFromJSON)),
    };
}

export function CalendarMonthsToJSON(value?: CalendarMonths | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'year': value.year,
        'days': value.days === undefined ? undefined : ((value.days as Array<any>).map(ProjectModelCurrentSelectionCalendarDayToJSON)),
    };
}

