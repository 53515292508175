/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalParticipantFormValuesDto
 */
export interface ExternalParticipantFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    externalParticipantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipantFormValuesDto
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalParticipantFormValuesDto
     */
    hadAdminRights?: boolean;
}

/**
 * Check if a given object implements the ExternalParticipantFormValuesDto interface.
 */
export function instanceOfExternalParticipantFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExternalParticipantFormValuesDtoFromJSON(json: any): ExternalParticipantFormValuesDto {
    return ExternalParticipantFormValuesDtoFromJSONTyped(json, false);
}

export function ExternalParticipantFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalParticipantFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalParticipantId': !exists(json, 'externalParticipantId') ? undefined : json['externalParticipantId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'hadAdminRights': !exists(json, 'hadAdminRights') ? undefined : json['hadAdminRights'],
    };
}

export function ExternalParticipantFormValuesDtoToJSON(value?: ExternalParticipantFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalParticipantId': value.externalParticipantId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'occupation': value.occupation,
        'phone': value.phone,
        'email': value.email,
        'hadAdminRights': value.hadAdminRights,
    };
}

