/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingModelsFormValuesDto
 */
export interface BookingModelsFormValuesDto {
    /**
     * 
     * @type {boolean}
     * @memberof BookingModelsFormValuesDto
     */
    showLogo?: boolean;
}

/**
 * Check if a given object implements the BookingModelsFormValuesDto interface.
 */
export function instanceOfBookingModelsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingModelsFormValuesDtoFromJSON(json: any): BookingModelsFormValuesDto {
    return BookingModelsFormValuesDtoFromJSONTyped(json, false);
}

export function BookingModelsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingModelsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'showLogo': !exists(json, 'showLogo') ? undefined : json['showLogo'],
    };
}

export function BookingModelsFormValuesDtoToJSON(value?: BookingModelsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'showLogo': value.showLogo,
    };
}

