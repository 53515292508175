/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';

/**
 * 
 * @export
 * @interface ChangeEmployeeCommand
 */
export interface ChangeEmployeeCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmployeeCommand
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {Employee}
     * @memberof ChangeEmployeeCommand
     */
    updatedEmployee?: Employee;
}

/**
 * Check if a given object implements the ChangeEmployeeCommand interface.
 */
export function instanceOfChangeEmployeeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeEmployeeCommandFromJSON(json: any): ChangeEmployeeCommand {
    return ChangeEmployeeCommandFromJSONTyped(json, false);
}

export function ChangeEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'updatedEmployee': !exists(json, 'updatedEmployee') ? undefined : EmployeeFromJSON(json['updatedEmployee']),
    };
}

export function ChangeEmployeeCommandToJSON(value?: ChangeEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'updatedEmployee': EmployeeToJSON(value.updatedEmployee),
    };
}

