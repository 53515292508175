/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { InstrumentFormValuesDto } from './InstrumentFormValuesDto';
import {
    InstrumentFormValuesDtoFromJSON,
    InstrumentFormValuesDtoFromJSONTyped,
    InstrumentFormValuesDtoToJSON,
} from './InstrumentFormValuesDto';

/**
 * 
 * @export
 * @interface InstrumentsFormValuesDto
 */
export interface InstrumentsFormValuesDto {
    /**
     * 
     * @type {Array<InstrumentFormValuesDto>}
     * @memberof InstrumentsFormValuesDto
     */
    instruments?: Array<InstrumentFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof InstrumentsFormValuesDto
     */
    instrumentOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstrumentsFormValuesDto
     */
    instrumentLevelOptions?: Array<string>;
}

/**
 * Check if a given object implements the InstrumentsFormValuesDto interface.
 */
export function instanceOfInstrumentsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstrumentsFormValuesDtoFromJSON(json: any): InstrumentsFormValuesDto {
    return InstrumentsFormValuesDtoFromJSONTyped(json, false);
}

export function InstrumentsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstrumentsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instruments': !exists(json, 'instruments') ? undefined : ((json['instruments'] as Array<any>).map(InstrumentFormValuesDtoFromJSON)),
        'instrumentOptions': !exists(json, 'instrumentOptions') ? undefined : ((json['instrumentOptions'] as Array<any>).map(AdminValueFromJSON)),
        'instrumentLevelOptions': !exists(json, 'instrumentLevelOptions') ? undefined : json['instrumentLevelOptions'],
    };
}

export function InstrumentsFormValuesDtoToJSON(value?: InstrumentsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instruments': value.instruments === undefined ? undefined : ((value.instruments as Array<any>).map(InstrumentFormValuesDtoToJSON)),
        'instrumentOptions': value.instrumentOptions === undefined ? undefined : ((value.instrumentOptions as Array<any>).map(AdminValueToJSON)),
        'instrumentLevelOptions': value.instrumentLevelOptions,
    };
}

