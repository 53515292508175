/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CmUnit } from './CmUnit';
import {
    CmUnitFromJSON,
    CmUnitFromJSONTyped,
    CmUnitToJSON,
} from './CmUnit';

/**
 * 
 * @export
 * @interface CastingBody
 */
export interface CastingBody {
    /**
     * 
     * @type {CmUnit}
     * @memberof CastingBody
     */
    height: CmUnit;
    /**
     * 
     * @type {CmUnit}
     * @memberof CastingBody
     */
    chestCircumference: CmUnit;
    /**
     * 
     * @type {CmUnit}
     * @memberof CastingBody
     */
    waistCircumference: CmUnit;
    /**
     * 
     * @type {CmUnit}
     * @memberof CastingBody
     */
    hipCircumference: CmUnit;
    /**
     * 
     * @type {string}
     * @memberof CastingBody
     */
    castingShow?: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBody
     */
    castingPhoto?: string;
}

/**
 * Check if a given object implements the CastingBody interface.
 */
export function instanceOfCastingBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "chestCircumference" in value;
    isInstance = isInstance && "waistCircumference" in value;
    isInstance = isInstance && "hipCircumference" in value;

    return isInstance;
}

export function CastingBodyFromJSON(json: any): CastingBody {
    return CastingBodyFromJSONTyped(json, false);
}

export function CastingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CastingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': CmUnitFromJSON(json['height']),
        'chestCircumference': CmUnitFromJSON(json['chestCircumference']),
        'waistCircumference': CmUnitFromJSON(json['waistCircumference']),
        'hipCircumference': CmUnitFromJSON(json['hipCircumference']),
        'castingShow': !exists(json, 'castingShow') ? undefined : json['castingShow'],
        'castingPhoto': !exists(json, 'castingPhoto') ? undefined : json['castingPhoto'],
    };
}

export function CastingBodyToJSON(value?: CastingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': CmUnitToJSON(value.height),
        'chestCircumference': CmUnitToJSON(value.chestCircumference),
        'waistCircumference': CmUnitToJSON(value.waistCircumference),
        'hipCircumference': CmUnitToJSON(value.hipCircumference),
        'castingShow': value.castingShow,
        'castingPhoto': value.castingPhoto,
    };
}

