/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatisticsDTO
 */
export interface StatisticsDTO {
    /**
     * 
     * @type {number}
     * @memberof StatisticsDTO
     */
    activeProjects?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsDTO
     */
    activeModels?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsDTO
     */
    externalModels?: number;
}

/**
 * Check if a given object implements the StatisticsDTO interface.
 */
export function instanceOfStatisticsDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatisticsDTOFromJSON(json: any): StatisticsDTO {
    return StatisticsDTOFromJSONTyped(json, false);
}

export function StatisticsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeProjects': !exists(json, 'activeProjects') ? undefined : json['activeProjects'],
        'activeModels': !exists(json, 'activeModels') ? undefined : json['activeModels'],
        'externalModels': !exists(json, 'externalModels') ? undefined : json['externalModels'],
    };
}

export function StatisticsDTOToJSON(value?: StatisticsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeProjects': value.activeProjects,
        'activeModels': value.activeModels,
        'externalModels': value.externalModels,
    };
}

