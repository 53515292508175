/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectModelBillingFormValuesDto
 */
export interface ProjectModelBillingFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingFormValuesDto
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    salary?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    overtime?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    overtimeCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    provision?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingFormValuesDto
     */
    buyoutDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingFormValuesDto
     */
    fee?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectModelBillingFormValuesDto
     */
    vat?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelBillingFormValuesDto
     */
    buyoutType?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelBillingFormValuesDto
     */
    dailyRate?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectModelBillingFormValuesDto
     */
    periodOptions?: Array<string>;
}

/**
 * Check if a given object implements the ProjectModelBillingFormValuesDto interface.
 */
export function instanceOfProjectModelBillingFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelBillingFormValuesDtoFromJSON(json: any): ProjectModelBillingFormValuesDto {
    return ProjectModelBillingFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectModelBillingFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelBillingFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'period': !exists(json, 'period') ? undefined : json['period'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
        'overtime': !exists(json, 'overtime') ? undefined : json['overtime'],
        'overtimeCosts': !exists(json, 'overtimeCosts') ? undefined : json['overtimeCosts'],
        'provision': !exists(json, 'provision') ? undefined : json['provision'],
        'buyoutDescription': !exists(json, 'buyoutDescription') ? undefined : json['buyoutDescription'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'periodOptions': !exists(json, 'periodOptions') ? undefined : json['periodOptions'],
    };
}

export function ProjectModelBillingFormValuesDtoToJSON(value?: ProjectModelBillingFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'period': value.period,
        'salary': value.salary,
        'overtime': value.overtime,
        'overtimeCosts': value.overtimeCosts,
        'provision': value.provision,
        'buyoutDescription': value.buyoutDescription,
        'fee': value.fee,
        'vat': value.vat,
        'buyoutValue': value.buyoutValue,
        'buyoutType': value.buyoutType,
        'dailyRate': value.dailyRate,
        'periodOptions': value.periodOptions,
    };
}

