/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgencyBookerNameFormValuesDto
 */
export interface AgencyBookerNameFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerNameFormValuesDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerNameFormValuesDto
     */
    lastName?: string;
}

/**
 * Check if a given object implements the AgencyBookerNameFormValuesDto interface.
 */
export function instanceOfAgencyBookerNameFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBookerNameFormValuesDtoFromJSON(json: any): AgencyBookerNameFormValuesDto {
    return AgencyBookerNameFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyBookerNameFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBookerNameFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function AgencyBookerNameFormValuesDtoToJSON(value?: AgencyBookerNameFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}

