/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectParticipantDataForMessenger } from './ProjectParticipantDataForMessenger';
import {
    ProjectParticipantDataForMessengerFromJSON,
    ProjectParticipantDataForMessengerFromJSONTyped,
    ProjectParticipantDataForMessengerToJSON,
} from './ProjectParticipantDataForMessenger';
import type { ProjectParticipantDataForMessengerByRole } from './ProjectParticipantDataForMessengerByRole';
import {
    ProjectParticipantDataForMessengerByRoleFromJSON,
    ProjectParticipantDataForMessengerByRoleFromJSONTyped,
    ProjectParticipantDataForMessengerByRoleToJSON,
} from './ProjectParticipantDataForMessengerByRole';

/**
 * 
 * @export
 * @interface ProjectParticipantDataForMessengerGrouped
 */
export interface ProjectParticipantDataForMessengerGrouped {
    /**
     * 
     * @type {Array<ProjectParticipantDataForMessengerByRole>}
     * @memberof ProjectParticipantDataForMessengerGrouped
     */
    models?: Array<ProjectParticipantDataForMessengerByRole>;
    /**
     * 
     * @type {Array<ProjectParticipantDataForMessenger>}
     * @memberof ProjectParticipantDataForMessengerGrouped
     */
    additional?: Array<ProjectParticipantDataForMessenger>;
    /**
     * 
     * @type {Array<ProjectParticipantDataForMessenger>}
     * @memberof ProjectParticipantDataForMessengerGrouped
     */
    external?: Array<ProjectParticipantDataForMessenger>;
}

/**
 * Check if a given object implements the ProjectParticipantDataForMessengerGrouped interface.
 */
export function instanceOfProjectParticipantDataForMessengerGrouped(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectParticipantDataForMessengerGroupedFromJSON(json: any): ProjectParticipantDataForMessengerGrouped {
    return ProjectParticipantDataForMessengerGroupedFromJSONTyped(json, false);
}

export function ProjectParticipantDataForMessengerGroupedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectParticipantDataForMessengerGrouped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(ProjectParticipantDataForMessengerByRoleFromJSON)),
        'additional': !exists(json, 'additional') ? undefined : ((json['additional'] as Array<any>).map(ProjectParticipantDataForMessengerFromJSON)),
        'external': !exists(json, 'external') ? undefined : ((json['external'] as Array<any>).map(ProjectParticipantDataForMessengerFromJSON)),
    };
}

export function ProjectParticipantDataForMessengerGroupedToJSON(value?: ProjectParticipantDataForMessengerGrouped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(ProjectParticipantDataForMessengerByRoleToJSON)),
        'additional': value.additional === undefined ? undefined : ((value.additional as Array<any>).map(ProjectParticipantDataForMessengerToJSON)),
        'external': value.external === undefined ? undefined : ((value.external as Array<any>).map(ProjectParticipantDataForMessengerToJSON)),
    };
}

