/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyActivityFieldsFormValuesDto } from './AgencyActivityFieldsFormValuesDto';
import {
    AgencyActivityFieldsFormValuesDtoFromJSON,
    AgencyActivityFieldsFormValuesDtoFromJSONTyped,
    AgencyActivityFieldsFormValuesDtoToJSON,
} from './AgencyActivityFieldsFormValuesDto';
import type { AgencyAddressFormValuesDto } from './AgencyAddressFormValuesDto';
import {
    AgencyAddressFormValuesDtoFromJSON,
    AgencyAddressFormValuesDtoFromJSONTyped,
    AgencyAddressFormValuesDtoToJSON,
} from './AgencyAddressFormValuesDto';
import type { AgencyBankInformationFormValuesDto } from './AgencyBankInformationFormValuesDto';
import {
    AgencyBankInformationFormValuesDtoFromJSON,
    AgencyBankInformationFormValuesDtoFromJSONTyped,
    AgencyBankInformationFormValuesDtoToJSON,
} from './AgencyBankInformationFormValuesDto';
import type { AgencyConditionsFormValuesDto } from './AgencyConditionsFormValuesDto';
import {
    AgencyConditionsFormValuesDtoFromJSON,
    AgencyConditionsFormValuesDtoFromJSONTyped,
    AgencyConditionsFormValuesDtoToJSON,
} from './AgencyConditionsFormValuesDto';
import type { AgencyContactFormValuesDto } from './AgencyContactFormValuesDto';
import {
    AgencyContactFormValuesDtoFromJSON,
    AgencyContactFormValuesDtoFromJSONTyped,
    AgencyContactFormValuesDtoToJSON,
} from './AgencyContactFormValuesDto';
import type { AgencyId } from './AgencyId';
import {
    AgencyIdFromJSON,
    AgencyIdFromJSONTyped,
    AgencyIdToJSON,
} from './AgencyId';
import type { AgencyNameFormValuesDto } from './AgencyNameFormValuesDto';
import {
    AgencyNameFormValuesDtoFromJSON,
    AgencyNameFormValuesDtoFromJSONTyped,
    AgencyNameFormValuesDtoToJSON,
} from './AgencyNameFormValuesDto';
import type { AgencyShortInfoFormValuesDto } from './AgencyShortInfoFormValuesDto';
import {
    AgencyShortInfoFormValuesDtoFromJSON,
    AgencyShortInfoFormValuesDtoFromJSONTyped,
    AgencyShortInfoFormValuesDtoToJSON,
} from './AgencyShortInfoFormValuesDto';
import type { AgencyTaxInformationFormValuesDto } from './AgencyTaxInformationFormValuesDto';
import {
    AgencyTaxInformationFormValuesDtoFromJSON,
    AgencyTaxInformationFormValuesDtoFromJSONTyped,
    AgencyTaxInformationFormValuesDtoToJSON,
} from './AgencyTaxInformationFormValuesDto';
import type { ProtocolsFormValuesDto } from './ProtocolsFormValuesDto';
import {
    ProtocolsFormValuesDtoFromJSON,
    ProtocolsFormValuesDtoFromJSONTyped,
    ProtocolsFormValuesDtoToJSON,
} from './ProtocolsFormValuesDto';

/**
 * 
 * @export
 * @interface AgencyFormValuesDto
 */
export interface AgencyFormValuesDto {
    /**
     * 
     * @type {AgencyId}
     * @memberof AgencyFormValuesDto
     */
    agencyId?: AgencyId;
    /**
     * 
     * @type {AgencyNameFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    name?: AgencyNameFormValuesDto;
    /**
     * 
     * @type {AgencyShortInfoFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    shortInfo?: AgencyShortInfoFormValuesDto;
    /**
     * 
     * @type {AgencyAddressFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    livingAddress?: AgencyAddressFormValuesDto;
    /**
     * 
     * @type {AgencyAddressFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    billingAddress?: AgencyAddressFormValuesDto;
    /**
     * 
     * @type {AgencyContactFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    contactInfo?: AgencyContactFormValuesDto;
    /**
     * 
     * @type {AgencyActivityFieldsFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    activityFields?: AgencyActivityFieldsFormValuesDto;
    /**
     * 
     * @type {AgencyBankInformationFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    bankInformation?: AgencyBankInformationFormValuesDto;
    /**
     * 
     * @type {AgencyTaxInformationFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    tax?: AgencyTaxInformationFormValuesDto;
    /**
     * 
     * @type {AgencyConditionsFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    conditions?: AgencyConditionsFormValuesDto;
    /**
     * 
     * @type {ProtocolsFormValuesDto}
     * @memberof AgencyFormValuesDto
     */
    protocol?: ProtocolsFormValuesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencyFormValuesDto
     */
    bookers?: Array<string>;
}

/**
 * Check if a given object implements the AgencyFormValuesDto interface.
 */
export function instanceOfAgencyFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyFormValuesDtoFromJSON(json: any): AgencyFormValuesDto {
    return AgencyFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agencyId') ? undefined : AgencyIdFromJSON(json['agencyId']),
        'name': !exists(json, 'name') ? undefined : AgencyNameFormValuesDtoFromJSON(json['name']),
        'shortInfo': !exists(json, 'shortInfo') ? undefined : AgencyShortInfoFormValuesDtoFromJSON(json['shortInfo']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AgencyAddressFormValuesDtoFromJSON(json['livingAddress']),
        'billingAddress': !exists(json, 'billingAddress') ? undefined : AgencyAddressFormValuesDtoFromJSON(json['billingAddress']),
        'contactInfo': !exists(json, 'contactInfo') ? undefined : AgencyContactFormValuesDtoFromJSON(json['contactInfo']),
        'activityFields': !exists(json, 'activityFields') ? undefined : AgencyActivityFieldsFormValuesDtoFromJSON(json['activityFields']),
        'bankInformation': !exists(json, 'bankInformation') ? undefined : AgencyBankInformationFormValuesDtoFromJSON(json['bankInformation']),
        'tax': !exists(json, 'tax') ? undefined : AgencyTaxInformationFormValuesDtoFromJSON(json['tax']),
        'conditions': !exists(json, 'conditions') ? undefined : AgencyConditionsFormValuesDtoFromJSON(json['conditions']),
        'protocol': !exists(json, 'protocol') ? undefined : ProtocolsFormValuesDtoFromJSON(json['protocol']),
        'bookers': !exists(json, 'bookers') ? undefined : json['bookers'],
    };
}

export function AgencyFormValuesDtoToJSON(value?: AgencyFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': AgencyIdToJSON(value.agencyId),
        'name': AgencyNameFormValuesDtoToJSON(value.name),
        'shortInfo': AgencyShortInfoFormValuesDtoToJSON(value.shortInfo),
        'livingAddress': AgencyAddressFormValuesDtoToJSON(value.livingAddress),
        'billingAddress': AgencyAddressFormValuesDtoToJSON(value.billingAddress),
        'contactInfo': AgencyContactFormValuesDtoToJSON(value.contactInfo),
        'activityFields': AgencyActivityFieldsFormValuesDtoToJSON(value.activityFields),
        'bankInformation': AgencyBankInformationFormValuesDtoToJSON(value.bankInformation),
        'tax': AgencyTaxInformationFormValuesDtoToJSON(value.tax),
        'conditions': AgencyConditionsFormValuesDtoToJSON(value.conditions),
        'protocol': ProtocolsFormValuesDtoToJSON(value.protocol),
        'bookers': value.bookers,
    };
}

