/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelProductionDetailsEvent } from './ProjectModelProductionDetailsEvent';
import {
    ProjectModelProductionDetailsEventFromJSON,
    ProjectModelProductionDetailsEventFromJSONTyped,
    ProjectModelProductionDetailsEventToJSON,
} from './ProjectModelProductionDetailsEvent';

/**
 * 
 * @export
 * @interface ProjectModelProductionDetails
 */
export interface ProjectModelProductionDetails {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetails
     */
    infoArrival?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetails
     */
    infoSleepOver?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelProductionDetails
     */
    infoAdditional?: string;
    /**
     * 
     * @type {Array<ProjectModelProductionDetailsEvent>}
     * @memberof ProjectModelProductionDetails
     */
    events: Array<ProjectModelProductionDetailsEvent>;
}

/**
 * Check if a given object implements the ProjectModelProductionDetails interface.
 */
export function instanceOfProjectModelProductionDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function ProjectModelProductionDetailsFromJSON(json: any): ProjectModelProductionDetails {
    return ProjectModelProductionDetailsFromJSONTyped(json, false);
}

export function ProjectModelProductionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelProductionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoArrival': !exists(json, 'infoArrival') ? undefined : json['infoArrival'],
        'infoSleepOver': !exists(json, 'infoSleepOver') ? undefined : json['infoSleepOver'],
        'infoAdditional': !exists(json, 'infoAdditional') ? undefined : json['infoAdditional'],
        'events': ((json['events'] as Array<any>).map(ProjectModelProductionDetailsEventFromJSON)),
    };
}

export function ProjectModelProductionDetailsToJSON(value?: ProjectModelProductionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoArrival': value.infoArrival,
        'infoSleepOver': value.infoSleepOver,
        'infoAdditional': value.infoAdditional,
        'events': ((value.events as Array<any>).map(ProjectModelProductionDetailsEventToJSON)),
    };
}

