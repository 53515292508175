/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalParticipantId } from './ExternalParticipantId';
import {
    ExternalParticipantIdFromJSON,
    ExternalParticipantIdFromJSONTyped,
    ExternalParticipantIdToJSON,
} from './ExternalParticipantId';

/**
 * 
 * @export
 * @interface ExternalParticipant
 */
export interface ExternalParticipant {
    /**
     * 
     * @type {ExternalParticipantId}
     * @memberof ExternalParticipant
     */
    externalParticipantId: ExternalParticipantId;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalParticipant
     */
    hadAdminRights?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalParticipant
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the ExternalParticipant interface.
 */
export function instanceOfExternalParticipant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalParticipantId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function ExternalParticipantFromJSON(json: any): ExternalParticipant {
    return ExternalParticipantFromJSONTyped(json, false);
}

export function ExternalParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalParticipant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalParticipantId': ExternalParticipantIdFromJSON(json['externalParticipantId']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'hadAdminRights': !exists(json, 'hadAdminRights') ? undefined : json['hadAdminRights'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ExternalParticipantToJSON(value?: ExternalParticipant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalParticipantId': ExternalParticipantIdToJSON(value.externalParticipantId),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'occupation': value.occupation,
        'phone': value.phone,
        'email': value.email,
        'hadAdminRights': value.hadAdminRights,
        'oauthId': value.oauthId,
    };
}

