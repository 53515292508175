/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioImage
 */
export interface PortfolioImage {
    /**
     * 
     * @type {string}
     * @memberof PortfolioImage
     */
    imageType?: PortfolioImageImageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioImage
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioImage
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioImage
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioImage
     */
    imageOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioImage
     */
    imageOrderTmp?: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioImage
     */
    imageReferenceName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioImage
     */
    isDeleted?: boolean;
}


/**
 * @export
 */
export const PortfolioImageImageTypeEnum = {
    Master: 'MASTER',
    Polaroid: 'POLAROID',
    Special: 'SPECIAL',
    Vita: 'VITA',
    Video: 'VIDEO',
    NotSupported: 'NOT_SUPPORTED'
} as const;
export type PortfolioImageImageTypeEnum = typeof PortfolioImageImageTypeEnum[keyof typeof PortfolioImageImageTypeEnum];


/**
 * Check if a given object implements the PortfolioImage interface.
 */
export function instanceOfPortfolioImage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PortfolioImageFromJSON(json: any): PortfolioImage {
    return PortfolioImageFromJSONTyped(json, false);
}

export function PortfolioImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageType': !exists(json, 'imageType') ? undefined : json['imageType'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'mimeType': !exists(json, 'mimeType') ? undefined : json['mimeType'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'imageOrder': !exists(json, 'imageOrder') ? undefined : json['imageOrder'],
        'imageOrderTmp': !exists(json, 'imageOrderTmp') ? undefined : json['imageOrderTmp'],
        'imageReferenceName': !exists(json, 'imageReferenceName') ? undefined : json['imageReferenceName'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function PortfolioImageToJSON(value?: PortfolioImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageType': value.imageType,
        'filename': value.filename,
        'mimeType': value.mimeType,
        'url': value.url,
        'imageOrder': value.imageOrder,
        'imageOrderTmp': value.imageOrderTmp,
        'imageReferenceName': value.imageReferenceName,
        'isDeleted': value.isDeleted,
    };
}

