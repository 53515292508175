/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyOutPerModelFormValuesDto } from './BuyOutPerModelFormValuesDto';
import {
    BuyOutPerModelFormValuesDtoFromJSON,
    BuyOutPerModelFormValuesDtoFromJSONTyped,
    BuyOutPerModelFormValuesDtoToJSON,
} from './BuyOutPerModelFormValuesDto';

/**
 * 
 * @export
 * @interface BuyOutPerModelListFormValuesDto
 */
export interface BuyOutPerModelListFormValuesDto {
    /**
     * 
     * @type {Array<BuyOutPerModelFormValuesDto>}
     * @memberof BuyOutPerModelListFormValuesDto
     */
    buyOutPerModelFormValuesDtoList?: Array<BuyOutPerModelFormValuesDto>;
}

/**
 * Check if a given object implements the BuyOutPerModelListFormValuesDto interface.
 */
export function instanceOfBuyOutPerModelListFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyOutPerModelListFormValuesDtoFromJSON(json: any): BuyOutPerModelListFormValuesDto {
    return BuyOutPerModelListFormValuesDtoFromJSONTyped(json, false);
}

export function BuyOutPerModelListFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyOutPerModelListFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyOutPerModelFormValuesDtoList': !exists(json, 'buyOutPerModelFormValuesDtoList') ? undefined : ((json['buyOutPerModelFormValuesDtoList'] as Array<any>).map(BuyOutPerModelFormValuesDtoFromJSON)),
    };
}

export function BuyOutPerModelListFormValuesDtoToJSON(value?: BuyOutPerModelListFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyOutPerModelFormValuesDtoList': value.buyOutPerModelFormValuesDtoList === undefined ? undefined : ((value.buyOutPerModelFormValuesDtoList as Array<any>).map(BuyOutPerModelFormValuesDtoToJSON)),
    };
}

