/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameDto
 */
export interface NameDto {
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    alias?: string;
}

/**
 * Check if a given object implements the NameDto interface.
 */
export function instanceOfNameDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "firstName" in value;

    return isInstance;
}

export function NameDtoFromJSON(json: any): NameDto {
    return NameDtoFromJSONTyped(json, false);
}

export function NameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firstName': json['firstName'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
    };
}

export function NameDtoToJSON(value?: NameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'alias': value.alias,
    };
}

