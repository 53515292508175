/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AgencyActivityFieldsFormValuesDto
 */
export interface AgencyActivityFieldsFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyActivityFieldsFormValuesDto
     */
    activityFieldOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyActivityFieldsFormValuesDto
     */
    activityFields?: Array<AdminValue>;
}

/**
 * Check if a given object implements the AgencyActivityFieldsFormValuesDto interface.
 */
export function instanceOfAgencyActivityFieldsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyActivityFieldsFormValuesDtoFromJSON(json: any): AgencyActivityFieldsFormValuesDto {
    return AgencyActivityFieldsFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyActivityFieldsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyActivityFieldsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityFieldOptions': !exists(json, 'activityFieldOptions') ? undefined : ((json['activityFieldOptions'] as Array<any>).map(AdminValueFromJSON)),
        'activityFields': !exists(json, 'activityFields') ? undefined : ((json['activityFields'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function AgencyActivityFieldsFormValuesDtoToJSON(value?: AgencyActivityFieldsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityFieldOptions': value.activityFieldOptions === undefined ? undefined : ((value.activityFieldOptions as Array<any>).map(AdminValueToJSON)),
        'activityFields': value.activityFields === undefined ? undefined : ((value.activityFields as Array<any>).map(AdminValueToJSON)),
    };
}

