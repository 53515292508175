/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { SpokenLanguageFormValuesDto } from './SpokenLanguageFormValuesDto';
import {
    SpokenLanguageFormValuesDtoFromJSON,
    SpokenLanguageFormValuesDtoFromJSONTyped,
    SpokenLanguageFormValuesDtoToJSON,
} from './SpokenLanguageFormValuesDto';

/**
 * 
 * @export
 * @interface SpokenLanguagesFormValuesDto
 */
export interface SpokenLanguagesFormValuesDto {
    /**
     * 
     * @type {Array<SpokenLanguageFormValuesDto>}
     * @memberof SpokenLanguagesFormValuesDto
     */
    spokenLanguages?: Array<SpokenLanguageFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof SpokenLanguagesFormValuesDto
     */
    spokenLanguageOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpokenLanguagesFormValuesDto
     */
    spokenLanguageLevelOptions?: Array<string>;
}

/**
 * Check if a given object implements the SpokenLanguagesFormValuesDto interface.
 */
export function instanceOfSpokenLanguagesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpokenLanguagesFormValuesDtoFromJSON(json: any): SpokenLanguagesFormValuesDto {
    return SpokenLanguagesFormValuesDtoFromJSONTyped(json, false);
}

export function SpokenLanguagesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpokenLanguagesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : ((json['spokenLanguages'] as Array<any>).map(SpokenLanguageFormValuesDtoFromJSON)),
        'spokenLanguageOptions': !exists(json, 'spokenLanguageOptions') ? undefined : ((json['spokenLanguageOptions'] as Array<any>).map(AdminValueFromJSON)),
        'spokenLanguageLevelOptions': !exists(json, 'spokenLanguageLevelOptions') ? undefined : json['spokenLanguageLevelOptions'],
    };
}

export function SpokenLanguagesFormValuesDtoToJSON(value?: SpokenLanguagesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spokenLanguages': value.spokenLanguages === undefined ? undefined : ((value.spokenLanguages as Array<any>).map(SpokenLanguageFormValuesDtoToJSON)),
        'spokenLanguageOptions': value.spokenLanguageOptions === undefined ? undefined : ((value.spokenLanguageOptions as Array<any>).map(AdminValueToJSON)),
        'spokenLanguageLevelOptions': value.spokenLanguageLevelOptions,
    };
}

