/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActingExperience { 
    level: ActingExperience.LevelEnum;
    experiences: string;
}
export namespace ActingExperience {
    export type LevelEnum = 'PROFESSIONAL' | 'SEMI_PROFESSIONAL' | 'COMMERCIALS';
    export const LevelEnum = {
        Professional: 'PROFESSIONAL' as LevelEnum,
        SemiProfessional: 'SEMI_PROFESSIONAL' as LevelEnum,
        Commercials: 'COMMERCIALS' as LevelEnum
    };
}


