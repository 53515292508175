<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'brody-logo-black' : 'brody-logo-white'}}.png"
      alt="logo">
    <span>EBooker</span>
  </a>

  <div class="model-search" style="margin-left: 70px">
    <p-autoComplete
      placeholder="Modelsuche"
      [(ngModel)]="selectedModelsAdvanced"
      [suggestions]="filteredModels"
      (completeMethod)="filterModels($event)"
      (onSelect)="selectModel($event)"
      field="firstName">
      <ng-template let-model pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img
            [src]="model.imageUrl"
            style="width: 18px" />
          <div>{{ model.firstName }} {{ model.lastName }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
          (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu flex align-items-center"
       [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
    <div class="flex align-items-center mr-5">
      <button class="p-link layout-topbar-button"><i class="pi pi-user"></i><span>Profile</span></button>
      <span>{{name}}</span>
      <!--            <i pbadge="" value="2" class="p-element pi pi-bell ml-3 p-text-secondary p-overlay-badge" style="font-size: 1.5rem;"><span id="pr_id_8_badge" class="p-badge p-component p-badge-no-gutter">2</span></i>-->
      <!--            <i pbadge="" severity="danger" class="p-element pi pi-calendar ml-4 p-text-secondary p-overlay-badge" style="font-size: 1.5rem;"><span id="pr_id_9_badge" class="p-badge p-component p-badge-danger">10+</span></i>-->
      <i (click)="openMessages()" pbadge="" severity="danger"
         class="p-element pi pi-envelope ml-4 mr-2 p-text-secondary p-overlay-badge" style="font-size: 1.5rem;"><span
        *ngIf="numberOfNewMessages" id="pr_id_10_badge" class="p-badge p-component p-badge-danger">{{numberOfNewMessages}}</span></i>
    </div>
    <a href="#" (click)="toGerman($event)">Deutsch</a> | <a href="#" (click)="toEnglish($event)">English</a>
    <div>
      <button class="p-link layout-topbar-button" (click)="handleLogout($event)"><i class="pi pi-sign-out"></i><span>Logout</span>
      </button>
    </div>
  </div>
</div>
