/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface TwoLayerFilterDto
 */
export interface TwoLayerFilterDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof TwoLayerFilterDto
     */
    value?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof TwoLayerFilterDto
     */
    level?: string;
}

/**
 * Check if a given object implements the TwoLayerFilterDto interface.
 */
export function instanceOfTwoLayerFilterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TwoLayerFilterDtoFromJSON(json: any): TwoLayerFilterDto {
    return TwoLayerFilterDtoFromJSONTyped(json, false);
}

export function TwoLayerFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwoLayerFilterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : AdminValueFromJSON(json['value']),
        'level': !exists(json, 'level') ? undefined : json['level'],
    };
}

export function TwoLayerFilterDtoToJSON(value?: TwoLayerFilterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': AdminValueToJSON(value.value),
        'level': value.level,
    };
}

