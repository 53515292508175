/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelPdfRequestDto
 */
export interface ModelPdfRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelPdfRequestDto
     */
    modelImages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ModelPdfRequestDto
     */
    layout?: number;
}

/**
 * Check if a given object implements the ModelPdfRequestDto interface.
 */
export function instanceOfModelPdfRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPdfRequestDtoFromJSON(json: any): ModelPdfRequestDto {
    return ModelPdfRequestDtoFromJSONTyped(json, false);
}

export function ModelPdfRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPdfRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelImages': !exists(json, 'modelImages') ? undefined : json['modelImages'],
        'layout': !exists(json, 'layout') ? undefined : json['layout'],
    };
}

export function ModelPdfRequestDtoToJSON(value?: ModelPdfRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelImages': value.modelImages,
        'layout': value.layout,
    };
}

