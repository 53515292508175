/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ProjectRoleFormValuesDto } from './ProjectRoleFormValuesDto';
import {
    ProjectRoleFormValuesDtoFromJSON,
    ProjectRoleFormValuesDtoFromJSONTyped,
    ProjectRoleFormValuesDtoToJSON,
} from './ProjectRoleFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectRolesFormValuesDto
 */
export interface ProjectRolesFormValuesDto {
    /**
     * 
     * @type {Array<ProjectRoleFormValuesDto>}
     * @memberof ProjectRolesFormValuesDto
     */
    roles?: Array<ProjectRoleFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRolesFormValuesDto
     */
    availableSexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRolesFormValuesDto
     */
    availableHairColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRolesFormValuesDto
     */
    availableCategories?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRolesFormValuesDto
     */
    availableDurations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRolesFormValuesDto
     */
    availableBuyoutTypes?: Array<string>;
}

/**
 * Check if a given object implements the ProjectRolesFormValuesDto interface.
 */
export function instanceOfProjectRolesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectRolesFormValuesDtoFromJSON(json: any): ProjectRolesFormValuesDto {
    return ProjectRolesFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectRolesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRolesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(ProjectRoleFormValuesDtoFromJSON)),
        'availableSexes': !exists(json, 'availableSexes') ? undefined : ((json['availableSexes'] as Array<any>).map(AdminValueFromJSON)),
        'availableHairColors': !exists(json, 'availableHairColors') ? undefined : ((json['availableHairColors'] as Array<any>).map(AdminValueFromJSON)),
        'availableCategories': !exists(json, 'availableCategories') ? undefined : ((json['availableCategories'] as Array<any>).map(AdminValueFromJSON)),
        'availableDurations': !exists(json, 'availableDurations') ? undefined : json['availableDurations'],
        'availableBuyoutTypes': !exists(json, 'availableBuyoutTypes') ? undefined : json['availableBuyoutTypes'],
    };
}

export function ProjectRolesFormValuesDtoToJSON(value?: ProjectRolesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(ProjectRoleFormValuesDtoToJSON)),
        'availableSexes': value.availableSexes === undefined ? undefined : ((value.availableSexes as Array<any>).map(AdminValueToJSON)),
        'availableHairColors': value.availableHairColors === undefined ? undefined : ((value.availableHairColors as Array<any>).map(AdminValueToJSON)),
        'availableCategories': value.availableCategories === undefined ? undefined : ((value.availableCategories as Array<any>).map(AdminValueToJSON)),
        'availableDurations': value.availableDurations,
        'availableBuyoutTypes': value.availableBuyoutTypes,
    };
}

