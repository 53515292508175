/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelAgency
 */
export interface ModelAgency {
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    agencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    bookerLastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAgency
     */
    onStay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAgency
     */
    contactPerson?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAgency
     */
    status?: ModelAgencyStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ModelAgency
     */
    beginDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelAgency
     */
    endDate?: Date;
}


/**
 * @export
 */
export const ModelAgencyStatusEnum = {
    None: 'NONE',
    OnCommission: 'ON_COMMISSION',
    CommissionPlaced: 'COMMISSION_PLACED',
    SelfManaged: 'SELF_MANAGED'
} as const;
export type ModelAgencyStatusEnum = typeof ModelAgencyStatusEnum[keyof typeof ModelAgencyStatusEnum];


/**
 * Check if a given object implements the ModelAgency interface.
 */
export function instanceOfModelAgency(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelAgencyFromJSON(json: any): ModelAgency {
    return ModelAgencyFromJSONTyped(json, false);
}

export function ModelAgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelAgency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
        'agencyName': !exists(json, 'agencyName') ? undefined : json['agencyName'],
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
        'onStay': !exists(json, 'onStay') ? undefined : json['onStay'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'beginDate': !exists(json, 'beginDate') ? undefined : (new Date(json['beginDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
    };
}

export function ModelAgencyToJSON(value?: ModelAgency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': value.agencyId,
        'agencyName': value.agencyName,
        'bookerId': value.bookerId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
        'onStay': value.onStay,
        'contactPerson': value.contactPerson,
        'status': value.status,
        'beginDate': value.beginDate === undefined ? undefined : (value.beginDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
    };
}

