/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerAndEmployeesForProjectsDto } from './CustomerAndEmployeesForProjectsDto';
import {
    CustomerAndEmployeesForProjectsDtoFromJSON,
    CustomerAndEmployeesForProjectsDtoFromJSONTyped,
    CustomerAndEmployeesForProjectsDtoToJSON,
} from './CustomerAndEmployeesForProjectsDto';

/**
 * 
 * @export
 * @interface CustomerAndEmployeesListForProjectsDto
 */
export interface CustomerAndEmployeesListForProjectsDto {
    /**
     * 
     * @type {Array<CustomerAndEmployeesForProjectsDto>}
     * @memberof CustomerAndEmployeesListForProjectsDto
     */
    customerAndEmployeesForProjectsDtoList?: Array<CustomerAndEmployeesForProjectsDto>;
}

/**
 * Check if a given object implements the CustomerAndEmployeesListForProjectsDto interface.
 */
export function instanceOfCustomerAndEmployeesListForProjectsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerAndEmployeesListForProjectsDtoFromJSON(json: any): CustomerAndEmployeesListForProjectsDto {
    return CustomerAndEmployeesListForProjectsDtoFromJSONTyped(json, false);
}

export function CustomerAndEmployeesListForProjectsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAndEmployeesListForProjectsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerAndEmployeesForProjectsDtoList': !exists(json, 'customerAndEmployeesForProjectsDtoList') ? undefined : ((json['customerAndEmployeesForProjectsDtoList'] as Array<any>).map(CustomerAndEmployeesForProjectsDtoFromJSON)),
    };
}

export function CustomerAndEmployeesListForProjectsDtoToJSON(value?: CustomerAndEmployeesListForProjectsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerAndEmployeesForProjectsDtoList': value.customerAndEmployeesForProjectsDtoList === undefined ? undefined : ((value.customerAndEmployeesForProjectsDtoList as Array<any>).map(CustomerAndEmployeesForProjectsDtoToJSON)),
    };
}

