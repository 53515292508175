/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobHistoryFormValuesDto
 */
export interface JobHistoryFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof JobHistoryFormValuesDto
     */
    project?: string;
    /**
     * 
     * @type {Date}
     * @memberof JobHistoryFormValuesDto
     */
    billReceived?: Date;
}

/**
 * Check if a given object implements the JobHistoryFormValuesDto interface.
 */
export function instanceOfJobHistoryFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobHistoryFormValuesDtoFromJSON(json: any): JobHistoryFormValuesDto {
    return JobHistoryFormValuesDtoFromJSONTyped(json, false);
}

export function JobHistoryFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobHistoryFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'project': !exists(json, 'project') ? undefined : json['project'],
        'billReceived': !exists(json, 'billReceived') ? undefined : (new Date(json['billReceived'])),
    };
}

export function JobHistoryFormValuesDtoToJSON(value?: JobHistoryFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project': value.project,
        'billReceived': value.billReceived === undefined ? undefined : (value.billReceived.toISOString().substr(0,10)),
    };
}

