/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelFormValuesDto } from './ProjectModelFormValuesDto';
import {
    ProjectModelFormValuesDtoFromJSON,
    ProjectModelFormValuesDtoFromJSONTyped,
    ProjectModelFormValuesDtoToJSON,
} from './ProjectModelFormValuesDto';

/**
 * 
 * @export
 * @interface AddModelToProjectRoleResponseDto
 */
export interface AddModelToProjectRoleResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddModelToProjectRoleResponseDto
     */
    modelIds: Array<string>;
    /**
     * 
     * @type {ProjectModelFormValuesDto}
     * @memberof AddModelToProjectRoleResponseDto
     */
    projectModel: ProjectModelFormValuesDto;
}

/**
 * Check if a given object implements the AddModelToProjectRoleResponseDto interface.
 */
export function instanceOfAddModelToProjectRoleResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelIds" in value;
    isInstance = isInstance && "projectModel" in value;

    return isInstance;
}

export function AddModelToProjectRoleResponseDtoFromJSON(json: any): AddModelToProjectRoleResponseDto {
    return AddModelToProjectRoleResponseDtoFromJSONTyped(json, false);
}

export function AddModelToProjectRoleResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddModelToProjectRoleResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelIds': json['modelIds'],
        'projectModel': ProjectModelFormValuesDtoFromJSON(json['projectModel']),
    };
}

export function AddModelToProjectRoleResponseDtoToJSON(value?: AddModelToProjectRoleResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelIds': value.modelIds,
        'projectModel': ProjectModelFormValuesDtoToJSON(value.projectModel),
    };
}

