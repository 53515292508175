<div class="conversation-list-box">
    <div *ngIf="!isFiltered">
        <ul *ngFor="let conversation of conversationNameList" class="conversation-list" >
            <li class="conversation-item"
                (click)="onSelectConversation(conversation)"
                [class.selected]="conversation.conversationId === conversationId"
                [ngClass]="{'new-message': conversation.hasNewMessages}"
            >
                <div>
                    <h6 class="conversationTitle">{{ conversation.conversationTitle }}</h6>
                    <span class="participantSpan">{{ conversation.participantNames}}</span>
                </div>
                <button class="delete-button" (click)="onConversationDelete(conversation.conversationId)">X</button>
            </li>
        </ul>
    </div>
    <div  *ngIf="filteredTitleList.length >= 1 && isFiltered">
        <div class="section-wrapper">
            <p class="section-title title-section">{{'CONVERSATION.LIST.FILTER.TITLE.label' | translate}}</p>
            <div class="section-line"></div>
        </div>
        <ul *ngFor="let conversation of filteredTitleList" class="conversation-list">
            <li class="conversation-item" (click)="onSelectConversation(conversation)" [class.selected]="conversation.conversationId === conversationId">
                <div>
                    <h6 class="conversationTitle">{{ conversation.conversationTitle }}</h6>
                    <span class="participantSpan">{{ conversation.participantNames}}</span>
                </div>
                <button class="delete-button" (click)="onConversationDelete(conversation.conversationId)">X</button>
            </li>
        </ul>
    </div>
    <div  *ngIf="filteredParticipantList.length >= 1 && isFiltered">
        <div class="section-wrapper">
            <p class="section-title title-section">{{'CONVERSATION.LIST.FILTER.PARTICIPANT.label' | translate}}</p>
            <div class="section-line"></div>
        </div>
        <ul *ngFor="let conversation of filteredParticipantList" class="conversation-list">
            <li class="conversation-item" (click)="onSelectConversation(conversation)" [class.selected]="conversation.conversationId === conversationId">
                <div>
                    <h6 class="conversationTitle">{{ conversation.conversationTitle }}</h6>
                    <span class="participantSpan">{{ conversation.participantNames}}</span>
                </div>
                <button class="delete-button" (click)="onConversationDelete(conversation.conversationId)">X</button>
            </li>
        </ul>
    </div>
</div>


