/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgencyNamesForModelsDto
 */
export interface AgencyNamesForModelsDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyNamesForModelsDto
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyNamesForModelsDto
     */
    agencyName?: string;
}

/**
 * Check if a given object implements the AgencyNamesForModelsDto interface.
 */
export function instanceOfAgencyNamesForModelsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyNamesForModelsDtoFromJSON(json: any): AgencyNamesForModelsDto {
    return AgencyNamesForModelsDtoFromJSONTyped(json, false);
}

export function AgencyNamesForModelsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyNamesForModelsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
        'agencyName': !exists(json, 'agencyName') ? undefined : json['agencyName'],
    };
}

export function AgencyNamesForModelsDtoToJSON(value?: AgencyNamesForModelsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': value.agencyId,
        'agencyName': value.agencyName,
    };
}

