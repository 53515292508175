/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProtocolFormValuesDto } from './ProtocolFormValuesDto';
import {
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoFromJSONTyped,
    ProtocolFormValuesDtoToJSON,
} from './ProtocolFormValuesDto';

/**
 * 
 * @export
 * @interface ModelProtocolEntryCreateRequestDto
 */
export interface ModelProtocolEntryCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ModelProtocolEntryCreateRequestDto
     */
    modelId: string;
    /**
     * 
     * @type {ProtocolFormValuesDto}
     * @memberof ModelProtocolEntryCreateRequestDto
     */
    protocolFormValuesDto: ProtocolFormValuesDto;
}

/**
 * Check if a given object implements the ModelProtocolEntryCreateRequestDto interface.
 */
export function instanceOfModelProtocolEntryCreateRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "protocolFormValuesDto" in value;

    return isInstance;
}

export function ModelProtocolEntryCreateRequestDtoFromJSON(json: any): ModelProtocolEntryCreateRequestDto {
    return ModelProtocolEntryCreateRequestDtoFromJSONTyped(json, false);
}

export function ModelProtocolEntryCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelProtocolEntryCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'protocolFormValuesDto': ProtocolFormValuesDtoFromJSON(json['protocolFormValuesDto']),
    };
}

export function ModelProtocolEntryCreateRequestDtoToJSON(value?: ModelProtocolEntryCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'protocolFormValuesDto': ProtocolFormValuesDtoToJSON(value.protocolFormValuesDto),
    };
}

