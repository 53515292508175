/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeProjectModelRequestDto
 */
export interface ChangeProjectModelRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeProjectModelRequestDto
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ChangeProjectModelRequestDto
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeProjectModelRequestDto
     */
    salary?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeProjectModelRequestDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProjectModelRequestDto
     */
    buyoutType?: string;
    /**
     * 
     * @type {number}
     * @memberof ChangeProjectModelRequestDto
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeProjectModelRequestDto
     */
    externalNote?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeProjectModelRequestDto
     */
    language?: string;
}

/**
 * Check if a given object implements the ChangeProjectModelRequestDto interface.
 */
export function instanceOfChangeProjectModelRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeProjectModelRequestDtoFromJSON(json: any): ChangeProjectModelRequestDto {
    return ChangeProjectModelRequestDtoFromJSONTyped(json, false);
}

export function ChangeProjectModelRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeProjectModelRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'externalNote': !exists(json, 'externalNote') ? undefined : json['externalNote'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function ChangeProjectModelRequestDtoToJSON(value?: ChangeProjectModelRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'dailyRate': value.dailyRate,
        'salary': value.salary,
        'note': value.note,
        'buyoutType': value.buyoutType,
        'buyoutValue': value.buyoutValue,
        'externalNote': value.externalNote,
        'language': value.language,
    };
}

