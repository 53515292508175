/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChargeableProject
 */
export interface ChargeableProject {
    /**
     * 
     * @type {string}
     * @memberof ChargeableProject
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProject
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProject
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProject
     */
    roleId: string;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProject
     */
    productionDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ChargeableProject
     */
    paid?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProject
     */
    datePaid?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProject
     */
    billCreated?: Date;
    /**
     * 
     * @type {number}
     * @memberof ChargeableProject
     */
    billId?: number;
}

/**
 * Check if a given object implements the ChargeableProject interface.
 */
export function instanceOfChargeableProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "roleId" in value;

    return isInstance;
}

export function ChargeableProjectFromJSON(json: any): ChargeableProject {
    return ChargeableProjectFromJSONTyped(json, false);
}

export function ChargeableProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeableProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'projectId': json['projectId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'roleId': json['roleId'],
        'productionDate': !exists(json, 'productionDate') ? undefined : (new Date(json['productionDate'])),
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'datePaid': !exists(json, 'datePaid') ? undefined : (new Date(json['datePaid'])),
        'billCreated': !exists(json, 'billCreated') ? undefined : (new Date(json['billCreated'])),
        'billId': !exists(json, 'billId') ? undefined : json['billId'],
    };
}

export function ChargeableProjectToJSON(value?: ChargeableProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectName': value.projectName,
        'projectId': value.projectId,
        'roleName': value.roleName,
        'roleId': value.roleId,
        'productionDate': value.productionDate === undefined ? undefined : (value.productionDate.toISOString().substr(0,10)),
        'paid': value.paid,
        'datePaid': value.datePaid === undefined ? undefined : (value.datePaid.toISOString().substr(0,10)),
        'billCreated': value.billCreated === undefined ? undefined : (value.billCreated.toISOString().substr(0,10)),
        'billId': value.billId,
    };
}

