/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ConditionsFormValuesDto
 */
export interface ConditionsFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ConditionsFormValuesDto
     */
    currency?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConditionsFormValuesDto
     */
    currencyOptions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConditionsFormValuesDto
     */
    commission?: string;
    /**
     * 
     * @type {number}
     * @memberof ConditionsFormValuesDto
     */
    service?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionsFormValuesDto
     */
    mediation?: number;
    /**
     * 
     * @type {AdminValue}
     * @memberof ConditionsFormValuesDto
     */
    paymentMethod?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ConditionsFormValuesDto
     */
    paymentMethodOptions?: Array<AdminValue>;
    /**
     * 
     * @type {number}
     * @memberof ConditionsFormValuesDto
     */
    firstWarning?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionsFormValuesDto
     */
    secondWarning?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionsFormValuesDto
     */
    thirdWarning?: number;
}

/**
 * Check if a given object implements the ConditionsFormValuesDto interface.
 */
export function instanceOfConditionsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConditionsFormValuesDtoFromJSON(json: any): ConditionsFormValuesDto {
    return ConditionsFormValuesDtoFromJSONTyped(json, false);
}

export function ConditionsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'currencyOptions': !exists(json, 'currencyOptions') ? undefined : json['currencyOptions'],
        'commission': !exists(json, 'commission') ? undefined : json['commission'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'mediation': !exists(json, 'mediation') ? undefined : json['mediation'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : AdminValueFromJSON(json['paymentMethod']),
        'paymentMethodOptions': !exists(json, 'paymentMethodOptions') ? undefined : ((json['paymentMethodOptions'] as Array<any>).map(AdminValueFromJSON)),
        'firstWarning': !exists(json, 'firstWarning') ? undefined : json['firstWarning'],
        'secondWarning': !exists(json, 'secondWarning') ? undefined : json['secondWarning'],
        'thirdWarning': !exists(json, 'thirdWarning') ? undefined : json['thirdWarning'],
    };
}

export function ConditionsFormValuesDtoToJSON(value?: ConditionsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'currencyOptions': value.currencyOptions,
        'commission': value.commission,
        'service': value.service,
        'mediation': value.mediation,
        'paymentMethod': AdminValueToJSON(value.paymentMethod),
        'paymentMethodOptions': value.paymentMethodOptions === undefined ? undefined : ((value.paymentMethodOptions as Array<any>).map(AdminValueToJSON)),
        'firstWarning': value.firstWarning,
        'secondWarning': value.secondWarning,
        'thirdWarning': value.thirdWarning,
    };
}

