/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectBillingSumFormValuesDto
 */
export interface ProjectBillingSumFormValuesDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    modelsSum?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    flatCharge?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    agentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    agentFeePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    serviceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    serviceFeePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    subTotalOne?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    travelCostModelsTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    extraCostsTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    subTotalTwo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    vatTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingSumFormValuesDto
     */
    finalSum?: number;
}

/**
 * Check if a given object implements the ProjectBillingSumFormValuesDto interface.
 */
export function instanceOfProjectBillingSumFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectBillingSumFormValuesDtoFromJSON(json: any): ProjectBillingSumFormValuesDto {
    return ProjectBillingSumFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectBillingSumFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBillingSumFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelsSum': !exists(json, 'modelsSum') ? undefined : json['modelsSum'],
        'flatCharge': !exists(json, 'flatCharge') ? undefined : json['flatCharge'],
        'agentFee': !exists(json, 'agentFee') ? undefined : json['agentFee'],
        'agentFeePercent': !exists(json, 'agentFeePercent') ? undefined : json['agentFeePercent'],
        'serviceFee': !exists(json, 'serviceFee') ? undefined : json['serviceFee'],
        'serviceFeePercent': !exists(json, 'serviceFeePercent') ? undefined : json['serviceFeePercent'],
        'subTotalOne': !exists(json, 'subTotalOne') ? undefined : json['subTotalOne'],
        'travelCostModelsTotal': !exists(json, 'travelCostModelsTotal') ? undefined : json['travelCostModelsTotal'],
        'extraCostsTotal': !exists(json, 'extraCostsTotal') ? undefined : json['extraCostsTotal'],
        'subTotalTwo': !exists(json, 'subTotalTwo') ? undefined : json['subTotalTwo'],
        'vatTotal': !exists(json, 'vatTotal') ? undefined : json['vatTotal'],
        'finalSum': !exists(json, 'finalSum') ? undefined : json['finalSum'],
    };
}

export function ProjectBillingSumFormValuesDtoToJSON(value?: ProjectBillingSumFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelsSum': value.modelsSum,
        'flatCharge': value.flatCharge,
        'agentFee': value.agentFee,
        'agentFeePercent': value.agentFeePercent,
        'serviceFee': value.serviceFee,
        'serviceFeePercent': value.serviceFeePercent,
        'subTotalOne': value.subTotalOne,
        'travelCostModelsTotal': value.travelCostModelsTotal,
        'extraCostsTotal': value.extraCostsTotal,
        'subTotalTwo': value.subTotalTwo,
        'vatTotal': value.vatTotal,
        'finalSum': value.finalSum,
    };
}

