import {Component, ElementRef, Input, ViewChild} from "@angular/core";

@Component({
    selector: 'app-conversation-filter',
    templateUrl: './conversationFilter.component.html',
    styleUrls: ['./conversationFilter.styles.css']
})

export class ConversationFilterComponent {
    @Input() onFilterInput!: (input: string) => void
    @Input() onResetFilterInput!:()=>void
    @ViewChild("filterInput") filterInput!: ElementRef;
    resetFilter(){
        this.onResetFilterInput()
        this.filterInput.nativeElement.value = '';
    }
}