/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDataResponseDto
 */
export interface UserDataResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    userFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    userLastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    userPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataResponseDto
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the UserDataResponseDto interface.
 */
export function instanceOfUserDataResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userFirstName" in value;
    isInstance = isInstance && "userLastName" in value;
    isInstance = isInstance && "userEmail" in value;

    return isInstance;
}

export function UserDataResponseDtoFromJSON(json: any): UserDataResponseDto {
    return UserDataResponseDtoFromJSONTyped(json, false);
}

export function UserDataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDataResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'userFirstName': json['userFirstName'],
        'userLastName': json['userLastName'],
        'userEmail': json['userEmail'],
        'userPhone': !exists(json, 'userPhone') ? undefined : json['userPhone'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function UserDataResponseDtoToJSON(value?: UserDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userFirstName': value.userFirstName,
        'userLastName': value.userLastName,
        'userEmail': value.userEmail,
        'userPhone': value.userPhone,
        'fullName': value.fullName,
        'oauthId': value.oauthId,
    };
}

