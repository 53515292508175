
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class LanguageService {
    private languageSubject: BehaviorSubject<string> = new BehaviorSubject<string>("de");
    public language$: Observable<string> = this.languageSubject.asObservable();

    constructor(private translate: TranslateService) {}

    init() {
        const defaultLang = "de";
        const storedLang = localStorage.getItem('brody_language');
        if (storedLang) {
            this.translate.use(storedLang);
            this.languageSubject.next(storedLang);
        } else {
            this.translate.setDefaultLang(defaultLang);
            this.translate.use(defaultLang);
            this.languageSubject.next(defaultLang);
        }
    }

    setLanguage(language: string) {
        const _language = language.toLowerCase();
        const currentLanguage = this.languageSubject.getValue()
        if (currentLanguage !== _language) {
            this.translate.use(_language);
            this.languageSubject.next(_language);
            localStorage.setItem('brody_language', _language);
        }
    }

    getLanguage(): string {
        return this.translate.currentLang;
    }

    getLanguage$(): Observable<string> {
        return this.language$;
    }
}
