/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelMasterDataTableItemDto
 */
export interface ModelMasterDataTableItemDto {
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataTableItemDto
     */
    lastEditedAt?: string;
}

/**
 * Check if a given object implements the ModelMasterDataTableItemDto interface.
 */
export function instanceOfModelMasterDataTableItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;

    return isInstance;
}

export function ModelMasterDataTableItemDtoFromJSON(json: any): ModelMasterDataTableItemDto {
    return ModelMasterDataTableItemDtoFromJSONTyped(json, false);
}

export function ModelMasterDataTableItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataTableItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : json['lastEditedAt'],
    };
}

export function ModelMasterDataTableItemDtoToJSON(value?: ModelMasterDataTableItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'email': value.email,
        'name': value.name,
        'firstName': value.firstName,
        'alias': value.alias,
        'mobile': value.mobile,
        'phoneNumber': value.phoneNumber,
        'city': value.city,
        'country': value.country,
        'zipCode': value.zipCode,
        'image': value.image,
        'lastEditedAt': value.lastEditedAt,
    };
}

