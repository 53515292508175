import {NgModule} from "@angular/core";
import {ButtonModule} from "primeng/button";
import {CommonModule} from "@angular/common";
import {TableModule} from "primeng/table";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputMaskModule} from "primeng/inputmask";
import {TranslateModule} from "@ngx-translate/core";
import {DialogModule} from "primeng/dialog";
import {ListboxModule} from "primeng/listbox";
import {RouterModule} from "@angular/router";
import {PaginatorModule} from "primeng/paginator";
import {ConversationComponent} from "./conversation.component";
import {ConversationControllerApi} from "@brody-bookings/api";
import {ConversationService} from "../../service/conversation.service";
import {ConversationListComponent} from "./conversationList/conversationList.component";
import {MultiSelectModule} from "primeng/multiselect";
import {ConversationChatComponent} from "./conversationChat/conversationChat.component";
import {MenuModule} from "primeng/menu";
import {TimestampFormatPipe} from "../shared/customPipes/conversationDateFormatter";
import {ModelSearchListComponent} from "./modelSearchList/modelSearchList.component";
import {ParticipantDisplayComponent} from "./participantDisplay/participantDisplay.component";
import {ChipModule} from "primeng/chip";
import {ConversationTitleComponent} from "./conversationTitle/conversationTitle.component";
import {ConversationFilterComponent} from "./conversationFilter/conversationFilter.component";
import {ChipsModule} from "primeng/chips";
import {NewConversationModalComponent} from "./newConversationModal/newConversationModal.component";
import {CustomerSearchListComponent} from "./customerSearchList/customerSearchList.component";
import {ProjectSearchListComponent} from "./projectSearchList/projectSearchList.component";
import {NewlinePipe} from "./conversationChat/NewlinePipe";

@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        TableModule,
        FormsModule,
        InputTextModule,
        InputMaskModule,
        TranslateModule,
        DialogModule,
        ListboxModule,
        RouterModule.forChild([
            {
                path: '',
                component: ConversationComponent
            }
        ]),
        PaginatorModule,
        MultiSelectModule,
        MenuModule,
        ChipModule,
        ChipsModule

    ],
    declarations: [
        ConversationComponent,
        ConversationListComponent,
        ConversationChatComponent,
        TimestampFormatPipe,
        ModelSearchListComponent,
        ParticipantDisplayComponent,
        ConversationTitleComponent,
        ConversationFilterComponent,
        NewConversationModalComponent,
        CustomerSearchListComponent,
        ProjectSearchListComponent,
        NewlinePipe
    ],
    exports: [
        ConversationChatComponent
    ],
    providers: [
        ConversationControllerApi,
        ConversationService
    ]
})

export class ConversationModule {}
