/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProjectMotifRequestDto
 */
export interface CreateProjectMotifRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectMotifRequestDto
     */
    motifName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectMotifRequestDto
     */
    locationName?: string;
}

/**
 * Check if a given object implements the CreateProjectMotifRequestDto interface.
 */
export function instanceOfCreateProjectMotifRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateProjectMotifRequestDtoFromJSON(json: any): CreateProjectMotifRequestDto {
    return CreateProjectMotifRequestDtoFromJSONTyped(json, false);
}

export function CreateProjectMotifRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectMotifRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'motifName': !exists(json, 'motifName') ? undefined : json['motifName'],
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
    };
}

export function CreateProjectMotifRequestDtoToJSON(value?: CreateProjectMotifRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'motifName': value.motifName,
        'locationName': value.locationName,
    };
}

