/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AgencyAddressFormValuesDto
 */
export interface AgencyAddressFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    addendum?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    room1?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    room2?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    zipCode?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof AgencyAddressFormValuesDto
     */
    country?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof AgencyAddressFormValuesDto
     */
    attentionTo?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyAddressFormValuesDto
     */
    countryOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the AgencyAddressFormValuesDto interface.
 */
export function instanceOfAgencyAddressFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyAddressFormValuesDtoFromJSON(json: any): AgencyAddressFormValuesDto {
    return AgencyAddressFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyAddressFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyAddressFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addendum': !exists(json, 'addendum') ? undefined : json['addendum'],
        'room1': !exists(json, 'room1') ? undefined : json['room1'],
        'room2': !exists(json, 'room2') ? undefined : json['room2'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
        'attentionTo': !exists(json, 'attentionTo') ? undefined : json['attentionTo'],
        'countryOptions': !exists(json, 'countryOptions') ? undefined : ((json['countryOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function AgencyAddressFormValuesDtoToJSON(value?: AgencyAddressFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'addendum': value.addendum,
        'room1': value.room1,
        'room2': value.room2,
        'street': value.street,
        'city': value.city,
        'zipCode': value.zipCode,
        'country': AdminValueToJSON(value.country),
        'attentionTo': value.attentionTo,
        'countryOptions': value.countryOptions === undefined ? undefined : ((value.countryOptions as Array<any>).map(AdminValueToJSON)),
    };
}

