/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgencyActivityFieldsFormValuesDto,
  AgencyAddressFormValuesDto,
  AgencyBankInformationFormValuesDto,
  AgencyBookerContactFormValuesDto,
  AgencyBookerFormValuesDto,
  AgencyBookerNameFormValuesDto,
  AgencyBookerNamesForModelsDto,
  AgencyBookerTableItemDto,
  AgencyBookerTableItemsDto,
  AgencyConditionsFormValuesDto,
  AgencyContactFormValuesDto,
  AgencyCreateRequestDto,
  AgencyFormValuesDto,
  AgencyNameFormValuesDto,
  AgencyNamesForModelsDto,
  AgencyProtocolEntryCreateRequestDto,
  AgencyShortInfoFormValuesDto,
  AgencyTableItemDto,
  AgencyTaxInformationFormValuesDto,
  BookerForMessagesDto,
  CreateAgencyBookerRequestDto,
  ProtocolFormValuesDto,
} from '../models';
import {
    AgencyActivityFieldsFormValuesDtoFromJSON,
    AgencyActivityFieldsFormValuesDtoToJSON,
    AgencyAddressFormValuesDtoFromJSON,
    AgencyAddressFormValuesDtoToJSON,
    AgencyBankInformationFormValuesDtoFromJSON,
    AgencyBankInformationFormValuesDtoToJSON,
    AgencyBookerContactFormValuesDtoFromJSON,
    AgencyBookerContactFormValuesDtoToJSON,
    AgencyBookerFormValuesDtoFromJSON,
    AgencyBookerFormValuesDtoToJSON,
    AgencyBookerNameFormValuesDtoFromJSON,
    AgencyBookerNameFormValuesDtoToJSON,
    AgencyBookerNamesForModelsDtoFromJSON,
    AgencyBookerNamesForModelsDtoToJSON,
    AgencyBookerTableItemDtoFromJSON,
    AgencyBookerTableItemDtoToJSON,
    AgencyBookerTableItemsDtoFromJSON,
    AgencyBookerTableItemsDtoToJSON,
    AgencyConditionsFormValuesDtoFromJSON,
    AgencyConditionsFormValuesDtoToJSON,
    AgencyContactFormValuesDtoFromJSON,
    AgencyContactFormValuesDtoToJSON,
    AgencyCreateRequestDtoFromJSON,
    AgencyCreateRequestDtoToJSON,
    AgencyFormValuesDtoFromJSON,
    AgencyFormValuesDtoToJSON,
    AgencyNameFormValuesDtoFromJSON,
    AgencyNameFormValuesDtoToJSON,
    AgencyNamesForModelsDtoFromJSON,
    AgencyNamesForModelsDtoToJSON,
    AgencyProtocolEntryCreateRequestDtoFromJSON,
    AgencyProtocolEntryCreateRequestDtoToJSON,
    AgencyShortInfoFormValuesDtoFromJSON,
    AgencyShortInfoFormValuesDtoToJSON,
    AgencyTableItemDtoFromJSON,
    AgencyTableItemDtoToJSON,
    AgencyTaxInformationFormValuesDtoFromJSON,
    AgencyTaxInformationFormValuesDtoToJSON,
    BookerForMessagesDtoFromJSON,
    BookerForMessagesDtoToJSON,
    CreateAgencyBookerRequestDtoFromJSON,
    CreateAgencyBookerRequestDtoToJSON,
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoToJSON,
} from '../models';

export interface ChangeActivityFieldsRequest {
    id: string;
    agencyActivityFieldsFormValuesDto: AgencyActivityFieldsFormValuesDto;
}

export interface ChangeAddressRequest {
    id: string;
    agencyAddressFormValuesDto: AgencyAddressFormValuesDto;
}

export interface ChangeAgencyBookerBirthdayRequest {
    agencyId: string;
    bookerId: string;
    bookerBirthday: Date;
}

export interface ChangeAgencyBookerContactRequest {
    agencyId: string;
    bookerId: string;
    agencyBookerContactFormValuesDto: AgencyBookerContactFormValuesDto;
}

export interface ChangeAgencyBookerNameRequest {
    agencyId: string;
    bookerId: string;
    agencyBookerNameFormValuesDto: AgencyBookerNameFormValuesDto;
}

export interface ChangeAgencyBookerOtherInformationRequest {
    agencyId: string;
    bookerId: string;
    bookerOtherInformation: string;
}

export interface ChangeAgencyBookerPositionRequest {
    agencyId: string;
    bookerId: string;
    bookerPosition: string;
}

export interface ChangeAgencyBookerSexRequest {
    agencyId: string;
    bookerId: string;
    bookerSex: string;
}

export interface ChangeAgencyBookerTitleRequest {
    agencyId: string;
    bookerId: string;
    bookerTitle: string;
}

export interface ChangeAgencyShortInfoRequest {
    agencyId: string;
    agencyShortInfoFormValuesDto: AgencyShortInfoFormValuesDto;
}

export interface ChangeBankInformationRequest {
    id: string;
    agencyBankInformationFormValuesDto: AgencyBankInformationFormValuesDto;
}

export interface ChangeBillingAddress1Request {
    id: string;
    agencyAddressFormValuesDto: AgencyAddressFormValuesDto;
}

export interface ChangeConditionsRequest {
    id: string;
    agencyConditionsFormValuesDto: AgencyConditionsFormValuesDto;
}

export interface ChangeContactRequest {
    id: string;
    agencyContactFormValuesDto: AgencyContactFormValuesDto;
}

export interface ChangeLivingAddress2Request {
    id: string;
    agencyAddressFormValuesDto: AgencyAddressFormValuesDto;
}

export interface ChangeName1Request {
    id: string;
    agencyNameFormValuesDto: AgencyNameFormValuesDto;
}

export interface ChangeTaxInformation1Request {
    id: string;
    agencyTaxInformationFormValuesDto: AgencyTaxInformationFormValuesDto;
}

export interface CreateAgency1Request {
    agencyCreateRequestDto: AgencyCreateRequestDto;
}

export interface CreateBookerRequest {
    agencyId: string;
    createAgencyBookerRequestDto: CreateAgencyBookerRequestDto;
}

export interface CreateProtocolEntry3Request {
    agencyProtocolEntryCreateRequestDto: AgencyProtocolEntryCreateRequestDto;
}

export interface DeleteAgencyRequest {
    id: string;
}

export interface DeleteAgencyBookerRequest {
    agencyId: string;
    bookerId: string;
}

export interface GetAgencyFormValuesRequest {
    lang: string;
}

export interface GetAgencyFormValues1Request {
    lang: string;
    agencyId: string;
}

export interface GetAgencysRequest {
    page?: number;
}

export interface GetBookerRequest {
    language: string;
    agencyId: string;
    bookerId: string;
}

export interface GetBookerForModelRequest {
    agencyId: string;
}

export interface GetBookersRequest {
    agencyId: string;
    language: string;
}

/**
 * 
 */
export class AgencyControllerApi extends runtime.BaseAPI {

    /**
     */
    async changeActivityFieldsRaw(requestParameters: ChangeActivityFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeActivityFields.');
        }

        if (requestParameters.agencyActivityFieldsFormValuesDto === null || requestParameters.agencyActivityFieldsFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyActivityFieldsFormValuesDto','Required parameter requestParameters.agencyActivityFieldsFormValuesDto was null or undefined when calling changeActivityFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/activity-fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyActivityFieldsFormValuesDtoToJSON(requestParameters.agencyActivityFieldsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeActivityFields(requestParameters: ChangeActivityFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeActivityFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAddressRaw(requestParameters: ChangeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeAddress.');
        }

        if (requestParameters.agencyAddressFormValuesDto === null || requestParameters.agencyAddressFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyAddressFormValuesDto','Required parameter requestParameters.agencyAddressFormValuesDto was null or undefined when calling changeAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyAddressFormValuesDtoToJSON(requestParameters.agencyAddressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeAddress(requestParameters: ChangeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerBirthdayRaw(requestParameters: ChangeAgencyBookerBirthdayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerBirthday.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerBirthday.');
        }

        if (requestParameters.bookerBirthday === null || requestParameters.bookerBirthday === undefined) {
            throw new runtime.RequiredError('bookerBirthday','Required parameter requestParameters.bookerBirthday was null or undefined when calling changeAgencyBookerBirthday.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookerBirthday !== undefined) {
            queryParameters['bookerBirthday'] = (requestParameters.bookerBirthday as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/change/booker-birthday/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerBirthday(requestParameters: ChangeAgencyBookerBirthdayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerBirthdayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerContactRaw(requestParameters: ChangeAgencyBookerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerContact.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerContact.');
        }

        if (requestParameters.agencyBookerContactFormValuesDto === null || requestParameters.agencyBookerContactFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyBookerContactFormValuesDto','Required parameter requestParameters.agencyBookerContactFormValuesDto was null or undefined when calling changeAgencyBookerContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/booker-contact/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyBookerContactFormValuesDtoToJSON(requestParameters.agencyBookerContactFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerContact(requestParameters: ChangeAgencyBookerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerNameRaw(requestParameters: ChangeAgencyBookerNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerName.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerName.');
        }

        if (requestParameters.agencyBookerNameFormValuesDto === null || requestParameters.agencyBookerNameFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyBookerNameFormValuesDto','Required parameter requestParameters.agencyBookerNameFormValuesDto was null or undefined when calling changeAgencyBookerName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/booker-name/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyBookerNameFormValuesDtoToJSON(requestParameters.agencyBookerNameFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerName(requestParameters: ChangeAgencyBookerNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerOtherInformationRaw(requestParameters: ChangeAgencyBookerOtherInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerOtherInformation.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerOtherInformation.');
        }

        if (requestParameters.bookerOtherInformation === null || requestParameters.bookerOtherInformation === undefined) {
            throw new runtime.RequiredError('bookerOtherInformation','Required parameter requestParameters.bookerOtherInformation was null or undefined when calling changeAgencyBookerOtherInformation.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookerOtherInformation !== undefined) {
            queryParameters['bookerOtherInformation'] = requestParameters.bookerOtherInformation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/change/booker-otherinformation/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerOtherInformation(requestParameters: ChangeAgencyBookerOtherInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerOtherInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerPositionRaw(requestParameters: ChangeAgencyBookerPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerPosition.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerPosition.');
        }

        if (requestParameters.bookerPosition === null || requestParameters.bookerPosition === undefined) {
            throw new runtime.RequiredError('bookerPosition','Required parameter requestParameters.bookerPosition was null or undefined when calling changeAgencyBookerPosition.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookerPosition !== undefined) {
            queryParameters['bookerPosition'] = requestParameters.bookerPosition;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/change/booker-position/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerPosition(requestParameters: ChangeAgencyBookerPositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerSexRaw(requestParameters: ChangeAgencyBookerSexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerSex.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerSex.');
        }

        if (requestParameters.bookerSex === null || requestParameters.bookerSex === undefined) {
            throw new runtime.RequiredError('bookerSex','Required parameter requestParameters.bookerSex was null or undefined when calling changeAgencyBookerSex.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookerSex !== undefined) {
            queryParameters['bookerSex'] = requestParameters.bookerSex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/change/booker-sex/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerSex(requestParameters: ChangeAgencyBookerSexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerSexRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyBookerTitleRaw(requestParameters: ChangeAgencyBookerTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyBookerTitle.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling changeAgencyBookerTitle.');
        }

        if (requestParameters.bookerTitle === null || requestParameters.bookerTitle === undefined) {
            throw new runtime.RequiredError('bookerTitle','Required parameter requestParameters.bookerTitle was null or undefined when calling changeAgencyBookerTitle.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookerTitle !== undefined) {
            queryParameters['bookerTitle'] = requestParameters.bookerTitle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/change/booker-title/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeAgencyBookerTitle(requestParameters: ChangeAgencyBookerTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeAgencyBookerTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAgencyShortInfoRaw(requestParameters: ChangeAgencyShortInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling changeAgencyShortInfo.');
        }

        if (requestParameters.agencyShortInfoFormValuesDto === null || requestParameters.agencyShortInfoFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyShortInfoFormValuesDto','Required parameter requestParameters.agencyShortInfoFormValuesDto was null or undefined when calling changeAgencyShortInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/shortInfo/{agencyId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyShortInfoFormValuesDtoToJSON(requestParameters.agencyShortInfoFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeAgencyShortInfo(requestParameters: ChangeAgencyShortInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeAgencyShortInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBankInformationRaw(requestParameters: ChangeBankInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBankInformation.');
        }

        if (requestParameters.agencyBankInformationFormValuesDto === null || requestParameters.agencyBankInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyBankInformationFormValuesDto','Required parameter requestParameters.agencyBankInformationFormValuesDto was null or undefined when calling changeBankInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/bank-account-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyBankInformationFormValuesDtoToJSON(requestParameters.agencyBankInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBankInformation(requestParameters: ChangeBankInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBankInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBillingAddress1Raw(requestParameters: ChangeBillingAddress1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBillingAddress1.');
        }

        if (requestParameters.agencyAddressFormValuesDto === null || requestParameters.agencyAddressFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyAddressFormValuesDto','Required parameter requestParameters.agencyAddressFormValuesDto was null or undefined when calling changeBillingAddress1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/billing-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyAddressFormValuesDtoToJSON(requestParameters.agencyAddressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBillingAddress1(requestParameters: ChangeBillingAddress1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBillingAddress1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeConditionsRaw(requestParameters: ChangeConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeConditions.');
        }

        if (requestParameters.agencyConditionsFormValuesDto === null || requestParameters.agencyConditionsFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyConditionsFormValuesDto','Required parameter requestParameters.agencyConditionsFormValuesDto was null or undefined when calling changeConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/conditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyConditionsFormValuesDtoToJSON(requestParameters.agencyConditionsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeConditions(requestParameters: ChangeConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeContactRaw(requestParameters: ChangeContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeContact.');
        }

        if (requestParameters.agencyContactFormValuesDto === null || requestParameters.agencyContactFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyContactFormValuesDto','Required parameter requestParameters.agencyContactFormValuesDto was null or undefined when calling changeContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyContactFormValuesDtoToJSON(requestParameters.agencyContactFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeContact(requestParameters: ChangeContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeLivingAddress2Raw(requestParameters: ChangeLivingAddress2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeLivingAddress2.');
        }

        if (requestParameters.agencyAddressFormValuesDto === null || requestParameters.agencyAddressFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyAddressFormValuesDto','Required parameter requestParameters.agencyAddressFormValuesDto was null or undefined when calling changeLivingAddress2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/living-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyAddressFormValuesDtoToJSON(requestParameters.agencyAddressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeLivingAddress2(requestParameters: ChangeLivingAddress2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeLivingAddress2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeName1Raw(requestParameters: ChangeName1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeName1.');
        }

        if (requestParameters.agencyNameFormValuesDto === null || requestParameters.agencyNameFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyNameFormValuesDto','Required parameter requestParameters.agencyNameFormValuesDto was null or undefined when calling changeName1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/name/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyNameFormValuesDtoToJSON(requestParameters.agencyNameFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeName1(requestParameters: ChangeName1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeName1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeTaxInformation1Raw(requestParameters: ChangeTaxInformation1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeTaxInformation1.');
        }

        if (requestParameters.agencyTaxInformationFormValuesDto === null || requestParameters.agencyTaxInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('agencyTaxInformationFormValuesDto','Required parameter requestParameters.agencyTaxInformationFormValuesDto was null or undefined when calling changeTaxInformation1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/change/tax-information/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyTaxInformationFormValuesDtoToJSON(requestParameters.agencyTaxInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeTaxInformation1(requestParameters: ChangeTaxInformation1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeTaxInformation1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAgency1Raw(requestParameters: CreateAgency1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyTableItemDto>> {
        if (requestParameters.agencyCreateRequestDto === null || requestParameters.agencyCreateRequestDto === undefined) {
            throw new runtime.RequiredError('agencyCreateRequestDto','Required parameter requestParameters.agencyCreateRequestDto was null or undefined when calling createAgency1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/createAgency`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyCreateRequestDtoToJSON(requestParameters.agencyCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyTableItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAgency1(requestParameters: CreateAgency1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyTableItemDto> {
        const response = await this.createAgency1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBookerRaw(requestParameters: CreateBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyBookerTableItemDto>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling createBooker.');
        }

        if (requestParameters.createAgencyBookerRequestDto === null || requestParameters.createAgencyBookerRequestDto === undefined) {
            throw new runtime.RequiredError('createAgencyBookerRequestDto','Required parameter requestParameters.createAgencyBookerRequestDto was null or undefined when calling createBooker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/create-booker/{agencyId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAgencyBookerRequestDtoToJSON(requestParameters.createAgencyBookerRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyBookerTableItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async createBooker(requestParameters: CreateBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyBookerTableItemDto> {
        const response = await this.createBookerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProtocolEntry3Raw(requestParameters: CreateProtocolEntry3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProtocolFormValuesDto>> {
        if (requestParameters.agencyProtocolEntryCreateRequestDto === null || requestParameters.agencyProtocolEntryCreateRequestDto === undefined) {
            throw new runtime.RequiredError('agencyProtocolEntryCreateRequestDto','Required parameter requestParameters.agencyProtocolEntryCreateRequestDto was null or undefined when calling createProtocolEntry3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/agency/create/protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgencyProtocolEntryCreateRequestDtoToJSON(requestParameters.agencyProtocolEntryCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProtocolFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProtocolEntry3(requestParameters: CreateProtocolEntry3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProtocolFormValuesDto> {
        const response = await this.createProtocolEntry3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAgencyRaw(requestParameters: DeleteAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAgency.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteAgency(requestParameters: DeleteAgencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteAgencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAgencyBookerRaw(requestParameters: DeleteAgencyBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling deleteAgencyBooker.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling deleteAgencyBooker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/delete/agency-booker/{agencyId}/{bookerId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteAgencyBooker(requestParameters: DeleteAgencyBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteAgencyBookerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgencyFormValuesRaw(requestParameters: GetAgencyFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getAgencyFormValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/formValues/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAgencyFormValues(requestParameters: GetAgencyFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyFormValuesDto> {
        const response = await this.getAgencyFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgencyFormValues1Raw(requestParameters: GetAgencyFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getAgencyFormValues1.');
        }

        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling getAgencyFormValues1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/formValues/{lang}/{agencyId}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAgencyFormValues1(requestParameters: GetAgencyFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyFormValuesDto> {
        const response = await this.getAgencyFormValues1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgencysRaw(requestParameters: GetAgencysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgencyTableItemDto>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/agencys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgencyTableItemDtoFromJSON));
    }

    /**
     */
    async getAgencys(requestParameters: GetAgencysRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgencyTableItemDto>> {
        const response = await this.getAgencysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllAgencyNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgencyNamesForModelsDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/search/all-agencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgencyNamesForModelsDtoFromJSON));
    }

    /**
     */
    async getAllAgencyNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgencyNamesForModelsDto>> {
        const response = await this.getAllAgencyNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookerRaw(requestParameters: GetBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyBookerFormValuesDto>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getBooker.');
        }

        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling getBooker.');
        }

        if (requestParameters.bookerId === null || requestParameters.bookerId === undefined) {
            throw new runtime.RequiredError('bookerId','Required parameter requestParameters.bookerId was null or undefined when calling getBooker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/booker/{language}/{agencyId}/{bookerId}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))).replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"bookerId"}}`, encodeURIComponent(String(requestParameters.bookerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyBookerFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBooker(requestParameters: GetBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyBookerFormValuesDto> {
        const response = await this.getBookerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookerForModelRaw(requestParameters: GetBookerForModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgencyBookerNamesForModelsDto>>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling getBookerForModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/search/booker/{agencyId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgencyBookerNamesForModelsDtoFromJSON));
    }

    /**
     */
    async getBookerForModel(requestParameters: GetBookerForModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgencyBookerNamesForModelsDto>> {
        const response = await this.getBookerForModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookersRaw(requestParameters: GetBookersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgencyBookerTableItemsDto>> {
        if (requestParameters.agencyId === null || requestParameters.agencyId === undefined) {
            throw new runtime.RequiredError('agencyId','Required parameter requestParameters.agencyId was null or undefined when calling getBookers.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getBookers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/bookers/{language}/{agencyId}`.replace(`{${"agencyId"}}`, encodeURIComponent(String(requestParameters.agencyId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgencyBookerTableItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBookers(requestParameters: GetBookersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgencyBookerTableItemsDto> {
        const response = await this.getBookersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookersForMessagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookerForMessagesDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/agency/bookers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookerForMessagesDtoFromJSON));
    }

    /**
     */
    async getBookersForMessages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookerForMessagesDto>> {
        const response = await this.getBookersForMessagesRaw(initOverrides);
        return await response.value();
    }

}
