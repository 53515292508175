/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ProjectRoleBuyoutFormValuesDto } from './ProjectRoleBuyoutFormValuesDto';
import {
    ProjectRoleBuyoutFormValuesDtoFromJSON,
    ProjectRoleBuyoutFormValuesDtoFromJSONTyped,
    ProjectRoleBuyoutFormValuesDtoToJSON,
} from './ProjectRoleBuyoutFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectRoleFormValuesDto
 */
export interface ProjectRoleFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleFormValuesDto
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleFormValuesDto
     */
    projectRoleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleFormValuesDto
     */
    name: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRoleFormValuesDto
     */
    availableSexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRoleFormValuesDto
     */
    availableHairColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRoleFormValuesDto
     */
    availableCategories?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRoleFormValuesDto
     */
    availableBuyoutTypes?: Array<string>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ProjectRoleFormValuesDto
     */
    availableDurations?: Array<AdminValue>;
    /**
     * 
     * @type {AdminValue}
     * @memberof ProjectRoleFormValuesDto
     */
    sex?: AdminValue;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoleFormValuesDto
     */
    ageFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoleFormValuesDto
     */
    ageTo?: number;
    /**
     * 
     * @type {AdminValue}
     * @memberof ProjectRoleFormValuesDto
     */
    hairColor?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ProjectRoleFormValuesDto
     */
    category?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleFormValuesDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoleFormValuesDto
     */
    isDoingSpecials?: boolean;
    /**
     * 
     * @type {AdminValue}
     * @memberof ProjectRoleFormValuesDto
     */
    duration?: AdminValue;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoleFormValuesDto
     */
    fee?: number;
    /**
     * 
     * @type {ProjectRoleBuyoutFormValuesDto}
     * @memberof ProjectRoleFormValuesDto
     */
    buyout?: ProjectRoleBuyoutFormValuesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRoleFormValuesDto
     */
    modelIds?: Array<string>;
}

/**
 * Check if a given object implements the ProjectRoleFormValuesDto interface.
 */
export function instanceOfProjectRoleFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "projectRoleId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ProjectRoleFormValuesDtoFromJSON(json: any): ProjectRoleFormValuesDto {
    return ProjectRoleFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectRoleFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['projectId'],
        'projectRoleId': json['projectRoleId'],
        'name': json['name'],
        'availableSexes': !exists(json, 'availableSexes') ? undefined : ((json['availableSexes'] as Array<any>).map(AdminValueFromJSON)),
        'availableHairColors': !exists(json, 'availableHairColors') ? undefined : ((json['availableHairColors'] as Array<any>).map(AdminValueFromJSON)),
        'availableCategories': !exists(json, 'availableCategories') ? undefined : ((json['availableCategories'] as Array<any>).map(AdminValueFromJSON)),
        'availableBuyoutTypes': !exists(json, 'availableBuyoutTypes') ? undefined : json['availableBuyoutTypes'],
        'availableDurations': !exists(json, 'availableDurations') ? undefined : ((json['availableDurations'] as Array<any>).map(AdminValueFromJSON)),
        'sex': !exists(json, 'sex') ? undefined : AdminValueFromJSON(json['sex']),
        'ageFrom': !exists(json, 'ageFrom') ? undefined : json['ageFrom'],
        'ageTo': !exists(json, 'ageTo') ? undefined : json['ageTo'],
        'hairColor': !exists(json, 'hairColor') ? undefined : AdminValueFromJSON(json['hairColor']),
        'category': !exists(json, 'category') ? undefined : AdminValueFromJSON(json['category']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isDoingSpecials': !exists(json, 'isDoingSpecials') ? undefined : json['isDoingSpecials'],
        'duration': !exists(json, 'duration') ? undefined : AdminValueFromJSON(json['duration']),
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'buyout': !exists(json, 'buyout') ? undefined : ProjectRoleBuyoutFormValuesDtoFromJSON(json['buyout']),
        'modelIds': !exists(json, 'modelIds') ? undefined : json['modelIds'],
    };
}

export function ProjectRoleFormValuesDtoToJSON(value?: ProjectRoleFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'projectRoleId': value.projectRoleId,
        'name': value.name,
        'availableSexes': value.availableSexes === undefined ? undefined : ((value.availableSexes as Array<any>).map(AdminValueToJSON)),
        'availableHairColors': value.availableHairColors === undefined ? undefined : ((value.availableHairColors as Array<any>).map(AdminValueToJSON)),
        'availableCategories': value.availableCategories === undefined ? undefined : ((value.availableCategories as Array<any>).map(AdminValueToJSON)),
        'availableBuyoutTypes': value.availableBuyoutTypes,
        'availableDurations': value.availableDurations === undefined ? undefined : ((value.availableDurations as Array<any>).map(AdminValueToJSON)),
        'sex': AdminValueToJSON(value.sex),
        'ageFrom': value.ageFrom,
        'ageTo': value.ageTo,
        'hairColor': AdminValueToJSON(value.hairColor),
        'category': AdminValueToJSON(value.category),
        'description': value.description,
        'isDoingSpecials': value.isDoingSpecials,
        'duration': AdminValueToJSON(value.duration),
        'fee': value.fee,
        'buyout': ProjectRoleBuyoutFormValuesDtoToJSON(value.buyout),
        'modelIds': value.modelIds,
    };
}

