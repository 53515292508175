/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AgencyConditionsFormValuesDto
 */
export interface AgencyConditionsFormValuesDto {
    /**
     * 
     * @type {boolean}
     * @memberof AgencyConditionsFormValuesDto
     */
    commissionAddUst?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgencyConditionsFormValuesDto
     */
    commissionNetto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgencyConditionsFormValuesDto
     */
    currency?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof AgencyConditionsFormValuesDto
     */
    billingPeriod?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof AgencyConditionsFormValuesDto
     */
    commissionOnStay?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyConditionsFormValuesDto
     */
    commissionFlyIn?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyConditionsFormValuesDto
     */
    billingPeriodOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgencyConditionsFormValuesDto
     */
    currencyOptions?: Array<string>;
}

/**
 * Check if a given object implements the AgencyConditionsFormValuesDto interface.
 */
export function instanceOfAgencyConditionsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyConditionsFormValuesDtoFromJSON(json: any): AgencyConditionsFormValuesDto {
    return AgencyConditionsFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyConditionsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyConditionsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commissionAddUst': !exists(json, 'commissionAddUst') ? undefined : json['commissionAddUst'],
        'commissionNetto': !exists(json, 'commissionNetto') ? undefined : json['commissionNetto'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'billingPeriod': !exists(json, 'billingPeriod') ? undefined : AdminValueFromJSON(json['billingPeriod']),
        'commissionOnStay': !exists(json, 'commissionOnStay') ? undefined : json['commissionOnStay'],
        'commissionFlyIn': !exists(json, 'commissionFlyIn') ? undefined : json['commissionFlyIn'],
        'billingPeriodOptions': !exists(json, 'billingPeriodOptions') ? undefined : ((json['billingPeriodOptions'] as Array<any>).map(AdminValueFromJSON)),
        'currencyOptions': !exists(json, 'currencyOptions') ? undefined : json['currencyOptions'],
    };
}

export function AgencyConditionsFormValuesDtoToJSON(value?: AgencyConditionsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commissionAddUst': value.commissionAddUst,
        'commissionNetto': value.commissionNetto,
        'currency': value.currency,
        'billingPeriod': AdminValueToJSON(value.billingPeriod),
        'commissionOnStay': value.commissionOnStay,
        'commissionFlyIn': value.commissionFlyIn,
        'billingPeriodOptions': value.billingPeriodOptions === undefined ? undefined : ((value.billingPeriodOptions as Array<any>).map(AdminValueToJSON)),
        'currencyOptions': value.currencyOptions,
    };
}

