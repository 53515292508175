/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleSearchItemDto } from './ProjectRoleSearchItemDto';
import {
    ProjectRoleSearchItemDtoFromJSON,
    ProjectRoleSearchItemDtoFromJSONTyped,
    ProjectRoleSearchItemDtoToJSON,
} from './ProjectRoleSearchItemDto';

/**
 * 
 * @export
 * @interface ProjectSearchItemDto
 */
export interface ProjectSearchItemDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectSearchItemDto
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSearchItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSearchItemDto
     */
    customer: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectSearchItemDto
     */
    productionStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectSearchItemDto
     */
    productionEndDate?: Date;
    /**
     * 
     * @type {Array<ProjectRoleSearchItemDto>}
     * @memberof ProjectSearchItemDto
     */
    roles: Array<ProjectRoleSearchItemDto>;
}

/**
 * Check if a given object implements the ProjectSearchItemDto interface.
 */
export function instanceOfProjectSearchItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "customer" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function ProjectSearchItemDtoFromJSON(json: any): ProjectSearchItemDto {
    return ProjectSearchItemDtoFromJSONTyped(json, false);
}

export function ProjectSearchItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSearchItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['projectId'],
        'title': json['title'],
        'customer': json['customer'],
        'productionStartDate': !exists(json, 'productionStartDate') ? undefined : (new Date(json['productionStartDate'])),
        'productionEndDate': !exists(json, 'productionEndDate') ? undefined : (new Date(json['productionEndDate'])),
        'roles': ((json['roles'] as Array<any>).map(ProjectRoleSearchItemDtoFromJSON)),
    };
}

export function ProjectSearchItemDtoToJSON(value?: ProjectSearchItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'title': value.title,
        'customer': value.customer,
        'productionStartDate': value.productionStartDate === undefined ? undefined : (value.productionStartDate.toISOString().substr(0,10)),
        'productionEndDate': value.productionEndDate === undefined ? undefined : (value.productionEndDate.toISOString().substr(0,10)),
        'roles': ((value.roles as Array<any>).map(ProjectRoleSearchItemDtoToJSON)),
    };
}

