/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Appointment } from './Appointment';
import {
    AppointmentFromJSON,
    AppointmentFromJSONTyped,
    AppointmentToJSON,
} from './Appointment';
import type { AppointmentOptionsDto } from './AppointmentOptionsDto';
import {
    AppointmentOptionsDtoFromJSON,
    AppointmentOptionsDtoFromJSONTyped,
    AppointmentOptionsDtoToJSON,
} from './AppointmentOptionsDto';

/**
 * 
 * @export
 * @interface ModelOptionRequestsResponseDto
 */
export interface ModelOptionRequestsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ModelOptionRequestsResponseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    jobDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    durationInHours?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    booker?: string;
    /**
     * 
     * @type {Date}
     * @memberof ModelOptionRequestsResponseDto
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    updatedAtAsString?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOptionRequestsResponseDto
     */
    projectModelId?: string;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ModelOptionRequestsResponseDto
     */
    appointments?: Array<Appointment>;
    /**
     * 
     * @type {Array<AppointmentOptionsDto>}
     * @memberof ModelOptionRequestsResponseDto
     */
    appointmentOptions?: Array<AppointmentOptionsDto>;
}

/**
 * Check if a given object implements the ModelOptionRequestsResponseDto interface.
 */
export function instanceOfModelOptionRequestsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelOptionRequestsResponseDtoFromJSON(json: any): ModelOptionRequestsResponseDto {
    return ModelOptionRequestsResponseDtoFromJSONTyped(json, false);
}

export function ModelOptionRequestsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelOptionRequestsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'jobDescription': !exists(json, 'jobDescription') ? undefined : json['jobDescription'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'durationInHours': !exists(json, 'durationInHours') ? undefined : json['durationInHours'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'booker': !exists(json, 'booker') ? undefined : json['booker'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'updatedAtAsString': !exists(json, 'updatedAtAsString') ? undefined : json['updatedAtAsString'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectModelId': !exists(json, 'projectModelId') ? undefined : json['projectModelId'],
        'appointments': !exists(json, 'appointments') ? undefined : ((json['appointments'] as Array<any>).map(AppointmentFromJSON)),
        'appointmentOptions': !exists(json, 'appointmentOptions') ? undefined : ((json['appointmentOptions'] as Array<any>).map(AppointmentOptionsDtoFromJSON)),
    };
}

export function ModelOptionRequestsResponseDtoToJSON(value?: ModelOptionRequestsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customer': value.customer,
        'jobTitle': value.jobTitle,
        'jobDescription': value.jobDescription,
        'roleName': value.roleName,
        'durationInHours': value.durationInHours,
        'status': value.status,
        'booker': value.booker,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updatedAtAsString': value.updatedAtAsString,
        'projectId': value.projectId,
        'projectModelId': value.projectModelId,
        'appointments': value.appointments === undefined ? undefined : ((value.appointments as Array<any>).map(AppointmentToJSON)),
        'appointmentOptions': value.appointmentOptions === undefined ? undefined : ((value.appointmentOptions as Array<any>).map(AppointmentOptionsDtoToJSON)),
    };
}

