/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CmUnit } from './CmUnit';
import {
    CmUnitFromJSON,
    CmUnitFromJSONTyped,
    CmUnitToJSON,
} from './CmUnit';

/**
 * 
 * @export
 * @interface ModelPublicDataDto
 */
export interface ModelPublicDataDto {
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    modeCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    image?: string;
    /**
     * 
     * @type {CmUnit}
     * @memberof ModelPublicDataDto
     */
    height?: CmUnit;
    /**
     * 
     * @type {CmUnit}
     * @memberof ModelPublicDataDto
     */
    chestCircumference?: CmUnit;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    clothesMin?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    clothesMax?: string;
    /**
     * 
     * @type {CmUnit}
     * @memberof ModelPublicDataDto
     */
    waist?: CmUnit;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    shoes?: string;
    /**
     * 
     * @type {CmUnit}
     * @memberof ModelPublicDataDto
     */
    hips?: CmUnit;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    zipCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelPublicDataDto
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    eyes?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    skin?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataDto
     */
    hair?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelPublicDataDto
     */
    portfolio?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelPublicDataDto
     */
    masters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelPublicDataDto
     */
    videos?: Array<string>;
}

/**
 * Check if a given object implements the ModelPublicDataDto interface.
 */
export function instanceOfModelPublicDataDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataDtoFromJSON(json: any): ModelPublicDataDto {
    return ModelPublicDataDtoFromJSONTyped(json, false);
}

export function ModelPublicDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'modeCategory': !exists(json, 'modeCategory') ? undefined : json['modeCategory'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'height': !exists(json, 'height') ? undefined : CmUnitFromJSON(json['height']),
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : CmUnitFromJSON(json['chestCircumference']),
        'clothesMin': !exists(json, 'clothesMin') ? undefined : json['clothesMin'],
        'clothesMax': !exists(json, 'clothesMax') ? undefined : json['clothesMax'],
        'waist': !exists(json, 'waist') ? undefined : CmUnitFromJSON(json['waist']),
        'shoes': !exists(json, 'shoes') ? undefined : json['shoes'],
        'hips': !exists(json, 'hips') ? undefined : CmUnitFromJSON(json['hips']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'eyes': !exists(json, 'eyes') ? undefined : json['eyes'],
        'skin': !exists(json, 'skin') ? undefined : json['skin'],
        'hair': !exists(json, 'hair') ? undefined : json['hair'],
        'portfolio': !exists(json, 'portfolio') ? undefined : json['portfolio'],
        'masters': !exists(json, 'masters') ? undefined : json['masters'],
        'videos': !exists(json, 'videos') ? undefined : json['videos'],
    };
}

export function ModelPublicDataDtoToJSON(value?: ModelPublicDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'modelId': value.modelId,
        'modeCategory': value.modeCategory,
        'lastName': value.lastName,
        'alias': value.alias,
        'image': value.image,
        'height': CmUnitToJSON(value.height),
        'chestCircumference': CmUnitToJSON(value.chestCircumference),
        'clothesMin': value.clothesMin,
        'clothesMax': value.clothesMax,
        'waist': CmUnitToJSON(value.waist),
        'shoes': value.shoes,
        'hips': CmUnitToJSON(value.hips),
        'zipCode': value.zipCode,
        'age': value.age,
        'ethnicity': value.ethnicity,
        'eyes': value.eyes,
        'skin': value.skin,
        'hair': value.hair,
        'portfolio': value.portfolio,
        'masters': value.masters,
        'videos': value.videos,
    };
}

