/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectStatusFormValuesDto
 */
export interface ProjectStatusFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectStatusFormValuesDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectStatusFormValuesDto
     */
    received?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectStatusFormValuesDto
     */
    projectStatusOptions?: Array<string>;
}

/**
 * Check if a given object implements the ProjectStatusFormValuesDto interface.
 */
export function instanceOfProjectStatusFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectStatusFormValuesDtoFromJSON(json: any): ProjectStatusFormValuesDto {
    return ProjectStatusFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectStatusFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatusFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'received': !exists(json, 'received') ? undefined : json['received'],
        'projectStatusOptions': !exists(json, 'projectStatusOptions') ? undefined : json['projectStatusOptions'],
    };
}

export function ProjectStatusFormValuesDtoToJSON(value?: ProjectStatusFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'received': value.received,
        'projectStatusOptions': value.projectStatusOptions,
    };
}

