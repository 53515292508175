/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PagingInformation
 */
export interface PagingInformation {
    /**
     * 
     * @type {number}
     * @memberof PagingInformation
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof PagingInformation
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof PagingInformation
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PagingInformation interface.
 */
export function instanceOfPagingInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageNumber" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagingInformationFromJSON(json: any): PagingInformation {
    return PagingInformationFromJSONTyped(json, false);
}

export function PagingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagingInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': json['pageNumber'],
        'pageSize': json['pageSize'],
        'totalPages': json['totalPages'],
    };
}

export function PagingInformationToJSON(value?: PagingInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
    };
}

