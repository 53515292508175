/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeNameFormValuesDto
 */
export interface EmployeeNameFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeNameFormValuesDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNameFormValuesDto
     */
    lastName?: string;
}

/**
 * Check if a given object implements the EmployeeNameFormValuesDto interface.
 */
export function instanceOfEmployeeNameFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeNameFormValuesDtoFromJSON(json: any): EmployeeNameFormValuesDto {
    return EmployeeNameFormValuesDtoFromJSONTyped(json, false);
}

export function EmployeeNameFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeNameFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function EmployeeNameFormValuesDtoToJSON(value?: EmployeeNameFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}

