/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SelectedUsageTypeFormValuesDto } from './SelectedUsageTypeFormValuesDto';
import {
    SelectedUsageTypeFormValuesDtoFromJSON,
    SelectedUsageTypeFormValuesDtoFromJSONTyped,
    SelectedUsageTypeFormValuesDtoToJSON,
} from './SelectedUsageTypeFormValuesDto';

/**
 * 
 * @export
 * @interface ChangeUsageTypeRequestDto
 */
export interface ChangeUsageTypeRequestDto {
    /**
     * 
     * @type {SelectedUsageTypeFormValuesDto}
     * @memberof ChangeUsageTypeRequestDto
     */
    selectedUsageType?: SelectedUsageTypeFormValuesDto;
}

/**
 * Check if a given object implements the ChangeUsageTypeRequestDto interface.
 */
export function instanceOfChangeUsageTypeRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeUsageTypeRequestDtoFromJSON(json: any): ChangeUsageTypeRequestDto {
    return ChangeUsageTypeRequestDtoFromJSONTyped(json, false);
}

export function ChangeUsageTypeRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeUsageTypeRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedUsageType': !exists(json, 'selectedUsageType') ? undefined : SelectedUsageTypeFormValuesDtoFromJSON(json['selectedUsageType']),
    };
}

export function ChangeUsageTypeRequestDtoToJSON(value?: ChangeUsageTypeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedUsageType': SelectedUsageTypeFormValuesDtoToJSON(value.selectedUsageType),
    };
}

