import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {CustomerEmployeeForMessenger, CustomerNamesForMessengerDto, ParticipantResponseDto} from "@brody-bookings/api";
import {Subscription} from "rxjs";
import {ConversationService} from "../../../service/conversation.service";

@Component({
    selector: 'app-conversation-customer-search-list',
    templateUrl: './customerSearchList.component.html',
    styleUrls: ['./customerSearchList.styles.css']
})

export class CustomerSearchListComponent implements OnInit, OnDestroy{
    private subscriptions: Subscription[] = [];

    customerNames: CustomerNamesForMessengerDto[] = []
    customerParticipantList: ParticipantResponseDto[] = [];
    showEmployeeList: boolean = false

    @Input() onSelectEmployee!: (employee: ParticipantResponseDto) => void

    constructor(
        private conversationService: ConversationService
    ){}

    ngOnInit(): void {
        this.conversationService.fetchCustomerNames().subscribe((customerNamesForMessengerDtos: CustomerNamesForMessengerDto[]) => {
            this.customerNames = customerNamesForMessengerDtos
        })
        this.conversationService.fetchCustomerParticipants().subscribe((participantResponseDtos: ParticipantResponseDto[]) => {
            this.customerParticipantList = participantResponseDtos
        })

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onSelectCustomer(event: any): void {
        this.conversationService.getCustomerParticipants(event.customerId)
        this.showEmployeeList = true
    }
}