/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TravelCostFormValuesDto
 */
export interface TravelCostFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof TravelCostFormValuesDto
     */
    transportationMeans?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelCostFormValuesDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof TravelCostFormValuesDto
     */
    cost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TravelCostFormValuesDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelCostFormValuesDto
     */
    tax?: boolean;
}

/**
 * Check if a given object implements the TravelCostFormValuesDto interface.
 */
export function instanceOfTravelCostFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TravelCostFormValuesDtoFromJSON(json: any): TravelCostFormValuesDto {
    return TravelCostFormValuesDtoFromJSONTyped(json, false);
}

export function TravelCostFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelCostFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transportationMeans': !exists(json, 'transportationMeans') ? undefined : json['transportationMeans'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
    };
}

export function TravelCostFormValuesDtoToJSON(value?: TravelCostFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transportationMeans': value.transportationMeans,
        'description': value.description,
        'cost': value.cost,
        'paid': value.paid,
        'tax': value.tax,
    };
}

