/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PushSubscriptionKeyDto } from './PushSubscriptionKeyDto';
import {
    PushSubscriptionKeyDtoFromJSON,
    PushSubscriptionKeyDtoFromJSONTyped,
    PushSubscriptionKeyDtoToJSON,
} from './PushSubscriptionKeyDto';

/**
 * 
 * @export
 * @interface PushSubscriptionRequestDto
 */
export interface PushSubscriptionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionRequestDto
     */
    endpoint?: string;
    /**
     * 
     * @type {PushSubscriptionKeyDto}
     * @memberof PushSubscriptionRequestDto
     */
    keys?: PushSubscriptionKeyDto;
}

/**
 * Check if a given object implements the PushSubscriptionRequestDto interface.
 */
export function instanceOfPushSubscriptionRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushSubscriptionRequestDtoFromJSON(json: any): PushSubscriptionRequestDto {
    return PushSubscriptionRequestDtoFromJSONTyped(json, false);
}

export function PushSubscriptionRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushSubscriptionRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'keys': !exists(json, 'keys') ? undefined : PushSubscriptionKeyDtoFromJSON(json['keys']),
    };
}

export function PushSubscriptionRequestDtoToJSON(value?: PushSubscriptionRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
        'keys': PushSubscriptionKeyDtoToJSON(value.keys),
    };
}

