/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyNameFormValuesDto } from './AgencyNameFormValuesDto';
import {
    AgencyNameFormValuesDtoFromJSON,
    AgencyNameFormValuesDtoFromJSONTyped,
    AgencyNameFormValuesDtoToJSON,
} from './AgencyNameFormValuesDto';

/**
 * 
 * @export
 * @interface AgencyCreateRequestDto
 */
export interface AgencyCreateRequestDto {
    /**
     * 
     * @type {AgencyNameFormValuesDto}
     * @memberof AgencyCreateRequestDto
     */
    name: AgencyNameFormValuesDto;
}

/**
 * Check if a given object implements the AgencyCreateRequestDto interface.
 */
export function instanceOfAgencyCreateRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AgencyCreateRequestDtoFromJSON(json: any): AgencyCreateRequestDto {
    return AgencyCreateRequestDtoFromJSONTyped(json, false);
}

export function AgencyCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': AgencyNameFormValuesDtoFromJSON(json['name']),
    };
}

export function AgencyCreateRequestDtoToJSON(value?: AgencyCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': AgencyNameFormValuesDtoToJSON(value.name),
    };
}

