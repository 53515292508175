/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface IndustryFieldFormValuesDto
 */
export interface IndustryFieldFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof IndustryFieldFormValuesDto
     */
    industryField?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof IndustryFieldFormValuesDto
     */
    industryFieldOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the IndustryFieldFormValuesDto interface.
 */
export function instanceOfIndustryFieldFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IndustryFieldFormValuesDtoFromJSON(json: any): IndustryFieldFormValuesDto {
    return IndustryFieldFormValuesDtoFromJSONTyped(json, false);
}

export function IndustryFieldFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndustryFieldFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industryField': !exists(json, 'industryField') ? undefined : ((json['industryField'] as Array<any>).map(AdminValueFromJSON)),
        'industryFieldOptions': !exists(json, 'industryFieldOptions') ? undefined : ((json['industryFieldOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function IndustryFieldFormValuesDtoToJSON(value?: IndustryFieldFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industryField': value.industryField === undefined ? undefined : ((value.industryField as Array<any>).map(AdminValueToJSON)),
        'industryFieldOptions': value.industryFieldOptions === undefined ? undefined : ((value.industryFieldOptions as Array<any>).map(AdminValueToJSON)),
    };
}

