
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
// @ts-ignore
import {ModelMasterDataRequestDto,ModelMasterDataControllerApi} from '@brody-bookings/api'
@Injectable()
export class PlaceholderModelsService{
    private modelsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public models$: Observable<ModelMasterDataRequestDto[]> = this.modelsSubject.asObservable();
    constructor(){}
    
    models= 
        [
            {
                name: "model1", alias:"alias1", city:"city1", zipCode:1234, phoneNumber:"123345678"
            },
            {
                name: "model2", alias:"alias2", city:"hotazhel", zipCode:1234, phoneNumber:"123345678"
            },
            {
                name: "model3", alias:"testalias1", city:"lochness", zipCode:1234, phoneNumber:"123345678"
            },
            {
                name: "model4", alias:"alias4", city:"itz", zipCode:1234, phoneNumber:"123345678"
            }]
    getModels(){
        this.modelsSubject.next(this.models)
        return this.models$
    }
  
}
    
