/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectCasting } from './ProjectCasting';
import {
    ProjectCastingFromJSON,
    ProjectCastingFromJSONTyped,
    ProjectCastingToJSON,
} from './ProjectCasting';
import type { ProjectRoleBuyout } from './ProjectRoleBuyout';
import {
    ProjectRoleBuyoutFromJSON,
    ProjectRoleBuyoutFromJSONTyped,
    ProjectRoleBuyoutToJSON,
} from './ProjectRoleBuyout';

/**
 * 
 * @export
 * @interface ProjectRole
 */
export interface ProjectRole {
    /**
     * 
     * @type {number}
     * @memberof ProjectRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    projectRoleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    sex?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRole
     */
    ageFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRole
     */
    ageTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    hairColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRole
     */
    isDoingSpecials?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    durationInHours?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRole
     */
    fee?: number;
    /**
     * 
     * @type {ProjectRoleBuyout}
     * @memberof ProjectRole
     */
    buyout?: ProjectRoleBuyout;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRole
     */
    modelIds?: Array<string>;
    /**
     * 
     * @type {ProjectCasting}
     * @memberof ProjectRole
     */
    casting?: ProjectCasting;
}

/**
 * Check if a given object implements the ProjectRole interface.
 */
export function instanceOfProjectRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectRoleId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ProjectRoleFromJSON(json: any): ProjectRole {
    return ProjectRoleFromJSONTyped(json, false);
}

export function ProjectRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectRoleId': json['projectRoleId'],
        'name': json['name'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'ageFrom': !exists(json, 'ageFrom') ? undefined : json['ageFrom'],
        'ageTo': !exists(json, 'ageTo') ? undefined : json['ageTo'],
        'hairColor': !exists(json, 'hairColor') ? undefined : json['hairColor'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isDoingSpecials': !exists(json, 'isDoingSpecials') ? undefined : json['isDoingSpecials'],
        'durationInHours': !exists(json, 'durationInHours') ? undefined : json['durationInHours'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'buyout': !exists(json, 'buyout') ? undefined : ProjectRoleBuyoutFromJSON(json['buyout']),
        'modelIds': !exists(json, 'modelIds') ? undefined : json['modelIds'],
        'casting': !exists(json, 'casting') ? undefined : ProjectCastingFromJSON(json['casting']),
    };
}

export function ProjectRoleToJSON(value?: ProjectRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectRoleId': value.projectRoleId,
        'name': value.name,
        'sex': value.sex,
        'ageFrom': value.ageFrom,
        'ageTo': value.ageTo,
        'hairColor': value.hairColor,
        'category': value.category,
        'description': value.description,
        'isDoingSpecials': value.isDoingSpecials,
        'durationInHours': value.durationInHours,
        'fee': value.fee,
        'buyout': ProjectRoleBuyoutToJSON(value.buyout),
        'modelIds': value.modelIds,
        'casting': ProjectCastingToJSON(value.casting),
    };
}

