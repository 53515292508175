/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DetailsCastingFormValuesDto } from './DetailsCastingFormValuesDto';
import {
    DetailsCastingFormValuesDtoFromJSON,
    DetailsCastingFormValuesDtoFromJSONTyped,
    DetailsCastingFormValuesDtoToJSON,
} from './DetailsCastingFormValuesDto';

/**
 * 
 * @export
 * @interface DetailsCastingFormValuesDtoList
 */
export interface DetailsCastingFormValuesDtoList {
    /**
     * 
     * @type {Array<DetailsCastingFormValuesDto>}
     * @memberof DetailsCastingFormValuesDtoList
     */
    formValuesDtos?: Array<DetailsCastingFormValuesDto>;
}

/**
 * Check if a given object implements the DetailsCastingFormValuesDtoList interface.
 */
export function instanceOfDetailsCastingFormValuesDtoList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DetailsCastingFormValuesDtoListFromJSON(json: any): DetailsCastingFormValuesDtoList {
    return DetailsCastingFormValuesDtoListFromJSONTyped(json, false);
}

export function DetailsCastingFormValuesDtoListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailsCastingFormValuesDtoList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formValuesDtos': !exists(json, 'formValuesDtos') ? undefined : ((json['formValuesDtos'] as Array<any>).map(DetailsCastingFormValuesDtoFromJSON)),
    };
}

export function DetailsCastingFormValuesDtoListToJSON(value?: DetailsCastingFormValuesDtoList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formValuesDtos': value.formValuesDtos === undefined ? undefined : ((value.formValuesDtos as Array<any>).map(DetailsCastingFormValuesDtoToJSON)),
    };
}

