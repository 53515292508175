/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorDto } from './ErrorDto';
import {
    ErrorDtoFromJSON,
    ErrorDtoFromJSONTyped,
    ErrorDtoToJSON,
} from './ErrorDto';
import type { ModelCostDto } from './ModelCostDto';
import {
    ModelCostDtoFromJSON,
    ModelCostDtoFromJSONTyped,
    ModelCostDtoToJSON,
} from './ModelCostDto';

/**
 * 
 * @export
 * @interface CreateModelCostResponseDto
 */
export interface CreateModelCostResponseDto {
    /**
     * 
     * @type {ModelCostDto}
     * @memberof CreateModelCostResponseDto
     */
    modelCost?: ModelCostDto;
    /**
     * 
     * @type {ErrorDto}
     * @memberof CreateModelCostResponseDto
     */
    error?: ErrorDto;
}

/**
 * Check if a given object implements the CreateModelCostResponseDto interface.
 */
export function instanceOfCreateModelCostResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateModelCostResponseDtoFromJSON(json: any): CreateModelCostResponseDto {
    return CreateModelCostResponseDtoFromJSONTyped(json, false);
}

export function CreateModelCostResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateModelCostResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelCost': !exists(json, 'modelCost') ? undefined : ModelCostDtoFromJSON(json['modelCost']),
        'error': !exists(json, 'error') ? undefined : ErrorDtoFromJSON(json['error']),
    };
}

export function CreateModelCostResponseDtoToJSON(value?: CreateModelCostResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelCost': ModelCostDtoToJSON(value.modelCost),
        'error': ErrorDtoToJSON(value.error),
    };
}

