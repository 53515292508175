/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveStateFormValuesDto
 */
export interface ActiveStateFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ActiveStateFormValuesDto
     */
    activeState?: ActiveStateFormValuesDtoActiveStateEnum;
}


/**
 * @export
 */
export const ActiveStateFormValuesDtoActiveStateEnum = {
    Active: 'Active',
    Inactive: 'Inactive'
} as const;
export type ActiveStateFormValuesDtoActiveStateEnum = typeof ActiveStateFormValuesDtoActiveStateEnum[keyof typeof ActiveStateFormValuesDtoActiveStateEnum];


/**
 * Check if a given object implements the ActiveStateFormValuesDto interface.
 */
export function instanceOfActiveStateFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActiveStateFormValuesDtoFromJSON(json: any): ActiveStateFormValuesDto {
    return ActiveStateFormValuesDtoFromJSONTyped(json, false);
}

export function ActiveStateFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveStateFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeState': !exists(json, 'activeState') ? undefined : json['activeState'],
    };
}

export function ActiveStateFormValuesDtoToJSON(value?: ActiveStateFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeState': value.activeState,
    };
}

