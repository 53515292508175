/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAgencyBookerRequestDto
 */
export interface CreateAgencyBookerRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyBookerRequestDto
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyBookerRequestDto
     */
    bookerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyBookerRequestDto
     */
    bookerSex?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyBookerRequestDto
     */
    bookerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyBookerRequestDto
     */
    bookerMobile?: string;
}

/**
 * Check if a given object implements the CreateAgencyBookerRequestDto interface.
 */
export function instanceOfCreateAgencyBookerRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAgencyBookerRequestDtoFromJSON(json: any): CreateAgencyBookerRequestDto {
    return CreateAgencyBookerRequestDtoFromJSONTyped(json, false);
}

export function CreateAgencyBookerRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgencyBookerRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
        'bookerSex': !exists(json, 'bookerSex') ? undefined : json['bookerSex'],
        'bookerEmail': !exists(json, 'bookerEmail') ? undefined : json['bookerEmail'],
        'bookerMobile': !exists(json, 'bookerMobile') ? undefined : json['bookerMobile'],
    };
}

export function CreateAgencyBookerRequestDtoToJSON(value?: CreateAgencyBookerRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
        'bookerSex': value.bookerSex,
        'bookerEmail': value.bookerEmail,
        'bookerMobile': value.bookerMobile,
    };
}

