/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteEmployeeCommand
 */
export interface DeleteEmployeeCommand {
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteEmployeeCommand
     */
    employeeUuid?: string;
}

/**
 * Check if a given object implements the DeleteEmployeeCommand interface.
 */
export function instanceOfDeleteEmployeeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteEmployeeCommandFromJSON(json: any): DeleteEmployeeCommand {
    return DeleteEmployeeCommandFromJSONTyped(json, false);
}

export function DeleteEmployeeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteEmployeeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'employeeUuid': !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
    };
}

export function DeleteEmployeeCommandToJSON(value?: DeleteEmployeeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'employeeUuid': value.employeeUuid,
    };
}

