/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdminValue } from './adminValue';
import { AppointmentCreator } from './appointmentCreator';


export interface Appointment { 
    appointmentId?: string;
    modelId?: string;
    projectId?: string;
    status?: Appointment.StatusEnum;
    type?: Appointment.TypeEnum;
    requestTimeFrameOption?: AdminValue;
    title?: string;
    description?: string;
    from?: string;
    to?: string;
    fromAsIsoString?: string;
    toAsIsoString?: string;
    createdAt?: string;
    creator?: AppointmentCreator;
    answeredAt?: string;
    projectRoleId?: string;
    confirmedRequest?: boolean;
}
export namespace Appointment {
    export type StatusEnum = 'REQUESTED' | 'MAYBE' | 'CONFIRMED' | 'CANCELED';
    export const StatusEnum = {
        Requested: 'REQUESTED' as StatusEnum,
        Maybe: 'MAYBE' as StatusEnum,
        Confirmed: 'CONFIRMED' as StatusEnum,
        Canceled: 'CANCELED' as StatusEnum
    };
    export type TypeEnum = 'FIRST_OPTION' | 'SECOND_OPTION' | 'THIRD_OPTION' | 'OTHER_FIRST_OPTION' | 'OTHER_SECOND_OPTION' | 'OTHER_THIRD_OPTION' | 'PRODUCTION' | 'OTHER_PRODUCTION' | 'FIRST_FOREIGN_OPTION' | 'SECOND_FOREIGN_OPTION' | 'THIRD_FOREIGN_OPTION' | 'ARRIVAL_DEPARTURE' | 'SICK' | 'EXTERNAL_BOOKED' | 'HOLIDAY' | 'DIFFERENT' | 'PREGNANCY' | 'REQUEST_TIMEFRAME' | 'MODEL_DECLINED';
    export const TypeEnum = {
        FirstOption: 'FIRST_OPTION' as TypeEnum,
        SecondOption: 'SECOND_OPTION' as TypeEnum,
        ThirdOption: 'THIRD_OPTION' as TypeEnum,
        OtherFirstOption: 'OTHER_FIRST_OPTION' as TypeEnum,
        OtherSecondOption: 'OTHER_SECOND_OPTION' as TypeEnum,
        OtherThirdOption: 'OTHER_THIRD_OPTION' as TypeEnum,
        Production: 'PRODUCTION' as TypeEnum,
        OtherProduction: 'OTHER_PRODUCTION' as TypeEnum,
        FirstForeignOption: 'FIRST_FOREIGN_OPTION' as TypeEnum,
        SecondForeignOption: 'SECOND_FOREIGN_OPTION' as TypeEnum,
        ThirdForeignOption: 'THIRD_FOREIGN_OPTION' as TypeEnum,
        ArrivalDeparture: 'ARRIVAL_DEPARTURE' as TypeEnum,
        Sick: 'SICK' as TypeEnum,
        ExternalBooked: 'EXTERNAL_BOOKED' as TypeEnum,
        Holiday: 'HOLIDAY' as TypeEnum,
        Different: 'DIFFERENT' as TypeEnum,
        Pregnancy: 'PREGNANCY' as TypeEnum,
        RequestTimeframe: 'REQUEST_TIMEFRAME' as TypeEnum,
        ModelDeclined: 'MODEL_DECLINED' as TypeEnum
    };
}


