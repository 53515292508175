/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelMasterDataForMappingToProjectModel
 */
export interface ModelMasterDataForMappingToProjectModel {
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    brodyUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    dailyRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataForMappingToProjectModel
     */
    oauthUserId?: string;
}

/**
 * Check if a given object implements the ModelMasterDataForMappingToProjectModel interface.
 */
export function instanceOfModelMasterDataForMappingToProjectModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelMasterDataForMappingToProjectModelFromJSON(json: any): ModelMasterDataForMappingToProjectModel {
    return ModelMasterDataForMappingToProjectModelFromJSONTyped(json, false);
}

export function ModelMasterDataForMappingToProjectModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataForMappingToProjectModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'brodyUserId': !exists(json, 'brodyUserId') ? undefined : json['brodyUserId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'oauthUserId': !exists(json, 'oauthUserId') ? undefined : json['oauthUserId'],
    };
}

export function ModelMasterDataForMappingToProjectModelToJSON(value?: ModelMasterDataForMappingToProjectModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'image': value.image,
        'brodyUserId': value.brodyUserId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mobile': value.mobile,
        'phone': value.phone,
        'email': value.email,
        'dailyRate': value.dailyRate,
        'oauthUserId': value.oauthUserId,
    };
}

