/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerId } from './CustomerId';
import {
    CustomerIdFromJSON,
    CustomerIdFromJSONTyped,
    CustomerIdToJSON,
} from './CustomerId';

/**
 * 
 * @export
 * @interface CustomerTableItemDto
 */
export interface CustomerTableItemDto {
    /**
     * 
     * @type {CustomerId}
     * @memberof CustomerTableItemDto
     */
    customerId: CustomerId;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTableItemDto
     */
    lastEdited?: string;
}

/**
 * Check if a given object implements the CustomerTableItemDto interface.
 */
export function instanceOfCustomerTableItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CustomerTableItemDtoFromJSON(json: any): CustomerTableItemDto {
    return CustomerTableItemDtoFromJSONTyped(json, false);
}

export function CustomerTableItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerTableItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': CustomerIdFromJSON(json['customerId']),
        'name': json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'lastEdited': !exists(json, 'lastEdited') ? undefined : json['lastEdited'],
    };
}

export function CustomerTableItemDtoToJSON(value?: CustomerTableItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': CustomerIdToJSON(value.customerId),
        'name': value.name,
        'city': value.city,
        'zipCode': value.zipCode,
        'phone': value.phone,
        'url': value.url,
        'lastEdited': value.lastEdited,
    };
}

