/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargeableProjectFormValuesDto } from './ChargeableProjectFormValuesDto';
import {
    ChargeableProjectFormValuesDtoFromJSON,
    ChargeableProjectFormValuesDtoFromJSONTyped,
    ChargeableProjectFormValuesDtoToJSON,
} from './ChargeableProjectFormValuesDto';
import type { ModelBillingCostFormValuesDto } from './ModelBillingCostFormValuesDto';
import {
    ModelBillingCostFormValuesDtoFromJSON,
    ModelBillingCostFormValuesDtoFromJSONTyped,
    ModelBillingCostFormValuesDtoToJSON,
} from './ModelBillingCostFormValuesDto';

/**
 * 
 * @export
 * @interface ModelBillingFormValuesDto
 */
export interface ModelBillingFormValuesDto {
    /**
     * 
     * @type {Array<ChargeableProjectFormValuesDto>}
     * @memberof ModelBillingFormValuesDto
     */
    chargeableProjects?: Array<ChargeableProjectFormValuesDto>;
    /**
     * 
     * @type {Array<ModelBillingCostFormValuesDto>}
     * @memberof ModelBillingFormValuesDto
     */
    billingCosts?: Array<ModelBillingCostFormValuesDto>;
}

/**
 * Check if a given object implements the ModelBillingFormValuesDto interface.
 */
export function instanceOfModelBillingFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelBillingFormValuesDtoFromJSON(json: any): ModelBillingFormValuesDto {
    return ModelBillingFormValuesDtoFromJSONTyped(json, false);
}

export function ModelBillingFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelBillingFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chargeableProjects': !exists(json, 'chargeableProjects') ? undefined : ((json['chargeableProjects'] as Array<any>).map(ChargeableProjectFormValuesDtoFromJSON)),
        'billingCosts': !exists(json, 'billingCosts') ? undefined : ((json['billingCosts'] as Array<any>).map(ModelBillingCostFormValuesDtoFromJSON)),
    };
}

export function ModelBillingFormValuesDtoToJSON(value?: ModelBillingFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chargeableProjects': value.chargeableProjects === undefined ? undefined : ((value.chargeableProjects as Array<any>).map(ChargeableProjectFormValuesDtoToJSON)),
        'billingCosts': value.billingCosts === undefined ? undefined : ((value.billingCosts as Array<any>).map(ModelBillingCostFormValuesDtoToJSON)),
    };
}

