/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestTimeframesDto } from './RequestTimeframesDto';
import {
    RequestTimeframesDtoFromJSON,
    RequestTimeframesDtoFromJSONTyped,
    RequestTimeframesDtoToJSON,
} from './RequestTimeframesDto';

/**
 * 
 * @export
 * @interface GetAppointmentsRequestDto
 */
export interface GetAppointmentsRequestDto {
    /**
     * 
     * @type {Date}
     * @memberof GetAppointmentsRequestDto
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAppointmentsRequestDto
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAppointmentsRequestDto
     */
    language?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAppointmentsRequestDto
     */
    appointmentTypes?: Array<GetAppointmentsRequestDtoAppointmentTypesEnum>;
    /**
     * 
     * @type {RequestTimeframesDto}
     * @memberof GetAppointmentsRequestDto
     */
    requestTimeframesDto?: RequestTimeframesDto;
}


/**
 * @export
 */
export const GetAppointmentsRequestDtoAppointmentTypesEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED'
} as const;
export type GetAppointmentsRequestDtoAppointmentTypesEnum = typeof GetAppointmentsRequestDtoAppointmentTypesEnum[keyof typeof GetAppointmentsRequestDtoAppointmentTypesEnum];


/**
 * Check if a given object implements the GetAppointmentsRequestDto interface.
 */
export function instanceOfGetAppointmentsRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAppointmentsRequestDtoFromJSON(json: any): GetAppointmentsRequestDto {
    return GetAppointmentsRequestDtoFromJSONTyped(json, false);
}

export function GetAppointmentsRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAppointmentsRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'language': !exists(json, 'language') ? undefined : json['language'],
        'appointmentTypes': !exists(json, 'appointmentTypes') ? undefined : json['appointmentTypes'],
        'requestTimeframesDto': !exists(json, 'requestTimeframesDto') ? undefined : RequestTimeframesDtoFromJSON(json['requestTimeframesDto']),
    };
}

export function GetAppointmentsRequestDtoToJSON(value?: GetAppointmentsRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
        'language': value.language,
        'appointmentTypes': value.appointmentTypes,
        'requestTimeframesDto': RequestTimeframesDtoToJSON(value.requestTimeframesDto),
    };
}

