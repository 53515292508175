/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    skype?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    twitter?: string;
}

/**
 * Check if a given object implements the ContactDto interface.
 */
export function instanceOfContactDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactDtoFromJSON(json: any): ContactDto {
    return ContactDtoFromJSONTyped(json, false);
}

export function ContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'skype': !exists(json, 'skype') ? undefined : json['skype'],
        'facebook': !exists(json, 'facebook') ? undefined : json['facebook'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'twitter': !exists(json, 'twitter') ? undefined : json['twitter'],
    };
}

export function ContactDtoToJSON(value?: ContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile': value.mobile,
        'email': value.email,
        'phone': value.phone,
        'website': value.website,
        'skype': value.skype,
        'facebook': value.facebook,
        'instagram': value.instagram,
        'twitter': value.twitter,
    };
}

