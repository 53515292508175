/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskManagerInfos } from './TaskManagerInfos';
import {
    TaskManagerInfosFromJSON,
    TaskManagerInfosFromJSONTyped,
    TaskManagerInfosToJSON,
} from './TaskManagerInfos';

/**
 * 
 * @export
 * @interface ProjectInfo
 */
export interface ProjectInfo {
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    workingName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    finalCustomer?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    lastUpdate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    lastEditor?: string;
    /**
     * 
     * @type {TaskManagerInfos}
     * @memberof ProjectInfo
     */
    taskManagerInfos?: TaskManagerInfos;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    creator?: string;
}

/**
 * Check if a given object implements the ProjectInfo interface.
 */
export function instanceOfProjectInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectInfoFromJSON(json: any): ProjectInfo {
    return ProjectInfoFromJSONTyped(json, false);
}

export function ProjectInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'workingName': !exists(json, 'workingName') ? undefined : json['workingName'],
        'finalCustomer': !exists(json, 'finalCustomer') ? undefined : json['finalCustomer'],
        'lastUpdate': !exists(json, 'lastUpdate') ? undefined : json['lastUpdate'],
        'lastEditor': !exists(json, 'lastEditor') ? undefined : json['lastEditor'],
        'taskManagerInfos': !exists(json, 'taskManagerInfos') ? undefined : TaskManagerInfosFromJSON(json['taskManagerInfos']),
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
    };
}

export function ProjectInfoToJSON(value?: ProjectInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'workingName': value.workingName,
        'finalCustomer': value.finalCustomer,
        'lastUpdate': value.lastUpdate,
        'lastEditor': value.lastEditor,
        'taskManagerInfos': TaskManagerInfosToJSON(value.taskManagerInfos),
        'creator': value.creator,
    };
}

