/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { BahnCard } from './BahnCard';
import {
    BahnCardFromJSON,
    BahnCardFromJSONTyped,
    BahnCardToJSON,
} from './BahnCard';
import type { BankAccountInformation } from './BankAccountInformation';
import {
    BankAccountInformationFromJSON,
    BankAccountInformationFromJSONTyped,
    BankAccountInformationToJSON,
} from './BankAccountInformation';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { PersonalInfos } from './PersonalInfos';
import {
    PersonalInfosFromJSON,
    PersonalInfosFromJSONTyped,
    PersonalInfosToJSON,
} from './PersonalInfos';
import type { Sport } from './Sport';
import {
    SportFromJSON,
    SportFromJSONTyped,
    SportToJSON,
} from './Sport';

/**
 * 
 * @export
 * @interface PublicModelSaveDto
 */
export interface PublicModelSaveDto {
    /**
     * 
     * @type {PersonalInfos}
     * @memberof PublicModelSaveDto
     */
    personalInfos?: PersonalInfos;
    /**
     * 
     * @type {Contact}
     * @memberof PublicModelSaveDto
     */
    contactInfos?: Contact;
    /**
     * 
     * @type {Address}
     * @memberof PublicModelSaveDto
     */
    livingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof PublicModelSaveDto
     */
    notifyAddress?: Address;
    /**
     * 
     * @type {BankAccountInformation}
     * @memberof PublicModelSaveDto
     */
    bankAccountInformation?: BankAccountInformation;
    /**
     * 
     * @type {BahnCard}
     * @memberof PublicModelSaveDto
     */
    bahnCard?: BahnCard;
    /**
     * 
     * @type {Array<Sport>}
     * @memberof PublicModelSaveDto
     */
    sports?: Array<Sport>;
}

/**
 * Check if a given object implements the PublicModelSaveDto interface.
 */
export function instanceOfPublicModelSaveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicModelSaveDtoFromJSON(json: any): PublicModelSaveDto {
    return PublicModelSaveDtoFromJSONTyped(json, false);
}

export function PublicModelSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicModelSaveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalInfos': !exists(json, 'personalInfos') ? undefined : PersonalInfosFromJSON(json['personalInfos']),
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ContactFromJSON(json['contactInfos']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressFromJSON(json['notifyAddress']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFromJSON(json['bankAccountInformation']),
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardFromJSON(json['bahnCard']),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportFromJSON)),
    };
}

export function PublicModelSaveDtoToJSON(value?: PublicModelSaveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalInfos': PersonalInfosToJSON(value.personalInfos),
        'contactInfos': ContactToJSON(value.contactInfos),
        'livingAddress': AddressToJSON(value.livingAddress),
        'notifyAddress': AddressToJSON(value.notifyAddress),
        'bankAccountInformation': BankAccountInformationToJSON(value.bankAccountInformation),
        'bahnCard': BahnCardToJSON(value.bahnCard),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportToJSON)),
    };
}

