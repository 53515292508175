/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelingExperienceFormValuesDto
 */
export interface ModelingExperienceFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ModelingExperienceFormValuesDto
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelingExperienceFormValuesDto
     */
    experiences?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelingExperienceFormValuesDto
     */
    modellingLevelOptions?: Array<string>;
}

/**
 * Check if a given object implements the ModelingExperienceFormValuesDto interface.
 */
export function instanceOfModelingExperienceFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelingExperienceFormValuesDtoFromJSON(json: any): ModelingExperienceFormValuesDto {
    return ModelingExperienceFormValuesDtoFromJSONTyped(json, false);
}

export function ModelingExperienceFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelingExperienceFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': !exists(json, 'level') ? undefined : json['level'],
        'experiences': !exists(json, 'experiences') ? undefined : json['experiences'],
        'modellingLevelOptions': !exists(json, 'modellingLevelOptions') ? undefined : json['modellingLevelOptions'],
    };
}

export function ModelingExperienceFormValuesDtoToJSON(value?: ModelingExperienceFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'experiences': value.experiences,
        'modellingLevelOptions': value.modellingLevelOptions,
    };
}

