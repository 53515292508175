/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectMotifFormValuesDto
 */
export interface ProjectMotifFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectMotifFormValuesDto
     */
    motifName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMotifFormValuesDto
     */
    motifDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMotifFormValuesDto
     */
    motifLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMotifFormValuesDto
     */
    motifId?: string;
}

/**
 * Check if a given object implements the ProjectMotifFormValuesDto interface.
 */
export function instanceOfProjectMotifFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectMotifFormValuesDtoFromJSON(json: any): ProjectMotifFormValuesDto {
    return ProjectMotifFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectMotifFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectMotifFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'motifName': !exists(json, 'motifName') ? undefined : json['motifName'],
        'motifDescription': !exists(json, 'motifDescription') ? undefined : json['motifDescription'],
        'motifLocation': !exists(json, 'motifLocation') ? undefined : json['motifLocation'],
        'motifId': !exists(json, 'motifId') ? undefined : json['motifId'],
    };
}

export function ProjectMotifFormValuesDtoToJSON(value?: ProjectMotifFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'motifName': value.motifName,
        'motifDescription': value.motifDescription,
        'motifLocation': value.motifLocation,
        'motifId': value.motifId,
    };
}

