/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ColorsAndTypesFormValuesDto
 */
export interface ColorsAndTypesFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    skinType?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    skinColor?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    colorEye1?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    colorEye2?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairColor?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairColorNuance?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairLength?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairStyle?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    skinTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    skinColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    eyeColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairColorNuanceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairLengthOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ColorsAndTypesFormValuesDto
     */
    hairStyleOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ColorsAndTypesFormValuesDto interface.
 */
export function instanceOfColorsAndTypesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColorsAndTypesFormValuesDtoFromJSON(json: any): ColorsAndTypesFormValuesDto {
    return ColorsAndTypesFormValuesDtoFromJSONTyped(json, false);
}

export function ColorsAndTypesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsAndTypesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skinType': !exists(json, 'skinType') ? undefined : AdminValueFromJSON(json['skinType']),
        'skinColor': !exists(json, 'skinColor') ? undefined : AdminValueFromJSON(json['skinColor']),
        'colorEye1': !exists(json, 'color_eye1') ? undefined : AdminValueFromJSON(json['color_eye1']),
        'colorEye2': !exists(json, 'color_eye2') ? undefined : AdminValueFromJSON(json['color_eye2']),
        'hairColor': !exists(json, 'hairColor') ? undefined : AdminValueFromJSON(json['hairColor']),
        'hairColorNuance': !exists(json, 'hairColorNuance') ? undefined : AdminValueFromJSON(json['hairColorNuance']),
        'hairLength': !exists(json, 'hairLength') ? undefined : AdminValueFromJSON(json['hairLength']),
        'hairStyle': !exists(json, 'hairStyle') ? undefined : AdminValueFromJSON(json['hairStyle']),
        'skinTypeOptions': !exists(json, 'skinTypeOptions') ? undefined : ((json['skinTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'skinColorOptions': !exists(json, 'skinColorOptions') ? undefined : ((json['skinColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'eyeColorOptions': !exists(json, 'eyeColorOptions') ? undefined : ((json['eyeColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairColorOptions': !exists(json, 'hairColorOptions') ? undefined : ((json['hairColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairColorNuanceOptions': !exists(json, 'hairColorNuanceOptions') ? undefined : ((json['hairColorNuanceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairLengthOptions': !exists(json, 'hairLengthOptions') ? undefined : ((json['hairLengthOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairStyleOptions': !exists(json, 'hairStyleOptions') ? undefined : ((json['hairStyleOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ColorsAndTypesFormValuesDtoToJSON(value?: ColorsAndTypesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skinType': AdminValueToJSON(value.skinType),
        'skinColor': AdminValueToJSON(value.skinColor),
        'color_eye1': AdminValueToJSON(value.colorEye1),
        'color_eye2': AdminValueToJSON(value.colorEye2),
        'hairColor': AdminValueToJSON(value.hairColor),
        'hairColorNuance': AdminValueToJSON(value.hairColorNuance),
        'hairLength': AdminValueToJSON(value.hairLength),
        'hairStyle': AdminValueToJSON(value.hairStyle),
        'skinTypeOptions': value.skinTypeOptions === undefined ? undefined : ((value.skinTypeOptions as Array<any>).map(AdminValueToJSON)),
        'skinColorOptions': value.skinColorOptions === undefined ? undefined : ((value.skinColorOptions as Array<any>).map(AdminValueToJSON)),
        'eyeColorOptions': value.eyeColorOptions === undefined ? undefined : ((value.eyeColorOptions as Array<any>).map(AdminValueToJSON)),
        'hairColorOptions': value.hairColorOptions === undefined ? undefined : ((value.hairColorOptions as Array<any>).map(AdminValueToJSON)),
        'hairColorNuanceOptions': value.hairColorNuanceOptions === undefined ? undefined : ((value.hairColorNuanceOptions as Array<any>).map(AdminValueToJSON)),
        'hairLengthOptions': value.hairLengthOptions === undefined ? undefined : ((value.hairLengthOptions as Array<any>).map(AdminValueToJSON)),
        'hairStyleOptions': value.hairStyleOptions === undefined ? undefined : ((value.hairStyleOptions as Array<any>).map(AdminValueToJSON)),
    };
}

