import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './service/app.auth.service';

@Component({
    templateUrl: './app.login.component.html'
})

export class LoginComponent implements OnInit {

    constructor(public auth: AuthenticationService) {
    }

    ngOnInit(): void {
        this.auth.handleLogin()
    }
}
