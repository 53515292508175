/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingModel
 */
export interface BookingModel {
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    travelCosts?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    bookingStatus?: BookingModelBookingStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BookingModel
     */
    isConfirmed: boolean;
    /**
     * 
     * @type {number}
     * @memberof BookingModel
     */
    bookingId: number;
    /**
     * 
     * @type {Date}
     * @memberof BookingModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModel
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModel
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingModel
     */
    end: Date;
}


/**
 * @export
 */
export const BookingModelBookingStatusEnum = {
    Transacted: 'TRANSACTED',
    Confirmed: 'CONFIRMED'
} as const;
export type BookingModelBookingStatusEnum = typeof BookingModelBookingStatusEnum[keyof typeof BookingModelBookingStatusEnum];


/**
 * Check if a given object implements the BookingModel interface.
 */
export function instanceOfBookingModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isConfirmed" in value;
    isInstance = isInstance && "bookingId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

    return isInstance;
}

export function BookingModelFromJSON(json: any): BookingModel {
    return BookingModelFromJSONTyped(json, false);
}

export function BookingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelCosts': !exists(json, 'travelCosts') ? undefined : json['travelCosts'],
        'bookingStatus': !exists(json, 'bookingStatus') ? undefined : json['bookingStatus'],
        'isConfirmed': json['isConfirmed'],
        'bookingId': json['bookingId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function BookingModelToJSON(value?: BookingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelCosts': value.travelCosts,
        'bookingStatus': value.bookingStatus,
        'isConfirmed': value.isConfirmed,
        'bookingId': value.bookingId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
    };
}

