/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminValue
 */
export interface AdminValue {
    /**
     * 
     * @type {string}
     * @memberof AdminValue
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminValue
     */
    value?: string;
}

/**
 * Check if a given object implements the AdminValue interface.
 */
export function instanceOfAdminValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminValueFromJSON(json: any): AdminValue {
    return AdminValueFromJSONTyped(json, false);
}

export function AdminValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AdminValueToJSON(value?: AdminValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}

