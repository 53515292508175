import {Component, Input} from "@angular/core";
import {CustomerNamesForMessengerDto, ParticipantResponseDto} from "@brody-bookings/api";
import {ConversationService} from "../../../service/conversation.service";

@Component({
    selector: 'new-conversation-modal',
    templateUrl: './newConversationModal.component.html'
})

export class NewConversationModalComponent {
    @Input() titleInput!: string;
    @Input() onTitleInput!: (event: any) => void;
    @Input() display!: boolean;

    showModelSearch: boolean = false
    showEmployeeSearch: boolean = false
    showProjectSearch: boolean = false

    @Input() currentFilterInput!: string

    @Input() selectedParticipants!: ParticipantResponseDto[]
    @Input() onAddToParticipantList!: (participant: ParticipantResponseDto) => void
    @Input() onConfirmSelection!: () => void
    @Input() onRemoveParticipant!: (participant: ParticipantResponseDto) => void

    constructor(
        private conversationService: ConversationService
    ) {
    }

    onToggleModelSearch() {
        this.conversationService.getModelsAsParticipants()
        this.showModelSearch = !this.showModelSearch
    }

    onToggleEmployeeSearch() {
        this.conversationService.getCustomerNames()
        this.showEmployeeSearch = !this.showEmployeeSearch
    }

    onToggleProjectSearch() {
        this.conversationService.getProjectNames()
        this.showProjectSearch = !this.showProjectSearch
    }


}