/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagingInformation } from './PagingInformation';
import {
    PagingInformationFromJSON,
    PagingInformationFromJSONTyped,
    PagingInformationToJSON,
} from './PagingInformation';
import type { ProjectTableItemDto } from './ProjectTableItemDto';
import {
    ProjectTableItemDtoFromJSON,
    ProjectTableItemDtoFromJSONTyped,
    ProjectTableItemDtoToJSON,
} from './ProjectTableItemDto';

/**
 * 
 * @export
 * @interface ProjectTableItemsDto
 */
export interface ProjectTableItemsDto {
    /**
     * 
     * @type {Array<ProjectTableItemDto>}
     * @memberof ProjectTableItemsDto
     */
    projectTableItems: Array<ProjectTableItemDto>;
    /**
     * 
     * @type {PagingInformation}
     * @memberof ProjectTableItemsDto
     */
    pagingInformation: PagingInformation;
}

/**
 * Check if a given object implements the ProjectTableItemsDto interface.
 */
export function instanceOfProjectTableItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectTableItems" in value;
    isInstance = isInstance && "pagingInformation" in value;

    return isInstance;
}

export function ProjectTableItemsDtoFromJSON(json: any): ProjectTableItemsDto {
    return ProjectTableItemsDtoFromJSONTyped(json, false);
}

export function ProjectTableItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTableItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTableItems': ((json['projectTableItems'] as Array<any>).map(ProjectTableItemDtoFromJSON)),
        'pagingInformation': PagingInformationFromJSON(json['pagingInformation']),
    };
}

export function ProjectTableItemsDtoToJSON(value?: ProjectTableItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectTableItems': ((value.projectTableItems as Array<any>).map(ProjectTableItemDtoToJSON)),
        'pagingInformation': PagingInformationToJSON(value.pagingInformation),
    };
}

