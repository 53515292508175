/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProtocolFormValuesDto } from './ProtocolFormValuesDto';
import {
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoFromJSONTyped,
    ProtocolFormValuesDtoToJSON,
} from './ProtocolFormValuesDto';

/**
 * 
 * @export
 * @interface ProtocolsFormValuesDto
 */
export interface ProtocolsFormValuesDto {
    /**
     * 
     * @type {Array<ProtocolFormValuesDto>}
     * @memberof ProtocolsFormValuesDto
     */
    protocols?: Array<ProtocolFormValuesDto>;
}

/**
 * Check if a given object implements the ProtocolsFormValuesDto interface.
 */
export function instanceOfProtocolsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProtocolsFormValuesDtoFromJSON(json: any): ProtocolsFormValuesDto {
    return ProtocolsFormValuesDtoFromJSONTyped(json, false);
}

export function ProtocolsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocols': !exists(json, 'protocols') ? undefined : ((json['protocols'] as Array<any>).map(ProtocolFormValuesDtoFromJSON)),
    };
}

export function ProtocolsFormValuesDtoToJSON(value?: ProtocolsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocols': value.protocols === undefined ? undefined : ((value.protocols as Array<any>).map(ProtocolFormValuesDtoToJSON)),
    };
}

