/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelCostDto } from './ModelCostDto';
import {
    ModelCostDtoFromJSON,
    ModelCostDtoFromJSONTyped,
    ModelCostDtoToJSON,
} from './ModelCostDto';

/**
 * 
 * @export
 * @interface ModelCostsResponseDto
 */
export interface ModelCostsResponseDto {
    /**
     * 
     * @type {Array<ModelCostDto>}
     * @memberof ModelCostsResponseDto
     */
    modelCosts: Array<ModelCostDto>;
}

/**
 * Check if a given object implements the ModelCostsResponseDto interface.
 */
export function instanceOfModelCostsResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelCosts" in value;

    return isInstance;
}

export function ModelCostsResponseDtoFromJSON(json: any): ModelCostsResponseDto {
    return ModelCostsResponseDtoFromJSONTyped(json, false);
}

export function ModelCostsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelCostsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelCosts': ((json['modelCosts'] as Array<any>).map(ModelCostDtoFromJSON)),
    };
}

export function ModelCostsResponseDtoToJSON(value?: ModelCostsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelCosts': ((value.modelCosts as Array<any>).map(ModelCostDtoToJSON)),
    };
}

