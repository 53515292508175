/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyAccessoriesFormValuesDto
 */
export interface BodyAccessoriesFormValuesDto {
    /**
     * 
     * @type {boolean}
     * @memberof BodyAccessoriesFormValuesDto
     */
    earHole?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyAccessoriesFormValuesDto
     */
    scars?: string;
}

/**
 * Check if a given object implements the BodyAccessoriesFormValuesDto interface.
 */
export function instanceOfBodyAccessoriesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BodyAccessoriesFormValuesDtoFromJSON(json: any): BodyAccessoriesFormValuesDto {
    return BodyAccessoriesFormValuesDtoFromJSONTyped(json, false);
}

export function BodyAccessoriesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyAccessoriesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'earHole': !exists(json, 'earHole') ? undefined : json['earHole'],
        'piercings': !exists(json, 'piercings') ? undefined : json['piercings'],
        'tattoos': !exists(json, 'tattoos') ? undefined : json['tattoos'],
        'scars': !exists(json, 'scars') ? undefined : json['scars'],
    };
}

export function BodyAccessoriesFormValuesDtoToJSON(value?: BodyAccessoriesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'earHole': value.earHole,
        'piercings': value.piercings,
        'tattoos': value.tattoos,
        'scars': value.scars,
    };
}

