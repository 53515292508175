/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgencyBookerNamesForModelsDto
 */
export interface AgencyBookerNamesForModelsDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerNamesForModelsDto
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerNamesForModelsDto
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerNamesForModelsDto
     */
    bookerLastName?: string;
}

/**
 * Check if a given object implements the AgencyBookerNamesForModelsDto interface.
 */
export function instanceOfAgencyBookerNamesForModelsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBookerNamesForModelsDtoFromJSON(json: any): AgencyBookerNamesForModelsDto {
    return AgencyBookerNamesForModelsDtoFromJSONTyped(json, false);
}

export function AgencyBookerNamesForModelsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBookerNamesForModelsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
    };
}

export function AgencyBookerNamesForModelsDtoToJSON(value?: AgencyBookerNamesForModelsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookerId': value.bookerId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
    };
}

