/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveModelFromSelectionCommand
 */
export interface RemoveModelFromSelectionCommand {
    /**
     * 
     * @type {string}
     * @memberof RemoveModelFromSelectionCommand
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveModelFromSelectionCommand
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveModelFromSelectionCommand
     */
    modelId?: string;
}

/**
 * Check if a given object implements the RemoveModelFromSelectionCommand interface.
 */
export function instanceOfRemoveModelFromSelectionCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveModelFromSelectionCommandFromJSON(json: any): RemoveModelFromSelectionCommand {
    return RemoveModelFromSelectionCommandFromJSONTyped(json, false);
}

export function RemoveModelFromSelectionCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveModelFromSelectionCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
    };
}

export function RemoveModelFromSelectionCommandToJSON(value?: RemoveModelFromSelectionCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'roleId': value.roleId,
        'modelId': value.modelId,
    };
}

