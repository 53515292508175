/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelPortfolioImagesChangeOrderItemDto } from './ModelPortfolioImagesChangeOrderItemDto';
import {
    ModelPortfolioImagesChangeOrderItemDtoFromJSON,
    ModelPortfolioImagesChangeOrderItemDtoFromJSONTyped,
    ModelPortfolioImagesChangeOrderItemDtoToJSON,
} from './ModelPortfolioImagesChangeOrderItemDto';

/**
 * 
 * @export
 * @interface ModelPortfolioImagesChangeOrderDto
 */
export interface ModelPortfolioImagesChangeOrderDto {
    /**
     * 
     * @type {Array<ModelPortfolioImagesChangeOrderItemDto>}
     * @memberof ModelPortfolioImagesChangeOrderDto
     */
    images?: Array<ModelPortfolioImagesChangeOrderItemDto>;
}

/**
 * Check if a given object implements the ModelPortfolioImagesChangeOrderDto interface.
 */
export function instanceOfModelPortfolioImagesChangeOrderDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPortfolioImagesChangeOrderDtoFromJSON(json: any): ModelPortfolioImagesChangeOrderDto {
    return ModelPortfolioImagesChangeOrderDtoFromJSONTyped(json, false);
}

export function ModelPortfolioImagesChangeOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPortfolioImagesChangeOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ModelPortfolioImagesChangeOrderItemDtoFromJSON)),
    };
}

export function ModelPortfolioImagesChangeOrderDtoToJSON(value?: ModelPortfolioImagesChangeOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ModelPortfolioImagesChangeOrderItemDtoToJSON)),
    };
}

