/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelPortfolioImagesChangeOrderItemDto
 */
export interface ModelPortfolioImagesChangeOrderItemDto {
    /**
     * 
     * @type {string}
     * @memberof ModelPortfolioImagesChangeOrderItemDto
     */
    filename: string;
    /**
     * 
     * @type {number}
     * @memberof ModelPortfolioImagesChangeOrderItemDto
     */
    imageOrderTmp: number;
}

/**
 * Check if a given object implements the ModelPortfolioImagesChangeOrderItemDto interface.
 */
export function instanceOfModelPortfolioImagesChangeOrderItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "imageOrderTmp" in value;

    return isInstance;
}

export function ModelPortfolioImagesChangeOrderItemDtoFromJSON(json: any): ModelPortfolioImagesChangeOrderItemDto {
    return ModelPortfolioImagesChangeOrderItemDtoFromJSONTyped(json, false);
}

export function ModelPortfolioImagesChangeOrderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPortfolioImagesChangeOrderItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'imageOrderTmp': json['imageOrderTmp'],
    };
}

export function ModelPortfolioImagesChangeOrderItemDtoToJSON(value?: ModelPortfolioImagesChangeOrderItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'imageOrderTmp': value.imageOrderTmp,
    };
}

