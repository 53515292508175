/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingProducer
 */
export interface BookingProducer {
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    targetPerson: BookingProducerTargetPersonEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addressee: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeId: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeStreet: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeZipCode: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeCity: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeCustomer: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    addresseeCustomerSuffix: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    overtime: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    hairAndMakeup: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    styling: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    travelCosts: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    additionalNote: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingProducer
     */
    isFinished: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingProducer
     */
    status: BookingProducerStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BookingProducer
     */
    showLogo: boolean;
}


/**
 * @export
 */
export const BookingProducerTargetPersonEnum = {
    Producer: 'PRODUCER',
    AddParticipant: 'ADD_PARTICIPANT'
} as const;
export type BookingProducerTargetPersonEnum = typeof BookingProducerTargetPersonEnum[keyof typeof BookingProducerTargetPersonEnum];

/**
 * @export
 */
export const BookingProducerStatusEnum = {
    Transacted: 'TRANSACTED',
    Confirmed: 'CONFIRMED'
} as const;
export type BookingProducerStatusEnum = typeof BookingProducerStatusEnum[keyof typeof BookingProducerStatusEnum];


/**
 * Check if a given object implements the BookingProducer interface.
 */
export function instanceOfBookingProducer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "targetPerson" in value;
    isInstance = isInstance && "addressee" in value;
    isInstance = isInstance && "addresseeId" in value;
    isInstance = isInstance && "addresseeStreet" in value;
    isInstance = isInstance && "addresseeZipCode" in value;
    isInstance = isInstance && "addresseeCity" in value;
    isInstance = isInstance && "addresseeCustomer" in value;
    isInstance = isInstance && "addresseeCustomerSuffix" in value;
    isInstance = isInstance && "overtime" in value;
    isInstance = isInstance && "hairAndMakeup" in value;
    isInstance = isInstance && "styling" in value;
    isInstance = isInstance && "travelCosts" in value;
    isInstance = isInstance && "additionalNote" in value;
    isInstance = isInstance && "isFinished" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "showLogo" in value;

    return isInstance;
}

export function BookingProducerFromJSON(json: any): BookingProducer {
    return BookingProducerFromJSONTyped(json, false);
}

export function BookingProducerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingProducer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetPerson': json['targetPerson'],
        'addressee': json['addressee'],
        'addresseeId': json['addresseeId'],
        'addresseeStreet': json['addresseeStreet'],
        'addresseeZipCode': json['addresseeZipCode'],
        'addresseeCity': json['addresseeCity'],
        'addresseeCustomer': json['addresseeCustomer'],
        'addresseeCustomerSuffix': json['addresseeCustomerSuffix'],
        'overtime': json['overtime'],
        'hairAndMakeup': json['hairAndMakeup'],
        'styling': json['styling'],
        'travelCosts': json['travelCosts'],
        'additionalNote': json['additionalNote'],
        'isFinished': json['isFinished'],
        'status': json['status'],
        'showLogo': json['showLogo'],
    };
}

export function BookingProducerToJSON(value?: BookingProducer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetPerson': value.targetPerson,
        'addressee': value.addressee,
        'addresseeId': value.addresseeId,
        'addresseeStreet': value.addresseeStreet,
        'addresseeZipCode': value.addresseeZipCode,
        'addresseeCity': value.addresseeCity,
        'addresseeCustomer': value.addresseeCustomer,
        'addresseeCustomerSuffix': value.addresseeCustomerSuffix,
        'overtime': value.overtime,
        'hairAndMakeup': value.hairAndMakeup,
        'styling': value.styling,
        'travelCosts': value.travelCosts,
        'additionalNote': value.additionalNote,
        'isFinished': value.isFinished,
        'status': value.status,
        'showLogo': value.showLogo,
    };
}

