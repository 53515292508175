/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DanceFormValuesDto
 */
export interface DanceFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof DanceFormValuesDto
     */
    dance?: string;
    /**
     * 
     * @type {string}
     * @memberof DanceFormValuesDto
     */
    level?: string;
}

/**
 * Check if a given object implements the DanceFormValuesDto interface.
 */
export function instanceOfDanceFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DanceFormValuesDtoFromJSON(json: any): DanceFormValuesDto {
    return DanceFormValuesDtoFromJSONTyped(json, false);
}

export function DanceFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DanceFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dance': !exists(json, 'dance') ? undefined : json['dance'],
        'level': !exists(json, 'level') ? undefined : json['level'],
    };
}

export function DanceFormValuesDtoToJSON(value?: DanceFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dance': value.dance,
        'level': value.level,
    };
}

