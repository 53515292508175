/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectParticipantDataForMessenger } from './ProjectParticipantDataForMessenger';
import {
    ProjectParticipantDataForMessengerFromJSON,
    ProjectParticipantDataForMessengerFromJSONTyped,
    ProjectParticipantDataForMessengerToJSON,
} from './ProjectParticipantDataForMessenger';

/**
 * 
 * @export
 * @interface ProjectParticipantDataForMessengerByRole
 */
export interface ProjectParticipantDataForMessengerByRole {
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessengerByRole
     */
    role?: string;
    /**
     * 
     * @type {Array<ProjectParticipantDataForMessenger>}
     * @memberof ProjectParticipantDataForMessengerByRole
     */
    models?: Array<ProjectParticipantDataForMessenger>;
}

/**
 * Check if a given object implements the ProjectParticipantDataForMessengerByRole interface.
 */
export function instanceOfProjectParticipantDataForMessengerByRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectParticipantDataForMessengerByRoleFromJSON(json: any): ProjectParticipantDataForMessengerByRole {
    return ProjectParticipantDataForMessengerByRoleFromJSONTyped(json, false);
}

export function ProjectParticipantDataForMessengerByRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectParticipantDataForMessengerByRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : json['role'],
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(ProjectParticipantDataForMessengerFromJSON)),
    };
}

export function ProjectParticipantDataForMessengerByRoleToJSON(value?: ProjectParticipantDataForMessengerByRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(ProjectParticipantDataForMessengerToJSON)),
    };
}

