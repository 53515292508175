/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOptionsToRequestedTimeframesRequestDtoItem
 */
export interface AddOptionsToRequestedTimeframesRequestDtoItem {
    /**
     * 
     * @type {string}
     * @memberof AddOptionsToRequestedTimeframesRequestDtoItem
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOptionsToRequestedTimeframesRequestDtoItem
     */
    option?: AddOptionsToRequestedTimeframesRequestDtoItemOptionEnum;
}


/**
 * @export
 */
export const AddOptionsToRequestedTimeframesRequestDtoItemOptionEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED'
} as const;
export type AddOptionsToRequestedTimeframesRequestDtoItemOptionEnum = typeof AddOptionsToRequestedTimeframesRequestDtoItemOptionEnum[keyof typeof AddOptionsToRequestedTimeframesRequestDtoItemOptionEnum];


/**
 * Check if a given object implements the AddOptionsToRequestedTimeframesRequestDtoItem interface.
 */
export function instanceOfAddOptionsToRequestedTimeframesRequestDtoItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddOptionsToRequestedTimeframesRequestDtoItemFromJSON(json: any): AddOptionsToRequestedTimeframesRequestDtoItem {
    return AddOptionsToRequestedTimeframesRequestDtoItemFromJSONTyped(json, false);
}

export function AddOptionsToRequestedTimeframesRequestDtoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOptionsToRequestedTimeframesRequestDtoItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': !exists(json, 'appointmentId') ? undefined : json['appointmentId'],
        'option': !exists(json, 'option') ? undefined : json['option'],
    };
}

export function AddOptionsToRequestedTimeframesRequestDtoItemToJSON(value?: AddOptionsToRequestedTimeframesRequestDtoItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'option': value.option,
    };
}

