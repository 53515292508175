/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingProducerTargetOptionFormValuesDto } from './BookingProducerTargetOptionFormValuesDto';
import {
    BookingProducerTargetOptionFormValuesDtoFromJSON,
    BookingProducerTargetOptionFormValuesDtoFromJSONTyped,
    BookingProducerTargetOptionFormValuesDtoToJSON,
} from './BookingProducerTargetOptionFormValuesDto';
import type { SelectedBookingProducerTargetFormValuesDto } from './SelectedBookingProducerTargetFormValuesDto';
import {
    SelectedBookingProducerTargetFormValuesDtoFromJSON,
    SelectedBookingProducerTargetFormValuesDtoFromJSONTyped,
    SelectedBookingProducerTargetFormValuesDtoToJSON,
} from './SelectedBookingProducerTargetFormValuesDto';

/**
 * 
 * @export
 * @interface BookingProducerFormValuesDto
 */
export interface BookingProducerFormValuesDto {
    /**
     * 
     * @type {SelectedBookingProducerTargetFormValuesDto}
     * @memberof BookingProducerFormValuesDto
     */
    selectedTarget?: SelectedBookingProducerTargetFormValuesDto;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    overtime?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    hairAndMakeup?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    styling?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    travelCosts?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    additionalNote?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingProducerFormValuesDto
     */
    isFinished?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerFormValuesDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingProducerFormValuesDto
     */
    showLogo?: boolean;
    /**
     * 
     * @type {Array<BookingProducerTargetOptionFormValuesDto>}
     * @memberof BookingProducerFormValuesDto
     */
    bookingTargetOptions?: Array<BookingProducerTargetOptionFormValuesDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingProducerFormValuesDto
     */
    bookingStatusOptions?: Array<string>;
}

/**
 * Check if a given object implements the BookingProducerFormValuesDto interface.
 */
export function instanceOfBookingProducerFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingProducerFormValuesDtoFromJSON(json: any): BookingProducerFormValuesDto {
    return BookingProducerFormValuesDtoFromJSONTyped(json, false);
}

export function BookingProducerFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingProducerFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedTarget': !exists(json, 'selectedTarget') ? undefined : SelectedBookingProducerTargetFormValuesDtoFromJSON(json['selectedTarget']),
        'overtime': !exists(json, 'overtime') ? undefined : json['overtime'],
        'hairAndMakeup': !exists(json, 'hairAndMakeup') ? undefined : json['hairAndMakeup'],
        'styling': !exists(json, 'styling') ? undefined : json['styling'],
        'travelCosts': !exists(json, 'travelCosts') ? undefined : json['travelCosts'],
        'additionalNote': !exists(json, 'additionalNote') ? undefined : json['additionalNote'],
        'isFinished': !exists(json, 'isFinished') ? undefined : json['isFinished'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'showLogo': !exists(json, 'showLogo') ? undefined : json['showLogo'],
        'bookingTargetOptions': !exists(json, 'bookingTargetOptions') ? undefined : ((json['bookingTargetOptions'] as Array<any>).map(BookingProducerTargetOptionFormValuesDtoFromJSON)),
        'bookingStatusOptions': !exists(json, 'bookingStatusOptions') ? undefined : json['bookingStatusOptions'],
    };
}

export function BookingProducerFormValuesDtoToJSON(value?: BookingProducerFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedTarget': SelectedBookingProducerTargetFormValuesDtoToJSON(value.selectedTarget),
        'overtime': value.overtime,
        'hairAndMakeup': value.hairAndMakeup,
        'styling': value.styling,
        'travelCosts': value.travelCosts,
        'additionalNote': value.additionalNote,
        'isFinished': value.isFinished,
        'status': value.status,
        'showLogo': value.showLogo,
        'bookingTargetOptions': value.bookingTargetOptions === undefined ? undefined : ((value.bookingTargetOptions as Array<any>).map(BookingProducerTargetOptionFormValuesDtoToJSON)),
        'bookingStatusOptions': value.bookingStatusOptions,
    };
}

