import {Component, Input} from "@angular/core";
import {ConversationDto} from "@brody-bookings/api";

interface ConversationListItem {
    conversationId: string,
    conversationTitle: string,
    participantNames: string[],
    isGroupChat: boolean,
    hasNewMessages: boolean
}

@Component({
    selector: 'app-conversation-list',
    templateUrl: './conversationList.component.html',
    styleUrls:['./conversationList.styles.css']
})
export class ConversationListComponent {
    @Input() conversationId!: string
    @Input() conversationList!: ConversationDto[]
    @Input() conversationNameList!: ConversationListItem[]
    @Input() filteredTitleList!: ConversationListItem[]
    @Input() filteredParticipantList!: ConversationListItem[]
    @Input() isFiltered!: boolean
    @Input() onSelectConversation!: (event: any) => void
    @Input() onConversationDelete!: (conversationId: string) => void
}