/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerNamesForProjectsDto } from './CustomerNamesForProjectsDto';
import {
    CustomerNamesForProjectsDtoFromJSON,
    CustomerNamesForProjectsDtoFromJSONTyped,
    CustomerNamesForProjectsDtoToJSON,
} from './CustomerNamesForProjectsDto';

/**
 * 
 * @export
 * @interface CustomerNameListForProject
 */
export interface CustomerNameListForProject {
    /**
     * 
     * @type {Array<CustomerNamesForProjectsDto>}
     * @memberof CustomerNameListForProject
     */
    customerNamesForProjectsDtoList?: Array<CustomerNamesForProjectsDto>;
}

/**
 * Check if a given object implements the CustomerNameListForProject interface.
 */
export function instanceOfCustomerNameListForProject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerNameListForProjectFromJSON(json: any): CustomerNameListForProject {
    return CustomerNameListForProjectFromJSONTyped(json, false);
}

export function CustomerNameListForProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerNameListForProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerNamesForProjectsDtoList': !exists(json, 'customerNamesForProjectsDtoList') ? undefined : ((json['customerNamesForProjectsDtoList'] as Array<any>).map(CustomerNamesForProjectsDtoFromJSON)),
    };
}

export function CustomerNameListForProjectToJSON(value?: CustomerNameListForProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerNamesForProjectsDtoList': value.customerNamesForProjectsDtoList === undefined ? undefined : ((value.customerNamesForProjectsDtoList as Array<any>).map(CustomerNamesForProjectsDtoToJSON)),
    };
}

