/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankAccountInformationFormValuesDto,
  ConditionsFormValuesDto,
  ContactInfoFormValuesDto,
  CustomerAddressFormValuesDto,
  CustomerAndEmployeesListForProjectsDto,
  CustomerEmployeeForMessenger,
  CustomerEmployeeListForProject,
  CustomerEmployeeOAuthId,
  CustomerMasterDataCreateRequestDto,
  CustomerMasterDataFormValuesDto,
  CustomerNameFormValuesDto,
  CustomerNameListForProject,
  CustomerSearchItemsDto,
  CustomerTableItemDto,
  CustomerTableItemsDto,
  CustomerTaxInformationFormValuesDto,
  EmailFormValuesDto,
  EmployeeCreateRequestDto,
  EmployeeFormValuesDto,
  IndustryFieldFormValuesDto,
  JobHistoryEntryCreateRequestDto,
  JobHistoryFormValuesDto,
  ProtocolEntryCreateRequestDto,
  ProtocolFormValuesDto,
} from '../models';
import {
    BankAccountInformationFormValuesDtoFromJSON,
    BankAccountInformationFormValuesDtoToJSON,
    ConditionsFormValuesDtoFromJSON,
    ConditionsFormValuesDtoToJSON,
    ContactInfoFormValuesDtoFromJSON,
    ContactInfoFormValuesDtoToJSON,
    CustomerAddressFormValuesDtoFromJSON,
    CustomerAddressFormValuesDtoToJSON,
    CustomerAndEmployeesListForProjectsDtoFromJSON,
    CustomerAndEmployeesListForProjectsDtoToJSON,
    CustomerEmployeeForMessengerFromJSON,
    CustomerEmployeeForMessengerToJSON,
    CustomerEmployeeListForProjectFromJSON,
    CustomerEmployeeListForProjectToJSON,
    CustomerEmployeeOAuthIdFromJSON,
    CustomerEmployeeOAuthIdToJSON,
    CustomerMasterDataCreateRequestDtoFromJSON,
    CustomerMasterDataCreateRequestDtoToJSON,
    CustomerMasterDataFormValuesDtoFromJSON,
    CustomerMasterDataFormValuesDtoToJSON,
    CustomerNameFormValuesDtoFromJSON,
    CustomerNameFormValuesDtoToJSON,
    CustomerNameListForProjectFromJSON,
    CustomerNameListForProjectToJSON,
    CustomerSearchItemsDtoFromJSON,
    CustomerSearchItemsDtoToJSON,
    CustomerTableItemDtoFromJSON,
    CustomerTableItemDtoToJSON,
    CustomerTableItemsDtoFromJSON,
    CustomerTableItemsDtoToJSON,
    CustomerTaxInformationFormValuesDtoFromJSON,
    CustomerTaxInformationFormValuesDtoToJSON,
    EmailFormValuesDtoFromJSON,
    EmailFormValuesDtoToJSON,
    EmployeeCreateRequestDtoFromJSON,
    EmployeeCreateRequestDtoToJSON,
    EmployeeFormValuesDtoFromJSON,
    EmployeeFormValuesDtoToJSON,
    IndustryFieldFormValuesDtoFromJSON,
    IndustryFieldFormValuesDtoToJSON,
    JobHistoryEntryCreateRequestDtoFromJSON,
    JobHistoryEntryCreateRequestDtoToJSON,
    JobHistoryFormValuesDtoFromJSON,
    JobHistoryFormValuesDtoToJSON,
    ProtocolEntryCreateRequestDtoFromJSON,
    ProtocolEntryCreateRequestDtoToJSON,
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoToJSON,
} from '../models';

export interface ChangeBillingAdressRequest {
    id: string;
    customerAddressFormValuesDto: CustomerAddressFormValuesDto;
}

export interface ChangeContactInfo1Request {
    id: string;
    contactInfoFormValuesDto: ContactInfoFormValuesDto;
}

export interface ChangeCustomerBankAccountInformationRequest {
    id: string;
    bankAccountInformationFormValuesDto: BankAccountInformationFormValuesDto;
}

export interface ChangeCustomerConditionsRequest {
    id: string;
    conditionsFormValuesDto: ConditionsFormValuesDto;
}

export interface ChangeCustomerEmailRequest {
    id: string;
    emailFormValuesDto: EmailFormValuesDto;
}

export interface ChangeCustomerEmployeeRequest {
    id: string;
    employeeFormValuesDto: EmployeeFormValuesDto;
}

export interface ChangeCustomerIndustryFieldRequest {
    id: string;
    industryFieldFormValuesDto: IndustryFieldFormValuesDto;
}

export interface ChangeCustomerJobHistoryRequest {
    id: string;
    jobHistoryFormValuesDto: Array<JobHistoryFormValuesDto>;
}

export interface ChangeCustomerNameRequest {
    id: string;
    customerNameFormValuesDto: CustomerNameFormValuesDto;
}

export interface ChangeCustomerTaxInformationRequest {
    id: string;
    customerTaxInformationFormValuesDto: CustomerTaxInformationFormValuesDto;
}

export interface ChangeLivingAddress1Request {
    id: string;
    customerAddressFormValuesDto: CustomerAddressFormValuesDto;
}

export interface CreateCustomerRequest {
    customerMasterDataCreateRequestDto: CustomerMasterDataCreateRequestDto;
}

export interface CreateEmployeeRequest {
    id: string;
    employeeCreateRequestDto: EmployeeCreateRequestDto;
}

export interface CreateJobHistoryEntryRequest {
    jobHistoryEntryCreateRequestDto: JobHistoryEntryCreateRequestDto;
}

export interface CreateProtocolEntry2Request {
    protocolEntryCreateRequestDto: ProtocolEntryCreateRequestDto;
}

export interface DeleteCustomerRequest {
    id: string;
}

export interface DeleteEmployeeRequest {
    customerId: string;
    employeeId: string;
}

export interface GetCustomerEmployeeOAuthIdRequest {
    customerId: string;
    employeeId: string;
}

export interface GetCustomerEmployeesByIdRequest {
    customerId: string;
}

export interface GetCustomerFormValuesRequest {
    lang: string;
}

export interface GetCustomerFormValues1Request {
    lang: string;
    customerId: string;
}

export interface GetCustomersStartsWithNameRequest {
    name: string;
}

export interface GetEmployeeForMessengerByNameRequest {
    customerId: string;
    name: string;
}

export interface GetEmployeesForMessengerRequest {
    customerId: string;
}

/**
 * 
 */
export class CustomerMasterDataControllerApi extends runtime.BaseAPI {

    /**
     */
    async changeBillingAdressRaw(requestParameters: ChangeBillingAdressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeBillingAdress.');
        }

        if (requestParameters.customerAddressFormValuesDto === null || requestParameters.customerAddressFormValuesDto === undefined) {
            throw new runtime.RequiredError('customerAddressFormValuesDto','Required parameter requestParameters.customerAddressFormValuesDto was null or undefined when calling changeBillingAdress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/billing-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAddressFormValuesDtoToJSON(requestParameters.customerAddressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBillingAdress(requestParameters: ChangeBillingAdressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBillingAdressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeContactInfo1Raw(requestParameters: ChangeContactInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeContactInfo1.');
        }

        if (requestParameters.contactInfoFormValuesDto === null || requestParameters.contactInfoFormValuesDto === undefined) {
            throw new runtime.RequiredError('contactInfoFormValuesDto','Required parameter requestParameters.contactInfoFormValuesDto was null or undefined when calling changeContactInfo1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/contact-info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactInfoFormValuesDtoToJSON(requestParameters.contactInfoFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeContactInfo1(requestParameters: ChangeContactInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeContactInfo1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerBankAccountInformationRaw(requestParameters: ChangeCustomerBankAccountInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerBankAccountInformation.');
        }

        if (requestParameters.bankAccountInformationFormValuesDto === null || requestParameters.bankAccountInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('bankAccountInformationFormValuesDto','Required parameter requestParameters.bankAccountInformationFormValuesDto was null or undefined when calling changeCustomerBankAccountInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/bankAccountInformation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankAccountInformationFormValuesDtoToJSON(requestParameters.bankAccountInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerBankAccountInformation(requestParameters: ChangeCustomerBankAccountInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerBankAccountInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerConditionsRaw(requestParameters: ChangeCustomerConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerConditions.');
        }

        if (requestParameters.conditionsFormValuesDto === null || requestParameters.conditionsFormValuesDto === undefined) {
            throw new runtime.RequiredError('conditionsFormValuesDto','Required parameter requestParameters.conditionsFormValuesDto was null or undefined when calling changeCustomerConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/customerConditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionsFormValuesDtoToJSON(requestParameters.conditionsFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerConditions(requestParameters: ChangeCustomerConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerEmailRaw(requestParameters: ChangeCustomerEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerEmail.');
        }

        if (requestParameters.emailFormValuesDto === null || requestParameters.emailFormValuesDto === undefined) {
            throw new runtime.RequiredError('emailFormValuesDto','Required parameter requestParameters.emailFormValuesDto was null or undefined when calling changeCustomerEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/customerEmail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailFormValuesDtoToJSON(requestParameters.emailFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerEmail(requestParameters: ChangeCustomerEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerEmployeeRaw(requestParameters: ChangeCustomerEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerEmployee.');
        }

        if (requestParameters.employeeFormValuesDto === null || requestParameters.employeeFormValuesDto === undefined) {
            throw new runtime.RequiredError('employeeFormValuesDto','Required parameter requestParameters.employeeFormValuesDto was null or undefined when calling changeCustomerEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/employee/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeFormValuesDtoToJSON(requestParameters.employeeFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerEmployee(requestParameters: ChangeCustomerEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerIndustryFieldRaw(requestParameters: ChangeCustomerIndustryFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerIndustryField.');
        }

        if (requestParameters.industryFieldFormValuesDto === null || requestParameters.industryFieldFormValuesDto === undefined) {
            throw new runtime.RequiredError('industryFieldFormValuesDto','Required parameter requestParameters.industryFieldFormValuesDto was null or undefined when calling changeCustomerIndustryField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/industryField/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IndustryFieldFormValuesDtoToJSON(requestParameters.industryFieldFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerIndustryField(requestParameters: ChangeCustomerIndustryFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerIndustryFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerJobHistoryRaw(requestParameters: ChangeCustomerJobHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerJobHistory.');
        }

        if (requestParameters.jobHistoryFormValuesDto === null || requestParameters.jobHistoryFormValuesDto === undefined) {
            throw new runtime.RequiredError('jobHistoryFormValuesDto','Required parameter requestParameters.jobHistoryFormValuesDto was null or undefined when calling changeCustomerJobHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/jobHistory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.jobHistoryFormValuesDto.map(JobHistoryFormValuesDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerJobHistory(requestParameters: ChangeCustomerJobHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerJobHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerNameRaw(requestParameters: ChangeCustomerNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerName.');
        }

        if (requestParameters.customerNameFormValuesDto === null || requestParameters.customerNameFormValuesDto === undefined) {
            throw new runtime.RequiredError('customerNameFormValuesDto','Required parameter requestParameters.customerNameFormValuesDto was null or undefined when calling changeCustomerName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/name/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerNameFormValuesDtoToJSON(requestParameters.customerNameFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerName(requestParameters: ChangeCustomerNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCustomerTaxInformationRaw(requestParameters: ChangeCustomerTaxInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeCustomerTaxInformation.');
        }

        if (requestParameters.customerTaxInformationFormValuesDto === null || requestParameters.customerTaxInformationFormValuesDto === undefined) {
            throw new runtime.RequiredError('customerTaxInformationFormValuesDto','Required parameter requestParameters.customerTaxInformationFormValuesDto was null or undefined when calling changeCustomerTaxInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/taxInformation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerTaxInformationFormValuesDtoToJSON(requestParameters.customerTaxInformationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeCustomerTaxInformation(requestParameters: ChangeCustomerTaxInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeCustomerTaxInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeLivingAddress1Raw(requestParameters: ChangeLivingAddress1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeLivingAddress1.');
        }

        if (requestParameters.customerAddressFormValuesDto === null || requestParameters.customerAddressFormValuesDto === undefined) {
            throw new runtime.RequiredError('customerAddressFormValuesDto','Required parameter requestParameters.customerAddressFormValuesDto was null or undefined when calling changeLivingAddress1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/change/living-address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAddressFormValuesDtoToJSON(requestParameters.customerAddressFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeLivingAddress1(requestParameters: ChangeLivingAddress1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeLivingAddress1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerTableItemDto>> {
        if (requestParameters.customerMasterDataCreateRequestDto === null || requestParameters.customerMasterDataCreateRequestDto === undefined) {
            throw new runtime.RequiredError('customerMasterDataCreateRequestDto','Required parameter requestParameters.customerMasterDataCreateRequestDto was null or undefined when calling createCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerMasterDataCreateRequestDtoToJSON(requestParameters.customerMasterDataCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerTableItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async createCustomer(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerTableItemDto> {
        const response = await this.createCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createEmployeeRaw(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmployeeFormValuesDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createEmployee.');
        }

        if (requestParameters.employeeCreateRequestDto === null || requestParameters.employeeCreateRequestDto === undefined) {
            throw new runtime.RequiredError('employeeCreateRequestDto','Required parameter requestParameters.employeeCreateRequestDto was null or undefined when calling createEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/createEmployee/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeCreateRequestDtoToJSON(requestParameters.employeeCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createEmployee(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmployeeFormValuesDto> {
        const response = await this.createEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createJobHistoryEntryRaw(requestParameters: CreateJobHistoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.jobHistoryEntryCreateRequestDto === null || requestParameters.jobHistoryEntryCreateRequestDto === undefined) {
            throw new runtime.RequiredError('jobHistoryEntryCreateRequestDto','Required parameter requestParameters.jobHistoryEntryCreateRequestDto was null or undefined when calling createJobHistoryEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/create/jobHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobHistoryEntryCreateRequestDtoToJSON(requestParameters.jobHistoryEntryCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async createJobHistoryEntry(requestParameters: CreateJobHistoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.createJobHistoryEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProtocolEntry2Raw(requestParameters: CreateProtocolEntry2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProtocolFormValuesDto>> {
        if (requestParameters.protocolEntryCreateRequestDto === null || requestParameters.protocolEntryCreateRequestDto === undefined) {
            throw new runtime.RequiredError('protocolEntryCreateRequestDto','Required parameter requestParameters.protocolEntryCreateRequestDto was null or undefined when calling createProtocolEntry2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer-master-data/create/protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProtocolEntryCreateRequestDtoToJSON(requestParameters.protocolEntryCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProtocolFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProtocolEntry2(requestParameters: CreateProtocolEntry2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProtocolFormValuesDto> {
        const response = await this.createProtocolEntry2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCustomerRaw(requestParameters: DeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/deleteCustomer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteCustomer(requestParameters: DeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEmployeeRaw(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteEmployee.');
        }

        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling deleteEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/deleteEmployee/{customerId}/{employeeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteEmployee(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCustomerNamesForProjectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerNameListForProject>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customers/search/all-customer-names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerNameListForProjectFromJSON(jsonValue));
    }

    /**
     */
    async getAllCustomerNamesForProject(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerNameListForProject> {
        const response = await this.getAllCustomerNamesForProjectRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerEmployeeOAuthIdRaw(requestParameters: GetCustomerEmployeeOAuthIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerEmployeeOAuthId>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerEmployeeOAuthId.');
        }

        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getCustomerEmployeeOAuthId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customer/{customerId}/{employeeId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEmployeeOAuthIdFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerEmployeeOAuthId(requestParameters: GetCustomerEmployeeOAuthIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerEmployeeOAuthId> {
        const response = await this.getCustomerEmployeeOAuthIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerEmployeesByIdRaw(requestParameters: GetCustomerEmployeesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerEmployeeListForProject>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerEmployeesById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customers/search/{customerId}/employees`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEmployeeListForProjectFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerEmployeesById(requestParameters: GetCustomerEmployeesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerEmployeeListForProject> {
        const response = await this.getCustomerEmployeesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerFormValuesRaw(requestParameters: GetCustomerFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerMasterDataFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getCustomerFormValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/formValues/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerMasterDataFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerFormValues(requestParameters: GetCustomerFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerMasterDataFormValuesDto> {
        const response = await this.getCustomerFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerFormValues1Raw(requestParameters: GetCustomerFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerMasterDataFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getCustomerFormValues1.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerFormValues1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/formValues/{lang}/{customerId}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerMasterDataFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerFormValues1(requestParameters: GetCustomerFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerMasterDataFormValuesDto> {
        const response = await this.getCustomerFormValues1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerTableItemsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerTableItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerTableItemsDto> {
        const response = await this.getCustomersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomersStartsWithNameRaw(requestParameters: GetCustomersStartsWithNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerSearchItemsDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getCustomersStartsWithName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customers/start-with/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSearchItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomersStartsWithName(requestParameters: GetCustomersStartsWithNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerSearchItemsDto> {
        const response = await this.getCustomersStartsWithNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomersWithEmployeesForProjectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerAndEmployeesListForProjectsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customers/search/customer-with-employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAndEmployeesListForProjectsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomersWithEmployeesForProject(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerAndEmployeesListForProjectsDto> {
        const response = await this.getCustomersWithEmployeesForProjectRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeeForMessengerByNameRaw(requestParameters: GetEmployeeForMessengerByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerEmployeeForMessenger>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getEmployeeForMessengerByName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getEmployeeForMessengerByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customer/messenger/{customerId}/employee/{name}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEmployeeForMessengerFromJSON(jsonValue));
    }

    /**
     */
    async getEmployeeForMessengerByName(requestParameters: GetEmployeeForMessengerByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerEmployeeForMessenger> {
        const response = await this.getEmployeeForMessengerByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeesForMessengerRaw(requestParameters: GetEmployeesForMessengerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerEmployeeForMessenger>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getEmployeesForMessenger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer-master-data/customer/messenger/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerEmployeeForMessengerFromJSON));
    }

    /**
     */
    async getEmployeesForMessenger(requestParameters: GetEmployeesForMessengerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerEmployeeForMessenger>> {
        const response = await this.getEmployeesForMessengerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
