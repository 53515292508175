/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColorsAndTypes
 */
export interface ColorsAndTypes {
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    skinType: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    skinColor: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    colorEye1: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    hairColor: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    hairLength: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    hairStyle: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    colorEye2: string;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypes
     */
    hairColorNuance: string;
}

/**
 * Check if a given object implements the ColorsAndTypes interface.
 */
export function instanceOfColorsAndTypes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "skinType" in value;
    isInstance = isInstance && "skinColor" in value;
    isInstance = isInstance && "colorEye1" in value;
    isInstance = isInstance && "hairColor" in value;
    isInstance = isInstance && "hairLength" in value;
    isInstance = isInstance && "hairStyle" in value;
    isInstance = isInstance && "colorEye2" in value;
    isInstance = isInstance && "hairColorNuance" in value;

    return isInstance;
}

export function ColorsAndTypesFromJSON(json: any): ColorsAndTypes {
    return ColorsAndTypesFromJSONTyped(json, false);
}

export function ColorsAndTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsAndTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skinType': json['skinType'],
        'skinColor': json['skinColor'],
        'colorEye1': json['color_eye1'],
        'hairColor': json['hairColor'],
        'hairLength': json['hairLength'],
        'hairStyle': json['hairStyle'],
        'colorEye2': json['color_eye2'],
        'hairColorNuance': json['hairColorNuance'],
    };
}

export function ColorsAndTypesToJSON(value?: ColorsAndTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skinType': value.skinType,
        'skinColor': value.skinColor,
        'color_eye1': value.colorEye1,
        'hairColor': value.hairColor,
        'hairLength': value.hairLength,
        'hairStyle': value.hairStyle,
        'color_eye2': value.colorEye2,
        'hairColorNuance': value.hairColorNuance,
    };
}

