/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnswerRequestCommand
 */
export interface AnswerRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof AnswerRequestCommand
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequestCommand
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequestCommand
     */
    status?: AnswerRequestCommandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequestCommand
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequestCommand
     */
    message?: string;
}


/**
 * @export
 */
export const AnswerRequestCommandStatusEnum = {
    Requested: 'REQUESTED',
    Maybe: 'MAYBE',
    Confirmed: 'CONFIRMED',
    Canceled: 'CANCELED'
} as const;
export type AnswerRequestCommandStatusEnum = typeof AnswerRequestCommandStatusEnum[keyof typeof AnswerRequestCommandStatusEnum];


/**
 * Check if a given object implements the AnswerRequestCommand interface.
 */
export function instanceOfAnswerRequestCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnswerRequestCommandFromJSON(json: any): AnswerRequestCommand {
    return AnswerRequestCommandFromJSONTyped(json, false);
}

export function AnswerRequestCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerRequestCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': !exists(json, 'appointmentId') ? undefined : json['appointmentId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AnswerRequestCommandToJSON(value?: AnswerRequestCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'modelId': value.modelId,
        'status': value.status,
        'language': value.language,
        'message': value.message,
    };
}

