/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelBookingRequestsResponseDto
 */
export interface ModelBookingRequestsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    startDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelBookingRequestsResponseDto
     */
    durationInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    motif?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    booker?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    projectModelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelBookingRequestsResponseDto
     */
    projectId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelBookingRequestsResponseDto
     */
    modelConfirmed?: boolean;
}

/**
 * Check if a given object implements the ModelBookingRequestsResponseDto interface.
 */
export function instanceOfModelBookingRequestsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelBookingRequestsResponseDtoFromJSON(json: any): ModelBookingRequestsResponseDto {
    return ModelBookingRequestsResponseDtoFromJSONTyped(json, false);
}

export function ModelBookingRequestsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelBookingRequestsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : json['startDateTime'],
        'durationInMinutes': !exists(json, 'durationInMinutes') ? undefined : json['durationInMinutes'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
        'booker': !exists(json, 'booker') ? undefined : json['booker'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'projectModelId': !exists(json, 'projectModelId') ? undefined : json['projectModelId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'modelConfirmed': !exists(json, 'modelConfirmed') ? undefined : json['modelConfirmed'],
    };
}

export function ModelBookingRequestsResponseDtoToJSON(value?: ModelBookingRequestsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectName': value.projectName,
        'description': value.description,
        'startDateTime': value.startDateTime,
        'durationInMinutes': value.durationInMinutes,
        'location': value.location,
        'motif': value.motif,
        'booker': value.booker,
        'customer': value.customer,
        'roleName': value.roleName,
        'projectModelId': value.projectModelId,
        'projectId': value.projectId,
        'modelConfirmed': value.modelConfirmed,
    };
}

