/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelPublicDataOverviewFilterDtoVisual
 */
export interface ModelPublicDataOverviewFilterDtoVisual {
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDtoVisual
     */
    skinColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDtoVisual
     */
    eyeColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDtoVisual
     */
    hairColor?: string;
}

/**
 * Check if a given object implements the ModelPublicDataOverviewFilterDtoVisual interface.
 */
export function instanceOfModelPublicDataOverviewFilterDtoVisual(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataOverviewFilterDtoVisualFromJSON(json: any): ModelPublicDataOverviewFilterDtoVisual {
    return ModelPublicDataOverviewFilterDtoVisualFromJSONTyped(json, false);
}

export function ModelPublicDataOverviewFilterDtoVisualFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataOverviewFilterDtoVisual {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skinColor': !exists(json, 'skinColor') ? undefined : json['skinColor'],
        'eyeColor': !exists(json, 'eyeColor') ? undefined : json['eyeColor'],
        'hairColor': !exists(json, 'hairColor') ? undefined : json['hairColor'],
    };
}

export function ModelPublicDataOverviewFilterDtoVisualToJSON(value?: ModelPublicDataOverviewFilterDtoVisual | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skinColor': value.skinColor,
        'eyeColor': value.eyeColor,
        'hairColor': value.hairColor,
    };
}

