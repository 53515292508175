/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Communication } from './Communication';
import {
    CommunicationFromJSON,
    CommunicationFromJSONTyped,
    CommunicationToJSON,
} from './Communication';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { Management } from './Management';
import {
    ManagementFromJSON,
    ManagementFromJSONTyped,
    ManagementToJSON,
} from './Management';
import type { ProtocolEntry } from './ProtocolEntry';
import {
    ProtocolEntryFromJSON,
    ProtocolEntryFromJSONTyped,
    ProtocolEntryToJSON,
} from './ProtocolEntry';

/**
 * 
 * @export
 * @interface ServiceProvider
 */
export interface ServiceProvider {
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvider
     */
    info?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProvider
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProvider
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Address}
     * @memberof ServiceProvider
     */
    postalAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof ServiceProvider
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Communication}
     * @memberof ServiceProvider
     */
    communication?: Communication;
    /**
     * 
     * @type {Management}
     * @memberof ServiceProvider
     */
    management?: Management;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ServiceProvider
     */
    employees?: Array<Employee>;
    /**
     * 
     * @type {Array<ProtocolEntry>}
     * @memberof ServiceProvider
     */
    protocolEntries?: Array<ProtocolEntry>;
}

/**
 * Check if a given object implements the ServiceProvider interface.
 */
export function instanceOfServiceProvider(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderFromJSON(json: any): ServiceProvider {
    return ServiceProviderFromJSONTyped(json, false);
}

export function ServiceProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressFromJSON(json['postalAddress']),
        'billingAddress': !exists(json, 'billingAddress') ? undefined : AddressFromJSON(json['billingAddress']),
        'communication': !exists(json, 'communication') ? undefined : CommunicationFromJSON(json['communication']),
        'management': !exists(json, 'management') ? undefined : ManagementFromJSON(json['management']),
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(EmployeeFromJSON)),
        'protocolEntries': !exists(json, 'protocolEntries') ? undefined : ((json['protocolEntries'] as Array<any>).map(ProtocolEntryFromJSON)),
    };
}

export function ServiceProviderToJSON(value?: ServiceProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'name': value.name,
        'info': value.info,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'postalAddress': AddressToJSON(value.postalAddress),
        'billingAddress': AddressToJSON(value.billingAddress),
        'communication': CommunicationToJSON(value.communication),
        'management': ManagementToJSON(value.management),
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(EmployeeToJSON)),
        'protocolEntries': value.protocolEntries === undefined ? undefined : ((value.protocolEntries as Array<any>).map(ProtocolEntryToJSON)),
    };
}

