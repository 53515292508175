/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActingExperience } from './ActingExperience';
import {
    ActingExperienceFromJSON,
    ActingExperienceFromJSONTyped,
    ActingExperienceToJSON,
} from './ActingExperience';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Appointment } from './Appointment';
import {
    AppointmentFromJSON,
    AppointmentFromJSONTyped,
    AppointmentToJSON,
} from './Appointment';
import type { BahnCard } from './BahnCard';
import {
    BahnCardFromJSON,
    BahnCardFromJSONTyped,
    BahnCardToJSON,
} from './BahnCard';
import type { BankAccountInformation } from './BankAccountInformation';
import {
    BankAccountInformationFromJSON,
    BankAccountInformationFromJSONTyped,
    BankAccountInformationToJSON,
} from './BankAccountInformation';
import type { BodyAccessories } from './BodyAccessories';
import {
    BodyAccessoriesFromJSON,
    BodyAccessoriesFromJSONTyped,
    BodyAccessoriesToJSON,
} from './BodyAccessories';
import type { BodyDetails } from './BodyDetails';
import {
    BodyDetailsFromJSON,
    BodyDetailsFromJSONTyped,
    BodyDetailsToJSON,
} from './BodyDetails';
import type { CastingBody } from './CastingBody';
import {
    CastingBodyFromJSON,
    CastingBodyFromJSONTyped,
    CastingBodyToJSON,
} from './CastingBody';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { ChargeableProject } from './ChargeableProject';
import {
    ChargeableProjectFromJSON,
    ChargeableProjectFromJSONTyped,
    ChargeableProjectToJSON,
} from './ChargeableProject';
import type { ColorsAndTypes } from './ColorsAndTypes';
import {
    ColorsAndTypesFromJSON,
    ColorsAndTypesFromJSONTyped,
    ColorsAndTypesToJSON,
} from './ColorsAndTypes';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Dance } from './Dance';
import {
    DanceFromJSON,
    DanceFromJSONTyped,
    DanceToJSON,
} from './Dance';
import type { Diet } from './Diet';
import {
    DietFromJSON,
    DietFromJSONTyped,
    DietToJSON,
} from './Diet';
import type { Email } from './Email';
import {
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import type { Identification } from './Identification';
import {
    IdentificationFromJSON,
    IdentificationFromJSONTyped,
    IdentificationToJSON,
} from './Identification';
import type { Instrument } from './Instrument';
import {
    InstrumentFromJSON,
    InstrumentFromJSONTyped,
    InstrumentToJSON,
} from './Instrument';
import type { Insurance } from './Insurance';
import {
    InsuranceFromJSON,
    InsuranceFromJSONTyped,
    InsuranceToJSON,
} from './Insurance';
import type { Job } from './Job';
import {
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
} from './Job';
import type { ModelAgency } from './ModelAgency';
import {
    ModelAgencyFromJSON,
    ModelAgencyFromJSONTyped,
    ModelAgencyToJSON,
} from './ModelAgency';
import type { ModelBillingCost } from './ModelBillingCost';
import {
    ModelBillingCostFromJSON,
    ModelBillingCostFromJSONTyped,
    ModelBillingCostToJSON,
} from './ModelBillingCost';
import type { ModelConditions } from './ModelConditions';
import {
    ModelConditionsFromJSON,
    ModelConditionsFromJSONTyped,
    ModelConditionsToJSON,
} from './ModelConditions';
import type { ModelCost } from './ModelCost';
import {
    ModelCostFromJSON,
    ModelCostFromJSONTyped,
    ModelCostToJSON,
} from './ModelCost';
import type { ModelId } from './ModelId';
import {
    ModelIdFromJSON,
    ModelIdFromJSONTyped,
    ModelIdToJSON,
} from './ModelId';
import type { ModelingExperience } from './ModelingExperience';
import {
    ModelingExperienceFromJSON,
    ModelingExperienceFromJSONTyped,
    ModelingExperienceToJSON,
} from './ModelingExperience';
import type { OAuthUserId } from './OAuthUserId';
import {
    OAuthUserIdFromJSON,
    OAuthUserIdFromJSONTyped,
    OAuthUserIdToJSON,
} from './OAuthUserId';
import type { PersonalInfos } from './PersonalInfos';
import {
    PersonalInfosFromJSON,
    PersonalInfosFromJSONTyped,
    PersonalInfosToJSON,
} from './PersonalInfos';
import type { Protocol } from './Protocol';
import {
    ProtocolFromJSON,
    ProtocolFromJSONTyped,
    ProtocolToJSON,
} from './Protocol';
import type { SpokenLanguage } from './SpokenLanguage';
import {
    SpokenLanguageFromJSON,
    SpokenLanguageFromJSONTyped,
    SpokenLanguageToJSON,
} from './SpokenLanguage';
import type { Sport } from './Sport';
import {
    SportFromJSON,
    SportFromJSONTyped,
    SportToJSON,
} from './Sport';
import type { TaxInformation } from './TaxInformation';
import {
    TaxInformationFromJSON,
    TaxInformationFromJSONTyped,
    TaxInformationToJSON,
} from './TaxInformation';

/**
 * 
 * @export
 * @interface ModelMasterData
 */
export interface ModelMasterData {
    /**
     * 
     * @type {number}
     * @memberof ModelMasterData
     */
    brodyUserReferenceId?: number;
    /**
     * 
     * @type {ModelId}
     * @memberof ModelMasterData
     */
    modelId?: ModelId;
    /**
     * 
     * @type {Date}
     * @memberof ModelMasterData
     */
    lastEditedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterData
     */
    activeState?: ModelMasterDataActiveStateEnum;
    /**
     * 
     * @type {Email}
     * @memberof ModelMasterData
     */
    email?: Email;
    /**
     * 
     * @type {PersonalInfos}
     * @memberof ModelMasterData
     */
    personalInfos?: PersonalInfos;
    /**
     * 
     * @type {Contact}
     * @memberof ModelMasterData
     */
    contactInfos?: Contact;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterData
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterData
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterData
     */
    updateStates?: Array<ModelMasterDataUpdateStatesEnum>;
    /**
     * 
     * @type {Address}
     * @memberof ModelMasterData
     */
    livingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof ModelMasterData
     */
    notifyAddress?: Address;
    /**
     * 
     * @type {Identification}
     * @memberof ModelMasterData
     */
    identification?: Identification;
    /**
     * 
     * @type {Array<SpokenLanguage>}
     * @memberof ModelMasterData
     */
    spokenLanguages?: Array<SpokenLanguage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterData
     */
    specials?: Array<string>;
    /**
     * 
     * @type {Diet}
     * @memberof ModelMasterData
     */
    diet?: Diet;
    /**
     * 
     * @type {Job}
     * @memberof ModelMasterData
     */
    job?: Job;
    /**
     * 
     * @type {BankAccountInformation}
     * @memberof ModelMasterData
     */
    bankAccountInformation?: BankAccountInformation;
    /**
     * 
     * @type {Insurance}
     * @memberof ModelMasterData
     */
    insurance?: Insurance;
    /**
     * 
     * @type {TaxInformation}
     * @memberof ModelMasterData
     */
    taxInformation?: TaxInformation;
    /**
     * 
     * @type {ColorsAndTypes}
     * @memberof ModelMasterData
     */
    colorsAndTypes?: ColorsAndTypes;
    /**
     * 
     * @type {CastingBody}
     * @memberof ModelMasterData
     */
    castingBody?: CastingBody;
    /**
     * 
     * @type {BodyAccessories}
     * @memberof ModelMasterData
     */
    bodyAccessories?: BodyAccessories;
    /**
     * 
     * @type {ModelConditions}
     * @memberof ModelMasterData
     */
    conditions?: ModelConditions;
    /**
     * 
     * @type {Array<Instrument>}
     * @memberof ModelMasterData
     */
    instruments?: Array<Instrument>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterData
     */
    additionalAgencies?: Array<string>;
    /**
     * 
     * @type {Array<Dance>}
     * @memberof ModelMasterData
     */
    dances?: Array<Dance>;
    /**
     * 
     * @type {Array<Sport>}
     * @memberof ModelMasterData
     */
    sports?: Array<Sport>;
    /**
     * 
     * @type {BodyDetails}
     * @memberof ModelMasterData
     */
    bodyDetails?: BodyDetails;
    /**
     * 
     * @type {ActingExperience}
     * @memberof ModelMasterData
     */
    actingExperience?: ActingExperience;
    /**
     * 
     * @type {ModelingExperience}
     * @memberof ModelMasterData
     */
    modelExperience?: ModelingExperience;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterData
     */
    parentAgency?: string;
    /**
     * 
     * @type {BahnCard}
     * @memberof ModelMasterData
     */
    bahnCard?: BahnCard;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterData
     */
    driversLicenses?: Array<string>;
    /**
     * 
     * @type {Category}
     * @memberof ModelMasterData
     */
    category?: Category;
    /**
     * 
     * @type {Array<Protocol>}
     * @memberof ModelMasterData
     */
    protocol?: Array<Protocol>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ModelMasterData
     */
    appointments?: Array<Appointment>;
    /**
     * 
     * @type {Array<ModelCost>}
     * @memberof ModelMasterData
     */
    modelCosts?: Array<ModelCost>;
    /**
     * 
     * @type {Array<ModelAgency>}
     * @memberof ModelMasterData
     */
    modelAgency?: Array<ModelAgency>;
    /**
     * 
     * @type {Array<ChargeableProject>}
     * @memberof ModelMasterData
     */
    chargeableProjects?: Array<ChargeableProject>;
    /**
     * 
     * @type {Array<ModelBillingCost>}
     * @memberof ModelMasterData
     */
    modelBillingCost?: Array<ModelBillingCost>;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterData
     */
    personalNotice?: string;
    /**
     * 
     * @type {OAuthUserId}
     * @memberof ModelMasterData
     */
    oauthUserId?: OAuthUserId;
}


/**
 * @export
 */
export const ModelMasterDataActiveStateEnum = {
    Active: 'Active',
    Inactive: 'Inactive'
} as const;
export type ModelMasterDataActiveStateEnum = typeof ModelMasterDataActiveStateEnum[keyof typeof ModelMasterDataActiveStateEnum];

/**
 * @export
 */
export const ModelMasterDataUpdateStatesEnum = {
    Images: 'IMAGES',
    Measurements: 'MEASUREMENTS'
} as const;
export type ModelMasterDataUpdateStatesEnum = typeof ModelMasterDataUpdateStatesEnum[keyof typeof ModelMasterDataUpdateStatesEnum];


/**
 * Check if a given object implements the ModelMasterData interface.
 */
export function instanceOfModelMasterData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelMasterDataFromJSON(json: any): ModelMasterData {
    return ModelMasterDataFromJSONTyped(json, false);
}

export function ModelMasterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brodyUserReferenceId': !exists(json, 'brodyUserReferenceId') ? undefined : json['brodyUserReferenceId'],
        'modelId': !exists(json, 'modelId') ? undefined : ModelIdFromJSON(json['modelId']),
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : (new Date(json['lastEditedAt'])),
        'activeState': !exists(json, 'activeState') ? undefined : json['activeState'],
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
        'personalInfos': !exists(json, 'personalInfos') ? undefined : PersonalInfosFromJSON(json['personalInfos']),
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ContactFromJSON(json['contactInfos']),
        'image': !exists(json, 'image') ? undefined : json['image'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'updateStates': !exists(json, 'updateStates') ? undefined : json['updateStates'],
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressFromJSON(json['notifyAddress']),
        'identification': !exists(json, 'identification') ? undefined : IdentificationFromJSON(json['identification']),
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : ((json['spokenLanguages'] as Array<any>).map(SpokenLanguageFromJSON)),
        'specials': !exists(json, 'specials') ? undefined : json['specials'],
        'diet': !exists(json, 'diet') ? undefined : DietFromJSON(json['diet']),
        'job': !exists(json, 'job') ? undefined : JobFromJSON(json['job']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFromJSON(json['bankAccountInformation']),
        'insurance': !exists(json, 'insurance') ? undefined : InsuranceFromJSON(json['insurance']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationFromJSON(json['taxInformation']),
        'colorsAndTypes': !exists(json, 'colorsAndTypes') ? undefined : ColorsAndTypesFromJSON(json['colorsAndTypes']),
        'castingBody': !exists(json, 'castingBody') ? undefined : CastingBodyFromJSON(json['castingBody']),
        'bodyAccessories': !exists(json, 'bodyAccessories') ? undefined : BodyAccessoriesFromJSON(json['bodyAccessories']),
        'conditions': !exists(json, 'conditions') ? undefined : ModelConditionsFromJSON(json['conditions']),
        'instruments': !exists(json, 'instruments') ? undefined : ((json['instruments'] as Array<any>).map(InstrumentFromJSON)),
        'additionalAgencies': !exists(json, 'additionalAgencies') ? undefined : json['additionalAgencies'],
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(DanceFromJSON)),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportFromJSON)),
        'bodyDetails': !exists(json, 'bodyDetails') ? undefined : BodyDetailsFromJSON(json['bodyDetails']),
        'actingExperience': !exists(json, 'actingExperience') ? undefined : ActingExperienceFromJSON(json['actingExperience']),
        'modelExperience': !exists(json, 'modelExperience') ? undefined : ModelingExperienceFromJSON(json['modelExperience']),
        'parentAgency': !exists(json, 'parentAgency') ? undefined : json['parentAgency'],
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardFromJSON(json['bahnCard']),
        'driversLicenses': !exists(json, 'driversLicenses') ? undefined : json['driversLicenses'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'protocol': !exists(json, 'protocol') ? undefined : ((json['protocol'] as Array<any>).map(ProtocolFromJSON)),
        'appointments': !exists(json, 'appointments') ? undefined : ((json['appointments'] as Array<any>).map(AppointmentFromJSON)),
        'modelCosts': !exists(json, 'modelCosts') ? undefined : ((json['modelCosts'] as Array<any>).map(ModelCostFromJSON)),
        'modelAgency': !exists(json, 'modelAgency') ? undefined : ((json['modelAgency'] as Array<any>).map(ModelAgencyFromJSON)),
        'chargeableProjects': !exists(json, 'chargeableProjects') ? undefined : ((json['chargeableProjects'] as Array<any>).map(ChargeableProjectFromJSON)),
        'modelBillingCost': !exists(json, 'modelBillingCost') ? undefined : ((json['modelBillingCost'] as Array<any>).map(ModelBillingCostFromJSON)),
        'personalNotice': !exists(json, 'personalNotice') ? undefined : json['personalNotice'],
        'oauthUserId': !exists(json, 'oauthUserId') ? undefined : OAuthUserIdFromJSON(json['oauthUserId']),
    };
}

export function ModelMasterDataToJSON(value?: ModelMasterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brodyUserReferenceId': value.brodyUserReferenceId,
        'modelId': ModelIdToJSON(value.modelId),
        'lastEditedAt': value.lastEditedAt === undefined ? undefined : (value.lastEditedAt.toISOString()),
        'activeState': value.activeState,
        'email': EmailToJSON(value.email),
        'personalInfos': PersonalInfosToJSON(value.personalInfos),
        'contactInfos': ContactToJSON(value.contactInfos),
        'image': value.image,
        'notes': value.notes,
        'updateStates': value.updateStates,
        'livingAddress': AddressToJSON(value.livingAddress),
        'notifyAddress': AddressToJSON(value.notifyAddress),
        'identification': IdentificationToJSON(value.identification),
        'spokenLanguages': value.spokenLanguages === undefined ? undefined : ((value.spokenLanguages as Array<any>).map(SpokenLanguageToJSON)),
        'specials': value.specials,
        'diet': DietToJSON(value.diet),
        'job': JobToJSON(value.job),
        'bankAccountInformation': BankAccountInformationToJSON(value.bankAccountInformation),
        'insurance': InsuranceToJSON(value.insurance),
        'taxInformation': TaxInformationToJSON(value.taxInformation),
        'colorsAndTypes': ColorsAndTypesToJSON(value.colorsAndTypes),
        'castingBody': CastingBodyToJSON(value.castingBody),
        'bodyAccessories': BodyAccessoriesToJSON(value.bodyAccessories),
        'conditions': ModelConditionsToJSON(value.conditions),
        'instruments': value.instruments === undefined ? undefined : ((value.instruments as Array<any>).map(InstrumentToJSON)),
        'additionalAgencies': value.additionalAgencies,
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(DanceToJSON)),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportToJSON)),
        'bodyDetails': BodyDetailsToJSON(value.bodyDetails),
        'actingExperience': ActingExperienceToJSON(value.actingExperience),
        'modelExperience': ModelingExperienceToJSON(value.modelExperience),
        'parentAgency': value.parentAgency,
        'bahnCard': BahnCardToJSON(value.bahnCard),
        'driversLicenses': value.driversLicenses,
        'category': CategoryToJSON(value.category),
        'protocol': value.protocol === undefined ? undefined : ((value.protocol as Array<any>).map(ProtocolToJSON)),
        'appointments': value.appointments === undefined ? undefined : ((value.appointments as Array<any>).map(AppointmentToJSON)),
        'modelCosts': value.modelCosts === undefined ? undefined : ((value.modelCosts as Array<any>).map(ModelCostToJSON)),
        'modelAgency': value.modelAgency === undefined ? undefined : ((value.modelAgency as Array<any>).map(ModelAgencyToJSON)),
        'chargeableProjects': value.chargeableProjects === undefined ? undefined : ((value.chargeableProjects as Array<any>).map(ChargeableProjectToJSON)),
        'modelBillingCost': value.modelBillingCost === undefined ? undefined : ((value.modelBillingCost as Array<any>).map(ModelBillingCostToJSON)),
        'personalNotice': value.personalNotice,
        'oauthUserId': OAuthUserIdToJSON(value.oauthUserId),
    };
}

