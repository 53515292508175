<div class="flex">
    <div class="container-list">
        <ng-container *ngIf="projectParticipants.length">
            <p-tree [value]="projectParticipants" class="w-full md:w-35rem" selectionMode="single" [(selection)]="selectedRecipient" (onNodeSelect)="nodeSelect($event)"></p-tree>
        </ng-container>
        <ng-container *ngIf="modelParticipants.length">
            <p-tree [value]="modelParticipants" class="w-full md:w-35rem" selectionMode="single" [filter]="true" filterPlaceholder="Model" [(selection)]="selectedRecipient" (onNodeSelect)="nodeSelect($event)"></p-tree>
        </ng-container>
    </div>
    <div class="container-chat">
        <app-conversation-chat
                [isVisible]="selectedRecipient !== null"
                [conversationId]="selectedReceiverConversationId"
                [messageList]="messageList"
                [currentUserOauthId]="currentUserOauthId"
                [isGroupChat]="false"
                [shouldScrollToBottom]="true"
                [onSendMessage]="onSendMessage.bind(this)"
                [onDeleteMessage]="onDeleteMessage.bind(this)"
                [onCameraButtonClick]="onCameraButtonClick.bind(this)"
                [onRefreshMessages]="onRefreshMessages.bind(this)"
        ></app-conversation-chat>
    </div>
</div>
<p-toast></p-toast>
