/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectInfo } from './ProjectInfo';
import {
    ProjectInfoFromJSON,
    ProjectInfoFromJSONTyped,
    ProjectInfoToJSON,
} from './ProjectInfo';

/**
 * 
 * @export
 * @interface GetProjectStatusResponse
 */
export interface GetProjectStatusResponse {
    /**
     * 
     * @type {Array<ProjectInfo>}
     * @memberof GetProjectStatusResponse
     */
    myProjects?: Array<ProjectInfo>;
    /**
     * 
     * @type {Array<ProjectInfo>}
     * @memberof GetProjectStatusResponse
     */
    otherProjects?: Array<ProjectInfo>;
}

/**
 * Check if a given object implements the GetProjectStatusResponse interface.
 */
export function instanceOfGetProjectStatusResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetProjectStatusResponseFromJSON(json: any): GetProjectStatusResponse {
    return GetProjectStatusResponseFromJSONTyped(json, false);
}

export function GetProjectStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'myProjects': !exists(json, 'myProjects') ? undefined : ((json['myProjects'] as Array<any>).map(ProjectInfoFromJSON)),
        'otherProjects': !exists(json, 'otherProjects') ? undefined : ((json['otherProjects'] as Array<any>).map(ProjectInfoFromJSON)),
    };
}

export function GetProjectStatusResponseToJSON(value?: GetProjectStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'myProjects': value.myProjects === undefined ? undefined : ((value.myProjects as Array<any>).map(ProjectInfoToJSON)),
        'otherProjects': value.otherProjects === undefined ? undefined : ((value.otherProjects as Array<any>).map(ProjectInfoToJSON)),
    };
}

