/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelOverviewSearchItem } from './ModelOverviewSearchItem';
import {
    ModelOverviewSearchItemFromJSON,
    ModelOverviewSearchItemFromJSONTyped,
    ModelOverviewSearchItemToJSON,
} from './ModelOverviewSearchItem';

/**
 * 
 * @export
 * @interface ModelOverviewDto
 */
export interface ModelOverviewDto {
    /**
     * 
     * @type {Array<ModelOverviewSearchItem>}
     * @memberof ModelOverviewDto
     */
    filteredModels?: Array<ModelOverviewSearchItem>;
    /**
     * 
     * @type {number}
     * @memberof ModelOverviewDto
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelOverviewDto
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelOverviewDto
     */
    totalPages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelOverviewDto
     */
    hasNextPage?: boolean;
}

/**
 * Check if a given object implements the ModelOverviewDto interface.
 */
export function instanceOfModelOverviewDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelOverviewDtoFromJSON(json: any): ModelOverviewDto {
    return ModelOverviewDtoFromJSONTyped(json, false);
}

export function ModelOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filteredModels': !exists(json, 'filteredModels') ? undefined : ((json['filteredModels'] as Array<any>).map(ModelOverviewSearchItemFromJSON)),
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'hasNextPage': !exists(json, 'hasNextPage') ? undefined : json['hasNextPage'],
    };
}

export function ModelOverviewDtoToJSON(value?: ModelOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filteredModels': value.filteredModels === undefined ? undefined : ((value.filteredModels as Array<any>).map(ModelOverviewSearchItemToJSON)),
        'currentPage': value.currentPage,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'hasNextPage': value.hasNextPage,
    };
}

