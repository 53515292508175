/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AvailablePublicFormValuesDto
 */
export interface AvailablePublicFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    availableSexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    nationalityOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    skinColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    eyeColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    hairColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    categoryTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    confectionSizeFemaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    confectionSizeMaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    shoeSizeEuOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailablePublicFormValuesDto
     */
    shoeSizeUsOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the AvailablePublicFormValuesDto interface.
 */
export function instanceOfAvailablePublicFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AvailablePublicFormValuesDtoFromJSON(json: any): AvailablePublicFormValuesDto {
    return AvailablePublicFormValuesDtoFromJSONTyped(json, false);
}

export function AvailablePublicFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailablePublicFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableSexes': !exists(json, 'availableSexes') ? undefined : ((json['availableSexes'] as Array<any>).map(AdminValueFromJSON)),
        'nationalityOptions': !exists(json, 'nationalityOptions') ? undefined : ((json['nationalityOptions'] as Array<any>).map(AdminValueFromJSON)),
        'skinColorOptions': !exists(json, 'skinColorOptions') ? undefined : ((json['skinColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'eyeColorOptions': !exists(json, 'eyeColorOptions') ? undefined : ((json['eyeColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairColorOptions': !exists(json, 'hairColorOptions') ? undefined : ((json['hairColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'categoryTypeOptions': !exists(json, 'categoryTypeOptions') ? undefined : ((json['categoryTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'confectionSizeFemaleOptions': !exists(json, 'confectionSizeFemaleOptions') ? undefined : ((json['confectionSizeFemaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'confectionSizeMaleOptions': !exists(json, 'confectionSizeMaleOptions') ? undefined : ((json['confectionSizeMaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeEuOptions': !exists(json, 'shoeSizeEuOptions') ? undefined : ((json['shoeSizeEuOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeUsOptions': !exists(json, 'shoeSizeUsOptions') ? undefined : ((json['shoeSizeUsOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function AvailablePublicFormValuesDtoToJSON(value?: AvailablePublicFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableSexes': value.availableSexes === undefined ? undefined : ((value.availableSexes as Array<any>).map(AdminValueToJSON)),
        'nationalityOptions': value.nationalityOptions === undefined ? undefined : ((value.nationalityOptions as Array<any>).map(AdminValueToJSON)),
        'skinColorOptions': value.skinColorOptions === undefined ? undefined : ((value.skinColorOptions as Array<any>).map(AdminValueToJSON)),
        'eyeColorOptions': value.eyeColorOptions === undefined ? undefined : ((value.eyeColorOptions as Array<any>).map(AdminValueToJSON)),
        'hairColorOptions': value.hairColorOptions === undefined ? undefined : ((value.hairColorOptions as Array<any>).map(AdminValueToJSON)),
        'categoryTypeOptions': value.categoryTypeOptions === undefined ? undefined : ((value.categoryTypeOptions as Array<any>).map(AdminValueToJSON)),
        'confectionSizeFemaleOptions': value.confectionSizeFemaleOptions === undefined ? undefined : ((value.confectionSizeFemaleOptions as Array<any>).map(AdminValueToJSON)),
        'confectionSizeMaleOptions': value.confectionSizeMaleOptions === undefined ? undefined : ((value.confectionSizeMaleOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeEuOptions': value.shoeSizeEuOptions === undefined ? undefined : ((value.shoeSizeEuOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeUsOptions': value.shoeSizeUsOptions === undefined ? undefined : ((value.shoeSizeUsOptions as Array<any>).map(AdminValueToJSON)),
    };
}

