/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBookerUserCommand
 */
export interface CreateBookerUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    role?: CreateBookerUserCommandRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookerUserCommand
     */
    agencyId?: string;
}


/**
 * @export
 */
export const CreateBookerUserCommandRoleEnum = {
    Admin: 'Admin',
    Booker: 'Booker',
    BookerAdmin: 'BookerAdmin',
    JuniorBooker: 'JuniorBooker',
    Model: 'Model',
    Customer: 'Customer',
    ExternalParticipant: 'ExternalParticipant'
} as const;
export type CreateBookerUserCommandRoleEnum = typeof CreateBookerUserCommandRoleEnum[keyof typeof CreateBookerUserCommandRoleEnum];


/**
 * Check if a given object implements the CreateBookerUserCommand interface.
 */
export function instanceOfCreateBookerUserCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function CreateBookerUserCommandFromJSON(json: any): CreateBookerUserCommand {
    return CreateBookerUserCommandFromJSONTyped(json, false);
}

export function CreateBookerUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBookerUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
    };
}

export function CreateBookerUserCommandToJSON(value?: CreateBookerUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'role': value.role,
        'bookerId': value.bookerId,
        'agencyId': value.agencyId,
    };
}

