/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SexFormValuesDto
 */
export interface SexFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof SexFormValuesDto
     */
    sex?: SexFormValuesDtoSexEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SexFormValuesDto
     */
    sexOptions?: Array<string>;
}


/**
 * @export
 */
export const SexFormValuesDtoSexEnum = {
    Female: 'FEMALE',
    Male: 'MALE',
    Diverse: 'DIVERSE'
} as const;
export type SexFormValuesDtoSexEnum = typeof SexFormValuesDtoSexEnum[keyof typeof SexFormValuesDtoSexEnum];


/**
 * Check if a given object implements the SexFormValuesDto interface.
 */
export function instanceOfSexFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SexFormValuesDtoFromJSON(json: any): SexFormValuesDto {
    return SexFormValuesDtoFromJSONTyped(json, false);
}

export function SexFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'sexOptions': !exists(json, 'sexOptions') ? undefined : json['sexOptions'],
    };
}

export function SexFormValuesDtoToJSON(value?: SexFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sex': value.sex,
        'sexOptions': value.sexOptions,
    };
}

