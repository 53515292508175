/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PortfolioImage } from './PortfolioImage';
import {
    PortfolioImageFromJSON,
    PortfolioImageFromJSONTyped,
    PortfolioImageToJSON,
} from './PortfolioImage';

/**
 * 
 * @export
 * @interface UploadedImageResult
 */
export interface UploadedImageResult {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageResult
     */
    status?: UploadedImageResultStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UploadedImageResult
     */
    message?: string;
    /**
     * 
     * @type {PortfolioImage}
     * @memberof UploadedImageResult
     */
    image?: PortfolioImage;
}


/**
 * @export
 */
export const UploadedImageResultStatusEnum = {
    Success: 'Success',
    Failure: 'Failure'
} as const;
export type UploadedImageResultStatusEnum = typeof UploadedImageResultStatusEnum[keyof typeof UploadedImageResultStatusEnum];


/**
 * Check if a given object implements the UploadedImageResult interface.
 */
export function instanceOfUploadedImageResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadedImageResultFromJSON(json: any): UploadedImageResult {
    return UploadedImageResultFromJSONTyped(json, false);
}

export function UploadedImageResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedImageResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'image': !exists(json, 'image') ? undefined : PortfolioImageFromJSON(json['image']),
    };
}

export function UploadedImageResultToJSON(value?: UploadedImageResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
        'image': PortfolioImageToJSON(value.image),
    };
}

