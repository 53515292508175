import {BASE_PATH} from "@brody-bookings/api";

export const environment = {
  production: true,
  auth: {
    domain:"auth.ebooker.brodybookings.com",
    clientId:"ovb4fbofIGk3DU2wgj6SOW2jt7YFaj4I",
    authorizationParams: {
      redirect_uri: 'https://ebooker.brodybookings.com',
      audience: "https://brodybookings.api.com",
    }
  },
  httpInterceptor: {
    allowedList: [`${BASE_PATH}/api`],
  }
};
