/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetailsCastingFormValuesDto
 */
export interface DetailsCastingFormValuesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailsCastingFormValuesDto
     */
    urls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DetailsCastingFormValuesDto
     */
    modelName?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailsCastingFormValuesDto
     */
    roleName?: string;
}

/**
 * Check if a given object implements the DetailsCastingFormValuesDto interface.
 */
export function instanceOfDetailsCastingFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DetailsCastingFormValuesDtoFromJSON(json: any): DetailsCastingFormValuesDto {
    return DetailsCastingFormValuesDtoFromJSONTyped(json, false);
}

export function DetailsCastingFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailsCastingFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urls': !exists(json, 'urls') ? undefined : json['urls'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
    };
}

export function DetailsCastingFormValuesDtoToJSON(value?: DetailsCastingFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urls': value.urls,
        'modelName': value.modelName,
        'roleName': value.roleName,
    };
}

