/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BahnCardDto
 */
export interface BahnCardDto {
    /**
     * 
     * @type {string}
     * @memberof BahnCardDto
     */
    type: BahnCardDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BahnCardDto
     */
    isFirstClass: boolean;
    /**
     * 
     * @type {string}
     * @memberof BahnCardDto
     */
    cardNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof BahnCardDto
     */
    expirationDate: Date;
}


/**
 * @export
 */
export const BahnCardDtoTypeEnum = {
    Twentyfive: 'TWENTYFIVE',
    Fifty: 'FIFTY',
    Onehundred: 'ONEHUNDRED'
} as const;
export type BahnCardDtoTypeEnum = typeof BahnCardDtoTypeEnum[keyof typeof BahnCardDtoTypeEnum];


/**
 * Check if a given object implements the BahnCardDto interface.
 */
export function instanceOfBahnCardDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "isFirstClass" in value;
    isInstance = isInstance && "cardNumber" in value;
    isInstance = isInstance && "expirationDate" in value;

    return isInstance;
}

export function BahnCardDtoFromJSON(json: any): BahnCardDto {
    return BahnCardDtoFromJSONTyped(json, false);
}

export function BahnCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BahnCardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'isFirstClass': json['isFirstClass'],
        'cardNumber': json['cardNumber'],
        'expirationDate': (new Date(json['expirationDate'])),
    };
}

export function BahnCardDtoToJSON(value?: BahnCardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'isFirstClass': value.isFirstClass,
        'cardNumber': value.cardNumber,
        'expirationDate': (value.expirationDate.toISOString().substr(0,10)),
    };
}

