/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskManagerInfos
 */
export interface TaskManagerInfos {
    /**
     * 
     * @type {string}
     * @memberof TaskManagerInfos
     */
    selectionStatus?: TaskManagerInfosSelectionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskManagerInfos
     */
    modelBookingStatus?: TaskManagerInfosModelBookingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskManagerInfos
     */
    producerBookingStatus?: TaskManagerInfosProducerBookingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskManagerInfos
     */
    lastVisitByCustomer?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskManagerInfos
     */
    lastChangeByCustomer?: string;
}


/**
 * @export
 */
export const TaskManagerInfosSelectionStatusEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;
export type TaskManagerInfosSelectionStatusEnum = typeof TaskManagerInfosSelectionStatusEnum[keyof typeof TaskManagerInfosSelectionStatusEnum];

/**
 * @export
 */
export const TaskManagerInfosModelBookingStatusEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;
export type TaskManagerInfosModelBookingStatusEnum = typeof TaskManagerInfosModelBookingStatusEnum[keyof typeof TaskManagerInfosModelBookingStatusEnum];

/**
 * @export
 */
export const TaskManagerInfosProducerBookingStatusEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;
export type TaskManagerInfosProducerBookingStatusEnum = typeof TaskManagerInfosProducerBookingStatusEnum[keyof typeof TaskManagerInfosProducerBookingStatusEnum];


/**
 * Check if a given object implements the TaskManagerInfos interface.
 */
export function instanceOfTaskManagerInfos(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaskManagerInfosFromJSON(json: any): TaskManagerInfos {
    return TaskManagerInfosFromJSONTyped(json, false);
}

export function TaskManagerInfosFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskManagerInfos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectionStatus': !exists(json, 'selectionStatus') ? undefined : json['selectionStatus'],
        'modelBookingStatus': !exists(json, 'modelBookingStatus') ? undefined : json['modelBookingStatus'],
        'producerBookingStatus': !exists(json, 'producerBookingStatus') ? undefined : json['producerBookingStatus'],
        'lastVisitByCustomer': !exists(json, 'lastVisitByCustomer') ? undefined : json['lastVisitByCustomer'],
        'lastChangeByCustomer': !exists(json, 'lastChangeByCustomer') ? undefined : json['lastChangeByCustomer'],
    };
}

export function TaskManagerInfosToJSON(value?: TaskManagerInfos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectionStatus': value.selectionStatus,
        'modelBookingStatus': value.modelBookingStatus,
        'producerBookingStatus': value.producerBookingStatus,
        'lastVisitByCustomer': value.lastVisitByCustomer,
        'lastChangeByCustomer': value.lastChangeByCustomer,
    };
}

