/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActingExperience } from './ActingExperience';
import {
    ActingExperienceFromJSON,
    ActingExperienceFromJSONTyped,
    ActingExperienceToJSON,
} from './ActingExperience';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { BahnCard } from './BahnCard';
import {
    BahnCardFromJSON,
    BahnCardFromJSONTyped,
    BahnCardToJSON,
} from './BahnCard';
import type { BankAccountInformation } from './BankAccountInformation';
import {
    BankAccountInformationFromJSON,
    BankAccountInformationFromJSONTyped,
    BankAccountInformationToJSON,
} from './BankAccountInformation';
import type { BodyAccessories } from './BodyAccessories';
import {
    BodyAccessoriesFromJSON,
    BodyAccessoriesFromJSONTyped,
    BodyAccessoriesToJSON,
} from './BodyAccessories';
import type { ColorsAndTypes } from './ColorsAndTypes';
import {
    ColorsAndTypesFromJSON,
    ColorsAndTypesFromJSONTyped,
    ColorsAndTypesToJSON,
} from './ColorsAndTypes';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Dance } from './Dance';
import {
    DanceFromJSON,
    DanceFromJSONTyped,
    DanceToJSON,
} from './Dance';
import type { Email } from './Email';
import {
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import type { Identification } from './Identification';
import {
    IdentificationFromJSON,
    IdentificationFromJSONTyped,
    IdentificationToJSON,
} from './Identification';
import type { Instrument } from './Instrument';
import {
    InstrumentFromJSON,
    InstrumentFromJSONTyped,
    InstrumentToJSON,
} from './Instrument';
import type { Job } from './Job';
import {
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
} from './Job';
import type { ModelingExperience } from './ModelingExperience';
import {
    ModelingExperienceFromJSON,
    ModelingExperienceFromJSONTyped,
    ModelingExperienceToJSON,
} from './ModelingExperience';
import type { PersonalInfos } from './PersonalInfos';
import {
    PersonalInfosFromJSON,
    PersonalInfosFromJSONTyped,
    PersonalInfosToJSON,
} from './PersonalInfos';
import type { Sport } from './Sport';
import {
    SportFromJSON,
    SportFromJSONTyped,
    SportToJSON,
} from './Sport';
import type { TaxInformation } from './TaxInformation';
import {
    TaxInformationFromJSON,
    TaxInformationFromJSONTyped,
    TaxInformationToJSON,
} from './TaxInformation';

/**
 * 
 * @export
 * @interface ModelApplicationSaveDto
 */
export interface ModelApplicationSaveDto {
    /**
     * 
     * @type {Email}
     * @memberof ModelApplicationSaveDto
     */
    email?: Email;
    /**
     * 
     * @type {PersonalInfos}
     * @memberof ModelApplicationSaveDto
     */
    personalInfos?: PersonalInfos;
    /**
     * 
     * @type {Contact}
     * @memberof ModelApplicationSaveDto
     */
    contactInfos?: Contact;
    /**
     * 
     * @type {Address}
     * @memberof ModelApplicationSaveDto
     */
    livingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof ModelApplicationSaveDto
     */
    notifyAddress?: Address;
    /**
     * 
     * @type {Identification}
     * @memberof ModelApplicationSaveDto
     */
    identification?: Identification;
    /**
     * 
     * @type {Job}
     * @memberof ModelApplicationSaveDto
     */
    job?: Job;
    /**
     * 
     * @type {BankAccountInformation}
     * @memberof ModelApplicationSaveDto
     */
    bankAccountInformation?: BankAccountInformation;
    /**
     * 
     * @type {TaxInformation}
     * @memberof ModelApplicationSaveDto
     */
    taxInformation?: TaxInformation;
    /**
     * 
     * @type {ColorsAndTypes}
     * @memberof ModelApplicationSaveDto
     */
    colorsAndTypes?: ColorsAndTypes;
    /**
     * 
     * @type {BodyAccessories}
     * @memberof ModelApplicationSaveDto
     */
    bodyAccessories?: BodyAccessories;
    /**
     * 
     * @type {Array<Instrument>}
     * @memberof ModelApplicationSaveDto
     */
    instruments?: Array<Instrument>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelApplicationSaveDto
     */
    additionalAgencies?: Array<string>;
    /**
     * 
     * @type {Array<Dance>}
     * @memberof ModelApplicationSaveDto
     */
    dances?: Array<Dance>;
    /**
     * 
     * @type {Array<Sport>}
     * @memberof ModelApplicationSaveDto
     */
    sports?: Array<Sport>;
    /**
     * 
     * @type {ActingExperience}
     * @memberof ModelApplicationSaveDto
     */
    actingExperience?: ActingExperience;
    /**
     * 
     * @type {ModelingExperience}
     * @memberof ModelApplicationSaveDto
     */
    modelExperience?: ModelingExperience;
    /**
     * 
     * @type {string}
     * @memberof ModelApplicationSaveDto
     */
    parentAgency?: string;
    /**
     * 
     * @type {BahnCard}
     * @memberof ModelApplicationSaveDto
     */
    bahnCard?: BahnCard;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelApplicationSaveDto
     */
    driversLicenses?: Array<string>;
}

/**
 * Check if a given object implements the ModelApplicationSaveDto interface.
 */
export function instanceOfModelApplicationSaveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelApplicationSaveDtoFromJSON(json: any): ModelApplicationSaveDto {
    return ModelApplicationSaveDtoFromJSONTyped(json, false);
}

export function ModelApplicationSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelApplicationSaveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
        'personalInfos': !exists(json, 'personalInfos') ? undefined : PersonalInfosFromJSON(json['personalInfos']),
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ContactFromJSON(json['contactInfos']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressFromJSON(json['notifyAddress']),
        'identification': !exists(json, 'identification') ? undefined : IdentificationFromJSON(json['identification']),
        'job': !exists(json, 'job') ? undefined : JobFromJSON(json['job']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFromJSON(json['bankAccountInformation']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationFromJSON(json['taxInformation']),
        'colorsAndTypes': !exists(json, 'colorsAndTypes') ? undefined : ColorsAndTypesFromJSON(json['colorsAndTypes']),
        'bodyAccessories': !exists(json, 'bodyAccessories') ? undefined : BodyAccessoriesFromJSON(json['bodyAccessories']),
        'instruments': !exists(json, 'instruments') ? undefined : ((json['instruments'] as Array<any>).map(InstrumentFromJSON)),
        'additionalAgencies': !exists(json, 'additionalAgencies') ? undefined : json['additionalAgencies'],
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(DanceFromJSON)),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportFromJSON)),
        'actingExperience': !exists(json, 'actingExperience') ? undefined : ActingExperienceFromJSON(json['actingExperience']),
        'modelExperience': !exists(json, 'modelExperience') ? undefined : ModelingExperienceFromJSON(json['modelExperience']),
        'parentAgency': !exists(json, 'parentAgency') ? undefined : json['parentAgency'],
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardFromJSON(json['bahnCard']),
        'driversLicenses': !exists(json, 'driversLicenses') ? undefined : json['driversLicenses'],
    };
}

export function ModelApplicationSaveDtoToJSON(value?: ModelApplicationSaveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': EmailToJSON(value.email),
        'personalInfos': PersonalInfosToJSON(value.personalInfos),
        'contactInfos': ContactToJSON(value.contactInfos),
        'livingAddress': AddressToJSON(value.livingAddress),
        'notifyAddress': AddressToJSON(value.notifyAddress),
        'identification': IdentificationToJSON(value.identification),
        'job': JobToJSON(value.job),
        'bankAccountInformation': BankAccountInformationToJSON(value.bankAccountInformation),
        'taxInformation': TaxInformationToJSON(value.taxInformation),
        'colorsAndTypes': ColorsAndTypesToJSON(value.colorsAndTypes),
        'bodyAccessories': BodyAccessoriesToJSON(value.bodyAccessories),
        'instruments': value.instruments === undefined ? undefined : ((value.instruments as Array<any>).map(InstrumentToJSON)),
        'additionalAgencies': value.additionalAgencies,
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(DanceToJSON)),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportToJSON)),
        'actingExperience': ActingExperienceToJSON(value.actingExperience),
        'modelExperience': ModelingExperienceToJSON(value.modelExperience),
        'parentAgency': value.parentAgency,
        'bahnCard': BahnCardToJSON(value.bahnCard),
        'driversLicenses': value.driversLicenses,
    };
}

