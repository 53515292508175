/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentOptionsDto
 */
export interface AppointmentOptionsDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentOptionsDto
     */
    key?: AppointmentOptionsDtoKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOptionsDto
     */
    value?: string;
}


/**
 * @export
 */
export const AppointmentOptionsDtoKeyEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED'
} as const;
export type AppointmentOptionsDtoKeyEnum = typeof AppointmentOptionsDtoKeyEnum[keyof typeof AppointmentOptionsDtoKeyEnum];


/**
 * Check if a given object implements the AppointmentOptionsDto interface.
 */
export function instanceOfAppointmentOptionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentOptionsDtoFromJSON(json: any): AppointmentOptionsDto {
    return AppointmentOptionsDtoFromJSONTyped(json, false);
}

export function AppointmentOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AppointmentOptionsDtoToJSON(value?: AppointmentOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}

