/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConversationDto,
  ConversationResponseDto,
  CreateConversationRequestDto,
  CustomerNamesForMessengerDto,
  GetConversationOfParticipantsCommand,
  MessageDto,
  MessageListDto,
  ModelsParticipantsResponseDto,
  ParticipantResponseDto,
  ProjectNamesForMessengerDto,
  ProjectParticipantResponseDto,
} from '../models';
import {
    ConversationDtoFromJSON,
    ConversationDtoToJSON,
    ConversationResponseDtoFromJSON,
    ConversationResponseDtoToJSON,
    CreateConversationRequestDtoFromJSON,
    CreateConversationRequestDtoToJSON,
    CustomerNamesForMessengerDtoFromJSON,
    CustomerNamesForMessengerDtoToJSON,
    GetConversationOfParticipantsCommandFromJSON,
    GetConversationOfParticipantsCommandToJSON,
    MessageDtoFromJSON,
    MessageDtoToJSON,
    MessageListDtoFromJSON,
    MessageListDtoToJSON,
    ModelsParticipantsResponseDtoFromJSON,
    ModelsParticipantsResponseDtoToJSON,
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoToJSON,
    ProjectNamesForMessengerDtoFromJSON,
    ProjectNamesForMessengerDtoToJSON,
    ProjectParticipantResponseDtoFromJSON,
    ProjectParticipantResponseDtoToJSON,
} from '../models';

export interface AddMessageRequest {
    conversationId: string;
    images?: Array<Blob>;
    message?: string;
}

export interface AddMessageNewRequest {
    images?: Array<Blob>;
    message?: string;
    isBroadcast?: string;
    participantIds?: string;
    projectId?: string;
}

export interface AutoUpdateTrackingRequest {
    conversationId: string;
}

export interface ChangeConversationTitleRequest {
    conversationId: string;
    title: string;
}

export interface CreateConversationRequest {
    language: string;
    createConversationRequestDto: CreateConversationRequestDto;
}

export interface DeleteConversationRequest {
    conversationId: string;
}

export interface DeleteMessageRequest {
    conversationId: string;
    messageId: string;
}

export interface GetConversationOfParticipantsRequest {
    getConversationOfParticipantsCommand: GetConversationOfParticipantsCommand;
}

export interface GetConversationsRequest {
    language: string;
}

export interface GetCustomerEmployeesRequest {
    customerId: string;
}

export interface GetGroupedProjectParticipantsRequest {
    projectId: string;
    language: string;
}

export interface GetMessagesByConversationIdRequest {
    conversationId: string;
}

export interface GetModelBySearchTermRequest {
    language: string;
    searchterm: string;
}

export interface GetModels1Request {
    language: string;
}

export interface GetProjectParticipantsRequest {
    projectId: string;
}

/**
 * 
 */
export class ConversationControllerApi extends runtime.BaseAPI {

    /**
     */
    async addMessageRaw(requestParameters: AddMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageListDto>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling addMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        if (requestParameters.message !== undefined) {
            formParams.append('message', requestParameters.message as any);
        }

        const response = await this.request({
            path: `/api/conversation/add/message/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageListDtoFromJSON(jsonValue));
    }

    /**
     */
    async addMessage(requestParameters: AddMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageListDto> {
        const response = await this.addMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addMessageNewRaw(requestParameters: AddMessageNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        if (requestParameters.message !== undefined) {
            formParams.append('message', requestParameters.message as any);
        }

        if (requestParameters.isBroadcast !== undefined) {
            formParams.append('isBroadcast', requestParameters.isBroadcast as any);
        }

        if (requestParameters.participantIds !== undefined) {
            formParams.append('participantIds', requestParameters.participantIds as any);
        }

        if (requestParameters.projectId !== undefined) {
            formParams.append('projectId', requestParameters.projectId as any);
        }

        const response = await this.request({
            path: `/api/conversation/add/message/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageListDtoFromJSON(jsonValue));
    }

    /**
     */
    async addMessageNew(requestParameters: AddMessageNewRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageListDto> {
        const response = await this.addMessageNewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async autoUpdateTrackingRaw(requestParameters: AutoUpdateTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling autoUpdateTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/auto-update/tracking/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async autoUpdateTracking(requestParameters: AutoUpdateTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.autoUpdateTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeConversationTitleRaw(requestParameters: ChangeConversationTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling changeConversationTitle.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling changeConversationTitle.');
        }

        const queryParameters: any = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/change/title/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeConversationTitle(requestParameters: ChangeConversationTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeConversationTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createConversationRaw(requestParameters: CreateConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversationResponseDto>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling createConversation.');
        }

        if (requestParameters.createConversationRequestDto === null || requestParameters.createConversationRequestDto === undefined) {
            throw new runtime.RequiredError('createConversationRequestDto','Required parameter requestParameters.createConversationRequestDto was null or undefined when calling createConversation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conversation/create/conversation/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConversationRequestDtoToJSON(requestParameters.createConversationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async createConversation(requestParameters: CreateConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversationResponseDto> {
        const response = await this.createConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteConversationRaw(requestParameters: DeleteConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling deleteConversation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/delete/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteConversation(requestParameters: DeleteConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMessageRaw(requestParameters: DeleteMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling deleteMessage.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling deleteMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/message/delete/{conversationId}/{messageId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteMessage(requestParameters: DeleteMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getConversationOfParticipantsRaw(requestParameters: GetConversationOfParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.getConversationOfParticipantsCommand === null || requestParameters.getConversationOfParticipantsCommand === undefined) {
            throw new runtime.RequiredError('getConversationOfParticipantsCommand','Required parameter requestParameters.getConversationOfParticipantsCommand was null or undefined when calling getConversationOfParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conversation/participants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetConversationOfParticipantsCommandToJSON(requestParameters.getConversationOfParticipantsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getConversationOfParticipants(requestParameters: GetConversationOfParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getConversationOfParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getConversationsRaw(requestParameters: GetConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConversationDto>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getConversations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConversationDtoFromJSON));
    }

    /**
     */
    async getConversations(requestParameters: GetConversationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConversationDto>> {
        const response = await this.getConversationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/currentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerEmployeesRaw(requestParameters: GetCustomerEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantResponseDto>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/customer/employees/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantResponseDtoFromJSON));
    }

    /**
     */
    async getCustomerEmployees(requestParameters: GetCustomerEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantResponseDto>> {
        const response = await this.getCustomerEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerNamesForMessengerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerNamesForMessengerDtoFromJSON));
    }

    /**
     */
    async getCustomerNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerNamesForMessengerDto>> {
        const response = await this.getCustomerNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupedProjectParticipantsRaw(requestParameters: GetGroupedProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectParticipantResponseDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getGroupedProjectParticipants.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getGroupedProjectParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/project/participants/{projectId}/{language}/grouped`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectParticipantResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getGroupedProjectParticipants(requestParameters: GetGroupedProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectParticipantResponseDto> {
        const response = await this.getGroupedProjectParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMessagesByConversationIdRaw(requestParameters: GetMessagesByConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDto>>> {
        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling getMessagesByConversationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/messages/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(requestParameters.conversationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageDtoFromJSON));
    }

    /**
     */
    async getMessagesByConversationId(requestParameters: GetMessagesByConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDto>> {
        const response = await this.getMessagesByConversationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelBySearchTermRaw(requestParameters: GetModelBySearchTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantResponseDto>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getModelBySearchTerm.');
        }

        if (requestParameters.searchterm === null || requestParameters.searchterm === undefined) {
            throw new runtime.RequiredError('searchterm','Required parameter requestParameters.searchterm was null or undefined when calling getModelBySearchTerm.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchterm !== undefined) {
            queryParameters['searchterm'] = requestParameters.searchterm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/search/model/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantResponseDtoFromJSON));
    }

    /**
     */
    async getModelBySearchTerm(requestParameters: GetModelBySearchTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantResponseDto>> {
        const response = await this.getModelBySearchTermRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModels1Raw(requestParameters: GetModels1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelsParticipantsResponseDto>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getModels1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/models/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelsParticipantsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModels1(requestParameters: GetModels1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelsParticipantsResponseDto> {
        const response = await this.getModels1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectParticipantsRaw(requestParameters: GetProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantResponseDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/project/participants/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantResponseDtoFromJSON));
    }

    /**
     */
    async getProjectParticipants(requestParameters: GetProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantResponseDto>> {
        const response = await this.getProjectParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectNamesForMessengerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conversation/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectNamesForMessengerDtoFromJSON));
    }

    /**
     */
    async getProjects(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectNamesForMessengerDto>> {
        const response = await this.getProjectsRaw(initOverrides);
        return await response.value();
    }

}
