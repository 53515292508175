/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEmployeeNamesForProjectsDto } from './CustomerEmployeeNamesForProjectsDto';
import {
    CustomerEmployeeNamesForProjectsDtoFromJSON,
    CustomerEmployeeNamesForProjectsDtoFromJSONTyped,
    CustomerEmployeeNamesForProjectsDtoToJSON,
} from './CustomerEmployeeNamesForProjectsDto';

/**
 * 
 * @export
 * @interface CustomerEmployeeListForProject
 */
export interface CustomerEmployeeListForProject {
    /**
     * 
     * @type {Array<CustomerEmployeeNamesForProjectsDto>}
     * @memberof CustomerEmployeeListForProject
     */
    customerEmployeeNamesForProjectsDtoList?: Array<CustomerEmployeeNamesForProjectsDto>;
}

/**
 * Check if a given object implements the CustomerEmployeeListForProject interface.
 */
export function instanceOfCustomerEmployeeListForProject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerEmployeeListForProjectFromJSON(json: any): CustomerEmployeeListForProject {
    return CustomerEmployeeListForProjectFromJSONTyped(json, false);
}

export function CustomerEmployeeListForProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEmployeeListForProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerEmployeeNamesForProjectsDtoList': !exists(json, 'customerEmployeeNamesForProjectsDtoList') ? undefined : ((json['customerEmployeeNamesForProjectsDtoList'] as Array<any>).map(CustomerEmployeeNamesForProjectsDtoFromJSON)),
    };
}

export function CustomerEmployeeListForProjectToJSON(value?: CustomerEmployeeListForProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerEmployeeNamesForProjectsDtoList': value.customerEmployeeNamesForProjectsDtoList === undefined ? undefined : ((value.customerEmployeeNamesForProjectsDtoList as Array<any>).map(CustomerEmployeeNamesForProjectsDtoToJSON)),
    };
}

