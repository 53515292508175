/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyAccessoriesDto
 */
export interface BodyAccessoriesDto {
    /**
     * 
     * @type {boolean}
     * @memberof BodyAccessoriesDto
     */
    earHole: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyAccessoriesDto
     */
    piercings?: Array<BodyAccessoriesDtoPiercingsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyAccessoriesDto
     */
    tattoos?: Array<BodyAccessoriesDtoTattoosEnum>;
}


/**
 * @export
 */
export const BodyAccessoriesDtoPiercingsEnum = {
    Nose: 'NOSE',
    Eyebrow: 'EYEBROW',
    Ear: 'EAR',
    Breast: 'BREAST'
} as const;
export type BodyAccessoriesDtoPiercingsEnum = typeof BodyAccessoriesDtoPiercingsEnum[keyof typeof BodyAccessoriesDtoPiercingsEnum];

/**
 * @export
 */
export const BodyAccessoriesDtoTattoosEnum = {
    Face: 'FACE',
    Neck: 'NECK',
    Breast: 'BREAST',
    Shoulder: 'SHOULDER',
    UpperArm: 'UPPER_ARM',
    LowerArm: 'LOWER_ARM',
    Hand: 'HAND',
    Belly: 'BELLY',
    UpperBack: 'UPPER_BACK',
    LowerBack: 'LOWER_BACK',
    Thigh: 'THIGH',
    Calves: 'CALVES',
    Foot: 'FOOT'
} as const;
export type BodyAccessoriesDtoTattoosEnum = typeof BodyAccessoriesDtoTattoosEnum[keyof typeof BodyAccessoriesDtoTattoosEnum];


/**
 * Check if a given object implements the BodyAccessoriesDto interface.
 */
export function instanceOfBodyAccessoriesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "earHole" in value;

    return isInstance;
}

export function BodyAccessoriesDtoFromJSON(json: any): BodyAccessoriesDto {
    return BodyAccessoriesDtoFromJSONTyped(json, false);
}

export function BodyAccessoriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyAccessoriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'earHole': json['earHole'],
        'piercings': !exists(json, 'piercings') ? undefined : json['piercings'],
        'tattoos': !exists(json, 'tattoos') ? undefined : json['tattoos'],
    };
}

export function BodyAccessoriesDtoToJSON(value?: BodyAccessoriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'earHole': value.earHole,
        'piercings': value.piercings,
        'tattoos': value.tattoos,
    };
}

