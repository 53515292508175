/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInformationRequestDto
 */
export interface UserInformationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserInformationRequestDto
     */
    userFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInformationRequestDto
     */
    userLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInformationRequestDto
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInformationRequestDto
     */
    userPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInformationRequestDto
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the UserInformationRequestDto interface.
 */
export function instanceOfUserInformationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserInformationRequestDtoFromJSON(json: any): UserInformationRequestDto {
    return UserInformationRequestDtoFromJSONTyped(json, false);
}

export function UserInformationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInformationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userFirstName': !exists(json, 'userFirstName') ? undefined : json['userFirstName'],
        'userLastName': !exists(json, 'userLastName') ? undefined : json['userLastName'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'userPhone': !exists(json, 'userPhone') ? undefined : json['userPhone'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function UserInformationRequestDtoToJSON(value?: UserInformationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userFirstName': value.userFirstName,
        'userLastName': value.userLastName,
        'userEmail': value.userEmail,
        'userPhone': value.userPhone,
        'oauthId': value.oauthId,
    };
}

