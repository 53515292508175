/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductionDetails
 */
export interface ProductionDetails {
    /**
     * 
     * @type {Date}
     * @memberof ProductionDetails
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductionDetails
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductionDetails
     */
    timeWindow?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDetails
     */
    productionContact?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDetails
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDetails
     */
    phone?: string;
}

/**
 * Check if a given object implements the ProductionDetails interface.
 */
export function instanceOfProductionDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductionDetailsFromJSON(json: any): ProductionDetails {
    return ProductionDetailsFromJSONTyped(json, false);
}

export function ProductionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'timeWindow': !exists(json, 'timeWindow') ? undefined : json['timeWindow'],
        'productionContact': !exists(json, 'productionContact') ? undefined : json['productionContact'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function ProductionDetailsToJSON(value?: ProductionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'timeWindow': value.timeWindow,
        'productionContact': value.productionContact,
        'mobile': value.mobile,
        'phone': value.phone,
    };
}

