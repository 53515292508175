/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BudgetFormValuesDto
 */
export interface BudgetFormValuesDto {
    /**
     * 
     * @type {number}
     * @memberof BudgetFormValuesDto
     */
    total?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetFormValuesDto
     */
    inclProvision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BudgetFormValuesDto
     */
    description?: string;
}

/**
 * Check if a given object implements the BudgetFormValuesDto interface.
 */
export function instanceOfBudgetFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BudgetFormValuesDtoFromJSON(json: any): BudgetFormValuesDto {
    return BudgetFormValuesDtoFromJSONTyped(json, false);
}

export function BudgetFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'inclProvision': !exists(json, 'inclProvision') ? undefined : json['inclProvision'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function BudgetFormValuesDtoToJSON(value?: BudgetFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'inclProvision': value.inclProvision,
        'description': value.description,
    };
}

