/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectMotifFormValuesDto } from './ProjectMotifFormValuesDto';
import {
    ProjectMotifFormValuesDtoFromJSON,
    ProjectMotifFormValuesDtoFromJSONTyped,
    ProjectMotifFormValuesDtoToJSON,
} from './ProjectMotifFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectLocationFormValuesDto
 */
export interface ProjectLocationFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationContact?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocationFormValuesDto
     */
    locationId?: string;
    /**
     * 
     * @type {Array<ProjectMotifFormValuesDto>}
     * @memberof ProjectLocationFormValuesDto
     */
    motifs?: Array<ProjectMotifFormValuesDto>;
}

/**
 * Check if a given object implements the ProjectLocationFormValuesDto interface.
 */
export function instanceOfProjectLocationFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectLocationFormValuesDtoFromJSON(json: any): ProjectLocationFormValuesDto {
    return ProjectLocationFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectLocationFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectLocationFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
        'locationAddress': !exists(json, 'locationAddress') ? undefined : json['locationAddress'],
        'locationInfo': !exists(json, 'locationInfo') ? undefined : json['locationInfo'],
        'locationContact': !exists(json, 'locationContact') ? undefined : json['locationContact'],
        'locationMobile': !exists(json, 'locationMobile') ? undefined : json['locationMobile'],
        'locationPhone': !exists(json, 'locationPhone') ? undefined : json['locationPhone'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'motifs': !exists(json, 'motifs') ? undefined : ((json['motifs'] as Array<any>).map(ProjectMotifFormValuesDtoFromJSON)),
    };
}

export function ProjectLocationFormValuesDtoToJSON(value?: ProjectLocationFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationName': value.locationName,
        'locationAddress': value.locationAddress,
        'locationInfo': value.locationInfo,
        'locationContact': value.locationContact,
        'locationMobile': value.locationMobile,
        'locationPhone': value.locationPhone,
        'locationId': value.locationId,
        'motifs': value.motifs === undefined ? undefined : ((value.motifs as Array<any>).map(ProjectMotifFormValuesDtoToJSON)),
    };
}

