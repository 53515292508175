/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ServiceProviderDto } from './ServiceProviderDto';
import {
    ServiceProviderDtoFromJSON,
    ServiceProviderDtoFromJSONTyped,
    ServiceProviderDtoToJSON,
} from './ServiceProviderDto';

/**
 * 
 * @export
 * @interface ServiceProviderWithOptionsDto
 */
export interface ServiceProviderWithOptionsDto {
    /**
     * 
     * @type {ServiceProviderDto}
     * @memberof ServiceProviderWithOptionsDto
     */
    serviceProvider?: ServiceProviderDto;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ServiceProviderWithOptionsDto
     */
    cities?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ServiceProviderWithOptionsDto
     */
    countries?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ServiceProviderWithOptionsDto
     */
    sexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ServiceProviderWithOptionsDto
     */
    positions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ServiceProviderWithOptionsDto interface.
 */
export function instanceOfServiceProviderWithOptionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderWithOptionsDtoFromJSON(json: any): ServiceProviderWithOptionsDto {
    return ServiceProviderWithOptionsDtoFromJSONTyped(json, false);
}

export function ServiceProviderWithOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderWithOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProvider': !exists(json, 'serviceProvider') ? undefined : ServiceProviderDtoFromJSON(json['serviceProvider']),
        'cities': !exists(json, 'cities') ? undefined : ((json['cities'] as Array<any>).map(AdminValueFromJSON)),
        'countries': !exists(json, 'countries') ? undefined : ((json['countries'] as Array<any>).map(AdminValueFromJSON)),
        'sexes': !exists(json, 'sexes') ? undefined : ((json['sexes'] as Array<any>).map(AdminValueFromJSON)),
        'positions': !exists(json, 'positions') ? undefined : ((json['positions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ServiceProviderWithOptionsDtoToJSON(value?: ServiceProviderWithOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProvider': ServiceProviderDtoToJSON(value.serviceProvider),
        'cities': value.cities === undefined ? undefined : ((value.cities as Array<any>).map(AdminValueToJSON)),
        'countries': value.countries === undefined ? undefined : ((value.countries as Array<any>).map(AdminValueToJSON)),
        'sexes': value.sexes === undefined ? undefined : ((value.sexes as Array<any>).map(AdminValueToJSON)),
        'positions': value.positions === undefined ? undefined : ((value.positions as Array<any>).map(AdminValueToJSON)),
    };
}

