/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OauthUserCreatedResponseDto
 */
export interface OauthUserCreatedResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OauthUserCreatedResponseDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof OauthUserCreatedResponseDto
     */
    password?: string;
}

/**
 * Check if a given object implements the OauthUserCreatedResponseDto interface.
 */
export function instanceOfOauthUserCreatedResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OauthUserCreatedResponseDtoFromJSON(json: any): OauthUserCreatedResponseDto {
    return OauthUserCreatedResponseDtoFromJSONTyped(json, false);
}

export function OauthUserCreatedResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthUserCreatedResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function OauthUserCreatedResponseDtoToJSON(value?: OauthUserCreatedResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
    };
}

