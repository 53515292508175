/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface SpecialsFormValuesDto
 */
export interface SpecialsFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof SpecialsFormValuesDto
     */
    specials?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof SpecialsFormValuesDto
     */
    specialsOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the SpecialsFormValuesDto interface.
 */
export function instanceOfSpecialsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpecialsFormValuesDtoFromJSON(json: any): SpecialsFormValuesDto {
    return SpecialsFormValuesDtoFromJSONTyped(json, false);
}

export function SpecialsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specials': !exists(json, 'specials') ? undefined : ((json['specials'] as Array<any>).map(AdminValueFromJSON)),
        'specialsOptions': !exists(json, 'specialsOptions') ? undefined : ((json['specialsOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function SpecialsFormValuesDtoToJSON(value?: SpecialsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specials': value.specials === undefined ? undefined : ((value.specials as Array<any>).map(AdminValueToJSON)),
        'specialsOptions': value.specialsOptions === undefined ? undefined : ((value.specialsOptions as Array<any>).map(AdminValueToJSON)),
    };
}

