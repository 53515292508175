/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AddServiceProviderCommand
 */
export interface AddServiceProviderCommand {
    /**
     * 
     * @type {string}
     * @memberof AddServiceProviderCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddServiceProviderCommand
     */
    info?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof AddServiceProviderCommand
     */
    city?: AdminValue;
}

/**
 * Check if a given object implements the AddServiceProviderCommand interface.
 */
export function instanceOfAddServiceProviderCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AddServiceProviderCommandFromJSON(json: any): AddServiceProviderCommand {
    return AddServiceProviderCommandFromJSONTyped(json, false);
}

export function AddServiceProviderCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddServiceProviderCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'city': !exists(json, 'city') ? undefined : AdminValueFromJSON(json['city']),
    };
}

export function AddServiceProviderCommandToJSON(value?: AddServiceProviderCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'info': value.info,
        'city': AdminValueToJSON(value.city),
    };
}

