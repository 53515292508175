/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeModelBookingInformationRequestDto
 */
export interface ChangeModelBookingInformationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    travelCosts?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    bookingStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    modelConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    bookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeModelBookingInformationRequestDto
     */
    roleId?: string;
}

/**
 * Check if a given object implements the ChangeModelBookingInformationRequestDto interface.
 */
export function instanceOfChangeModelBookingInformationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeModelBookingInformationRequestDtoFromJSON(json: any): ChangeModelBookingInformationRequestDto {
    return ChangeModelBookingInformationRequestDtoFromJSONTyped(json, false);
}

export function ChangeModelBookingInformationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeModelBookingInformationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelCosts': !exists(json, 'travelCosts') ? undefined : json['travelCosts'],
        'bookingStatus': !exists(json, 'bookingStatus') ? undefined : json['bookingStatus'],
        'modelConfirmed': !exists(json, 'modelConfirmed') ? undefined : json['modelConfirmed'],
        'bookingId': !exists(json, 'bookingId') ? undefined : json['bookingId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
    };
}

export function ChangeModelBookingInformationRequestDtoToJSON(value?: ChangeModelBookingInformationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelCosts': value.travelCosts,
        'bookingStatus': value.bookingStatus,
        'modelConfirmed': value.modelConfirmed,
        'bookingId': value.bookingId,
        'modelId': value.modelId,
        'roleId': value.roleId,
    };
}

