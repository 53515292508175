/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ExtraCostFormValuesDto } from './ExtraCostFormValuesDto';
import {
    ExtraCostFormValuesDtoFromJSON,
    ExtraCostFormValuesDtoFromJSONTyped,
    ExtraCostFormValuesDtoToJSON,
} from './ExtraCostFormValuesDto';

/**
 * 
 * @export
 * @interface ExtraCostsListFormValuesDto
 */
export interface ExtraCostsListFormValuesDto {
    /**
     * 
     * @type {Array<ExtraCostFormValuesDto>}
     * @memberof ExtraCostsListFormValuesDto
     */
    extraCosts?: Array<ExtraCostFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ExtraCostsListFormValuesDto
     */
    typeOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ExtraCostsListFormValuesDto interface.
 */
export function instanceOfExtraCostsListFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtraCostsListFormValuesDtoFromJSON(json: any): ExtraCostsListFormValuesDto {
    return ExtraCostsListFormValuesDtoFromJSONTyped(json, false);
}

export function ExtraCostsListFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraCostsListFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extraCosts': !exists(json, 'extraCosts') ? undefined : ((json['extraCosts'] as Array<any>).map(ExtraCostFormValuesDtoFromJSON)),
        'typeOptions': !exists(json, 'typeOptions') ? undefined : ((json['typeOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ExtraCostsListFormValuesDtoToJSON(value?: ExtraCostsListFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extraCosts': value.extraCosts === undefined ? undefined : ((value.extraCosts as Array<any>).map(ExtraCostFormValuesDtoToJSON)),
        'typeOptions': value.typeOptions === undefined ? undefined : ((value.typeOptions as Array<any>).map(AdminValueToJSON)),
    };
}

