/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectNameFormValuesDto
 */
export interface ProjectNameFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectNameFormValuesDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNameFormValuesDto
     */
    workingTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNameFormValuesDto
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNameFormValuesDto
     */
    description?: string;
}

/**
 * Check if a given object implements the ProjectNameFormValuesDto interface.
 */
export function instanceOfProjectNameFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectNameFormValuesDtoFromJSON(json: any): ProjectNameFormValuesDto {
    return ProjectNameFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectNameFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectNameFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'workingTitle': !exists(json, 'workingTitle') ? undefined : json['workingTitle'],
        'projectNumber': !exists(json, 'projectNumber') ? undefined : json['projectNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ProjectNameFormValuesDtoToJSON(value?: ProjectNameFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'workingTitle': value.workingTitle,
        'projectNumber': value.projectNumber,
        'description': value.description,
    };
}

