/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Communication } from './Communication';
import {
    CommunicationFromJSON,
    CommunicationFromJSONTyped,
    CommunicationToJSON,
} from './Communication';

/**
 * 
 * @export
 * @interface ChangeCommunicationCommand
 */
export interface ChangeCommunicationCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeCommunicationCommand
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {Communication}
     * @memberof ChangeCommunicationCommand
     */
    newCommunication?: Communication;
}

/**
 * Check if a given object implements the ChangeCommunicationCommand interface.
 */
export function instanceOfChangeCommunicationCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeCommunicationCommandFromJSON(json: any): ChangeCommunicationCommand {
    return ChangeCommunicationCommandFromJSONTyped(json, false);
}

export function ChangeCommunicationCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeCommunicationCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'newCommunication': !exists(json, 'newCommunication') ? undefined : CommunicationFromJSON(json['newCommunication']),
    };
}

export function ChangeCommunicationCommandToJSON(value?: ChangeCommunicationCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'newCommunication': CommunicationToJSON(value.newCommunication),
    };
}

