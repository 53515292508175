import { Component } from '@angular/core';
import {GlobalProgressBarService} from "../../../service/global-progress-bar.service";

@Component({
    selector: 'app-global-progress-bar',
    templateUrl: './global-progress-bar.component.html',
    styleUrls: ['./global-progress-bar.component.scss']
})
export class GlobalProgressBarComponent {
    constructor(public progressBar: GlobalProgressBarService) {}
}
