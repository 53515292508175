/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { EmployeeContactFormValuesDto } from './EmployeeContactFormValuesDto';
import {
    EmployeeContactFormValuesDtoFromJSON,
    EmployeeContactFormValuesDtoFromJSONTyped,
    EmployeeContactFormValuesDtoToJSON,
} from './EmployeeContactFormValuesDto';
import type { EmployeeNameFormValuesDto } from './EmployeeNameFormValuesDto';
import {
    EmployeeNameFormValuesDtoFromJSON,
    EmployeeNameFormValuesDtoFromJSONTyped,
    EmployeeNameFormValuesDtoToJSON,
} from './EmployeeNameFormValuesDto';
import type { EmployeeSexFormValuesDto } from './EmployeeSexFormValuesDto';
import {
    EmployeeSexFormValuesDtoFromJSON,
    EmployeeSexFormValuesDtoFromJSONTyped,
    EmployeeSexFormValuesDtoToJSON,
} from './EmployeeSexFormValuesDto';

/**
 * 
 * @export
 * @interface EmployeeFormValuesDto
 */
export interface EmployeeFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeFormValuesDto
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFormValuesDto
     */
    oauthId?: string;
    /**
     * 
     * @type {EmployeeNameFormValuesDto}
     * @memberof EmployeeFormValuesDto
     */
    employeeName?: EmployeeNameFormValuesDto;
    /**
     * 
     * @type {EmployeeSexFormValuesDto}
     * @memberof EmployeeFormValuesDto
     */
    sex?: EmployeeSexFormValuesDto;
    /**
     * 
     * @type {AdminValue}
     * @memberof EmployeeFormValuesDto
     */
    position?: AdminValue;
    /**
     * 
     * @type {EmployeeContactFormValuesDto}
     * @memberof EmployeeFormValuesDto
     */
    employeeContactFormValuesDto?: EmployeeContactFormValuesDto;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeFormValuesDto
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFormValuesDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeFormValuesDto
     */
    otherInformation?: string;
}

/**
 * Check if a given object implements the EmployeeFormValuesDto interface.
 */
export function instanceOfEmployeeFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeFormValuesDtoFromJSON(json: any): EmployeeFormValuesDto {
    return EmployeeFormValuesDtoFromJSONTyped(json, false);
}

export function EmployeeFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
        'employeeName': !exists(json, 'employeeName') ? undefined : EmployeeNameFormValuesDtoFromJSON(json['employeeName']),
        'sex': !exists(json, 'sex') ? undefined : EmployeeSexFormValuesDtoFromJSON(json['sex']),
        'position': !exists(json, 'position') ? undefined : AdminValueFromJSON(json['position']),
        'employeeContactFormValuesDto': !exists(json, 'employeeContactFormValuesDto') ? undefined : EmployeeContactFormValuesDtoFromJSON(json['employeeContactFormValuesDto']),
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'otherInformation': !exists(json, 'otherInformation') ? undefined : json['otherInformation'],
    };
}

export function EmployeeFormValuesDtoToJSON(value?: EmployeeFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'oauthId': value.oauthId,
        'employeeName': EmployeeNameFormValuesDtoToJSON(value.employeeName),
        'sex': EmployeeSexFormValuesDtoToJSON(value.sex),
        'position': AdminValueToJSON(value.position),
        'employeeContactFormValuesDto': EmployeeContactFormValuesDtoToJSON(value.employeeContactFormValuesDto),
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'title': value.title,
        'otherInformation': value.otherInformation,
    };
}

