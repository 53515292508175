<div class="search-list-box">
<p-listbox [options]="projectNames" [filter]="true" (onChange)="onSelectProject($event.value)">
    <ng-template let-project pTemplate="item">
        <h6>{{project.title}}</h6>
        <span>{{project.customerName}}</span>
    </ng-template>
</p-listbox>
</div>
<div *ngIf="showProjectParticipantList" class="search-list-box">
    <p-listbox [options]="projectParticipantList" [filter]="true"
               (onChange)="onSelectProjectParticipant($event.value)">
        <ng-template let-projectParticipant pTemplate="item">
            <h6>{{projectParticipant.participantFirstName}} {{projectParticipant.participantLastName}}</h6>
            <span>{{projectParticipant.participantRole}}</span>
        </ng-template>
    </p-listbox>
</div>