import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalProgressBarService {
    public isVisible = false;

    public show() {
        this.isVisible = true;
    }
    public hide() {
        this.isVisible = false;
    }
}
