/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceProviderTableItemsDto
 */
export interface ServiceProviderTableItemsDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderTableItemsDto
     */
    updatedAt?: string;
}

/**
 * Check if a given object implements the ServiceProviderTableItemsDto interface.
 */
export function instanceOfServiceProviderTableItemsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderTableItemsDtoFromJSON(json: any): ServiceProviderTableItemsDto {
    return ServiceProviderTableItemsDtoFromJSONTyped(json, false);
}

export function ServiceProviderTableItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderTableItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
    };
}

export function ServiceProviderTableItemsDtoToJSON(value?: ServiceProviderTableItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'name': value.name,
        'city': value.city,
        'phone': value.phone,
        'email': value.email,
        'mobile': value.mobile,
        'website': value.website,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

