/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelBookingRequestsResponseDto } from './ModelBookingRequestsResponseDto';
import {
    ModelBookingRequestsResponseDtoFromJSON,
    ModelBookingRequestsResponseDtoFromJSONTyped,
    ModelBookingRequestsResponseDtoToJSON,
} from './ModelBookingRequestsResponseDto';
import type { ModelOptionRequestsResponseDto } from './ModelOptionRequestsResponseDto';
import {
    ModelOptionRequestsResponseDtoFromJSON,
    ModelOptionRequestsResponseDtoFromJSONTyped,
    ModelOptionRequestsResponseDtoToJSON,
} from './ModelOptionRequestsResponseDto';

/**
 * 
 * @export
 * @interface ModelOptionAndBookingRequestsResponseDto
 */
export interface ModelOptionAndBookingRequestsResponseDto {
    /**
     * 
     * @type {Array<ModelOptionRequestsResponseDto>}
     * @memberof ModelOptionAndBookingRequestsResponseDto
     */
    optionRequests?: Array<ModelOptionRequestsResponseDto>;
    /**
     * 
     * @type {Array<ModelBookingRequestsResponseDto>}
     * @memberof ModelOptionAndBookingRequestsResponseDto
     */
    bookingRequests?: Array<ModelBookingRequestsResponseDto>;
}

/**
 * Check if a given object implements the ModelOptionAndBookingRequestsResponseDto interface.
 */
export function instanceOfModelOptionAndBookingRequestsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelOptionAndBookingRequestsResponseDtoFromJSON(json: any): ModelOptionAndBookingRequestsResponseDto {
    return ModelOptionAndBookingRequestsResponseDtoFromJSONTyped(json, false);
}

export function ModelOptionAndBookingRequestsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelOptionAndBookingRequestsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionRequests': !exists(json, 'optionRequests') ? undefined : ((json['optionRequests'] as Array<any>).map(ModelOptionRequestsResponseDtoFromJSON)),
        'bookingRequests': !exists(json, 'bookingRequests') ? undefined : ((json['bookingRequests'] as Array<any>).map(ModelBookingRequestsResponseDtoFromJSON)),
    };
}

export function ModelOptionAndBookingRequestsResponseDtoToJSON(value?: ModelOptionAndBookingRequestsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionRequests': value.optionRequests === undefined ? undefined : ((value.optionRequests as Array<any>).map(ModelOptionRequestsResponseDtoToJSON)),
        'bookingRequests': value.bookingRequests === undefined ? undefined : ((value.bookingRequests as Array<any>).map(ModelBookingRequestsResponseDtoToJSON)),
    };
}

