/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectId } from './ProjectId';
import {
    ProjectIdFromJSON,
    ProjectIdFromJSONTyped,
    ProjectIdToJSON,
} from './ProjectId';

/**
 * 
 * @export
 * @interface ProjectNamesForMessengerDto
 */
export interface ProjectNamesForMessengerDto {
    /**
     * 
     * @type {ProjectId}
     * @memberof ProjectNamesForMessengerDto
     */
    id: ProjectId;
    /**
     * 
     * @type {string}
     * @memberof ProjectNamesForMessengerDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNamesForMessengerDto
     */
    customerName: string;
}

/**
 * Check if a given object implements the ProjectNamesForMessengerDto interface.
 */
export function instanceOfProjectNamesForMessengerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "customerName" in value;

    return isInstance;
}

export function ProjectNamesForMessengerDtoFromJSON(json: any): ProjectNamesForMessengerDto {
    return ProjectNamesForMessengerDtoFromJSONTyped(json, false);
}

export function ProjectNamesForMessengerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectNamesForMessengerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': ProjectIdFromJSON(json['id']),
        'title': json['title'],
        'customerName': json['customerName'],
    };
}

export function ProjectNamesForMessengerDtoToJSON(value?: ProjectNamesForMessengerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': ProjectIdToJSON(value.id),
        'title': value.title,
        'customerName': value.customerName,
    };
}

