/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateModelUserCommand
 */
export interface CreateModelUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    role?: CreateModelUserCommandRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateModelUserCommand
     */
    modelId?: string;
}


/**
 * @export
 */
export const CreateModelUserCommandRoleEnum = {
    Admin: 'Admin',
    Booker: 'Booker',
    BookerAdmin: 'BookerAdmin',
    JuniorBooker: 'JuniorBooker',
    Model: 'Model',
    Customer: 'Customer',
    ExternalParticipant: 'ExternalParticipant'
} as const;
export type CreateModelUserCommandRoleEnum = typeof CreateModelUserCommandRoleEnum[keyof typeof CreateModelUserCommandRoleEnum];


/**
 * Check if a given object implements the CreateModelUserCommand interface.
 */
export function instanceOfCreateModelUserCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function CreateModelUserCommandFromJSON(json: any): CreateModelUserCommand {
    return CreateModelUserCommandFromJSONTyped(json, false);
}

export function CreateModelUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateModelUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
    };
}

export function CreateModelUserCommandToJSON(value?: CreateModelUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'role': value.role,
        'modelId': value.modelId,
    };
}

