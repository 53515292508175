/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactInfoFormValuesDto } from './ContactInfoFormValuesDto';
import {
    ContactInfoFormValuesDtoFromJSON,
    ContactInfoFormValuesDtoFromJSONTyped,
    ContactInfoFormValuesDtoToJSON,
} from './ContactInfoFormValuesDto';
import type { PersonalInfosFormValuesDto } from './PersonalInfosFormValuesDto';
import {
    PersonalInfosFormValuesDtoFromJSON,
    PersonalInfosFormValuesDtoFromJSONTyped,
    PersonalInfosFormValuesDtoToJSON,
} from './PersonalInfosFormValuesDto';

/**
 * 
 * @export
 * @interface ModelMasterDataCreateRequestDto
 */
export interface ModelMasterDataCreateRequestDto {
    /**
     * 
     * @type {PersonalInfosFormValuesDto}
     * @memberof ModelMasterDataCreateRequestDto
     */
    personalInfos: PersonalInfosFormValuesDto;
    /**
     * 
     * @type {ContactInfoFormValuesDto}
     * @memberof ModelMasterDataCreateRequestDto
     */
    contactInfos: ContactInfoFormValuesDto;
}

/**
 * Check if a given object implements the ModelMasterDataCreateRequestDto interface.
 */
export function instanceOfModelMasterDataCreateRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "personalInfos" in value;
    isInstance = isInstance && "contactInfos" in value;

    return isInstance;
}

export function ModelMasterDataCreateRequestDtoFromJSON(json: any): ModelMasterDataCreateRequestDto {
    return ModelMasterDataCreateRequestDtoFromJSONTyped(json, false);
}

export function ModelMasterDataCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalInfos': PersonalInfosFormValuesDtoFromJSON(json['personalInfos']),
        'contactInfos': ContactInfoFormValuesDtoFromJSON(json['contactInfos']),
    };
}

export function ModelMasterDataCreateRequestDtoToJSON(value?: ModelMasterDataCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalInfos': PersonalInfosFormValuesDtoToJSON(value.personalInfos),
        'contactInfos': ContactInfoFormValuesDtoToJSON(value.contactInfos),
    };
}

