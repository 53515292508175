/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookerForMessagesDto
 */
export interface BookerForMessagesDto {
    /**
     * 
     * @type {string}
     * @memberof BookerForMessagesDto
     */
    bookerId: string;
    /**
     * 
     * @type {string}
     * @memberof BookerForMessagesDto
     */
    oauthId: string;
    /**
     * 
     * @type {string}
     * @memberof BookerForMessagesDto
     */
    bookerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof BookerForMessagesDto
     */
    bookerLastName: string;
}

/**
 * Check if a given object implements the BookerForMessagesDto interface.
 */
export function instanceOfBookerForMessagesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bookerId" in value;
    isInstance = isInstance && "oauthId" in value;
    isInstance = isInstance && "bookerFirstName" in value;
    isInstance = isInstance && "bookerLastName" in value;

    return isInstance;
}

export function BookerForMessagesDtoFromJSON(json: any): BookerForMessagesDto {
    return BookerForMessagesDtoFromJSONTyped(json, false);
}

export function BookerForMessagesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookerForMessagesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookerId': json['bookerId'],
        'oauthId': json['oauthId'],
        'bookerFirstName': json['bookerFirstName'],
        'bookerLastName': json['bookerLastName'],
    };
}

export function BookerForMessagesDtoToJSON(value?: BookerForMessagesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookerId': value.bookerId,
        'oauthId': value.oauthId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
    };
}

