/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelDataForMessenger
 */
export interface ModelDataForMessenger {
    /**
     * 
     * @type {string}
     * @memberof ModelDataForMessenger
     */
    modelFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDataForMessenger
     */
    modelLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDataForMessenger
     */
    modelEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDataForMessenger
     */
    modelPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDataForMessenger
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the ModelDataForMessenger interface.
 */
export function instanceOfModelDataForMessenger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelDataForMessengerFromJSON(json: any): ModelDataForMessenger {
    return ModelDataForMessengerFromJSONTyped(json, false);
}

export function ModelDataForMessengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDataForMessenger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelFirstName': !exists(json, 'modelFirstName') ? undefined : json['modelFirstName'],
        'modelLastName': !exists(json, 'modelLastName') ? undefined : json['modelLastName'],
        'modelEmail': !exists(json, 'modelEmail') ? undefined : json['modelEmail'],
        'modelPhone': !exists(json, 'modelPhone') ? undefined : json['modelPhone'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ModelDataForMessengerToJSON(value?: ModelDataForMessenger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelFirstName': value.modelFirstName,
        'modelLastName': value.modelLastName,
        'modelEmail': value.modelEmail,
        'modelPhone': value.modelPhone,
        'oauthId': value.oauthId,
    };
}

