/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgencyBookerTableItemDto
 */
export interface AgencyBookerTableItemDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerTableItemDto
     */
    bookerPosition?: string;
}

/**
 * Check if a given object implements the AgencyBookerTableItemDto interface.
 */
export function instanceOfAgencyBookerTableItemDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBookerTableItemDtoFromJSON(json: any): AgencyBookerTableItemDto {
    return AgencyBookerTableItemDtoFromJSONTyped(json, false);
}

export function AgencyBookerTableItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBookerTableItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
        'bookerEmail': !exists(json, 'bookerEmail') ? undefined : json['bookerEmail'],
        'bookerPhone': !exists(json, 'bookerPhone') ? undefined : json['bookerPhone'],
        'bookerPosition': !exists(json, 'bookerPosition') ? undefined : json['bookerPosition'],
    };
}

export function AgencyBookerTableItemDtoToJSON(value?: AgencyBookerTableItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookerId': value.bookerId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
        'bookerEmail': value.bookerEmail,
        'bookerPhone': value.bookerPhone,
        'bookerPosition': value.bookerPosition,
    };
}

