/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateMessageRequestDto } from './CreateMessageRequestDto';
import {
    CreateMessageRequestDtoFromJSON,
    CreateMessageRequestDtoFromJSONTyped,
    CreateMessageRequestDtoToJSON,
} from './CreateMessageRequestDto';

/**
 * 
 * @export
 * @interface CreateConversationRequestDto
 */
export interface CreateConversationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateConversationRequestDto
     */
    conversationTitle: string;
    /**
     * 
     * @type {CreateMessageRequestDto}
     * @memberof CreateConversationRequestDto
     */
    createMessageRequestDto: CreateMessageRequestDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateConversationRequestDto
     */
    participantIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateConversationRequestDto
     */
    projectId?: string;
}

/**
 * Check if a given object implements the CreateConversationRequestDto interface.
 */
export function instanceOfCreateConversationRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conversationTitle" in value;
    isInstance = isInstance && "createMessageRequestDto" in value;
    isInstance = isInstance && "participantIds" in value;

    return isInstance;
}

export function CreateConversationRequestDtoFromJSON(json: any): CreateConversationRequestDto {
    return CreateConversationRequestDtoFromJSONTyped(json, false);
}

export function CreateConversationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConversationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationTitle': json['conversationTitle'],
        'createMessageRequestDto': CreateMessageRequestDtoFromJSON(json['createMessageRequestDto']),
        'participantIds': json['participantIds'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}

export function CreateConversationRequestDtoToJSON(value?: CreateConversationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationTitle': value.conversationTitle,
        'createMessageRequestDto': CreateMessageRequestDtoToJSON(value.createMessageRequestDto),
        'participantIds': value.participantIds,
        'projectId': value.projectId,
    };
}

