/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectedBookingProducerTargetFormValuesDto
 */
export interface SelectedBookingProducerTargetFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddressee?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeZipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeCity?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeCustomer?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedBookingProducerTargetFormValuesDto
     */
    selectedAddresseeCustomerSuffix?: string;
}

/**
 * Check if a given object implements the SelectedBookingProducerTargetFormValuesDto interface.
 */
export function instanceOfSelectedBookingProducerTargetFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SelectedBookingProducerTargetFormValuesDtoFromJSON(json: any): SelectedBookingProducerTargetFormValuesDto {
    return SelectedBookingProducerTargetFormValuesDtoFromJSONTyped(json, false);
}

export function SelectedBookingProducerTargetFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedBookingProducerTargetFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedTarget': !exists(json, 'selectedTarget') ? undefined : json['selectedTarget'],
        'selectedAddressee': !exists(json, 'selectedAddressee') ? undefined : json['selectedAddressee'],
        'selectedAddresseeId': !exists(json, 'selectedAddresseeId') ? undefined : json['selectedAddresseeId'],
        'selectedAddresseeStreet': !exists(json, 'selectedAddresseeStreet') ? undefined : json['selectedAddresseeStreet'],
        'selectedAddresseeZipCode': !exists(json, 'selectedAddresseeZipCode') ? undefined : json['selectedAddresseeZipCode'],
        'selectedAddresseeCity': !exists(json, 'selectedAddresseeCity') ? undefined : json['selectedAddresseeCity'],
        'selectedAddresseeCustomer': !exists(json, 'selectedAddresseeCustomer') ? undefined : json['selectedAddresseeCustomer'],
        'selectedAddresseeCustomerSuffix': !exists(json, 'selectedAddresseeCustomerSuffix') ? undefined : json['selectedAddresseeCustomerSuffix'],
    };
}

export function SelectedBookingProducerTargetFormValuesDtoToJSON(value?: SelectedBookingProducerTargetFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedTarget': value.selectedTarget,
        'selectedAddressee': value.selectedAddressee,
        'selectedAddresseeId': value.selectedAddresseeId,
        'selectedAddresseeStreet': value.selectedAddresseeStreet,
        'selectedAddresseeZipCode': value.selectedAddresseeZipCode,
        'selectedAddresseeCity': value.selectedAddresseeCity,
        'selectedAddresseeCustomer': value.selectedAddresseeCustomer,
        'selectedAddresseeCustomerSuffix': value.selectedAddresseeCustomerSuffix,
    };
}

