import {ProjectsTranslations} from "../../demo/service/translation.service";

export function createProjectsMenu(projectsTranslations: ProjectsTranslations): any[] {
    const {
        label: projectsLabel,
        labelInProgress: projectLabelInProgress,
        labelFinished: projectLabelFinished,
        labelDiscarded: projectLabelDiscarded
    } = projectsTranslations;

    return [
        {
            label: projectsLabel,
            items: [
                {
                    label: projectLabelInProgress,
                    icon: 'pi pi-fw pi-angle-double-right',
                    routerLink: ['/projects/in-progress']
                },
                {
                    label: projectLabelFinished,
                    icon: 'pi pi-fw pi-check',
                    routerLink: ['/projects/finished']
                },
                {
                    label: projectLabelDiscarded,
                    icon: 'pi pi-fw pi-ban',
                    routerLink: ['/projects/stopped']
                }
            ]
        }
    ];
}
