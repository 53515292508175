/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectParticipantDataForMessenger
 */
export interface ProjectParticipantDataForMessenger {
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectParticipantRole?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectParticipantFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectParticipantLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectParticipantEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectParticipantPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    projectRoleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectParticipantDataForMessenger
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the ProjectParticipantDataForMessenger interface.
 */
export function instanceOfProjectParticipantDataForMessenger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectParticipantDataForMessengerFromJSON(json: any): ProjectParticipantDataForMessenger {
    return ProjectParticipantDataForMessengerFromJSONTyped(json, false);
}

export function ProjectParticipantDataForMessengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectParticipantDataForMessenger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectParticipantRole': !exists(json, 'projectParticipantRole') ? undefined : json['projectParticipantRole'],
        'projectParticipantFirstName': !exists(json, 'projectParticipantFirstName') ? undefined : json['projectParticipantFirstName'],
        'projectParticipantLastName': !exists(json, 'projectParticipantLastName') ? undefined : json['projectParticipantLastName'],
        'projectParticipantEmail': !exists(json, 'projectParticipantEmail') ? undefined : json['projectParticipantEmail'],
        'projectParticipantPhone': !exists(json, 'projectParticipantPhone') ? undefined : json['projectParticipantPhone'],
        'projectRoleName': !exists(json, 'projectRoleName') ? undefined : json['projectRoleName'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ProjectParticipantDataForMessengerToJSON(value?: ProjectParticipantDataForMessenger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectParticipantRole': value.projectParticipantRole,
        'projectParticipantFirstName': value.projectParticipantFirstName,
        'projectParticipantLastName': value.projectParticipantLastName,
        'projectParticipantEmail': value.projectParticipantEmail,
        'projectParticipantPhone': value.projectParticipantPhone,
        'projectRoleName': value.projectRoleName,
        'oauthId': value.oauthId,
    };
}

