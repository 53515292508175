/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { AgencyBookerTableItemDto } from './AgencyBookerTableItemDto';
import {
    AgencyBookerTableItemDtoFromJSON,
    AgencyBookerTableItemDtoFromJSONTyped,
    AgencyBookerTableItemDtoToJSON,
} from './AgencyBookerTableItemDto';

/**
 * 
 * @export
 * @interface AgencyBookerTableItemsDto
 */
export interface AgencyBookerTableItemsDto {
    /**
     * 
     * @type {Array<AgencyBookerTableItemDto>}
     * @memberof AgencyBookerTableItemsDto
     */
    agencyBookerTableItemDtoList?: Array<AgencyBookerTableItemDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyBookerTableItemsDto
     */
    positionOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyBookerTableItemsDto
     */
    sexOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the AgencyBookerTableItemsDto interface.
 */
export function instanceOfAgencyBookerTableItemsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBookerTableItemsDtoFromJSON(json: any): AgencyBookerTableItemsDto {
    return AgencyBookerTableItemsDtoFromJSONTyped(json, false);
}

export function AgencyBookerTableItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBookerTableItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyBookerTableItemDtoList': !exists(json, 'agencyBookerTableItemDtoList') ? undefined : ((json['agencyBookerTableItemDtoList'] as Array<any>).map(AgencyBookerTableItemDtoFromJSON)),
        'positionOptions': !exists(json, 'positionOptions') ? undefined : ((json['positionOptions'] as Array<any>).map(AdminValueFromJSON)),
        'sexOptions': !exists(json, 'sexOptions') ? undefined : ((json['sexOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function AgencyBookerTableItemsDtoToJSON(value?: AgencyBookerTableItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyBookerTableItemDtoList': value.agencyBookerTableItemDtoList === undefined ? undefined : ((value.agencyBookerTableItemDtoList as Array<any>).map(AgencyBookerTableItemDtoToJSON)),
        'positionOptions': value.positionOptions === undefined ? undefined : ((value.positionOptions as Array<any>).map(AdminValueToJSON)),
        'sexOptions': value.sexOptions === undefined ? undefined : ((value.sexOptions as Array<any>).map(AdminValueToJSON)),
    };
}

