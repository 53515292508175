/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ServiceProviderAddressDto
 */
export interface ServiceProviderAddressDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    addition?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    att?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    room1?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    room2?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderAddressDto
     */
    postalCode?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof ServiceProviderAddressDto
     */
    city?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof ServiceProviderAddressDto
     */
    country?: AdminValue;
}

/**
 * Check if a given object implements the ServiceProviderAddressDto interface.
 */
export function instanceOfServiceProviderAddressDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderAddressDtoFromJSON(json: any): ServiceProviderAddressDto {
    return ServiceProviderAddressDtoFromJSONTyped(json, false);
}

export function ServiceProviderAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderAddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addition': !exists(json, 'addition') ? undefined : json['addition'],
        'att': !exists(json, 'att') ? undefined : json['att'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'room1': !exists(json, 'room1') ? undefined : json['room1'],
        'room2': !exists(json, 'room2') ? undefined : json['room2'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : AdminValueFromJSON(json['city']),
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
    };
}

export function ServiceProviderAddressDtoToJSON(value?: ServiceProviderAddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'addition': value.addition,
        'att': value.att,
        'street': value.street,
        'room1': value.room1,
        'room2': value.room2,
        'postalCode': value.postalCode,
        'city': AdminValueToJSON(value.city),
        'country': AdminValueToJSON(value.country),
    };
}

