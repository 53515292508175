/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CastingBodyFormValuesDto
 */
export interface CastingBodyFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    height: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    chestCircumference: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    waistCircumference: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    hipCircumference: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    castingShow?: string;
    /**
     * 
     * @type {string}
     * @memberof CastingBodyFormValuesDto
     */
    castingPhoto?: string;
}

/**
 * Check if a given object implements the CastingBodyFormValuesDto interface.
 */
export function instanceOfCastingBodyFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "chestCircumference" in value;
    isInstance = isInstance && "waistCircumference" in value;
    isInstance = isInstance && "hipCircumference" in value;

    return isInstance;
}

export function CastingBodyFormValuesDtoFromJSON(json: any): CastingBodyFormValuesDto {
    return CastingBodyFormValuesDtoFromJSONTyped(json, false);
}

export function CastingBodyFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CastingBodyFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': json['height'],
        'chestCircumference': json['chestCircumference'],
        'waistCircumference': json['waistCircumference'],
        'hipCircumference': json['hipCircumference'],
        'castingShow': !exists(json, 'castingShow') ? undefined : json['castingShow'],
        'castingPhoto': !exists(json, 'castingPhoto') ? undefined : json['castingPhoto'],
    };
}

export function CastingBodyFormValuesDtoToJSON(value?: CastingBodyFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': value.height,
        'chestCircumference': value.chestCircumference,
        'waistCircumference': value.waistCircumference,
        'hipCircumference': value.hipCircumference,
        'castingShow': value.castingShow,
        'castingPhoto': value.castingPhoto,
    };
}

