import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'newline' })
export class NewlinePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) return value;
    // Replace newline characters with <br> tags
    const replacedValue = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // Sanitize the replaced value to prevent XSS attacks
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
