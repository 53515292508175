/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SportFormValuesDto
 */
export interface SportFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof SportFormValuesDto
     */
    sport: string;
    /**
     * 
     * @type {string}
     * @memberof SportFormValuesDto
     */
    level: string;
}

/**
 * Check if a given object implements the SportFormValuesDto interface.
 */
export function instanceOfSportFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sport" in value;
    isInstance = isInstance && "level" in value;

    return isInstance;
}

export function SportFormValuesDtoFromJSON(json: any): SportFormValuesDto {
    return SportFormValuesDtoFromJSONTyped(json, false);
}

export function SportFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SportFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sport': json['sport'],
        'level': json['level'],
    };
}

export function SportFormValuesDtoToJSON(value?: SportFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sport': value.sport,
        'level': value.level,
    };
}

