/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorDto } from './ErrorDto';
import {
    ErrorDtoFromJSON,
    ErrorDtoFromJSONTyped,
    ErrorDtoToJSON,
} from './ErrorDto';

/**
 * 
 * @export
 * @interface ChangeModelDataResponseDto
 */
export interface ChangeModelDataResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeModelDataResponseDto
     */
    message?: string;
    /**
     * 
     * @type {ErrorDto}
     * @memberof ChangeModelDataResponseDto
     */
    error?: ErrorDto;
}

/**
 * Check if a given object implements the ChangeModelDataResponseDto interface.
 */
export function instanceOfChangeModelDataResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeModelDataResponseDtoFromJSON(json: any): ChangeModelDataResponseDto {
    return ChangeModelDataResponseDtoFromJSONTyped(json, false);
}

export function ChangeModelDataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeModelDataResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'error': !exists(json, 'error') ? undefined : ErrorDtoFromJSON(json['error']),
    };
}

export function ChangeModelDataResponseDtoToJSON(value?: ChangeModelDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'error': ErrorDtoToJSON(value.error),
    };
}

