/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectBillingAddresseeFormValuesDto
 */
export interface ProjectBillingAddresseeFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectBillingAddresseeFormValuesDto
     */
    target?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectBillingAddresseeFormValuesDto
     */
    addressee?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectBillingAddresseeFormValuesDto
     */
    addresseeOptions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectBillingAddresseeFormValuesDto
     */
    note?: string;
}

/**
 * Check if a given object implements the ProjectBillingAddresseeFormValuesDto interface.
 */
export function instanceOfProjectBillingAddresseeFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectBillingAddresseeFormValuesDtoFromJSON(json: any): ProjectBillingAddresseeFormValuesDto {
    return ProjectBillingAddresseeFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectBillingAddresseeFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBillingAddresseeFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': !exists(json, 'target') ? undefined : json['target'],
        'addressee': !exists(json, 'addressee') ? undefined : json['addressee'],
        'addresseeOptions': !exists(json, 'addresseeOptions') ? undefined : json['addresseeOptions'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function ProjectBillingAddresseeFormValuesDtoToJSON(value?: ProjectBillingAddresseeFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'addressee': value.addressee,
        'addresseeOptions': value.addresseeOptions,
        'note': value.note,
    };
}

