/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColorsAndTypesDto
 */
export interface ColorsAndTypesDto {
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    skinType: ColorsAndTypesDtoSkinTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    skinColor: ColorsAndTypesDtoSkinColorEnum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    colorEye1: ColorsAndTypesDtoColorEye1Enum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    hairColor: ColorsAndTypesDtoHairColorEnum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    hairLength: ColorsAndTypesDtoHairLengthEnum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    hairStyle: ColorsAndTypesDtoHairStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    colorEye2?: ColorsAndTypesDtoColorEye2Enum;
    /**
     * 
     * @type {string}
     * @memberof ColorsAndTypesDto
     */
    hairColorNuance?: ColorsAndTypesDtoHairColorNuanceEnum;
}


/**
 * @export
 */
export const ColorsAndTypesDtoSkinTypeEnum = {
    European: 'EUROPEAN',
    Asian: 'ASIAN',
    African: 'AFRICAN'
} as const;
export type ColorsAndTypesDtoSkinTypeEnum = typeof ColorsAndTypesDtoSkinTypeEnum[keyof typeof ColorsAndTypesDtoSkinTypeEnum];

/**
 * @export
 */
export const ColorsAndTypesDtoSkinColorEnum = {
    Fair: 'FAIR',
    SlightlyColored: 'SLIGHTLY_COLORED'
} as const;
export type ColorsAndTypesDtoSkinColorEnum = typeof ColorsAndTypesDtoSkinColorEnum[keyof typeof ColorsAndTypesDtoSkinColorEnum];

/**
 * @export
 */
export const ColorsAndTypesDtoColorEye1Enum = {
    Blue: 'BLUE',
    Brown: 'BROWN',
    Green: 'GREEN',
    Hazel: 'HAZEL',
    Gray: 'GRAY',
    Amber: 'AMBER'
} as const;
export type ColorsAndTypesDtoColorEye1Enum = typeof ColorsAndTypesDtoColorEye1Enum[keyof typeof ColorsAndTypesDtoColorEye1Enum];

/**
 * @export
 */
export const ColorsAndTypesDtoHairColorEnum = {
    Black: 'BLACK',
    Brown: 'BROWN',
    Blonde: 'BLONDE',
    Red: 'RED',
    Gray: 'GRAY',
    White: 'WHITE',
    Auburn: 'AUBURN',
    Other: 'OTHER'
} as const;
export type ColorsAndTypesDtoHairColorEnum = typeof ColorsAndTypesDtoHairColorEnum[keyof typeof ColorsAndTypesDtoHairColorEnum];

/**
 * @export
 */
export const ColorsAndTypesDtoHairLengthEnum = {
    Short: 'SHORT',
    Medium: 'MEDIUM',
    Long: 'LONG'
} as const;
export type ColorsAndTypesDtoHairLengthEnum = typeof ColorsAndTypesDtoHairLengthEnum[keyof typeof ColorsAndTypesDtoHairLengthEnum];

/**
 * @export
 */
export const ColorsAndTypesDtoHairStyleEnum = {
    Curls: 'CURLS',
    Frizzy: 'FRIZZY',
    Afro: 'AFRO',
    Page: 'PAGE',
    Layered: 'LAYERED',
    Straight: 'STRAIGHT'
} as const;
export type ColorsAndTypesDtoHairStyleEnum = typeof ColorsAndTypesDtoHairStyleEnum[keyof typeof ColorsAndTypesDtoHairStyleEnum];

/**
 * @export
 */
export const ColorsAndTypesDtoColorEye2Enum = {
    Blue: 'BLUE',
    Brown: 'BROWN',
    Green: 'GREEN',
    Hazel: 'HAZEL',
    Gray: 'GRAY',
    Amber: 'AMBER'
} as const;
export type ColorsAndTypesDtoColorEye2Enum = typeof ColorsAndTypesDtoColorEye2Enum[keyof typeof ColorsAndTypesDtoColorEye2Enum];

/**
 * @export
 */
export const ColorsAndTypesDtoHairColorNuanceEnum = {
    Light: 'LIGHT',
    Medium: 'MEDIUM',
    Dark: 'DARK'
} as const;
export type ColorsAndTypesDtoHairColorNuanceEnum = typeof ColorsAndTypesDtoHairColorNuanceEnum[keyof typeof ColorsAndTypesDtoHairColorNuanceEnum];


/**
 * Check if a given object implements the ColorsAndTypesDto interface.
 */
export function instanceOfColorsAndTypesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "skinType" in value;
    isInstance = isInstance && "skinColor" in value;
    isInstance = isInstance && "colorEye1" in value;
    isInstance = isInstance && "hairColor" in value;
    isInstance = isInstance && "hairLength" in value;
    isInstance = isInstance && "hairStyle" in value;

    return isInstance;
}

export function ColorsAndTypesDtoFromJSON(json: any): ColorsAndTypesDto {
    return ColorsAndTypesDtoFromJSONTyped(json, false);
}

export function ColorsAndTypesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsAndTypesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skinType': json['skinType'],
        'skinColor': json['skinColor'],
        'colorEye1': json['color_eye1'],
        'hairColor': json['hairColor'],
        'hairLength': json['hairLength'],
        'hairStyle': json['hairStyle'],
        'colorEye2': !exists(json, 'color_eye2') ? undefined : json['color_eye2'],
        'hairColorNuance': !exists(json, 'hairColorNuance') ? undefined : json['hairColorNuance'],
    };
}

export function ColorsAndTypesDtoToJSON(value?: ColorsAndTypesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skinType': value.skinType,
        'skinColor': value.skinColor,
        'color_eye1': value.colorEye1,
        'hairColor': value.hairColor,
        'hairLength': value.hairLength,
        'hairStyle': value.hairStyle,
        'color_eye2': value.colorEye2,
        'hairColorNuance': value.hairColorNuance,
    };
}

