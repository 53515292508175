/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerNameFormValuesDto
 */
export interface CustomerNameFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerNameFormValuesDto
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNameFormValuesDto
     */
    customerNameSuffix?: string;
}

/**
 * Check if a given object implements the CustomerNameFormValuesDto interface.
 */
export function instanceOfCustomerNameFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerNameFormValuesDtoFromJSON(json: any): CustomerNameFormValuesDto {
    return CustomerNameFormValuesDtoFromJSONTyped(json, false);
}

export function CustomerNameFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerNameFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerNameSuffix': !exists(json, 'customerNameSuffix') ? undefined : json['customerNameSuffix'],
    };
}

export function CustomerNameFormValuesDtoToJSON(value?: CustomerNameFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerName': value.customerName,
        'customerNameSuffix': value.customerNameSuffix,
    };
}

