/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CmUnit } from './CmUnit';
import {
    CmUnitFromJSON,
    CmUnitFromJSONTyped,
    CmUnitToJSON,
} from './CmUnit';

/**
 * 
 * @export
 * @interface BodyDetailsDto
 */
export interface BodyDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    confectionSize: BodyDetailsDtoConfectionSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    shoeSizeEu: BodyDetailsDtoShoeSizeEuEnum;
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    shoeSizeUs: BodyDetailsDtoShoeSizeUsEnum;
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    braCircumference: BodyDetailsDtoBraCircumferenceEnum;
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    braCupSize: BodyDetailsDtoBraCupSizeEnum;
    /**
     * 
     * @type {CmUnit}
     * @memberof BodyDetailsDto
     */
    legLength: CmUnit;
    /**
     * 
     * @type {CmUnit}
     * @memberof BodyDetailsDto
     */
    headCircumference: CmUnit;
    /**
     * 
     * @type {string}
     * @memberof BodyDetailsDto
     */
    pantSize: BodyDetailsDtoPantSizeEnum;
}


/**
 * @export
 */
export const BodyDetailsDtoConfectionSizeEnum = {
    _27: 'CF_27',
    _28: 'CF_28',
    _32: 'CF_32',
    _34: 'CF_34',
    _36: 'CF_36',
    _38: 'CF_38',
    _40: 'CF_40',
    _42: 'CF_42',
    _44: 'CF_44',
    _46: 'CF_46',
    _48: 'CF_48',
    _50: 'CF_50',
    _52: 'CF_52',
    _54: 'CF_54',
    _56: 'CF_56',
    _58: 'CF_58',
    _60: 'CF_60',
    _62: 'CF_62',
    _64: 'CF_64',
    _66: 'CF_66',
    _68: 'CF_68',
    _70: 'CF_70',
    _72: 'CF_72',
    _74: 'CF_74',
    _76: 'CF_76',
    _78: 'CF_78',
    _80: 'CF_80',
    _82: 'CF_82',
    _84: 'CF_84',
    _86: 'CF_86',
    _88: 'CF_88',
    _90: 'CF_90',
    _92: 'CF_92',
    _94: 'CF_94',
    _96: 'CF_96',
    _98: 'CF_98',
    _100: 'CF_100',
    _102: 'CF_102',
    _104: 'CF_104',
    _106: 'CF_106',
    _108: 'CF_108',
    _110: 'CF_110',
    _112: 'CF_112',
    _114: 'CF_114',
    _116: 'CF_116',
    _118: 'CF_118',
    _120: 'CF_120',
    _122: 'CF_122',
    _124: 'CF_124',
    _126: 'CF_126',
    _128: 'CF_128',
    _130: 'CF_130',
    _132: 'CF_132',
    _134: 'CF_134',
    _136: 'CF_136',
    _138: 'CF_138',
    _140: 'CF_140',
    _142: 'CF_142',
    _144: 'CF_144',
    _146: 'CF_146',
    _148: 'CF_148',
    _150: 'CF_150',
    _152: 'CF_152',
    _154: 'CF_154',
    _156: 'CF_156',
    _158: 'CF_158',
    _160: 'CF_160',
    _162: 'CF_162',
    _164: 'CF_164',
    _166: 'CF_166',
    _168: 'CF_168',
    _170: 'CF_170',
    _172: 'CF_172',
    _174: 'CF_174',
    _176: 'CF_176'
} as const;
export type BodyDetailsDtoConfectionSizeEnum = typeof BodyDetailsDtoConfectionSizeEnum[keyof typeof BodyDetailsDtoConfectionSizeEnum];

/**
 * @export
 */
export const BodyDetailsDtoShoeSizeEuEnum = {
    _17: 'EU_17',
    _175: 'EU_17_5',
    _18: 'EU_18',
    _185: 'EU_18_5',
    _19: 'EU_19',
    _195: 'EU_19_5',
    _20: 'EU_20',
    _205: 'EU_20_5',
    _21: 'EU_21',
    _215: 'EU_21_5',
    _22: 'EU_22',
    _225: 'EU_22_5',
    _23: 'EU_23',
    _235: 'EU_23_5',
    _24: 'EU_24',
    _245: 'EU_24_5',
    _25: 'EU_25',
    _255: 'EU_25_5',
    _26: 'EU_26',
    _265: 'EU_26_5',
    _27: 'EU_27',
    _275: 'EU_27_5',
    _28: 'EU_28',
    _285: 'EU_28_5',
    _29: 'EU_29',
    _295: 'EU_29_5',
    _30: 'EU_30',
    _305: 'EU_30_5',
    _31: 'EU_31',
    _315: 'EU_31_5',
    _32: 'EU_32',
    _325: 'EU_32_5',
    _33: 'EU_33',
    _335: 'EU_33_5',
    _34: 'EU_34',
    _345: 'EU_34_5',
    _35: 'EU_35',
    _355: 'EU_35_5',
    _36: 'EU_36',
    _365: 'EU_36_5',
    _37: 'EU_37',
    _375: 'EU_37_5',
    _38: 'EU_38',
    _385: 'EU_38_5',
    _39: 'EU_39',
    _395: 'EU_39_5',
    _40: 'EU_40',
    _405: 'EU_40_5',
    _41: 'EU_41',
    _415: 'EU_41_5',
    _42: 'EU_42',
    _425: 'EU_42_5',
    _43: 'EU_43',
    _435: 'EU_43_5',
    _44: 'EU_44',
    _445: 'EU_44_5',
    _45: 'EU_45',
    _455: 'EU_45_5',
    _46: 'EU_46',
    _465: 'EU_46_5',
    _47: 'EU_47',
    _475: 'EU_47_5'
} as const;
export type BodyDetailsDtoShoeSizeEuEnum = typeof BodyDetailsDtoShoeSizeEuEnum[keyof typeof BodyDetailsDtoShoeSizeEuEnum];

/**
 * @export
 */
export const BodyDetailsDtoShoeSizeUsEnum = {
    _2: 'US_2',
    _25: 'US_2_5',
    _3: 'US_3',
    _35: 'US_3_5',
    _4: 'US_4',
    _45: 'US_4_5',
    _5: 'US_5',
    _55: 'US_5_5',
    _6: 'US_6',
    _65: 'US_6_5',
    _7: 'US_7',
    _75: 'US_7_5',
    _8: 'US_8',
    _85: 'US_8_5',
    _9: 'US_9',
    _95: 'US_9_5',
    _10: 'US_10',
    _105: 'US_10_5',
    _11: 'US_11',
    _115: 'US_11_5',
    _12: 'US_12',
    _125: 'US_12_5',
    _13: 'US_13',
    _135: 'US_13_5',
    _14: 'US_14',
    _145: 'US_14_5'
} as const;
export type BodyDetailsDtoShoeSizeUsEnum = typeof BodyDetailsDtoShoeSizeUsEnum[keyof typeof BodyDetailsDtoShoeSizeUsEnum];

/**
 * @export
 */
export const BodyDetailsDtoBraCircumferenceEnum = {
    _70: 'SIZE_70',
    _75: 'SIZE_75',
    _80: 'SIZE_80',
    _85: 'SIZE_85',
    _90: 'SIZE_90',
    _95: 'SIZE_95',
    _100: 'SIZE_100'
} as const;
export type BodyDetailsDtoBraCircumferenceEnum = typeof BodyDetailsDtoBraCircumferenceEnum[keyof typeof BodyDetailsDtoBraCircumferenceEnum];

/**
 * @export
 */
export const BodyDetailsDtoBraCupSizeEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F'
} as const;
export type BodyDetailsDtoBraCupSizeEnum = typeof BodyDetailsDtoBraCupSizeEnum[keyof typeof BodyDetailsDtoBraCupSizeEnum];

/**
 * @export
 */
export const BodyDetailsDtoPantSizeEnum = {
    _2533: 'SIZE_25_33',
    _2629: 'SIZE_26_29',
    _2630: 'SIZE_26_30',
    _2631: 'SIZE_26_31',
    _2632: 'SIZE_26_32',
    _2633: 'SIZE_26_33',
    _2726: 'SIZE_27_26',
    _2730: 'SIZE_27_30',
    _2732: 'SIZE_27_32',
    _2734: 'SIZE_27_34',
    _2736: 'SIZE_27_36',
    _2828: 'SIZE_28_28',
    _2829: 'SIZE_28_29',
    _2830: 'SIZE_28_30',
    _2831: 'SIZE_28_31',
    _2832: 'SIZE_28_32',
    _2833: 'SIZE_28_33',
    _2834: 'SIZE_28_34',
    _2836: 'SIZE_28_36',
    _2929: 'SIZE_29_29',
    _2931: 'SIZE_29_31',
    _2932: 'SIZE_29_32',
    _2933: 'SIZE_29_33',
    _2934: 'SIZE_29_34',
    _3032: 'SIZE_30_32',
    _3034: 'SIZE_30_34',
    _3036: 'SIZE_30_36',
    _3129: 'SIZE_31_29',
    _3131: 'SIZE_31_31',
    _3133: 'SIZE_31_33',
    _3229: 'SIZE_32_29',
    _3231: 'SIZE_32_31',
    _3233: 'SIZE_32_33',
    _3234: 'SIZE_32_34',
    _3429: 'SIZE_34_29',
    _3431: 'SIZE_34_31',
    _3432: 'SIZE_34_32',
    _3433: 'SIZE_34_33',
    _3434: 'SIZE_34_34',
    _3436: 'SIZE_34_36',
    _3629: 'SIZE_36_29',
    _3631: 'SIZE_36_31',
    _3632: 'SIZE_36_32',
    _3633: 'SIZE_36_33'
} as const;
export type BodyDetailsDtoPantSizeEnum = typeof BodyDetailsDtoPantSizeEnum[keyof typeof BodyDetailsDtoPantSizeEnum];


/**
 * Check if a given object implements the BodyDetailsDto interface.
 */
export function instanceOfBodyDetailsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "confectionSize" in value;
    isInstance = isInstance && "shoeSizeEu" in value;
    isInstance = isInstance && "shoeSizeUs" in value;
    isInstance = isInstance && "braCircumference" in value;
    isInstance = isInstance && "braCupSize" in value;
    isInstance = isInstance && "legLength" in value;
    isInstance = isInstance && "headCircumference" in value;
    isInstance = isInstance && "pantSize" in value;

    return isInstance;
}

export function BodyDetailsDtoFromJSON(json: any): BodyDetailsDto {
    return BodyDetailsDtoFromJSONTyped(json, false);
}

export function BodyDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confectionSize': json['confectionSize'],
        'shoeSizeEu': json['shoeSizeEu'],
        'shoeSizeUs': json['shoeSizeUs'],
        'braCircumference': json['braCircumference'],
        'braCupSize': json['braCupSize'],
        'legLength': CmUnitFromJSON(json['legLength']),
        'headCircumference': CmUnitFromJSON(json['headCircumference']),
        'pantSize': json['pantSize'],
    };
}

export function BodyDetailsDtoToJSON(value?: BodyDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confectionSize': value.confectionSize,
        'shoeSizeEu': value.shoeSizeEu,
        'shoeSizeUs': value.shoeSizeUs,
        'braCircumference': value.braCircumference,
        'braCupSize': value.braCupSize,
        'legLength': CmUnitToJSON(value.legLength),
        'headCircumference': CmUnitToJSON(value.headCircumference),
        'pantSize': value.pantSize,
    };
}

