import { AddressBookTranslations } from '../../demo/service/translation.service';

export function createAddressBookMenu(
    menuTranslations: AddressBookTranslations
    ): any[] {

    return [
        {
            label: menuTranslations.label,
            items: [
                {
                    label: menuTranslations.model.label,
                    icon: 'pi pi-fw pi-users',
                    routerLink: ['/models'],
                },
                {
                    label: menuTranslations.customer.label,
                    icon: 'pi pi-fw pi-users',
                    routerLink: ['/customers']
                },
                {
                    label: menuTranslations.agency.label,
                    icon: 'pi pi-fw pi-building',
                    routerLink: ['/agencys']
                },
                {
                    label: menuTranslations.service_provider.label,
                    icon: 'pi pi-fw pi-building',
                    routerLink: ['/service-providers']
                }
            ]
        }
    ];
}
