/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtraCost } from './ExtraCost';
import {
    ExtraCostFromJSON,
    ExtraCostFromJSONTyped,
    ExtraCostToJSON,
} from './ExtraCost';

/**
 * 
 * @export
 * @interface ProjectBilling
 */
export interface ProjectBilling {
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    modelsTravelAndFees?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    modelsSum?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    flatCharge?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    agentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    agentFeePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    serviceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    serviceFeePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    subTotalOne?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    travelCostsModelsTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    extraCostsTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    subTotalTwo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    vatTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    finalSum?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectBilling
     */
    addressee?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectBilling
     */
    target?: ProjectBillingTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectBilling
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectBilling
     */
    identification?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProjectBilling
     */
    billCreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectBilling
     */
    invoiceStatus?: ProjectBillingInvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectBilling
     */
    dueDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectBilling
     */
    ifPaymentReceived?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectBilling
     */
    paymentReceived?: Date;
    /**
     * 
     * @type {Array<ExtraCost>}
     * @memberof ProjectBilling
     */
    extraCostList?: Array<ExtraCost>;
    /**
     * 
     * @type {Array<ExtraCost>}
     * @memberof ProjectBilling
     */
    extraCosts?: Array<ExtraCost>;
}


/**
 * @export
 */
export const ProjectBillingTargetEnum = {
    Producer: 'PRODUCER',
    AddParticipant: 'ADD_PARTICIPANT'
} as const;
export type ProjectBillingTargetEnum = typeof ProjectBillingTargetEnum[keyof typeof ProjectBillingTargetEnum];

/**
 * @export
 */
export const ProjectBillingInvoiceStatusEnum = {
    Paid: 'PAID',
    Invoiced: 'INVOICED'
} as const;
export type ProjectBillingInvoiceStatusEnum = typeof ProjectBillingInvoiceStatusEnum[keyof typeof ProjectBillingInvoiceStatusEnum];


/**
 * Check if a given object implements the ProjectBilling interface.
 */
export function instanceOfProjectBilling(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectBillingFromJSON(json: any): ProjectBilling {
    return ProjectBillingFromJSONTyped(json, false);
}

export function ProjectBillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelsTravelAndFees': !exists(json, 'modelsTravelAndFees') ? undefined : json['modelsTravelAndFees'],
        'modelsSum': !exists(json, 'modelsSum') ? undefined : json['modelsSum'],
        'flatCharge': !exists(json, 'flatCharge') ? undefined : json['flatCharge'],
        'agentFee': !exists(json, 'agentFee') ? undefined : json['agentFee'],
        'agentFeePercent': !exists(json, 'agentFeePercent') ? undefined : json['agentFeePercent'],
        'serviceFee': !exists(json, 'serviceFee') ? undefined : json['serviceFee'],
        'serviceFeePercent': !exists(json, 'serviceFeePercent') ? undefined : json['serviceFeePercent'],
        'subTotalOne': !exists(json, 'subTotalOne') ? undefined : json['subTotalOne'],
        'travelCostsModelsTotal': !exists(json, 'travelCostsModelsTotal') ? undefined : json['travelCostsModelsTotal'],
        'extraCostsTotal': !exists(json, 'extraCostsTotal') ? undefined : json['extraCostsTotal'],
        'subTotalTwo': !exists(json, 'subTotalTwo') ? undefined : json['subTotalTwo'],
        'vatTotal': !exists(json, 'vatTotal') ? undefined : json['vatTotal'],
        'finalSum': !exists(json, 'finalSum') ? undefined : json['finalSum'],
        'addressee': !exists(json, 'addressee') ? undefined : json['addressee'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'identification': !exists(json, 'identification') ? undefined : json['identification'],
        'billCreated': !exists(json, 'billCreated') ? undefined : (new Date(json['billCreated'])),
        'invoiceStatus': !exists(json, 'invoiceStatus') ? undefined : json['invoiceStatus'],
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'ifPaymentReceived': !exists(json, 'ifPaymentReceived') ? undefined : json['ifPaymentReceived'],
        'paymentReceived': !exists(json, 'paymentReceived') ? undefined : (new Date(json['paymentReceived'])),
        'extraCostList': !exists(json, 'extraCostList') ? undefined : ((json['extraCostList'] as Array<any>).map(ExtraCostFromJSON)),
        'extraCosts': !exists(json, 'extraCosts') ? undefined : ((json['extraCosts'] as Array<any>).map(ExtraCostFromJSON)),
    };
}

export function ProjectBillingToJSON(value?: ProjectBilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelsTravelAndFees': value.modelsTravelAndFees,
        'modelsSum': value.modelsSum,
        'flatCharge': value.flatCharge,
        'agentFee': value.agentFee,
        'agentFeePercent': value.agentFeePercent,
        'serviceFee': value.serviceFee,
        'serviceFeePercent': value.serviceFeePercent,
        'subTotalOne': value.subTotalOne,
        'travelCostsModelsTotal': value.travelCostsModelsTotal,
        'extraCostsTotal': value.extraCostsTotal,
        'subTotalTwo': value.subTotalTwo,
        'vatTotal': value.vatTotal,
        'finalSum': value.finalSum,
        'addressee': value.addressee,
        'target': value.target,
        'note': value.note,
        'identification': value.identification,
        'billCreated': value.billCreated === undefined ? undefined : (value.billCreated.toISOString().substr(0,10)),
        'invoiceStatus': value.invoiceStatus,
        'dueDate': value.dueDate,
        'ifPaymentReceived': value.ifPaymentReceived,
        'paymentReceived': value.paymentReceived === undefined ? undefined : (value.paymentReceived.toISOString().substr(0,10)),
        'extraCostList': value.extraCostList === undefined ? undefined : ((value.extraCostList as Array<any>).map(ExtraCostToJSON)),
        'extraCosts': value.extraCosts === undefined ? undefined : ((value.extraCosts as Array<any>).map(ExtraCostToJSON)),
    };
}

