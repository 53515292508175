import { HomeTranslations } from "../../demo/service/translation.service";

export function createModelMenu(homeTranslations: HomeTranslations, modelId: string): any[] {
    return [
        {
            label: homeTranslations.label,
            items: [
                {
                    label: homeTranslations.dashboard.label,
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/modelview/dashboard/' + modelId],
                },
                {
                    label: "Meine Daten",
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/models/personal/' + modelId],
                },
                {
                    label: "Mein Kalender",
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/modelview/calendar/' + modelId],
                },
                {
                    label: "Meine Jobs",
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/models/personal/' + modelId],
                },
                {
                    label: "Nachrichten",
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/models/personal/' + modelId],
                },
            ],
        },
    ];
}
