/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectedUsageType
 */
export interface SelectedUsageType {
    /**
     * 
     * @type {string}
     * @memberof SelectedUsageType
     */
    selectedUsageType: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedUsageType
     */
    selectedUsageSubType: string;
}

/**
 * Check if a given object implements the SelectedUsageType interface.
 */
export function instanceOfSelectedUsageType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "selectedUsageType" in value;
    isInstance = isInstance && "selectedUsageSubType" in value;

    return isInstance;
}

export function SelectedUsageTypeFromJSON(json: any): SelectedUsageType {
    return SelectedUsageTypeFromJSONTyped(json, false);
}

export function SelectedUsageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedUsageType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedUsageType': json['selectedUsageType'],
        'selectedUsageSubType': json['selectedUsageSubType'],
    };
}

export function SelectedUsageTypeToJSON(value?: SelectedUsageType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedUsageType': value.selectedUsageType,
        'selectedUsageSubType': value.selectedUsageSubType,
    };
}

