import {AdminTranslations} from "../../demo/service/translation.service";

export function createAdminMenu(adminTranslations: AdminTranslations): any[] {

    return [
        {
            label: 'Admin',
            items: [
                {
                    label: adminTranslations.label,
                    icon: 'pi pi-fw pi-cog',
                    items: [
                        {
                            label: adminTranslations.labelCountries,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/countries'],
                        },
                        {
                            label: adminTranslations.labelCities,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/cities'],
                        },
                        {
                            label: adminTranslations.labelEthnicities,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/ethnicities'],
                        },
                        {
                            label: adminTranslations.labelDanceStyles,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/dancestyles'],
                        },
                        {
                            label: adminTranslations.labelDiets,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/diets'],
                        },
                        {
                            label: adminTranslations.labelDriverLicenses,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/driverlicenses'],
                        },
                        {
                            label: adminTranslations.labelEyeColors,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/eyecolors'],
                        },
                        {
                            label: adminTranslations.labelHairColorNuances,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/haircolornuances'],
                        },
                        {
                            label: adminTranslations.labelHairColors,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/haircolors'],
                        },
                        {
                            label: adminTranslations.labelHairLengths,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/hairlengths'],
                        },
                        {
                            label: adminTranslations.labelHairStyles,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/hairstyles'],
                        },
                        {
                            label: adminTranslations.labelPiercings,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/piercings'],
                        },
                        {
                            label: adminTranslations.labelSex,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/sex'],
                        },
                        {
                            label: adminTranslations.labelSkinColors,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/skincolors'],
                        },
                        {
                            label: adminTranslations.labelSkinTypes,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/skintypes'],
                        },
                        {
                            label: adminTranslations.labelSportTypes,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/sporttypes'],
                        },
                        {
                            label: adminTranslations.labelTattoos,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/tattoos'],
                        },
                        {
                            label: adminTranslations.labelLanguages,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/spoken-languages']
                        },
                        {
                            label: adminTranslations.labelInstruments,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/instruments']
                        },
                        {
                            label: adminTranslations.labelCategories,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/categories']
                        },
                        {
                            label: adminTranslations.labelSpecials,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/specials']
                        },
                        {
                            label: adminTranslations.labelAttaches+ " DEPRECATED",
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/attaches']
                        },
                        {
                            label: adminTranslations.labelIndustryFields,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/industry-fields']
                        },
                        {
                            label: adminTranslations.labelActivityField,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/activity-field']
                        },
                        {
                            label: adminTranslations.labelPositions,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/positions']
                        },
                        {
                            label: adminTranslations.labelCostReasons,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/cost-reasons']
                        },
                        {
                            label: adminTranslations.labelTransportationMeans,
                            icon: 'pi pi-fw pi-car',
                            routerLink: ['/admin/transportation-means']
                        },
                        {
                            label: adminTranslations.labelExtraExpenses,
                            icon: 'pi pi-fw pi-cog',
                            routerLink: ['/admin/extra-expenses']
                        }
                    ]
                }
                // {
                //     label:"User",
                //     icon: 'pi pi-fw pi-cog',
                //     routerLink: ['/admin/users/createuser'],
                // }
            ]
        }
    ];
}
