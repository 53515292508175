/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FinalCustomerFormValuesDto
 */
export interface FinalCustomerFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof FinalCustomerFormValuesDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalCustomerFormValuesDto
     */
    contactPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalCustomerFormValuesDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalCustomerFormValuesDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalCustomerFormValuesDto
     */
    city?: string;
}

/**
 * Check if a given object implements the FinalCustomerFormValuesDto interface.
 */
export function instanceOfFinalCustomerFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinalCustomerFormValuesDtoFromJSON(json: any): FinalCustomerFormValuesDto {
    return FinalCustomerFormValuesDtoFromJSONTyped(json, false);
}

export function FinalCustomerFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinalCustomerFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
    };
}

export function FinalCustomerFormValuesDtoToJSON(value?: FinalCustomerFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'contactPerson': value.contactPerson,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
    };
}

