/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyDetails
 */
export interface BodyDetails {
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    confectionSizeMin?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    confectionSizeMax?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    shoeSizeEu?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    shoeSizeUs?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    braCircumference?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    braCupSize?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    legLength?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    headCircumference?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDetails
     */
    pantSize?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BodyDetails
     */
    isPregnant?: boolean;
}

/**
 * Check if a given object implements the BodyDetails interface.
 */
export function instanceOfBodyDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BodyDetailsFromJSON(json: any): BodyDetails {
    return BodyDetailsFromJSONTyped(json, false);
}

export function BodyDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confectionSizeMin': !exists(json, 'confectionSizeMin') ? undefined : json['confectionSizeMin'],
        'confectionSizeMax': !exists(json, 'confectionSizeMax') ? undefined : json['confectionSizeMax'],
        'shoeSizeEu': !exists(json, 'shoeSizeEu') ? undefined : json['shoeSizeEu'],
        'shoeSizeUs': !exists(json, 'shoeSizeUs') ? undefined : json['shoeSizeUs'],
        'braCircumference': !exists(json, 'braCircumference') ? undefined : json['braCircumference'],
        'braCupSize': !exists(json, 'braCupSize') ? undefined : json['braCupSize'],
        'legLength': !exists(json, 'legLength') ? undefined : json['legLength'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'pantSize': !exists(json, 'pantSize') ? undefined : json['pantSize'],
        'isPregnant': !exists(json, 'isPregnant') ? undefined : json['isPregnant'],
    };
}

export function BodyDetailsToJSON(value?: BodyDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confectionSizeMin': value.confectionSizeMin,
        'confectionSizeMax': value.confectionSizeMax,
        'shoeSizeEu': value.shoeSizeEu,
        'shoeSizeUs': value.shoeSizeUs,
        'braCircumference': value.braCircumference,
        'braCupSize': value.braCupSize,
        'legLength': value.legLength,
        'headCircumference': value.headCircumference,
        'pantSize': value.pantSize,
        'isPregnant': value.isPregnant,
    };
}

