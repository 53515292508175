/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelCostCreateRequestDto
 */
export interface ModelCostCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ModelCostCreateRequestDto
     */
    modelId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ModelCostCreateRequestDto
     */
    costDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ModelCostCreateRequestDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelCostCreateRequestDto
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCostCreateRequestDto
     */
    costs?: number;
    /**
     * 
     * @type {Date}
     * @memberof ModelCostCreateRequestDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCostCreateRequestDto
     */
    isPaid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelCostCreateRequestDto
     */
    bookerName?: string;
}

/**
 * Check if a given object implements the ModelCostCreateRequestDto interface.
 */
export function instanceOfModelCostCreateRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelCostCreateRequestDtoFromJSON(json: any): ModelCostCreateRequestDto {
    return ModelCostCreateRequestDtoFromJSONTyped(json, false);
}

export function ModelCostCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelCostCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'costDate': !exists(json, 'costDate') ? undefined : (new Date(json['costDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'costs': !exists(json, 'costs') ? undefined : json['costs'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'isPaid': !exists(json, 'isPaid') ? undefined : json['isPaid'],
        'bookerName': !exists(json, 'bookerName') ? undefined : json['bookerName'],
    };
}

export function ModelCostCreateRequestDtoToJSON(value?: ModelCostCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'costDate': value.costDate === undefined ? undefined : (value.costDate.toISOString().substr(0,10)),
        'amount': value.amount,
        'reason': value.reason,
        'costs': value.costs,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString().substr(0,10)),
        'isPaid': value.isPaid,
        'bookerName': value.bookerName,
    };
}

