/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarMonths } from './CalendarMonths';
import {
    CalendarMonthsFromJSON,
    CalendarMonthsFromJSONTyped,
    CalendarMonthsToJSON,
} from './CalendarMonths';

/**
 * 
 * @export
 * @interface ProjectModelCurrentSelection
 */
export interface ProjectModelCurrentSelection {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    status?: ProjectModelCurrentSelectionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    statusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    roleName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelCurrentSelection
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelCurrentSelection
     */
    sizes?: string;
    /**
     * 
     * @type {Array<CalendarMonths>}
     * @memberof ProjectModelCurrentSelection
     */
    calendarMonths?: Array<CalendarMonths>;
}


/**
 * @export
 */
export const ProjectModelCurrentSelectionStatusEnum = {
    Rejected: 'REJECTED',
    Shortlist: 'SHORTLIST',
    OptRequested: 'OPT_REQUESTED',
    RejectedByModel: 'REJECTED_BY_MODEL',
    Optioned: 'OPTIONED',
    Casting: 'CASTING',
    RejectedByCustomer: 'REJECTED_BY_CUSTOMER',
    Selected: 'SELECTED'
} as const;
export type ProjectModelCurrentSelectionStatusEnum = typeof ProjectModelCurrentSelectionStatusEnum[keyof typeof ProjectModelCurrentSelectionStatusEnum];


/**
 * Check if a given object implements the ProjectModelCurrentSelection interface.
 */
export function instanceOfProjectModelCurrentSelection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function ProjectModelCurrentSelectionFromJSON(json: any): ProjectModelCurrentSelection {
    return ProjectModelCurrentSelectionFromJSONTyped(json, false);
}

export function ProjectModelCurrentSelectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelCurrentSelection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'modelId': json['modelId'],
        'projectId': json['projectId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDisplay': !exists(json, 'statusDisplay') ? undefined : json['statusDisplay'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'sizes': !exists(json, 'sizes') ? undefined : json['sizes'],
        'calendarMonths': !exists(json, 'calendarMonths') ? undefined : ((json['calendarMonths'] as Array<any>).map(CalendarMonthsFromJSON)),
    };
}

export function ProjectModelCurrentSelectionToJSON(value?: ProjectModelCurrentSelection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'modelId': value.modelId,
        'projectId': value.projectId,
        'image': value.image,
        'status': value.status,
        'statusDisplay': value.statusDisplay,
        'roleId': value.roleId,
        'roleName': value.roleName,
        'height': value.height,
        'sizes': value.sizes,
        'calendarMonths': value.calendarMonths === undefined ? undefined : ((value.calendarMonths as Array<any>).map(CalendarMonthsToJSON)),
    };
}

