/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface CustomerAddressFormValuesDto
 */
export interface CustomerAddressFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    addendum?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    attn?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    roomOne?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    roomTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressFormValuesDto
     */
    city?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof CustomerAddressFormValuesDto
     */
    country?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof CustomerAddressFormValuesDto
     */
    countryOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the CustomerAddressFormValuesDto interface.
 */
export function instanceOfCustomerAddressFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerAddressFormValuesDtoFromJSON(json: any): CustomerAddressFormValuesDto {
    return CustomerAddressFormValuesDtoFromJSONTyped(json, false);
}

export function CustomerAddressFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAddressFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addendum': !exists(json, 'addendum') ? undefined : json['addendum'],
        'attn': !exists(json, 'attn') ? undefined : json['attn'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'roomOne': !exists(json, 'roomOne') ? undefined : json['roomOne'],
        'roomTwo': !exists(json, 'roomTwo') ? undefined : json['roomTwo'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
        'countryOptions': !exists(json, 'countryOptions') ? undefined : ((json['countryOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function CustomerAddressFormValuesDtoToJSON(value?: CustomerAddressFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'addendum': value.addendum,
        'attn': value.attn,
        'street': value.street,
        'roomOne': value.roomOne,
        'roomTwo': value.roomTwo,
        'zipCode': value.zipCode,
        'city': value.city,
        'country': AdminValueToJSON(value.country),
        'countryOptions': value.countryOptions === undefined ? undefined : ((value.countryOptions as Array<any>).map(AdminValueToJSON)),
    };
}

