/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PushGetSubscriptionRequestDto,
  PushSubscriptionRequestDto,
  PushSubscriptionResponseDto,
} from '../models';
import {
    PushGetSubscriptionRequestDtoFromJSON,
    PushGetSubscriptionRequestDtoToJSON,
    PushSubscriptionRequestDtoFromJSON,
    PushSubscriptionRequestDtoToJSON,
    PushSubscriptionResponseDtoFromJSON,
    PushSubscriptionResponseDtoToJSON,
} from '../models';

export interface GetSubscriptionRequest {
    pushGetSubscriptionRequestDto: PushGetSubscriptionRequestDto;
}

export interface SubscribeRequest {
    pushSubscriptionRequestDto: PushSubscriptionRequestDto;
}

export interface UnsubscribeRequest {
    subscriptionId: string;
}

/**
 * 
 */
export class NotificationControllerApi extends runtime.BaseAPI {

    /**
     */
    async getSubscriptionRaw(requestParameters: GetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PushSubscriptionResponseDto>> {
        if (requestParameters.pushGetSubscriptionRequestDto === null || requestParameters.pushGetSubscriptionRequestDto === undefined) {
            throw new runtime.RequiredError('pushGetSubscriptionRequestDto','Required parameter requestParameters.pushGetSubscriptionRequestDto was null or undefined when calling getSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/get-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushGetSubscriptionRequestDtoToJSON(requestParameters.pushGetSubscriptionRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PushSubscriptionResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSubscription(requestParameters: GetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PushSubscriptionResponseDto> {
        const response = await this.getSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getVapidPublicKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/public-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getVapidPublicKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getVapidPublicKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeRaw(requestParameters: SubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PushSubscriptionResponseDto>> {
        if (requestParameters.pushSubscriptionRequestDto === null || requestParameters.pushSubscriptionRequestDto === undefined) {
            throw new runtime.RequiredError('pushSubscriptionRequestDto','Required parameter requestParameters.pushSubscriptionRequestDto was null or undefined when calling subscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushSubscriptionRequestDtoToJSON(requestParameters.pushSubscriptionRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PushSubscriptionResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async subscribe(requestParameters: SubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PushSubscriptionResponseDto> {
        const response = await this.subscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/unsubscribe/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unsubscribe(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeRaw(requestParameters, initOverrides);
    }

}
