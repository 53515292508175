<p-dialog header="{{'CONVERSATION.NEW.CONVERSATION.TITLE.label' | translate}}" [(visible)]="display" [modal]="true" showEffect="fade"
          [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}">

    <div class="field">
        <input pInputText type="text" placeholder="{{'CONVERSATION.NEW.CONV-TITLE.label' | translate}}" [(ngModel)]="titleInput"
               (input)="onTitleInput($event)">
    </div>

    <div class="field">
        <app-conversation-participant-display
                [selectedParticipantList]="selectedParticipants"
                [onRemoveParticipant]="onRemoveParticipant"
        ></app-conversation-participant-display>
    </div>

    <div class="field">
        <a *ngIf="!showModelSearch" (click)="onToggleModelSearch()">{{'CONVERSATION.NEW.CONVERSATION.ADD-MODEL.label' | translate}}</a>
        <a *ngIf="showModelSearch" (click)="onToggleModelSearch()">{{'CONVERSATION.NEW.CONVERSATION.CLOSE-MODEL.label' | translate}}</a>
        <div *ngIf="showModelSearch">
            <app-conversation-model-list
                    [selectedParticipants]="selectedParticipants"
                    [onModelSelection]="onAddToParticipantList.bind(this)"
            ></app-conversation-model-list>
        </div>
    </div>

    <div class="field">
        <a *ngIf="!showEmployeeSearch" (click)="onToggleEmployeeSearch()">{{'CONVERSATION.NEW.CONVERSATION.ADD-EMPLOYEE.label' | translate}}</a>
        <a *ngIf="showEmployeeSearch" (click)="onToggleEmployeeSearch()">{{'CONVERSATION.NEW.CONVERSATION.CLOSE-EMPLOYEE.label' | translate}}</a>
        <div *ngIf="showEmployeeSearch">
            <app-conversation-customer-search-list
                    [onSelectEmployee]="onAddToParticipantList.bind(this)"
            ></app-conversation-customer-search-list>
        </div>
    </div>

    <div class="field">
        <a *ngIf="!showProjectSearch" (click)="onToggleProjectSearch()">{{'CONVERSATION.NEW.CONVERSATION.ADD-PROJECT.label' | translate}}</a>
        <a *ngIf="showProjectSearch" (click)="onToggleProjectSearch()">{{'CONVERSATION.NEW.CONVERSATION.CLOSE-PROJECT.label' | translate}}</a>
        <div *ngIf="showProjectSearch">
            <app-conversation-project-search-list
                    [onSelectProjectParticipant]="onAddToParticipantList.bind(this)"
            ></app-conversation-project-search-list>
        </div>
    </div>


    <ng-template pTemplate="footer">
        <button
                (click)="onConfirmSelection()"
                type="submit"
                pButton
                class="p-button-outlined"
        >{{'CONVERSATION.NEW.CONVERSATION.SUBMIT-BUTTON.label' | translate}}
        </button>
    </ng-template>
</p-dialog>