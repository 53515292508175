/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerSearchItemDto } from './CustomerSearchItemDto';
import {
    CustomerSearchItemDtoFromJSON,
    CustomerSearchItemDtoFromJSONTyped,
    CustomerSearchItemDtoToJSON,
} from './CustomerSearchItemDto';

/**
 * 
 * @export
 * @interface CustomerSearchItemsDto
 */
export interface CustomerSearchItemsDto {
    /**
     * 
     * @type {Array<CustomerSearchItemDto>}
     * @memberof CustomerSearchItemsDto
     */
    customerSearchItems: Array<CustomerSearchItemDto>;
}

/**
 * Check if a given object implements the CustomerSearchItemsDto interface.
 */
export function instanceOfCustomerSearchItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerSearchItems" in value;

    return isInstance;
}

export function CustomerSearchItemsDtoFromJSON(json: any): CustomerSearchItemsDto {
    return CustomerSearchItemsDtoFromJSONTyped(json, false);
}

export function CustomerSearchItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSearchItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerSearchItems': ((json['customerSearchItems'] as Array<any>).map(CustomerSearchItemDtoFromJSON)),
    };
}

export function CustomerSearchItemsDtoToJSON(value?: CustomerSearchItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerSearchItems': ((value.customerSearchItems as Array<any>).map(CustomerSearchItemDtoToJSON)),
    };
}

