/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelCostDto
 */
export interface ModelCostDto {
    /**
     * 
     * @type {string}
     * @memberof ModelCostDto
     */
    modelCostId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCostDto
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCostDto
     */
    costDate: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCostDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ModelCostDto
     */
    costReason: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCostDto
     */
    costs: number;
    /**
     * 
     * @type {string}
     * @memberof ModelCostDto
     */
    creationDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCostDto
     */
    isPaid: boolean;
}

/**
 * Check if a given object implements the ModelCostDto interface.
 */
export function instanceOfModelCostDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelCostId" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "costDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "costReason" in value;
    isInstance = isInstance && "costs" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "isPaid" in value;

    return isInstance;
}

export function ModelCostDtoFromJSON(json: any): ModelCostDto {
    return ModelCostDtoFromJSONTyped(json, false);
}

export function ModelCostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelCostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelCostId': json['modelCostId'],
        'modelId': json['modelId'],
        'costDate': json['costDate'],
        'amount': json['amount'],
        'costReason': json['costReason'],
        'costs': json['costs'],
        'creationDate': json['creationDate'],
        'isPaid': json['isPaid'],
    };
}

export function ModelCostDtoToJSON(value?: ModelCostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelCostId': value.modelCostId,
        'modelId': value.modelId,
        'costDate': value.costDate,
        'amount': value.amount,
        'costReason': value.costReason,
        'costs': value.costs,
        'creationDate': value.creationDate,
        'isPaid': value.isPaid,
    };
}

