/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantResponseDto } from './ParticipantResponseDto';
import {
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoFromJSONTyped,
    ParticipantResponseDtoToJSON,
} from './ParticipantResponseDto';

/**
 * 
 * @export
 * @interface ModelsParticipantsResponseDto
 */
export interface ModelsParticipantsResponseDto {
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ModelsParticipantsResponseDto
     */
    myModels?: Array<ParticipantResponseDto>;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ModelsParticipantsResponseDto
     */
    otherModels?: Array<ParticipantResponseDto>;
}

/**
 * Check if a given object implements the ModelsParticipantsResponseDto interface.
 */
export function instanceOfModelsParticipantsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelsParticipantsResponseDtoFromJSON(json: any): ModelsParticipantsResponseDto {
    return ModelsParticipantsResponseDtoFromJSONTyped(json, false);
}

export function ModelsParticipantsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelsParticipantsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'myModels': !exists(json, 'myModels') ? undefined : ((json['myModels'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
        'otherModels': !exists(json, 'otherModels') ? undefined : ((json['otherModels'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
    };
}

export function ModelsParticipantsResponseDtoToJSON(value?: ModelsParticipantsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'myModels': value.myModels === undefined ? undefined : ((value.myModels as Array<any>).map(ParticipantResponseDtoToJSON)),
        'otherModels': value.otherModels === undefined ? undefined : ((value.otherModels as Array<any>).map(ParticipantResponseDtoToJSON)),
    };
}

