/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingModelsFormValuesDto } from './BookingModelsFormValuesDto';
import {
    BookingModelsFormValuesDtoFromJSON,
    BookingModelsFormValuesDtoFromJSONTyped,
    BookingModelsFormValuesDtoToJSON,
} from './BookingModelsFormValuesDto';
import type { BookingProducerFormValuesDto } from './BookingProducerFormValuesDto';
import {
    BookingProducerFormValuesDtoFromJSON,
    BookingProducerFormValuesDtoFromJSONTyped,
    BookingProducerFormValuesDtoToJSON,
} from './BookingProducerFormValuesDto';
import type { BudgetFormValuesDto } from './BudgetFormValuesDto';
import {
    BudgetFormValuesDtoFromJSON,
    BudgetFormValuesDtoFromJSONTyped,
    BudgetFormValuesDtoToJSON,
} from './BudgetFormValuesDto';
import type { ExecutorFormValuesDto } from './ExecutorFormValuesDto';
import {
    ExecutorFormValuesDtoFromJSON,
    ExecutorFormValuesDtoFromJSONTyped,
    ExecutorFormValuesDtoToJSON,
} from './ExecutorFormValuesDto';
import type { ExternalParticipantFormValuesDto } from './ExternalParticipantFormValuesDto';
import {
    ExternalParticipantFormValuesDtoFromJSON,
    ExternalParticipantFormValuesDtoFromJSONTyped,
    ExternalParticipantFormValuesDtoToJSON,
} from './ExternalParticipantFormValuesDto';
import type { FinalCustomerFormValuesDto } from './FinalCustomerFormValuesDto';
import {
    FinalCustomerFormValuesDtoFromJSON,
    FinalCustomerFormValuesDtoFromJSONTyped,
    FinalCustomerFormValuesDtoToJSON,
} from './FinalCustomerFormValuesDto';
import type { ProductionDetailsFormValuesDto } from './ProductionDetailsFormValuesDto';
import {
    ProductionDetailsFormValuesDtoFromJSON,
    ProductionDetailsFormValuesDtoFromJSONTyped,
    ProductionDetailsFormValuesDtoToJSON,
} from './ProductionDetailsFormValuesDto';
import type { ProjectAdditionalParticipantFormValuesDto } from './ProjectAdditionalParticipantFormValuesDto';
import {
    ProjectAdditionalParticipantFormValuesDtoFromJSON,
    ProjectAdditionalParticipantFormValuesDtoFromJSONTyped,
    ProjectAdditionalParticipantFormValuesDtoToJSON,
} from './ProjectAdditionalParticipantFormValuesDto';
import type { ProjectBillingFormValuesDto } from './ProjectBillingFormValuesDto';
import {
    ProjectBillingFormValuesDtoFromJSON,
    ProjectBillingFormValuesDtoFromJSONTyped,
    ProjectBillingFormValuesDtoToJSON,
} from './ProjectBillingFormValuesDto';
import type { ProjectCastingFormValuesDto } from './ProjectCastingFormValuesDto';
import {
    ProjectCastingFormValuesDtoFromJSON,
    ProjectCastingFormValuesDtoFromJSONTyped,
    ProjectCastingFormValuesDtoToJSON,
} from './ProjectCastingFormValuesDto';
import type { ProjectCreatorFormValuesDto } from './ProjectCreatorFormValuesDto';
import {
    ProjectCreatorFormValuesDtoFromJSON,
    ProjectCreatorFormValuesDtoFromJSONTyped,
    ProjectCreatorFormValuesDtoToJSON,
} from './ProjectCreatorFormValuesDto';
import type { ProjectIdFormValuesDto } from './ProjectIdFormValuesDto';
import {
    ProjectIdFormValuesDtoFromJSON,
    ProjectIdFormValuesDtoFromJSONTyped,
    ProjectIdFormValuesDtoToJSON,
} from './ProjectIdFormValuesDto';
import type { ProjectLocationsAndMotifsFormValuesDto } from './ProjectLocationsAndMotifsFormValuesDto';
import {
    ProjectLocationsAndMotifsFormValuesDtoFromJSON,
    ProjectLocationsAndMotifsFormValuesDtoFromJSONTyped,
    ProjectLocationsAndMotifsFormValuesDtoToJSON,
} from './ProjectLocationsAndMotifsFormValuesDto';
import type { ProjectModelFormValuesDto } from './ProjectModelFormValuesDto';
import {
    ProjectModelFormValuesDtoFromJSON,
    ProjectModelFormValuesDtoFromJSONTyped,
    ProjectModelFormValuesDtoToJSON,
} from './ProjectModelFormValuesDto';
import type { ProjectNameFormValuesDto } from './ProjectNameFormValuesDto';
import {
    ProjectNameFormValuesDtoFromJSON,
    ProjectNameFormValuesDtoFromJSONTyped,
    ProjectNameFormValuesDtoToJSON,
} from './ProjectNameFormValuesDto';
import type { ProjectProducerFormValueDto } from './ProjectProducerFormValueDto';
import {
    ProjectProducerFormValueDtoFromJSON,
    ProjectProducerFormValueDtoFromJSONTyped,
    ProjectProducerFormValueDtoToJSON,
} from './ProjectProducerFormValueDto';
import type { ProjectRolesFormValuesDto } from './ProjectRolesFormValuesDto';
import {
    ProjectRolesFormValuesDtoFromJSON,
    ProjectRolesFormValuesDtoFromJSONTyped,
    ProjectRolesFormValuesDtoToJSON,
} from './ProjectRolesFormValuesDto';
import type { ProjectStatusFormValuesDto } from './ProjectStatusFormValuesDto';
import {
    ProjectStatusFormValuesDtoFromJSON,
    ProjectStatusFormValuesDtoFromJSONTyped,
    ProjectStatusFormValuesDtoToJSON,
} from './ProjectStatusFormValuesDto';
import type { ProtocolsFormValuesDto } from './ProtocolsFormValuesDto';
import {
    ProtocolsFormValuesDtoFromJSON,
    ProtocolsFormValuesDtoFromJSONTyped,
    ProtocolsFormValuesDtoToJSON,
} from './ProtocolsFormValuesDto';
import type { UsageTypesFormValuesDto } from './UsageTypesFormValuesDto';
import {
    UsageTypesFormValuesDtoFromJSON,
    UsageTypesFormValuesDtoFromJSONTyped,
    UsageTypesFormValuesDtoToJSON,
} from './UsageTypesFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectFormValuesDto
 */
export interface ProjectFormValuesDto {
    /**
     * 
     * @type {ProjectIdFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    id?: ProjectIdFormValuesDto;
    /**
     * 
     * @type {ProjectNameFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    name?: ProjectNameFormValuesDto;
    /**
     * 
     * @type {ProjectStatusFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    status?: ProjectStatusFormValuesDto;
    /**
     * 
     * @type {ProjectCreatorFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    creator?: ProjectCreatorFormValuesDto;
    /**
     * 
     * @type {ProjectProducerFormValueDto}
     * @memberof ProjectFormValuesDto
     */
    producer?: ProjectProducerFormValueDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectFormValuesDto
     */
    createdAt?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectFormValuesDto
     */
    lastEditedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectFormValuesDto
     */
    lastWatchedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFormValuesDto
     */
    lastEditor?: string;
    /**
     * 
     * @type {FinalCustomerFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    finalCustomer?: FinalCustomerFormValuesDto;
    /**
     * 
     * @type {ProductionDetailsFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    productionDetails?: ProductionDetailsFormValuesDto;
    /**
     * 
     * @type {BudgetFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    budget?: BudgetFormValuesDto;
    /**
     * 
     * @type {UsageTypesFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    usageType?: UsageTypesFormValuesDto;
    /**
     * 
     * @type {ExecutorFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    executor?: ExecutorFormValuesDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectFormValuesDto
     */
    buyOut?: string;
    /**
     * 
     * @type {ProjectCastingFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    casting?: ProjectCastingFormValuesDto;
    /**
     * 
     * @type {Array<ProjectAdditionalParticipantFormValuesDto>}
     * @memberof ProjectFormValuesDto
     */
    additionalParticipantList?: Array<ProjectAdditionalParticipantFormValuesDto>;
    /**
     * 
     * @type {Array<ExternalParticipantFormValuesDto>}
     * @memberof ProjectFormValuesDto
     */
    externalParticipantList?: Array<ExternalParticipantFormValuesDto>;
    /**
     * 
     * @type {ProjectRolesFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    roles?: ProjectRolesFormValuesDto;
    /**
     * 
     * @type {Array<ProjectModelFormValuesDto>}
     * @memberof ProjectFormValuesDto
     */
    projectModels?: Array<ProjectModelFormValuesDto>;
    /**
     * 
     * @type {BookingProducerFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    bookingProducer?: BookingProducerFormValuesDto;
    /**
     * 
     * @type {BookingModelsFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    bookingModels?: BookingModelsFormValuesDto;
    /**
     * 
     * @type {ProtocolsFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    protocol?: ProtocolsFormValuesDto;
    /**
     * 
     * @type {ProjectLocationsAndMotifsFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    projectLocations?: ProjectLocationsAndMotifsFormValuesDto;
    /**
     * 
     * @type {ProjectBillingFormValuesDto}
     * @memberof ProjectFormValuesDto
     */
    billing?: ProjectBillingFormValuesDto;
}

/**
 * Check if a given object implements the ProjectFormValuesDto interface.
 */
export function instanceOfProjectFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectFormValuesDtoFromJSON(json: any): ProjectFormValuesDto {
    return ProjectFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : ProjectIdFormValuesDtoFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : ProjectNameFormValuesDtoFromJSON(json['name']),
        'status': !exists(json, 'status') ? undefined : ProjectStatusFormValuesDtoFromJSON(json['status']),
        'creator': !exists(json, 'creator') ? undefined : ProjectCreatorFormValuesDtoFromJSON(json['creator']),
        'producer': !exists(json, 'producer') ? undefined : ProjectProducerFormValueDtoFromJSON(json['producer']),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : (new Date(json['lastEditedAt'])),
        'lastWatchedBy': !exists(json, 'lastWatchedBy') ? undefined : json['lastWatchedBy'],
        'lastEditor': !exists(json, 'lastEditor') ? undefined : json['lastEditor'],
        'finalCustomer': !exists(json, 'finalCustomer') ? undefined : FinalCustomerFormValuesDtoFromJSON(json['finalCustomer']),
        'productionDetails': !exists(json, 'productionDetails') ? undefined : ProductionDetailsFormValuesDtoFromJSON(json['productionDetails']),
        'budget': !exists(json, 'budget') ? undefined : BudgetFormValuesDtoFromJSON(json['budget']),
        'usageType': !exists(json, 'usageType') ? undefined : UsageTypesFormValuesDtoFromJSON(json['usageType']),
        'executor': !exists(json, 'executor') ? undefined : ExecutorFormValuesDtoFromJSON(json['executor']),
        'buyOut': !exists(json, 'buyOut') ? undefined : json['buyOut'],
        'casting': !exists(json, 'casting') ? undefined : ProjectCastingFormValuesDtoFromJSON(json['casting']),
        'additionalParticipantList': !exists(json, 'additionalParticipantList') ? undefined : ((json['additionalParticipantList'] as Array<any>).map(ProjectAdditionalParticipantFormValuesDtoFromJSON)),
        'externalParticipantList': !exists(json, 'externalParticipantList') ? undefined : ((json['externalParticipantList'] as Array<any>).map(ExternalParticipantFormValuesDtoFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : ProjectRolesFormValuesDtoFromJSON(json['roles']),
        'projectModels': !exists(json, 'projectModels') ? undefined : ((json['projectModels'] as Array<any>).map(ProjectModelFormValuesDtoFromJSON)),
        'bookingProducer': !exists(json, 'bookingProducer') ? undefined : BookingProducerFormValuesDtoFromJSON(json['bookingProducer']),
        'bookingModels': !exists(json, 'bookingModels') ? undefined : BookingModelsFormValuesDtoFromJSON(json['bookingModels']),
        'protocol': !exists(json, 'protocol') ? undefined : ProtocolsFormValuesDtoFromJSON(json['protocol']),
        'projectLocations': !exists(json, 'projectLocations') ? undefined : ProjectLocationsAndMotifsFormValuesDtoFromJSON(json['projectLocations']),
        'billing': !exists(json, 'billing') ? undefined : ProjectBillingFormValuesDtoFromJSON(json['billing']),
    };
}

export function ProjectFormValuesDtoToJSON(value?: ProjectFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': ProjectIdFormValuesDtoToJSON(value.id),
        'name': ProjectNameFormValuesDtoToJSON(value.name),
        'status': ProjectStatusFormValuesDtoToJSON(value.status),
        'creator': ProjectCreatorFormValuesDtoToJSON(value.creator),
        'producer': ProjectProducerFormValueDtoToJSON(value.producer),
        'createdAt': value.createdAt,
        'lastEditedAt': value.lastEditedAt === undefined ? undefined : (value.lastEditedAt.toISOString()),
        'lastWatchedBy': value.lastWatchedBy,
        'lastEditor': value.lastEditor,
        'finalCustomer': FinalCustomerFormValuesDtoToJSON(value.finalCustomer),
        'productionDetails': ProductionDetailsFormValuesDtoToJSON(value.productionDetails),
        'budget': BudgetFormValuesDtoToJSON(value.budget),
        'usageType': UsageTypesFormValuesDtoToJSON(value.usageType),
        'executor': ExecutorFormValuesDtoToJSON(value.executor),
        'buyOut': value.buyOut,
        'casting': ProjectCastingFormValuesDtoToJSON(value.casting),
        'additionalParticipantList': value.additionalParticipantList === undefined ? undefined : ((value.additionalParticipantList as Array<any>).map(ProjectAdditionalParticipantFormValuesDtoToJSON)),
        'externalParticipantList': value.externalParticipantList === undefined ? undefined : ((value.externalParticipantList as Array<any>).map(ExternalParticipantFormValuesDtoToJSON)),
        'roles': ProjectRolesFormValuesDtoToJSON(value.roles),
        'projectModels': value.projectModels === undefined ? undefined : ((value.projectModels as Array<any>).map(ProjectModelFormValuesDtoToJSON)),
        'bookingProducer': BookingProducerFormValuesDtoToJSON(value.bookingProducer),
        'bookingModels': BookingModelsFormValuesDtoToJSON(value.bookingModels),
        'protocol': ProtocolsFormValuesDtoToJSON(value.protocol),
        'projectLocations': ProjectLocationsAndMotifsFormValuesDtoToJSON(value.projectLocations),
        'billing': ProjectBillingFormValuesDtoToJSON(value.billing),
    };
}

