/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddAdditionalParticipantRequestDto
 */
export interface AddAdditionalParticipantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    customerStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    customerZipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    customerCity?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeeFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeeLastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddAdditionalParticipantRequestDto
     */
    hasAdminRights?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    employeeMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAdditionalParticipantRequestDto
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the AddAdditionalParticipantRequestDto interface.
 */
export function instanceOfAddAdditionalParticipantRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddAdditionalParticipantRequestDtoFromJSON(json: any): AddAdditionalParticipantRequestDto {
    return AddAdditionalParticipantRequestDtoFromJSONTyped(json, false);
}

export function AddAdditionalParticipantRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAdditionalParticipantRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerStreet': !exists(json, 'customerStreet') ? undefined : json['customerStreet'],
        'customerZipCode': !exists(json, 'customerZipCode') ? undefined : json['customerZipCode'],
        'customerCity': !exists(json, 'customerCity') ? undefined : json['customerCity'],
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'employeeFirstName': !exists(json, 'employeeFirstName') ? undefined : json['employeeFirstName'],
        'employeeLastName': !exists(json, 'employeeLastName') ? undefined : json['employeeLastName'],
        'hasAdminRights': !exists(json, 'hasAdminRights') ? undefined : json['hasAdminRights'],
        'employeeEmail': !exists(json, 'employeeEmail') ? undefined : json['employeeEmail'],
        'employeePhone': !exists(json, 'employeePhone') ? undefined : json['employeePhone'],
        'employeeMobile': !exists(json, 'employeeMobile') ? undefined : json['employeeMobile'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function AddAdditionalParticipantRequestDtoToJSON(value?: AddAdditionalParticipantRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'customerStreet': value.customerStreet,
        'customerZipCode': value.customerZipCode,
        'customerCity': value.customerCity,
        'employeeId': value.employeeId,
        'employeeFirstName': value.employeeFirstName,
        'employeeLastName': value.employeeLastName,
        'hasAdminRights': value.hasAdminRights,
        'employeeEmail': value.employeeEmail,
        'employeePhone': value.employeePhone,
        'employeeMobile': value.employeeMobile,
        'oauthId': value.oauthId,
    };
}

