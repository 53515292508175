/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectSearchItemDto } from './ProjectSearchItemDto';
import {
    ProjectSearchItemDtoFromJSON,
    ProjectSearchItemDtoFromJSONTyped,
    ProjectSearchItemDtoToJSON,
} from './ProjectSearchItemDto';

/**
 * 
 * @export
 * @interface ProjectSearchItemsDto
 */
export interface ProjectSearchItemsDto {
    /**
     * 
     * @type {Array<ProjectSearchItemDto>}
     * @memberof ProjectSearchItemsDto
     */
    projectSearchItems: Array<ProjectSearchItemDto>;
}

/**
 * Check if a given object implements the ProjectSearchItemsDto interface.
 */
export function instanceOfProjectSearchItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectSearchItems" in value;

    return isInstance;
}

export function ProjectSearchItemsDtoFromJSON(json: any): ProjectSearchItemsDto {
    return ProjectSearchItemsDtoFromJSONTyped(json, false);
}

export function ProjectSearchItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSearchItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectSearchItems': ((json['projectSearchItems'] as Array<any>).map(ProjectSearchItemDtoFromJSON)),
    };
}

export function ProjectSearchItemsDtoToJSON(value?: ProjectSearchItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectSearchItems': ((value.projectSearchItems as Array<any>).map(ProjectSearchItemDtoToJSON)),
    };
}

