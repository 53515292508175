/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelPublicDataOverviewFilterDtoGeneral } from './ModelPublicDataOverviewFilterDtoGeneral';
import {
    ModelPublicDataOverviewFilterDtoGeneralFromJSON,
    ModelPublicDataOverviewFilterDtoGeneralFromJSONTyped,
    ModelPublicDataOverviewFilterDtoGeneralToJSON,
} from './ModelPublicDataOverviewFilterDtoGeneral';
import type { ModelPublicDataOverviewFilterDtoSizing } from './ModelPublicDataOverviewFilterDtoSizing';
import {
    ModelPublicDataOverviewFilterDtoSizingFromJSON,
    ModelPublicDataOverviewFilterDtoSizingFromJSONTyped,
    ModelPublicDataOverviewFilterDtoSizingToJSON,
} from './ModelPublicDataOverviewFilterDtoSizing';
import type { ModelPublicDataOverviewFilterDtoVisual } from './ModelPublicDataOverviewFilterDtoVisual';
import {
    ModelPublicDataOverviewFilterDtoVisualFromJSON,
    ModelPublicDataOverviewFilterDtoVisualFromJSONTyped,
    ModelPublicDataOverviewFilterDtoVisualToJSON,
} from './ModelPublicDataOverviewFilterDtoVisual';

/**
 * 
 * @export
 * @interface ModelPublicDataOverviewFilterDto
 */
export interface ModelPublicDataOverviewFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDto
     */
    searchTerm?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ModelPublicDataOverviewFilterDto
     */
    category?: { [key: string]: boolean; };
    /**
     * 
     * @type {ModelPublicDataOverviewFilterDtoGeneral}
     * @memberof ModelPublicDataOverviewFilterDto
     */
    general?: ModelPublicDataOverviewFilterDtoGeneral;
    /**
     * 
     * @type {ModelPublicDataOverviewFilterDtoVisual}
     * @memberof ModelPublicDataOverviewFilterDto
     */
    visual?: ModelPublicDataOverviewFilterDtoVisual;
    /**
     * 
     * @type {ModelPublicDataOverviewFilterDtoSizing}
     * @memberof ModelPublicDataOverviewFilterDto
     */
    sizing?: ModelPublicDataOverviewFilterDtoSizing;
}

/**
 * Check if a given object implements the ModelPublicDataOverviewFilterDto interface.
 */
export function instanceOfModelPublicDataOverviewFilterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataOverviewFilterDtoFromJSON(json: any): ModelPublicDataOverviewFilterDto {
    return ModelPublicDataOverviewFilterDtoFromJSONTyped(json, false);
}

export function ModelPublicDataOverviewFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataOverviewFilterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'general': !exists(json, 'general') ? undefined : ModelPublicDataOverviewFilterDtoGeneralFromJSON(json['general']),
        'visual': !exists(json, 'visual') ? undefined : ModelPublicDataOverviewFilterDtoVisualFromJSON(json['visual']),
        'sizing': !exists(json, 'sizing') ? undefined : ModelPublicDataOverviewFilterDtoSizingFromJSON(json['sizing']),
    };
}

export function ModelPublicDataOverviewFilterDtoToJSON(value?: ModelPublicDataOverviewFilterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerm': value.searchTerm,
        'category': value.category,
        'general': ModelPublicDataOverviewFilterDtoGeneralToJSON(value.general),
        'visual': ModelPublicDataOverviewFilterDtoVisualToJSON(value.visual),
        'sizing': ModelPublicDataOverviewFilterDtoSizingToJSON(value.sizing),
    };
}

