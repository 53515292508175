/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UploadedImageResult } from './UploadedImageResult';
import {
    UploadedImageResultFromJSON,
    UploadedImageResultFromJSONTyped,
    UploadedImageResultToJSON,
} from './UploadedImageResult';

/**
 * 
 * @export
 * @interface DoUploadActionResult
 */
export interface DoUploadActionResult {
    /**
     * 
     * @type {Array<UploadedImageResult>}
     * @memberof DoUploadActionResult
     */
    images?: Array<UploadedImageResult>;
    /**
     * 
     * @type {string}
     * @memberof DoUploadActionResult
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the DoUploadActionResult interface.
 */
export function instanceOfDoUploadActionResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoUploadActionResultFromJSON(json: any): DoUploadActionResult {
    return DoUploadActionResultFromJSONTyped(json, false);
}

export function DoUploadActionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoUploadActionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(UploadedImageResultFromJSON)),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function DoUploadActionResultToJSON(value?: DoUploadActionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(UploadedImageResultToJSON)),
        'errorMessage': value.errorMessage,
    };
}

