/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductionDetailsEventsCommand
 */
export interface GetProductionDetailsEventsCommand {
    /**
     * 
     * @type {Date}
     * @memberof GetProductionDetailsEventsCommand
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetProductionDetailsEventsCommand
     */
    to?: Date;
}

/**
 * Check if a given object implements the GetProductionDetailsEventsCommand interface.
 */
export function instanceOfGetProductionDetailsEventsCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetProductionDetailsEventsCommandFromJSON(json: any): GetProductionDetailsEventsCommand {
    return GetProductionDetailsEventsCommandFromJSONTyped(json, false);
}

export function GetProductionDetailsEventsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductionDetailsEventsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
    };
}

export function GetProductionDetailsEventsCommandToJSON(value?: GetProductionDetailsEventsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
    };
}

