/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantResponseDto } from './ParticipantResponseDto';
import {
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoFromJSONTyped,
    ParticipantResponseDtoToJSON,
} from './ParticipantResponseDto';

/**
 * 
 * @export
 * @interface ProjectModelParticipantsByProjectRoleResponseDto
 */
export interface ProjectModelParticipantsByProjectRoleResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelParticipantsByProjectRoleResponseDto
     */
    role?: string;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ProjectModelParticipantsByProjectRoleResponseDto
     */
    models?: Array<ParticipantResponseDto>;
}

/**
 * Check if a given object implements the ProjectModelParticipantsByProjectRoleResponseDto interface.
 */
export function instanceOfProjectModelParticipantsByProjectRoleResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelParticipantsByProjectRoleResponseDtoFromJSON(json: any): ProjectModelParticipantsByProjectRoleResponseDto {
    return ProjectModelParticipantsByProjectRoleResponseDtoFromJSONTyped(json, false);
}

export function ProjectModelParticipantsByProjectRoleResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelParticipantsByProjectRoleResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : json['role'],
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
    };
}

export function ProjectModelParticipantsByProjectRoleResponseDtoToJSON(value?: ProjectModelParticipantsByProjectRoleResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(ParticipantResponseDtoToJSON)),
    };
}

