/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailableFormValuesDto,
} from '../models';
import {
    AvailableFormValuesDtoFromJSON,
    AvailableFormValuesDtoToJSON,
} from '../models';

export interface GetFormValuesRequest {
    language: GetFormValuesLanguageEnum;
}

/**
 * 
 */
export class AdminControllerApi extends runtime.BaseAPI {

    /**
     */
    async getFormValuesRaw(requestParameters: GetFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableFormValuesDto>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getFormValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/form-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFormValues(requestParameters: GetFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableFormValuesDto> {
        const response = await this.getFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetFormValuesLanguageEnum = {
    De: 'de',
    En: 'en'
} as const;
export type GetFormValuesLanguageEnum = typeof GetFormValuesLanguageEnum[keyof typeof GetFormValuesLanguageEnum];
