/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';

/**
 * 
 * @export
 * @interface Identification
 */
export interface Identification {
    /**
     * 
     * @type {Country}
     * @memberof Identification
     */
    nationality?: Country;
    /**
     * 
     * @type {Date}
     * @memberof Identification
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof Identification
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Identification
     */
    authority?: string;
    /**
     * 
     * @type {Date}
     * @memberof Identification
     */
    passportValidUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof Identification
     */
    ethnicityFather?: string;
    /**
     * 
     * @type {string}
     * @memberof Identification
     */
    ethnicityMother?: string;
}

/**
 * Check if a given object implements the Identification interface.
 */
export function instanceOfIdentification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentificationFromJSON(json: any): Identification {
    return IdentificationFromJSONTyped(json, false);
}

export function IdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Identification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nationality': !exists(json, 'nationality') ? undefined : CountryFromJSON(json['nationality']),
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'passportNumber': !exists(json, 'passportNumber') ? undefined : json['passportNumber'],
        'authority': !exists(json, 'authority') ? undefined : json['authority'],
        'passportValidUntil': !exists(json, 'passportValidUntil') ? undefined : (new Date(json['passportValidUntil'])),
        'ethnicityFather': !exists(json, 'ethnicityFather') ? undefined : json['ethnicityFather'],
        'ethnicityMother': !exists(json, 'ethnicityMother') ? undefined : json['ethnicityMother'],
    };
}

export function IdentificationToJSON(value?: Identification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nationality': CountryToJSON(value.nationality),
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'passportNumber': value.passportNumber,
        'authority': value.authority,
        'passportValidUntil': value.passportValidUntil === undefined ? undefined : (value.passportValidUntil.toISOString().substr(0,10)),
        'ethnicityFather': value.ethnicityFather,
        'ethnicityMother': value.ethnicityMother,
    };
}

