import {ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {firstValueFrom, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {AuthenticationService} from "./app.auth.service";


@Injectable({
    providedIn: 'root',
})

export class AuthGuardService {
    //userRole!:string|undefined;
    constructor(
        private auth: AuthService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Promise((resolve, reject) => {

            // we subscribe to the isAuthenticated$ observable
            // in case something changes we check if the user is authenticated
            // additionally we check if the user has the right roles
            // if the user is not authenticated we navigate to the login start
            // if the user is authenticated but has no roles we redirect to the unauthenticated start
            this.auth.isAuthenticated$.subscribe(async (isLogin) => {
                if (isLogin) {
                    const isAuthenticated = await this.checkUserRoles(next);
                    resolve(isAuthenticated);
                } else {
                    this.router.navigate(['/login']);
                    reject(false);
                }
            });
        })

    }

    canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => this.canActivate(route, state);

    async checkUserRoles(route: ActivatedRouteSnapshot): Promise<boolean> {
        let userRoles: string[] = [];

        const frontendUser = await firstValueFrom(this.authenticationService.getFrontendUser());

        // that's the case when a user logs in the first time
        // the user is logged in by auth0, that's already true
        // but there is no frontendUser yet
        // we allow this only for the redirect path "/"
        if (!frontendUser && route.routeConfig?.path === "") {
            return true;
        }

        // the user opens any start except the redirect path "/" and there is no frontendUser
        if (!frontendUser) {
            this.router.navigate(['/unauthenticated']);
            return false;
        }

        // we get the optional accessRoles from the route
        // remember you don't need to secure child routes in case the parent root is already secured and there is no difference
        const accessRoles: string[] = route.data["role"] ? route.data["role"] : [];

        // no access roles means no restrictions
        if (!accessRoles.length) {
            return true;
        }

        // we get the user role from the frontendUser
        // if the user doesn't have one, we assume it's an admin
        userRoles = frontendUser.userRoles ? frontendUser.userRoles : ["Admin"];

        // we check if the user role is in the access roles
        if (userRoles.some(role => accessRoles.includes(role))) {
            return true;
        }

        this.router.navigate(['/unauthenticated']);
        return false;
    }
}
