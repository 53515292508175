/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantResponseDto } from './ParticipantResponseDto';
import {
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoFromJSONTyped,
    ParticipantResponseDtoToJSON,
} from './ParticipantResponseDto';

/**
 * 
 * @export
 * @interface ConversationDto
 */
export interface ConversationDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    conversationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    conversationTitle?: string;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ConversationDto
     */
    participantResponseDtoList?: Array<ParticipantResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    formattedTimeStamp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationDto
     */
    isGroupChat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationDto
     */
    hasNewMessage?: boolean;
}

/**
 * Check if a given object implements the ConversationDto interface.
 */
export function instanceOfConversationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationDtoFromJSON(json: any): ConversationDto {
    return ConversationDtoFromJSONTyped(json, false);
}

export function ConversationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationId': !exists(json, 'conversationId') ? undefined : json['conversationId'],
        'conversationTitle': !exists(json, 'conversationTitle') ? undefined : json['conversationTitle'],
        'participantResponseDtoList': !exists(json, 'participantResponseDtoList') ? undefined : ((json['participantResponseDtoList'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
        'formattedTimeStamp': !exists(json, 'formattedTimeStamp') ? undefined : json['formattedTimeStamp'],
        'isGroupChat': !exists(json, 'isGroupChat') ? undefined : json['isGroupChat'],
        'hasNewMessage': !exists(json, 'hasNewMessage') ? undefined : json['hasNewMessage'],
    };
}

export function ConversationDtoToJSON(value?: ConversationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationId': value.conversationId,
        'conversationTitle': value.conversationTitle,
        'participantResponseDtoList': value.participantResponseDtoList === undefined ? undefined : ((value.participantResponseDtoList as Array<any>).map(ParticipantResponseDtoToJSON)),
        'formattedTimeStamp': value.formattedTimeStamp,
        'isGroupChat': value.isGroupChat,
        'hasNewMessage': value.hasNewMessage,
    };
}

