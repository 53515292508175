<div *ngIf="selectedParticipants.length >= 1; else existingTitle">
    <input pInputText type="text" placeholder="{{'CONVERSATION.NEW.CONV-TITLE.label' | translate}}" [(ngModel)]="titleInput"
           (input)="onTitleInput($event)">
</div>

<ng-template #existingTitle>
    <div *ngIf="!titleEditMode" class="title-container">
        <span *ngIf="conversationId" (click)="onEnableTitleEditMode()"  class="pi pi-pencil edit-button"></span>
        <h4 class="conversation-title">{{conversationTitle}}</h4>
    </div>
    <div *ngIf="titleEditMode">
        <input pInputText type="text" [(ngModel)]="titleInput" (input)="onTitleInput($event)" class="title-input">
        <span (click)="onChangeTitle()" class="pi pi-check submit-button"></span>
        <span (click)="onAbortTitleEditMode()" class="pi pi-times cancel-button"></span>
    </div>
</ng-template>
