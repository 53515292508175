/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectCustomerEmployeeFormValuesDto } from './ProjectCustomerEmployeeFormValuesDto';
import {
    ProjectCustomerEmployeeFormValuesDtoFromJSON,
    ProjectCustomerEmployeeFormValuesDtoFromJSONTyped,
    ProjectCustomerEmployeeFormValuesDtoToJSON,
} from './ProjectCustomerEmployeeFormValuesDto';
import type { ProjectProducerAddressFormValuesDto } from './ProjectProducerAddressFormValuesDto';
import {
    ProjectProducerAddressFormValuesDtoFromJSON,
    ProjectProducerAddressFormValuesDtoFromJSONTyped,
    ProjectProducerAddressFormValuesDtoToJSON,
} from './ProjectProducerAddressFormValuesDto';
import type { ProjectProducerContactFormValuesDto } from './ProjectProducerContactFormValuesDto';
import {
    ProjectProducerContactFormValuesDtoFromJSON,
    ProjectProducerContactFormValuesDtoFromJSONTyped,
    ProjectProducerContactFormValuesDtoToJSON,
} from './ProjectProducerContactFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectProducerFormValueDto
 */
export interface ProjectProducerFormValueDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerFormValueDto
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerFormValueDto
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerFormValueDto
     */
    note?: string;
    /**
     * 
     * @type {ProjectProducerAddressFormValuesDto}
     * @memberof ProjectProducerFormValueDto
     */
    address?: ProjectProducerAddressFormValuesDto;
    /**
     * 
     * @type {ProjectProducerContactFormValuesDto}
     * @memberof ProjectProducerFormValueDto
     */
    contactInfos?: ProjectProducerContactFormValuesDto;
    /**
     * 
     * @type {Array<ProjectCustomerEmployeeFormValuesDto>}
     * @memberof ProjectProducerFormValueDto
     */
    responsiblePerson?: Array<ProjectCustomerEmployeeFormValuesDto>;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerFormValueDto
     */
    selectedEmployee?: string;
}

/**
 * Check if a given object implements the ProjectProducerFormValueDto interface.
 */
export function instanceOfProjectProducerFormValueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectProducerFormValueDtoFromJSON(json: any): ProjectProducerFormValueDto {
    return ProjectProducerFormValueDtoFromJSONTyped(json, false);
}

export function ProjectProducerFormValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectProducerFormValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'address': !exists(json, 'address') ? undefined : ProjectProducerAddressFormValuesDtoFromJSON(json['address']),
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ProjectProducerContactFormValuesDtoFromJSON(json['contactInfos']),
        'responsiblePerson': !exists(json, 'responsiblePerson') ? undefined : ((json['responsiblePerson'] as Array<any>).map(ProjectCustomerEmployeeFormValuesDtoFromJSON)),
        'selectedEmployee': !exists(json, 'selectedEmployee') ? undefined : json['selectedEmployee'],
    };
}

export function ProjectProducerFormValueDtoToJSON(value?: ProjectProducerFormValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'note': value.note,
        'address': ProjectProducerAddressFormValuesDtoToJSON(value.address),
        'contactInfos': ProjectProducerContactFormValuesDtoToJSON(value.contactInfos),
        'responsiblePerson': value.responsiblePerson === undefined ? undefined : ((value.responsiblePerson as Array<any>).map(ProjectCustomerEmployeeFormValuesDtoToJSON)),
        'selectedEmployee': value.selectedEmployee,
    };
}

