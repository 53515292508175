/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { BahnCard } from './BahnCard';
import {
    BahnCardFromJSON,
    BahnCardFromJSONTyped,
    BahnCardToJSON,
} from './BahnCard';
import type { BankAccountInformation } from './BankAccountInformation';
import {
    BankAccountInformationFromJSON,
    BankAccountInformationFromJSONTyped,
    BankAccountInformationToJSON,
} from './BankAccountInformation';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Identification } from './Identification';
import {
    IdentificationFromJSON,
    IdentificationFromJSONTyped,
    IdentificationToJSON,
} from './Identification';
import type { PersonalInfos } from './PersonalInfos';
import {
    PersonalInfosFromJSON,
    PersonalInfosFromJSONTyped,
    PersonalInfosToJSON,
} from './PersonalInfos';
import type { Sport } from './Sport';
import {
    SportFromJSON,
    SportFromJSONTyped,
    SportToJSON,
} from './Sport';
import type { TaxInformation } from './TaxInformation';
import {
    TaxInformationFromJSON,
    TaxInformationFromJSONTyped,
    TaxInformationToJSON,
} from './TaxInformation';

/**
 * 
 * @export
 * @interface PublicModelDto
 */
export interface PublicModelDto {
    /**
     * 
     * @type {string}
     * @memberof PublicModelDto
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicModelDto
     */
    image?: string;
    /**
     * 
     * @type {PersonalInfos}
     * @memberof PublicModelDto
     */
    personalInfos?: PersonalInfos;
    /**
     * 
     * @type {Contact}
     * @memberof PublicModelDto
     */
    contactInfos?: Contact;
    /**
     * 
     * @type {Address}
     * @memberof PublicModelDto
     */
    livingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof PublicModelDto
     */
    notifyAddress?: Address;
    /**
     * 
     * @type {Identification}
     * @memberof PublicModelDto
     */
    identification?: Identification;
    /**
     * 
     * @type {BankAccountInformation}
     * @memberof PublicModelDto
     */
    bankAccountInformation?: BankAccountInformation;
    /**
     * 
     * @type {TaxInformation}
     * @memberof PublicModelDto
     */
    taxInformation?: TaxInformation;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicModelDto
     */
    driverLicenses?: Array<string>;
    /**
     * 
     * @type {BahnCard}
     * @memberof PublicModelDto
     */
    bahnCard?: BahnCard;
    /**
     * 
     * @type {Array<Sport>}
     * @memberof PublicModelDto
     */
    sports?: Array<Sport>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PublicModelDto
     */
    bahncardTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PublicModelDto
     */
    bahncardClassOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PublicModelDto
     */
    availableCountries?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PublicModelDto
     */
    sportTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof PublicModelDto
     */
    driverLicenseOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the PublicModelDto interface.
 */
export function instanceOfPublicModelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicModelDtoFromJSON(json: any): PublicModelDto {
    return PublicModelDtoFromJSONTyped(json, false);
}

export function PublicModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicModelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'personalInfos': !exists(json, 'personalInfos') ? undefined : PersonalInfosFromJSON(json['personalInfos']),
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ContactFromJSON(json['contactInfos']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressFromJSON(json['notifyAddress']),
        'identification': !exists(json, 'identification') ? undefined : IdentificationFromJSON(json['identification']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFromJSON(json['bankAccountInformation']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationFromJSON(json['taxInformation']),
        'driverLicenses': !exists(json, 'driverLicenses') ? undefined : json['driverLicenses'],
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardFromJSON(json['bahnCard']),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportFromJSON)),
        'bahncardTypeOptions': !exists(json, 'bahncardTypeOptions') ? undefined : ((json['bahncardTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'bahncardClassOptions': !exists(json, 'bahncardClassOptions') ? undefined : ((json['bahncardClassOptions'] as Array<any>).map(AdminValueFromJSON)),
        'availableCountries': !exists(json, 'availableCountries') ? undefined : ((json['availableCountries'] as Array<any>).map(AdminValueFromJSON)),
        'sportTypeOptions': !exists(json, 'sportTypeOptions') ? undefined : ((json['sportTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'driverLicenseOptions': !exists(json, 'driverLicenseOptions') ? undefined : ((json['driverLicenseOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function PublicModelDtoToJSON(value?: PublicModelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'image': value.image,
        'personalInfos': PersonalInfosToJSON(value.personalInfos),
        'contactInfos': ContactToJSON(value.contactInfos),
        'livingAddress': AddressToJSON(value.livingAddress),
        'notifyAddress': AddressToJSON(value.notifyAddress),
        'identification': IdentificationToJSON(value.identification),
        'bankAccountInformation': BankAccountInformationToJSON(value.bankAccountInformation),
        'taxInformation': TaxInformationToJSON(value.taxInformation),
        'driverLicenses': value.driverLicenses,
        'bahnCard': BahnCardToJSON(value.bahnCard),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportToJSON)),
        'bahncardTypeOptions': value.bahncardTypeOptions === undefined ? undefined : ((value.bahncardTypeOptions as Array<any>).map(AdminValueToJSON)),
        'bahncardClassOptions': value.bahncardClassOptions === undefined ? undefined : ((value.bahncardClassOptions as Array<any>).map(AdminValueToJSON)),
        'availableCountries': value.availableCountries === undefined ? undefined : ((value.availableCountries as Array<any>).map(AdminValueToJSON)),
        'sportTypeOptions': value.sportTypeOptions === undefined ? undefined : ((value.sportTypeOptions as Array<any>).map(AdminValueToJSON)),
        'driverLicenseOptions': value.driverLicenseOptions === undefined ? undefined : ((value.driverLicenseOptions as Array<any>).map(AdminValueToJSON)),
    };
}

