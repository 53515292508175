/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    referenceId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userLastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userEmail: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    userRoles: Array<UserUserRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    oauthId?: string;
}


/**
 * @export
 */
export const UserUserRolesEnum = {
    Admin: 'Admin',
    Booker: 'Booker',
    BookerAdmin: 'BookerAdmin',
    JuniorBooker: 'JuniorBooker',
    Model: 'Model',
    Customer: 'Customer',
    ExternalParticipant: 'ExternalParticipant'
} as const;
export type UserUserRolesEnum = typeof UserUserRolesEnum[keyof typeof UserUserRolesEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "referenceId" in value;
    isInstance = isInstance && "userFirstName" in value;
    isInstance = isInstance && "userLastName" in value;
    isInstance = isInstance && "userEmail" in value;
    isInstance = isInstance && "userRoles" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'referenceId': json['referenceId'],
        'userFirstName': json['userFirstName'],
        'userLastName': json['userLastName'],
        'userEmail': json['userEmail'],
        'userRoles': json['userRoles'],
        'userPhone': !exists(json, 'userPhone') ? undefined : json['userPhone'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'referenceId': value.referenceId,
        'userFirstName': value.userFirstName,
        'userLastName': value.userLastName,
        'userEmail': value.userEmail,
        'userRoles': value.userRoles,
        'userPhone': value.userPhone,
        'oauthId': value.oauthId,
    };
}

