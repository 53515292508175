/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectProducerAddressFormValuesDto
 */
export interface ProjectProducerAddressFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerAddressFormValuesDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerAddressFormValuesDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducerAddressFormValuesDto
     */
    city?: string;
}

/**
 * Check if a given object implements the ProjectProducerAddressFormValuesDto interface.
 */
export function instanceOfProjectProducerAddressFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectProducerAddressFormValuesDtoFromJSON(json: any): ProjectProducerAddressFormValuesDto {
    return ProjectProducerAddressFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectProducerAddressFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectProducerAddressFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
    };
}

export function ProjectProducerAddressFormValuesDtoToJSON(value?: ProjectProducerAddressFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
    };
}

