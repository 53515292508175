import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export type ConfirmModalType = {
  message: string;
  callback: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {
  private showConfirmModalSubject = new Subject<ConfirmModalType>();

  showConfirmModal(message: string, callback: () => void): void {
    this.showConfirmModalSubject.next({
      message,
      callback
    });
  }

  get showConfirmModal$(): Observable<ConfirmModalType> {
    return this.showConfirmModalSubject.asObservable();
  }
}
