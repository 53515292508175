/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { EmployeeFormValuesDto } from './EmployeeFormValuesDto';
import {
    EmployeeFormValuesDtoFromJSON,
    EmployeeFormValuesDtoFromJSONTyped,
    EmployeeFormValuesDtoToJSON,
} from './EmployeeFormValuesDto';

/**
 * 
 * @export
 * @interface EmployeesFormValuesDto
 */
export interface EmployeesFormValuesDto {
    /**
     * 
     * @type {Array<EmployeeFormValuesDto>}
     * @memberof EmployeesFormValuesDto
     */
    employees?: Array<EmployeeFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof EmployeesFormValuesDto
     */
    positionOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof EmployeesFormValuesDto
     */
    sexOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the EmployeesFormValuesDto interface.
 */
export function instanceOfEmployeesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeesFormValuesDtoFromJSON(json: any): EmployeesFormValuesDto {
    return EmployeesFormValuesDtoFromJSONTyped(json, false);
}

export function EmployeesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(EmployeeFormValuesDtoFromJSON)),
        'positionOptions': !exists(json, 'positionOptions') ? undefined : ((json['positionOptions'] as Array<any>).map(AdminValueFromJSON)),
        'sexOptions': !exists(json, 'sexOptions') ? undefined : ((json['sexOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function EmployeesFormValuesDtoToJSON(value?: EmployeesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(EmployeeFormValuesDtoToJSON)),
        'positionOptions': value.positionOptions === undefined ? undefined : ((value.positionOptions as Array<any>).map(AdminValueToJSON)),
        'sexOptions': value.sexOptions === undefined ? undefined : ((value.sexOptions as Array<any>).map(AdminValueToJSON)),
    };
}

