<ng-container *ngIf="isVisible">
<div class="messagebox" #messageContainer>
    <ul *ngFor="let message of messageList" class="messageList">
        <li>
            <div [ngClass]="{'message-left-container': message.senderId !== currentUserOauthId, 'message-right-container': message.senderId === currentUserOauthId}">
                <div *ngIf="message.message" [ngClass]="{'message-left': message.senderId !== currentUserOauthId, 'message-right': message.senderId === currentUserOauthId}">
                    <span [innerHTML]="message.message | newline"></span>
                </div>
                <div *ngIf="message.imageUrl" class="image-container" [ngClass]="{'message-left': message.senderId !== currentUserOauthId, 'message-right': message.senderId === currentUserOauthId}">
                    <a href="{{message.imageUrl}}" target="_blank">
                        <img src="{{message.imageUrl}}" />
                    </a>
                </div>
                <div *ngIf="message.videoUrl" class="video-container" [ngClass]="{'message-left': message.senderId !== currentUserOauthId, 'message-right': message.senderId === currentUserOauthId}">
                    <a href="{{message.videoUrl}}" target="_blank">
                        <video src="{{message.videoUrl}}" controls></video>
                    </a>
                </div>
                <div class="message-sub-info">
                    <div class="timestamp">
                        {{message.formattedTimeStamp | date : 'dd.MM.yy - HH:mm:ss'}}
                    </div>
                    <a *ngIf="message.senderId !== currentUserOauthId" class="delete-message" (click)="onDeleteMessage(message.messageId!)">{{'CONVERSATION.CHAT.DELETE.label' | translate}}</a>
                    <span *ngIf="isGroupChat && message.senderId != currentUserOauthId" class="sender-name">{{message.senderName}}</span>
                </div>
            </div>
        </li>
    </ul>
</div>
<div *ngIf="conversationId" class="chat-input field" style="position: relative">
    <input class="chatfield" pInputText [(ngModel)]="newMessage" (keydown.enter)="sendNewMessage()"
           placeholder="{{'CONVERSATION.CHAT.PLACEHOLDER.label' | translate}}">
    <input type="file" #fileInput (change)="onFileSelected()" accept="image/*,video/*" multiple
           style="display: none;">
    <div style="position: relative">
        <button class="p-button-success refresh" pButton icon="pi pi-refresh" (click)="refreshMessages()"></button>
        <span class="counter">{{counter}}s</span>
    </div>
    <button class="p-button-success camera" pButton icon="pi pi-camera" (click)=fileInput.click()></button>
    <button *ngIf="!isSending" class="p-button-success sendbutton" pButton icon="pi pi-send"
            (click)="sendNewMessage()" [disabled]="newMessage == '' && !thumbnails.length"></button>
    <i *ngIf="isSending" class="pi pi-spin pi-spinner" style="font-size: 2.3rem"></i>
</div>
<div class="thumbnails">
    <div class="thumbnails__thumbnail" *ngFor="let thumb of thumbnails">
        <img [src]="thumb" alt="Thumbnail" />
        <div class="thumbnails__thumbnail__overlay" (click)="removeThumbnail(thumb)">
            <i class="pi pi-trash"></i>
        </div>
    </div>

</div>
</ng-container>
