/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface TaxInformationFormValuesDto
 */
export interface TaxInformationFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof TaxInformationFormValuesDto
     */
    country?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationFormValuesDto
     */
    foreignTaxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationFormValuesDto
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationFormValuesDto
     */
    taxIdentificationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationFormValuesDto
     */
    taxOffice?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationFormValuesDto
     */
    vatId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxInformationFormValuesDto
     */
    liableToVat?: boolean;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof TaxInformationFormValuesDto
     */
    countryOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the TaxInformationFormValuesDto interface.
 */
export function instanceOfTaxInformationFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaxInformationFormValuesDtoFromJSON(json: any): TaxInformationFormValuesDto {
    return TaxInformationFormValuesDtoFromJSONTyped(json, false);
}

export function TaxInformationFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxInformationFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
        'foreignTaxNumber': !exists(json, 'foreignTaxNumber') ? undefined : json['foreignTaxNumber'],
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'taxIdentificationNumber': !exists(json, 'taxIdentificationNumber') ? undefined : json['taxIdentificationNumber'],
        'taxOffice': !exists(json, 'taxOffice') ? undefined : json['taxOffice'],
        'vatId': !exists(json, 'vatId') ? undefined : json['vatId'],
        'liableToVat': !exists(json, 'liableToVat') ? undefined : json['liableToVat'],
        'countryOptions': !exists(json, 'countryOptions') ? undefined : ((json['countryOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function TaxInformationFormValuesDtoToJSON(value?: TaxInformationFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': AdminValueToJSON(value.country),
        'foreignTaxNumber': value.foreignTaxNumber,
        'taxNumber': value.taxNumber,
        'taxIdentificationNumber': value.taxIdentificationNumber,
        'taxOffice': value.taxOffice,
        'vatId': value.vatId,
        'liableToVat': value.liableToVat,
        'countryOptions': value.countryOptions === undefined ? undefined : ((value.countryOptions as Array<any>).map(AdminValueToJSON)),
    };
}

