/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Communication } from './Communication';
import {
    CommunicationFromJSON,
    CommunicationFromJSONTyped,
    CommunicationToJSON,
} from './Communication';

/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    employeeUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    wholeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    info?: string;
    /**
     * 
     * @type {Communication}
     * @memberof Employee
     */
    communication?: Communication;
}

/**
 * Check if a given object implements the Employee interface.
 */
export function instanceOfEmployee(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeFromJSON(json: any): Employee {
    return EmployeeFromJSONTyped(json, false);
}

export function EmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Employee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeUuid': !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'wholeName': !exists(json, 'wholeName') ? undefined : json['wholeName'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'communication': !exists(json, 'communication') ? undefined : CommunicationFromJSON(json['communication']),
    };
}

export function EmployeeToJSON(value?: Employee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeUuid': value.employeeUuid,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'wholeName': value.wholeName,
        'title': value.title,
        'position': value.position,
        'gender': value.gender,
        'birthDate': value.birthDate,
        'info': value.info,
        'communication': CommunicationToJSON(value.communication),
    };
}

