import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ConversationService} from "../../service/conversation.service";
import {FormBuilder} from "@angular/forms";
import {
    ConversationDto,
    CreateConversationRequestDto, CustomerNamesForMessengerDto, MessageDto,
    ParticipantResponseDto, ProjectNamesForMessengerDto
} from "@brody-bookings/api";

interface ConversationListItem {
    conversationId: string,
    conversationTitle: string,
    participantNames: string[],
    isGroupChat: boolean,
    hasNewMessages: boolean
}

@Component({
    selector: 'app-conversation',
    providers: [ConversationService],
    styleUrls: ['./conversation.styles.css'],
    template: `
        <h5>{{ 'CONVERSATION.headline' | translate }}</h5>
        <div class="card">
            <div class="flex">
                <div class="col-12 md:col-6 xl:col-4">
                    <app-conversation-filter
                        [onFilterInput]="onFilterInput.bind(this)"
                        [onResetFilterInput]="onResetFilterInput.bind(this)"
                    ></app-conversation-filter>
                </div>
                <div class="col-12 md:col-6 xl:col-8">
                    <div class="conversation-meta-data">
                        <app-conversation-participant-display
                            [selectedParticipantList]="selectedParticipants"
                            [onRemoveParticipant]="
                                onRemoveParticipant.bind(this)
                            "
                        ></app-conversation-participant-display>
                        <app-conversation-title
                            [conversationTitle]="conversationTitle"
                            [selectedParticipants]="selectedParticipants"
                            [titleInput]="titleInput"
                            [conversationId]="conversationId"
                            [onTitleInput]="onTitleInput.bind(this)"
                            [titleEditMode]="titleEditMode"
                            [onEnableTitleEditMode]="
                                onEnableTitleEditMode.bind(this)
                            "
                            [onAbortTitleEditMode]="
                                onAbortTitleEditMode.bind(this)
                            "
                            [onChangeTitle]="onChangeTitle.bind(this)"
                        ></app-conversation-title>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="col-12 md:col-6 xl:col-4">
                    <app-conversation-list
                        [conversationId]="conversationId"
                        [conversationList]="conversationList"
                        [conversationNameList]="conversationNameList"
                        [filteredTitleList]="filteredTitleList"
                        [filteredParticipantList]="filteredParticipantList"
                        [isFiltered]="isFiltered"
                        [onSelectConversation]="onConversationSelect.bind(this)"
                        [onConversationDelete]="onConversationDelete.bind(this)"
                    ></app-conversation-list>
                </div>
                <div class="col-12 md:col-6 xl:col-8">
                    <app-conversation-chat
                        [messageList]="messageList"
                        [currentUserOauthId]="currentUser"
                        [isGroupChat]="isGroupChat"
                        [shouldScrollToBottom]="shouldScrollToBottom"
                        [onSendMessage]="onSendMessage.bind(this)"
                        [onDeleteMessage]="onDeleteMessage.bind(this)"
                        [onCameraButtonClick]="onCameraButtonClick.bind(this)"
                    ></app-conversation-chat>
                </div>
            </div>
        </div>
        <button class="p-button-success" pButton (click)="onOpenModal()">
            {{ 'CONVERSATION.NEW-CONVERSATION.label' | translate }}
        </button>
        <new-conversation-modal
            [currentFilterInput]="filterInput"
            [selectedParticipants]="selectedParticipants"
            [display]="display"
            [titleInput]="titleInput"
            [onTitleInput]="onTitleInput.bind(this)"
            [onConfirmSelection]="onConfirmSelection.bind(this)"
            [onRemoveParticipant]="onRemoveParticipant.bind(this)"
            [onAddToParticipantList]="onAddToParticipantList.bind(this)"
        ></new-conversation-modal>
    `,
})
export class ConversationComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    conversationNameList: ConversationListItem[] = [];
    filteredTitleList: ConversationListItem[] = [];
    filteredParticipantList: ConversationListItem[] = [];

    conversationList: ConversationDto[] = [];
    messageList: MessageDto[] = [];
    customerList: CustomerNamesForMessengerDto[] = [];
    projectsList: ProjectNamesForMessengerDto[] = [];

    modelParticipantList: ParticipantResponseDto[] = [];
    customerParticipantList: ParticipantResponseDto[] = [];
    projectParticipantList: ParticipantResponseDto[] = [];
    selectedParticipants: ParticipantResponseDto[] = [];

    conversationId: string = '';
    conversationTitle: string = '';
    titleInput: string = '';
    currentUser: string = '';
    filterInput: string = '';

    isFiltered: boolean = false;
    display: boolean = false;
    preSelection: boolean = false;
    isGroupChat: boolean = false;
    titleEditMode: boolean = false;

    showModelSearch: boolean = false;
    shouldScrollToBottom: boolean = false;

    cameraIsActive: boolean = false;

    constructor(
        private conversationService: ConversationService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.conversationService
                .getConversationList()
                .subscribe((conversationList: ConversationDto[]) => {
                    this.conversationList = conversationList;
                    if (Object.keys(conversationList).length > 0) {
                        this.initializeData(this.conversationList);
                        if (this.preSelection) {
                            this.onConversationSelect(conversationList[0]);
                            this.preSelection = false;
                        }
                    }
                }),

            this.conversationService
                .fetchMessages()
                .subscribe((messageResponseDtoList: MessageDto[]) => {
                    this.messageList = messageResponseDtoList;
                }),

            this.conversationService
                .fetchCustomerNames()
                .subscribe(
                    (
                        customerResponseDtoList: CustomerNamesForMessengerDto[]
                    ) => {
                        this.customerList = customerResponseDtoList;
                    }
                ),

            this.conversationService
                .fetchProjectNames()
                .subscribe(
                    (projectResponseDtoList: ProjectNamesForMessengerDto[]) => {
                        this.projectsList = projectResponseDtoList;
                    }
                ),

            this.conversationService
                .getCurrentUser()
                .subscribe((currentUser: string) => {
                    this.currentUser = currentUser;
                }),

            this.conversationService
                .fetchModelParticipants()
                .subscribe(
                    (participantResponseDtoList: ParticipantResponseDto[]) => {
                        this.modelParticipantList = participantResponseDtoList;
                    }
                ),

            this.conversationService
                .fetchCustomerParticipants()
                .subscribe(
                    (participantResponseDtoList: ParticipantResponseDto[]) => {
                        this.customerParticipantList =
                            participantResponseDtoList;
                    }
                ),

            this.conversationService
                .fetchProjectParticipants()
                .subscribe(
                    (participantResponseDtoList: ParticipantResponseDto[]) => {
                        this.projectParticipantList =
                            participantResponseDtoList;
                    }
                )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    initializeData(conversationList: ConversationDto[]) {
        this.conversationNameList =
            this.convertConversationList(conversationList);

        // Sort the conversation list with new messages on top
        this.conversationNameList.sort((a, b) => {
            if (a.hasNewMessages && !b.hasNewMessages) {
                return -1;
            } else if (!a.hasNewMessages && b.hasNewMessages) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    convertConversationList(conversationList: ConversationDto[]) {
        return conversationList.map((c) => {
            return {
                conversationId: c.conversationId || '',
                conversationTitle: c.conversationTitle || '',
                participantNames:
                    c.participantResponseDtoList?.map((p) => {
                        return p.participantFirstName || '';
                    }) || [],
                isGroupChat: c.isGroupChat || false,
                hasNewMessages: c.hasNewMessage || false,
            };
        });
    }

    onConversationSelect(event: any) {
        if (event.conversationId == this.conversationId) {
            this.conversationId = '';
            this.conversationTitle = '';
            this.messageList = [];
            this.onAbortTitleEditMode();
            return;
        }
        this.selectedParticipants = [];
        this.conversationId = event.conversationId;
        this.conversationTitle = event.conversationTitle;
        this.conversationService.getMessagesByConversationId(
            event.conversationId
        );
        this.shouldScrollToBottom = true;
        this.isGroupChat = event.isGroupChat;
        this.onAbortTitleEditMode();

        if (event.hasNewMessages) {
            setTimeout(() => {
                this.conversationService.updateMessageTracker(
                    this.conversationId
                );
            }, 2000);
        }
    }

    onSendMessage(newMessage: string, images: Array<Blob>) {
        if (this.conversationId == '') {
            this.onStartNewConversation(newMessage);
            return;
        }
        return this.conversationService.sendMessage(
            this.conversationId,
            newMessage,
            images
        );
    }

    onStartNewConversation(newMessage: string) {
        let newConversation: CreateConversationRequestDto = {
            conversationTitle: this.titleInput,
            createMessageRequestDto: {
                message: newMessage,
            },
            participantIds: this.selectedParticipants.map(sp => sp.participantId),
        };

        this.conversationService.createConversation(newConversation);
        this.selectedParticipants = [];
        this.titleInput = '';
        this.preSelection = true;
    }

    onConversationDelete(conversationId: string) {
        this.conversationService.deleteConversation(conversationId);
    }

    onDeleteMessage(messageId: string) {
        this.conversationService.deleteMessage(this.conversationId, messageId);
    }

    onFilterInput(input: string) {
        if (input) {
            const lowerCaseInput = input.toLowerCase();
            this.filterInput = lowerCaseInput;

            const titleMatches = this.conversationList.filter(
                (conversation) => {
                    return conversation.conversationTitle
                        ?.toLowerCase()
                        .includes(lowerCaseInput);
                }
            );

            const participantMatches = this.conversationList.filter(
                (conversation) => {
                    return conversation.participantResponseDtoList?.some(
                        (participant) => {
                            const fullName =
                                `${participant.participantFirstName} ${participant.participantLastName}`.toLowerCase();
                            return fullName.includes(lowerCaseInput);
                        }
                    );
                }
            );

            this.filteredTitleList = this.convertConversationList(titleMatches);

            this.filteredParticipantList =
                this.convertConversationList(participantMatches);

            this.isFiltered = true;
        } else {
            this.filteredParticipantList = [];
            this.filteredTitleList = [];
            this.showModelSearch = false;
            this.isFiltered = false;
            this.filterInput = '';
        }
    }

    onRemoveParticipant(participant: ParticipantResponseDto) {
        this.selectedParticipants = this.selectedParticipants.filter(
            (p) => p.participantId !== participant.participantId
        );
    }

    onTitleInput(event: any) {
        this.titleInput = event.target.value;
    }

    onOpenModal() {
        this.conversationId = '';
        this.conversationTitle = '';
        this.messageList = [];
        this.selectedParticipants = [];
        this.onAbortTitleEditMode();
        this.display = !this.display;
    }

    onConfirmSelection() {
        this.display = false;
        if (this.titleInput == '') {
            this.titleInput = 'New Conversation';
        }
    }

    onAddToParticipantList(participant: ParticipantResponseDto) {
        if (this.selectedParticipants.includes(participant)) {
            return;
        }
        this.selectedParticipants.push(participant);
    }

    onResetFilterInput() {
        this.filterInput = '';
        this.isFiltered = false;
    }

    onEnableTitleEditMode() {
        this.titleEditMode = true;
        this.titleInput = this.conversationTitle;
    }

    onAbortTitleEditMode() {
        this.titleEditMode = false;
        this.titleInput = '';
    }

    onChangeTitle() {
        this.conversationService.changeConversationTitle(
            this.conversationId,
            this.titleInput
        );
        this.titleEditMode = false;
        this.conversationTitle = this.titleInput;
    }

    onCameraButtonClick() {
        this.cameraIsActive = true;
    }
}
