/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DemoPdfRequestDtoData
 */
export interface DemoPdfRequestDtoData {
    /**
     * 
     * @type {string}
     * @memberof DemoPdfRequestDtoData
     */
    name?: string;
}

/**
 * Check if a given object implements the DemoPdfRequestDtoData interface.
 */
export function instanceOfDemoPdfRequestDtoData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemoPdfRequestDtoDataFromJSON(json: any): DemoPdfRequestDtoData {
    return DemoPdfRequestDtoDataFromJSONTyped(json, false);
}

export function DemoPdfRequestDtoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemoPdfRequestDtoData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function DemoPdfRequestDtoDataToJSON(value?: DemoPdfRequestDtoData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}

