/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectRoleForSelectionDto
 */
export interface ProjectRoleForSelectionDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleForSelectionDto
     */
    projectRoleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleForSelectionDto
     */
    name: string;
}

/**
 * Check if a given object implements the ProjectRoleForSelectionDto interface.
 */
export function instanceOfProjectRoleForSelectionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectRoleId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ProjectRoleForSelectionDtoFromJSON(json: any): ProjectRoleForSelectionDto {
    return ProjectRoleForSelectionDtoFromJSONTyped(json, false);
}

export function ProjectRoleForSelectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleForSelectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectRoleId': json['projectRoleId'],
        'name': json['name'],
    };
}

export function ProjectRoleForSelectionDtoToJSON(value?: ProjectRoleForSelectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectRoleId': value.projectRoleId,
        'name': value.name,
    };
}

