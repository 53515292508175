/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectName
 */
export interface ProjectName {
    /**
     * 
     * @type {string}
     * @memberof ProjectName
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectName
     */
    workingTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectName
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectName
     */
    description?: string;
}

/**
 * Check if a given object implements the ProjectName interface.
 */
export function instanceOfProjectName(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workingTitle" in value;

    return isInstance;
}

export function ProjectNameFromJSON(json: any): ProjectName {
    return ProjectNameFromJSONTyped(json, false);
}

export function ProjectNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'workingTitle': json['workingTitle'],
        'projectNumber': !exists(json, 'projectNumber') ? undefined : json['projectNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ProjectNameToJSON(value?: ProjectName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'workingTitle': value.workingTitle,
        'projectNumber': value.projectNumber,
        'description': value.description,
    };
}

