/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeleteMediaRequestDto,
  DoDeleteActionResult,
  DoUploadActionResult,
  ModelPortfolioDto,
  ModelPortfolioImagesChangeOrderDto,
  ModelPortfolioVideosChangeOrderDto,
  PortfolioImage,
  PortfolioVideo,
  VideoUploadResult,
} from '../models';
import {
    DeleteMediaRequestDtoFromJSON,
    DeleteMediaRequestDtoToJSON,
    DoDeleteActionResultFromJSON,
    DoDeleteActionResultToJSON,
    DoUploadActionResultFromJSON,
    DoUploadActionResultToJSON,
    ModelPortfolioDtoFromJSON,
    ModelPortfolioDtoToJSON,
    ModelPortfolioImagesChangeOrderDtoFromJSON,
    ModelPortfolioImagesChangeOrderDtoToJSON,
    ModelPortfolioVideosChangeOrderDtoFromJSON,
    ModelPortfolioVideosChangeOrderDtoToJSON,
    PortfolioImageFromJSON,
    PortfolioImageToJSON,
    PortfolioVideoFromJSON,
    PortfolioVideoToJSON,
    VideoUploadResultFromJSON,
    VideoUploadResultToJSON,
} from '../models';

export interface ChangeImageOrderRequest {
    modelId: string;
    imageType: ChangeImageOrderImageTypeEnum;
    modelPortfolioImagesChangeOrderDto: ModelPortfolioImagesChangeOrderDto;
}

export interface ChangeVideoOrderRequest {
    modelId: string;
    modelPortfolioVideosChangeOrderDto: ModelPortfolioVideosChangeOrderDto;
}

export interface DeleteMasterImagesRequest {
    modelId: string;
    deleteMediaRequestDto: DeleteMediaRequestDto;
}

export interface DeletePolaroidImagesRequest {
    modelId: string;
    deleteMediaRequestDto: DeleteMediaRequestDto;
}

export interface DeleteSpecialImagesRequest {
    modelId: string;
    deleteMediaRequestDto: DeleteMediaRequestDto;
}

export interface DeleteVideosRequest {
    modelId: string;
    deleteMediaRequestDto: DeleteMediaRequestDto;
}

export interface DeleteVitaImagesRequest {
    modelId: string;
    deleteMediaRequestDto: DeleteMediaRequestDto;
}

export interface GetModelPortfolioImagesRequest {
    modelId: string;
}

export interface PublishImageOrderRequest {
    modelId: string;
    imageType: PublishImageOrderImageTypeEnum;
}

export interface PublishVideoOrderRequest {
    modelId: string;
}

export interface UploadMasterImagesRequest {
    modelId: string;
    images: Array<Blob>;
}

export interface UploadPolaroidImagesRequest {
    modelId: string;
    images: Array<Blob>;
}

export interface UploadSpecialImagesRequest {
    modelId: string;
    images: Array<Blob>;
}

export interface UploadVideosRequest {
    modelId: string;
    videos: Array<Blob>;
}

export interface UploadVitaImagesRequest {
    modelId: string;
    images: Array<Blob>;
}

/**
 * 
 */
export class PortfolioControllerApi extends runtime.BaseAPI {

    /**
     */
    async changeImageOrderRaw(requestParameters: ChangeImageOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioImage>>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeImageOrder.');
        }

        if (requestParameters.imageType === null || requestParameters.imageType === undefined) {
            throw new runtime.RequiredError('imageType','Required parameter requestParameters.imageType was null or undefined when calling changeImageOrder.');
        }

        if (requestParameters.modelPortfolioImagesChangeOrderDto === null || requestParameters.modelPortfolioImagesChangeOrderDto === undefined) {
            throw new runtime.RequiredError('modelPortfolioImagesChangeOrderDto','Required parameter requestParameters.modelPortfolioImagesChangeOrderDto was null or undefined when calling changeImageOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/images/{imageType}/change-order`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"imageType"}}`, encodeURIComponent(String(requestParameters.imageType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelPortfolioImagesChangeOrderDtoToJSON(requestParameters.modelPortfolioImagesChangeOrderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioImageFromJSON));
    }

    /**
     */
    async changeImageOrder(requestParameters: ChangeImageOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioImage>> {
        const response = await this.changeImageOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeVideoOrderRaw(requestParameters: ChangeVideoOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioVideo>>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeVideoOrder.');
        }

        if (requestParameters.modelPortfolioVideosChangeOrderDto === null || requestParameters.modelPortfolioVideosChangeOrderDto === undefined) {
            throw new runtime.RequiredError('modelPortfolioVideosChangeOrderDto','Required parameter requestParameters.modelPortfolioVideosChangeOrderDto was null or undefined when calling changeVideoOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/videos/change-order`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelPortfolioVideosChangeOrderDtoToJSON(requestParameters.modelPortfolioVideosChangeOrderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioVideoFromJSON));
    }

    /**
     */
    async changeVideoOrder(requestParameters: ChangeVideoOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioVideo>> {
        const response = await this.changeVideoOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMasterImagesRaw(requestParameters: DeleteMasterImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoDeleteActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteMasterImages.');
        }

        if (requestParameters.deleteMediaRequestDto === null || requestParameters.deleteMediaRequestDto === undefined) {
            throw new runtime.RequiredError('deleteMediaRequestDto','Required parameter requestParameters.deleteMediaRequestDto was null or undefined when calling deleteMasterImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/master/delete`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteMediaRequestDtoToJSON(requestParameters.deleteMediaRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoDeleteActionResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteMasterImages(requestParameters: DeleteMasterImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoDeleteActionResult> {
        const response = await this.deleteMasterImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePolaroidImagesRaw(requestParameters: DeletePolaroidImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoDeleteActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deletePolaroidImages.');
        }

        if (requestParameters.deleteMediaRequestDto === null || requestParameters.deleteMediaRequestDto === undefined) {
            throw new runtime.RequiredError('deleteMediaRequestDto','Required parameter requestParameters.deleteMediaRequestDto was null or undefined when calling deletePolaroidImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/polaroid/delete`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteMediaRequestDtoToJSON(requestParameters.deleteMediaRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoDeleteActionResultFromJSON(jsonValue));
    }

    /**
     */
    async deletePolaroidImages(requestParameters: DeletePolaroidImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoDeleteActionResult> {
        const response = await this.deletePolaroidImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSpecialImagesRaw(requestParameters: DeleteSpecialImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoDeleteActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteSpecialImages.');
        }

        if (requestParameters.deleteMediaRequestDto === null || requestParameters.deleteMediaRequestDto === undefined) {
            throw new runtime.RequiredError('deleteMediaRequestDto','Required parameter requestParameters.deleteMediaRequestDto was null or undefined when calling deleteSpecialImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/special/delete`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteMediaRequestDtoToJSON(requestParameters.deleteMediaRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoDeleteActionResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteSpecialImages(requestParameters: DeleteSpecialImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoDeleteActionResult> {
        const response = await this.deleteSpecialImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteVideosRaw(requestParameters: DeleteVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoDeleteActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteVideos.');
        }

        if (requestParameters.deleteMediaRequestDto === null || requestParameters.deleteMediaRequestDto === undefined) {
            throw new runtime.RequiredError('deleteMediaRequestDto','Required parameter requestParameters.deleteMediaRequestDto was null or undefined when calling deleteVideos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/video/delete`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteMediaRequestDtoToJSON(requestParameters.deleteMediaRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoDeleteActionResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteVideos(requestParameters: DeleteVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoDeleteActionResult> {
        const response = await this.deleteVideosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteVitaImagesRaw(requestParameters: DeleteVitaImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoDeleteActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteVitaImages.');
        }

        if (requestParameters.deleteMediaRequestDto === null || requestParameters.deleteMediaRequestDto === undefined) {
            throw new runtime.RequiredError('deleteMediaRequestDto','Required parameter requestParameters.deleteMediaRequestDto was null or undefined when calling deleteVitaImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/portfolio/{modelId}/vita/delete`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteMediaRequestDtoToJSON(requestParameters.deleteMediaRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoDeleteActionResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteVitaImages(requestParameters: DeleteVitaImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoDeleteActionResult> {
        const response = await this.deleteVitaImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelPortfolioImagesRaw(requestParameters: GetModelPortfolioImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelPortfolioDto>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelPortfolioImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portfolio/{modelId}/images`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelPortfolioDtoFromJSON(jsonValue));
    }

    /**
     */
    async getModelPortfolioImages(requestParameters: GetModelPortfolioImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelPortfolioDto> {
        const response = await this.getModelPortfolioImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishImageOrderRaw(requestParameters: PublishImageOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioImage>>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling publishImageOrder.');
        }

        if (requestParameters.imageType === null || requestParameters.imageType === undefined) {
            throw new runtime.RequiredError('imageType','Required parameter requestParameters.imageType was null or undefined when calling publishImageOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portfolio/{modelId}/images/{imageType}/publish-order`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"imageType"}}`, encodeURIComponent(String(requestParameters.imageType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioImageFromJSON));
    }

    /**
     */
    async publishImageOrder(requestParameters: PublishImageOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioImage>> {
        const response = await this.publishImageOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishVideoOrderRaw(requestParameters: PublishVideoOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioVideo>>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling publishVideoOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portfolio/{modelId}/videos/publish-order`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioVideoFromJSON));
    }

    /**
     */
    async publishVideoOrder(requestParameters: PublishVideoOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioVideo>> {
        const response = await this.publishVideoOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadMasterImagesRaw(requestParameters: UploadMasterImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoUploadActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling uploadMasterImages.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling uploadMasterImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/api/portfolio/{modelId}/master/upload`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoUploadActionResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadMasterImages(requestParameters: UploadMasterImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoUploadActionResult> {
        const response = await this.uploadMasterImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadPolaroidImagesRaw(requestParameters: UploadPolaroidImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoUploadActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling uploadPolaroidImages.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling uploadPolaroidImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/api/portfolio/{modelId}/polaroid/upload`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoUploadActionResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadPolaroidImages(requestParameters: UploadPolaroidImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoUploadActionResult> {
        const response = await this.uploadPolaroidImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadSpecialImagesRaw(requestParameters: UploadSpecialImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoUploadActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling uploadSpecialImages.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling uploadSpecialImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/api/portfolio/{modelId}/special/upload`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoUploadActionResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadSpecialImages(requestParameters: UploadSpecialImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoUploadActionResult> {
        const response = await this.uploadSpecialImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadVideosRaw(requestParameters: UploadVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VideoUploadResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling uploadVideos.');
        }

        if (requestParameters.videos === null || requestParameters.videos === undefined) {
            throw new runtime.RequiredError('videos','Required parameter requestParameters.videos was null or undefined when calling uploadVideos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.videos) {
            requestParameters.videos.forEach((element) => {
                formParams.append('videos', element as any);
            })
        }

        const response = await this.request({
            path: `/api/portfolio/{modelId}/video/upload`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoUploadResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadVideos(requestParameters: UploadVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VideoUploadResult> {
        const response = await this.uploadVideosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadVitaImagesRaw(requestParameters: UploadVitaImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoUploadActionResult>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling uploadVitaImages.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling uploadVitaImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/api/portfolio/{modelId}/vita/upload`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoUploadActionResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadVitaImages(requestParameters: UploadVitaImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoUploadActionResult> {
        const response = await this.uploadVitaImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ChangeImageOrderImageTypeEnum = {
    Master: 'MASTER',
    Polaroid: 'POLAROID',
    Special: 'SPECIAL',
    Vita: 'VITA',
    Video: 'VIDEO',
    NotSupported: 'NOT_SUPPORTED'
} as const;
export type ChangeImageOrderImageTypeEnum = typeof ChangeImageOrderImageTypeEnum[keyof typeof ChangeImageOrderImageTypeEnum];
/**
 * @export
 */
export const PublishImageOrderImageTypeEnum = {
    Master: 'MASTER',
    Polaroid: 'POLAROID',
    Special: 'SPECIAL',
    Vita: 'VITA',
    Video: 'VIDEO',
    NotSupported: 'NOT_SUPPORTED'
} as const;
export type PublishImageOrderImageTypeEnum = typeof PublishImageOrderImageTypeEnum[keyof typeof PublishImageOrderImageTypeEnum];
