import {Injectable} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {AuthenticationControllerApi, UserInformationRequestDto} from "@brody-bookings/api";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    private frontendUser: BehaviorSubject<FrontendUser | null> = new BehaviorSubject<FrontendUser | null>(null);
    frontendUser$ = this.frontendUser.asObservable();

    constructor(
        public authService: AuthService,
        public authenticationControllerApi: AuthenticationControllerApi,
        public router: Router
    ) {
    }


    getFrontendUser() {
        if (!this.frontendUser.value) {
            this.loadFrontendUser();
        }
        return this.frontendUser$;
    }

    handleLogout(): void {
        this.authService?.logout({
                logoutParams: {returnTo: document.location.origin}
            }
        );
    }

    handleLogin(): void {
        this.authService?.loginWithRedirect({
            authorizationParams: {
                prompt: 'login',
            }
        });
    }

    handleAuthCallback(): void {
        this.authService.user$.subscribe((user) => {
            if (user) {
                this.loadFrontendUser();

                const userInformationRequestDto: UserInformationRequestDto = {
                    userFirstName: user.given_name,
                    userLastName: user.family_name,
                    userEmail: user.email,
                    userPhone: user.phone_number,
                    oauthId: user.sub,
                }
                this.authenticationControllerApi.saveUser({userInformationRequestDto}).then((res) => {
                    this.saveFrontendUser({
                        userId: res.oauthId!,
                        userFirstName: res.userFirstName,
                        userLastName: res.userLastName,
                        userEmail: res.userEmail,
                        userRoles: res.userRoles.map((ur) => ur.toString())
                    })
                }).catch(() => {
                    localStorage.removeItem("accessToken");
                    this.deleteFrontendUser();
                })
            } else {
                this.deleteFrontendUser();
            }
        });
    }

    loadFrontendUser() {
        const savedUser = localStorage.getItem('frontendUser');
        this.frontendUser.next(savedUser ? JSON.parse(savedUser) : null);
    }

    saveFrontendUser(frontendUser: FrontendUser): void {
        const current = JSON.parse(localStorage.getItem('frontendUser') || '{}');
        if (current !== frontendUser) {
            localStorage.setItem('frontendUser', JSON.stringify(frontendUser));
            this.frontendUser.next(frontendUser);
        }
    }

    deleteFrontendUser(): void {
        localStorage.removeItem('frontendUser');
        this.frontendUser.next(null);
    }

}

export type FrontendUser = {
    userId: string,
    userFirstName: string,
    userLastName: string,
    userEmail: string,
    userRoles: string[]
}

