/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerEmployeeForMessenger
 */
export interface CustomerEmployeeForMessenger {
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    employeeFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    employeeLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    employeeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    employeePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeForMessenger
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the CustomerEmployeeForMessenger interface.
 */
export function instanceOfCustomerEmployeeForMessenger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerEmployeeForMessengerFromJSON(json: any): CustomerEmployeeForMessenger {
    return CustomerEmployeeForMessengerFromJSONTyped(json, false);
}

export function CustomerEmployeeForMessengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEmployeeForMessenger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'employeeFirstName': !exists(json, 'employeeFirstName') ? undefined : json['employeeFirstName'],
        'employeeLastName': !exists(json, 'employeeLastName') ? undefined : json['employeeLastName'],
        'employeeEmail': !exists(json, 'employeeEmail') ? undefined : json['employeeEmail'],
        'employeePhone': !exists(json, 'employeePhone') ? undefined : json['employeePhone'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function CustomerEmployeeForMessengerToJSON(value?: CustomerEmployeeForMessenger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'employeeFirstName': value.employeeFirstName,
        'employeeLastName': value.employeeLastName,
        'employeeEmail': value.employeeEmail,
        'employeePhone': value.employeePhone,
        'oauthId': value.oauthId,
    };
}

