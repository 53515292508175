/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface IdentificationFormValuesDto
 */
export interface IdentificationFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof IdentificationFormValuesDto
     */
    nationality?: AdminValue;
    /**
     * 
     * @type {Date}
     * @memberof IdentificationFormValuesDto
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof IdentificationFormValuesDto
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentificationFormValuesDto
     */
    authority?: string;
    /**
     * 
     * @type {Date}
     * @memberof IdentificationFormValuesDto
     */
    passportValidUntil?: Date;
    /**
     * 
     * @type {AdminValue}
     * @memberof IdentificationFormValuesDto
     */
    ethnicityFather?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof IdentificationFormValuesDto
     */
    ethnicityMother?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof IdentificationFormValuesDto
     */
    nationalityOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof IdentificationFormValuesDto
     */
    ethnicityOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the IdentificationFormValuesDto interface.
 */
export function instanceOfIdentificationFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentificationFormValuesDtoFromJSON(json: any): IdentificationFormValuesDto {
    return IdentificationFormValuesDtoFromJSONTyped(json, false);
}

export function IdentificationFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentificationFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nationality': !exists(json, 'nationality') ? undefined : AdminValueFromJSON(json['nationality']),
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'passportNumber': !exists(json, 'passportNumber') ? undefined : json['passportNumber'],
        'authority': !exists(json, 'authority') ? undefined : json['authority'],
        'passportValidUntil': !exists(json, 'passportValidUntil') ? undefined : (new Date(json['passportValidUntil'])),
        'ethnicityFather': !exists(json, 'ethnicityFather') ? undefined : AdminValueFromJSON(json['ethnicityFather']),
        'ethnicityMother': !exists(json, 'ethnicityMother') ? undefined : AdminValueFromJSON(json['ethnicityMother']),
        'nationalityOptions': !exists(json, 'nationalityOptions') ? undefined : ((json['nationalityOptions'] as Array<any>).map(AdminValueFromJSON)),
        'ethnicityOptions': !exists(json, 'ethnicityOptions') ? undefined : ((json['ethnicityOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function IdentificationFormValuesDtoToJSON(value?: IdentificationFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nationality': AdminValueToJSON(value.nationality),
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'passportNumber': value.passportNumber,
        'authority': value.authority,
        'passportValidUntil': value.passportValidUntil === undefined ? undefined : (value.passportValidUntil.toISOString().substr(0,10)),
        'ethnicityFather': AdminValueToJSON(value.ethnicityFather),
        'ethnicityMother': AdminValueToJSON(value.ethnicityMother),
        'nationalityOptions': value.nationalityOptions === undefined ? undefined : ((value.nationalityOptions as Array<any>).map(AdminValueToJSON)),
        'ethnicityOptions': value.ethnicityOptions === undefined ? undefined : ((value.ethnicityOptions as Array<any>).map(AdminValueToJSON)),
    };
}

