/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingModelFormValuesDto
 */
export interface BookingModelFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    travelCosts?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    bookingStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingModelFormValuesDto
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    bookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    bookingCreatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModelFormValuesDto
     */
    end?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingModelFormValuesDto
     */
    bookingStatusOptions?: Array<string>;
}

/**
 * Check if a given object implements the BookingModelFormValuesDto interface.
 */
export function instanceOfBookingModelFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingModelFormValuesDtoFromJSON(json: any): BookingModelFormValuesDto {
    return BookingModelFormValuesDtoFromJSONTyped(json, false);
}

export function BookingModelFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingModelFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelCosts': !exists(json, 'travelCosts') ? undefined : json['travelCosts'],
        'bookingStatus': !exists(json, 'bookingStatus') ? undefined : json['bookingStatus'],
        'isConfirmed': !exists(json, 'isConfirmed') ? undefined : json['isConfirmed'],
        'bookingId': !exists(json, 'bookingId') ? undefined : json['bookingId'],
        'bookingCreatedAt': !exists(json, 'bookingCreatedAt') ? undefined : json['bookingCreatedAt'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'bookingStatusOptions': !exists(json, 'bookingStatusOptions') ? undefined : json['bookingStatusOptions'],
    };
}

export function BookingModelFormValuesDtoToJSON(value?: BookingModelFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelCosts': value.travelCosts,
        'bookingStatus': value.bookingStatus,
        'isConfirmed': value.isConfirmed,
        'bookingId': value.bookingId,
        'bookingCreatedAt': value.bookingCreatedAt,
        'start': value.start,
        'end': value.end,
        'bookingStatusOptions': value.bookingStatusOptions,
    };
}

