/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectCustomerEmployee } from './ProjectCustomerEmployee';
import {
    ProjectCustomerEmployeeFromJSON,
    ProjectCustomerEmployeeFromJSONTyped,
    ProjectCustomerEmployeeToJSON,
} from './ProjectCustomerEmployee';

/**
 * 
 * @export
 * @interface ProjectProducer
 */
export interface ProjectProducer {
    /**
     * 
     * @type {string}
     * @memberof ProjectProducer
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducer
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducer
     */
    note?: string;
    /**
     * 
     * @type {Array<ProjectCustomerEmployee>}
     * @memberof ProjectProducer
     */
    responsiblePersons?: Array<ProjectCustomerEmployee>;
    /**
     * 
     * @type {string}
     * @memberof ProjectProducer
     */
    selectedEmployee?: string;
}

/**
 * Check if a given object implements the ProjectProducer interface.
 */
export function instanceOfProjectProducer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "customerName" in value;

    return isInstance;
}

export function ProjectProducerFromJSON(json: any): ProjectProducer {
    return ProjectProducerFromJSONTyped(json, false);
}

export function ProjectProducerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectProducer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'customerName': json['customerName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'responsiblePersons': !exists(json, 'responsiblePersons') ? undefined : ((json['responsiblePersons'] as Array<any>).map(ProjectCustomerEmployeeFromJSON)),
        'selectedEmployee': !exists(json, 'selectedEmployee') ? undefined : json['selectedEmployee'],
    };
}

export function ProjectProducerToJSON(value?: ProjectProducer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'note': value.note,
        'responsiblePersons': value.responsiblePersons === undefined ? undefined : ((value.responsiblePersons as Array<any>).map(ProjectCustomerEmployeeToJSON)),
        'selectedEmployee': value.selectedEmployee,
    };
}

