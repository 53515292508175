/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelPortfolioVideosChangeOrderItemDto } from './ModelPortfolioVideosChangeOrderItemDto';
import {
    ModelPortfolioVideosChangeOrderItemDtoFromJSON,
    ModelPortfolioVideosChangeOrderItemDtoFromJSONTyped,
    ModelPortfolioVideosChangeOrderItemDtoToJSON,
} from './ModelPortfolioVideosChangeOrderItemDto';

/**
 * 
 * @export
 * @interface ModelPortfolioVideosChangeOrderDto
 */
export interface ModelPortfolioVideosChangeOrderDto {
    /**
     * 
     * @type {Array<ModelPortfolioVideosChangeOrderItemDto>}
     * @memberof ModelPortfolioVideosChangeOrderDto
     */
    videos?: Array<ModelPortfolioVideosChangeOrderItemDto>;
}

/**
 * Check if a given object implements the ModelPortfolioVideosChangeOrderDto interface.
 */
export function instanceOfModelPortfolioVideosChangeOrderDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPortfolioVideosChangeOrderDtoFromJSON(json: any): ModelPortfolioVideosChangeOrderDto {
    return ModelPortfolioVideosChangeOrderDtoFromJSONTyped(json, false);
}

export function ModelPortfolioVideosChangeOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPortfolioVideosChangeOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videos': !exists(json, 'videos') ? undefined : ((json['videos'] as Array<any>).map(ModelPortfolioVideosChangeOrderItemDtoFromJSON)),
    };
}

export function ModelPortfolioVideosChangeOrderDtoToJSON(value?: ModelPortfolioVideosChangeOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videos': value.videos === undefined ? undefined : ((value.videos as Array<any>).map(ModelPortfolioVideosChangeOrderItemDtoToJSON)),
    };
}

