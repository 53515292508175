/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface ChangeBillingAddressCommand
 */
export interface ChangeBillingAddressCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeBillingAddressCommand
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {Address}
     * @memberof ChangeBillingAddressCommand
     */
    newBillingAddress?: Address;
}

/**
 * Check if a given object implements the ChangeBillingAddressCommand interface.
 */
export function instanceOfChangeBillingAddressCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeBillingAddressCommandFromJSON(json: any): ChangeBillingAddressCommand {
    return ChangeBillingAddressCommandFromJSONTyped(json, false);
}

export function ChangeBillingAddressCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeBillingAddressCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'newBillingAddress': !exists(json, 'newBillingAddress') ? undefined : AddressFromJSON(json['newBillingAddress']),
    };
}

export function ChangeBillingAddressCommandToJSON(value?: ChangeBillingAddressCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'newBillingAddress': AddressToJSON(value.newBillingAddress),
    };
}

