/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { AppointmentCreator } from './AppointmentCreator';
import {
    AppointmentCreatorFromJSON,
    AppointmentCreatorFromJSONTyped,
    AppointmentCreatorToJSON,
} from './AppointmentCreator';

/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    status?: AppointmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    type?: AppointmentTypeEnum;
    /**
     * 
     * @type {AdminValue}
     * @memberof Appointment
     */
    requestTimeFrameOption?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    fromAsIsoString?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    toAsIsoString?: string;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    createdAt?: Date;
    /**
     * 
     * @type {AppointmentCreator}
     * @memberof Appointment
     */
    creator?: AppointmentCreator;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    answeredAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    projectRoleId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    confirmedRequest?: boolean;
}


/**
 * @export
 */
export const AppointmentStatusEnum = {
    Requested: 'REQUESTED',
    Maybe: 'MAYBE',
    Confirmed: 'CONFIRMED',
    Canceled: 'CANCELED'
} as const;
export type AppointmentStatusEnum = typeof AppointmentStatusEnum[keyof typeof AppointmentStatusEnum];

/**
 * @export
 */
export const AppointmentTypeEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED'
} as const;
export type AppointmentTypeEnum = typeof AppointmentTypeEnum[keyof typeof AppointmentTypeEnum];


/**
 * Check if a given object implements the Appointment interface.
 */
export function instanceOfAppointment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentFromJSON(json: any): Appointment {
    return AppointmentFromJSONTyped(json, false);
}

export function AppointmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Appointment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': !exists(json, 'appointmentId') ? undefined : json['appointmentId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'requestTimeFrameOption': !exists(json, 'requestTimeFrameOption') ? undefined : AdminValueFromJSON(json['requestTimeFrameOption']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'fromAsIsoString': !exists(json, 'fromAsIsoString') ? undefined : json['fromAsIsoString'],
        'toAsIsoString': !exists(json, 'toAsIsoString') ? undefined : json['toAsIsoString'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'creator': !exists(json, 'creator') ? undefined : AppointmentCreatorFromJSON(json['creator']),
        'answeredAt': !exists(json, 'answeredAt') ? undefined : (new Date(json['answeredAt'])),
        'projectRoleId': !exists(json, 'projectRoleId') ? undefined : json['projectRoleId'],
        'confirmedRequest': !exists(json, 'confirmedRequest') ? undefined : json['confirmedRequest'],
    };
}

export function AppointmentToJSON(value?: Appointment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'modelId': value.modelId,
        'projectId': value.projectId,
        'status': value.status,
        'type': value.type,
        'requestTimeFrameOption': AdminValueToJSON(value.requestTimeFrameOption),
        'title': value.title,
        'description': value.description,
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'fromAsIsoString': value.fromAsIsoString,
        'toAsIsoString': value.toAsIsoString,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'creator': AppointmentCreatorToJSON(value.creator),
        'answeredAt': value.answeredAt === undefined ? undefined : (value.answeredAt.toISOString()),
        'projectRoleId': value.projectRoleId,
        'confirmedRequest': value.confirmedRequest,
    };
}

