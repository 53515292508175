/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddProductionDetailsEventDto
 */
export interface AddProductionDetailsEventDto {
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    productionEventName: string;
    /**
     * 
     * @type {Date}
     * @memberof AddProductionDetailsEventDto
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof AddProductionDetailsEventDto
     */
    motif?: string;
}

/**
 * Check if a given object implements the AddProductionDetailsEventDto interface.
 */
export function instanceOfAddProductionDetailsEventDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roleId" in value;
    isInstance = isInstance && "productionEventName" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function AddProductionDetailsEventDtoFromJSON(json: any): AddProductionDetailsEventDto {
    return AddProductionDetailsEventDtoFromJSONTyped(json, false);
}

export function AddProductionDetailsEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProductionDetailsEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
        'productionEventName': json['productionEventName'],
        'startDate': (new Date(json['startDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startTime': json['startTime'],
        'location': json['location'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
    };
}

export function AddProductionDetailsEventDtoToJSON(value?: AddProductionDetailsEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'productionEventName': value.productionEventName,
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'description': value.description,
        'startTime': value.startTime,
        'location': value.location,
        'motif': value.motif,
    };
}

