/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SelectedUsageTypeFormValuesDto } from './SelectedUsageTypeFormValuesDto';
import {
    SelectedUsageTypeFormValuesDtoFromJSON,
    SelectedUsageTypeFormValuesDtoFromJSONTyped,
    SelectedUsageTypeFormValuesDtoToJSON,
} from './SelectedUsageTypeFormValuesDto';
import type { UsageTypeFormValueDto } from './UsageTypeFormValueDto';
import {
    UsageTypeFormValueDtoFromJSON,
    UsageTypeFormValueDtoFromJSONTyped,
    UsageTypeFormValueDtoToJSON,
} from './UsageTypeFormValueDto';

/**
 * 
 * @export
 * @interface UsageTypesFormValuesDto
 */
export interface UsageTypesFormValuesDto {
    /**
     * 
     * @type {Array<UsageTypeFormValueDto>}
     * @memberof UsageTypesFormValuesDto
     */
    usageTypes?: Array<UsageTypeFormValueDto>;
    /**
     * 
     * @type {SelectedUsageTypeFormValuesDto}
     * @memberof UsageTypesFormValuesDto
     */
    selectedUsageType?: SelectedUsageTypeFormValuesDto;
}

/**
 * Check if a given object implements the UsageTypesFormValuesDto interface.
 */
export function instanceOfUsageTypesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UsageTypesFormValuesDtoFromJSON(json: any): UsageTypesFormValuesDto {
    return UsageTypesFormValuesDtoFromJSONTyped(json, false);
}

export function UsageTypesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageTypesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usageTypes': !exists(json, 'usageTypes') ? undefined : ((json['usageTypes'] as Array<any>).map(UsageTypeFormValueDtoFromJSON)),
        'selectedUsageType': !exists(json, 'selectedUsageType') ? undefined : SelectedUsageTypeFormValuesDtoFromJSON(json['selectedUsageType']),
    };
}

export function UsageTypesFormValuesDtoToJSON(value?: UsageTypesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usageTypes': value.usageTypes === undefined ? undefined : ((value.usageTypes as Array<any>).map(UsageTypeFormValueDtoToJSON)),
        'selectedUsageType': SelectedUsageTypeFormValuesDtoToJSON(value.selectedUsageType),
    };
}

