/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Communication } from './Communication';
import {
    CommunicationFromJSON,
    CommunicationFromJSONTyped,
    CommunicationToJSON,
} from './Communication';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { ProtocolEntryDto } from './ProtocolEntryDto';
import {
    ProtocolEntryDtoFromJSON,
    ProtocolEntryDtoFromJSONTyped,
    ProtocolEntryDtoToJSON,
} from './ProtocolEntryDto';
import type { ServiceProviderAddressDto } from './ServiceProviderAddressDto';
import {
    ServiceProviderAddressDtoFromJSON,
    ServiceProviderAddressDtoFromJSONTyped,
    ServiceProviderAddressDtoToJSON,
} from './ServiceProviderAddressDto';
import type { ServiceProviderManagementDto } from './ServiceProviderManagementDto';
import {
    ServiceProviderManagementDtoFromJSON,
    ServiceProviderManagementDtoFromJSONTyped,
    ServiceProviderManagementDtoToJSON,
} from './ServiceProviderManagementDto';

/**
 * 
 * @export
 * @interface ServiceProviderDto
 */
export interface ServiceProviderDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    serviceProviderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {ServiceProviderAddressDto}
     * @memberof ServiceProviderDto
     */
    postalAddress?: ServiceProviderAddressDto;
    /**
     * 
     * @type {ServiceProviderAddressDto}
     * @memberof ServiceProviderDto
     */
    billingAddress?: ServiceProviderAddressDto;
    /**
     * 
     * @type {Communication}
     * @memberof ServiceProviderDto
     */
    communication?: Communication;
    /**
     * 
     * @type {ServiceProviderManagementDto}
     * @memberof ServiceProviderDto
     */
    management?: ServiceProviderManagementDto;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ServiceProviderDto
     */
    employees?: Array<Employee>;
    /**
     * 
     * @type {Array<ProtocolEntryDto>}
     * @memberof ServiceProviderDto
     */
    protocolEntries?: Array<ProtocolEntryDto>;
}

/**
 * Check if a given object implements the ServiceProviderDto interface.
 */
export function instanceOfServiceProviderDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderDtoFromJSON(json: any): ServiceProviderDto {
    return ServiceProviderDtoFromJSONTyped(json, false);
}

export function ServiceProviderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviderUuid': !exists(json, 'serviceProviderUuid') ? undefined : json['serviceProviderUuid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'postalAddress': !exists(json, 'postalAddress') ? undefined : ServiceProviderAddressDtoFromJSON(json['postalAddress']),
        'billingAddress': !exists(json, 'billingAddress') ? undefined : ServiceProviderAddressDtoFromJSON(json['billingAddress']),
        'communication': !exists(json, 'communication') ? undefined : CommunicationFromJSON(json['communication']),
        'management': !exists(json, 'management') ? undefined : ServiceProviderManagementDtoFromJSON(json['management']),
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(EmployeeFromJSON)),
        'protocolEntries': !exists(json, 'protocolEntries') ? undefined : ((json['protocolEntries'] as Array<any>).map(ProtocolEntryDtoFromJSON)),
    };
}

export function ServiceProviderDtoToJSON(value?: ServiceProviderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviderUuid': value.serviceProviderUuid,
        'name': value.name,
        'info': value.info,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'postalAddress': ServiceProviderAddressDtoToJSON(value.postalAddress),
        'billingAddress': ServiceProviderAddressDtoToJSON(value.billingAddress),
        'communication': CommunicationToJSON(value.communication),
        'management': ServiceProviderManagementDtoToJSON(value.management),
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(EmployeeToJSON)),
        'protocolEntries': value.protocolEntries === undefined ? undefined : ((value.protocolEntries as Array<any>).map(ProtocolEntryDtoToJSON)),
    };
}

