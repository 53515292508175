/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentDeleteRequestDto
 */
export interface AppointmentDeleteRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeleteRequestDto
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeleteRequestDto
     */
    appointmentId: string;
}

/**
 * Check if a given object implements the AppointmentDeleteRequestDto interface.
 */
export function instanceOfAppointmentDeleteRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "appointmentId" in value;

    return isInstance;
}

export function AppointmentDeleteRequestDtoFromJSON(json: any): AppointmentDeleteRequestDto {
    return AppointmentDeleteRequestDtoFromJSONTyped(json, false);
}

export function AppointmentDeleteRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentDeleteRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'appointmentId': json['appointmentId'],
    };
}

export function AppointmentDeleteRequestDtoToJSON(value?: AppointmentDeleteRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'appointmentId': value.appointmentId,
    };
}

