import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {ParticipantResponseDto, ProjectNamesForMessengerDto} from "@brody-bookings/api";
import {ConversationService} from "../../../service/conversation.service";

@Component({
    selector: 'app-conversation-project-search-list',
    templateUrl: './projectSearchList.component.html',
    styleUrls: ['./projectSearchList.styles.css']
})

export class ProjectSearchListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    projectNames: ProjectNamesForMessengerDto[] = []
    projectParticipantList: ParticipantResponseDto[] = [];
    showProjectParticipantList: boolean = false

    @Input() onSelectProjectParticipant!: (projectParticipant: ParticipantResponseDto) => void

    constructor(
        private conversationService: ConversationService
    ) {
    }

    ngOnInit(): void {
        this.conversationService.fetchProjectNames().subscribe((projectNamesForMessengerDtos: ProjectNamesForMessengerDto[]) => {
            this.projectNames = projectNamesForMessengerDtos
        })
        this.conversationService.fetchProjectParticipants().subscribe((participantResponseDtos: ParticipantResponseDto[]) => {
            this.projectParticipantList = participantResponseDtos
        })

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onSelectProject(event: any): void {
        this.conversationService.getProjectParticipants(event.customerId)
        this.showProjectParticipantList = true
    }
}