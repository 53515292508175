/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantResponseDto
 */
export interface ParticipantResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantId: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantRole: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantLastName: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponseDto
     */
    participantPhone: string;
}

/**
 * Check if a given object implements the ParticipantResponseDto interface.
 */
export function instanceOfParticipantResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "participantId" in value;
    isInstance = isInstance && "participantRole" in value;
    isInstance = isInstance && "participantFirstName" in value;
    isInstance = isInstance && "participantLastName" in value;
    isInstance = isInstance && "participantEmail" in value;
    isInstance = isInstance && "participantPhone" in value;

    return isInstance;
}

export function ParticipantResponseDtoFromJSON(json: any): ParticipantResponseDto {
    return ParticipantResponseDtoFromJSONTyped(json, false);
}

export function ParticipantResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantId': json['participantId'],
        'participantRole': json['participantRole'],
        'participantFirstName': json['participantFirstName'],
        'participantLastName': json['participantLastName'],
        'participantEmail': json['participantEmail'],
        'participantPhone': json['participantPhone'],
    };
}

export function ParticipantResponseDtoToJSON(value?: ParticipantResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantId': value.participantId,
        'participantRole': value.participantRole,
        'participantFirstName': value.participantFirstName,
        'participantLastName': value.participantLastName,
        'participantEmail': value.participantEmail,
        'participantPhone': value.participantPhone,
    };
}

