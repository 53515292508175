import { Component, Input } from '@angular/core';
import { ParticipantResponseDto } from '@brody-bookings/api';

@Component({
    selector: 'app-conversation-title',
    templateUrl: './conversationTitle.component.html',
    styleUrls: ['./conversationTitle.styles.css'],
})
export class ConversationTitleComponent {
    @Input() conversationTitle!: string;
    @Input() selectedParticipants!: ParticipantResponseDto[];
    @Input() titleInput!: string;
    @Input() conversationId!: string;
    @Input() onTitleInput!: (event: any) => void;
    @Input() titleEditMode: boolean = false;
    @Input() onEnableTitleEditMode!: () => void;
    @Input() onAbortTitleEditMode!: () => void;
    @Input() onChangeTitle!: () => void;
}
