/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelProductionDetailsEventFormValuesDto } from './ModelProductionDetailsEventFormValuesDto';
import {
    ModelProductionDetailsEventFormValuesDtoFromJSON,
    ModelProductionDetailsEventFormValuesDtoFromJSONTyped,
    ModelProductionDetailsEventFormValuesDtoToJSON,
} from './ModelProductionDetailsEventFormValuesDto';

/**
 * 
 * @export
 * @interface ModelProductionDetailsFormValuesDto
 */
export interface ModelProductionDetailsFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ModelProductionDetailsFormValuesDto
     */
    infoArrival?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProductionDetailsFormValuesDto
     */
    infoSleepOver?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProductionDetailsFormValuesDto
     */
    infoAdditional?: string;
    /**
     * 
     * @type {Array<ModelProductionDetailsEventFormValuesDto>}
     * @memberof ModelProductionDetailsFormValuesDto
     */
    events: Array<ModelProductionDetailsEventFormValuesDto>;
}

/**
 * Check if a given object implements the ModelProductionDetailsFormValuesDto interface.
 */
export function instanceOfModelProductionDetailsFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function ModelProductionDetailsFormValuesDtoFromJSON(json: any): ModelProductionDetailsFormValuesDto {
    return ModelProductionDetailsFormValuesDtoFromJSONTyped(json, false);
}

export function ModelProductionDetailsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelProductionDetailsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoArrival': !exists(json, 'infoArrival') ? undefined : json['infoArrival'],
        'infoSleepOver': !exists(json, 'infoSleepOver') ? undefined : json['infoSleepOver'],
        'infoAdditional': !exists(json, 'infoAdditional') ? undefined : json['infoAdditional'],
        'events': ((json['events'] as Array<any>).map(ModelProductionDetailsEventFormValuesDtoFromJSON)),
    };
}

export function ModelProductionDetailsFormValuesDtoToJSON(value?: ModelProductionDetailsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoArrival': value.infoArrival,
        'infoSleepOver': value.infoSleepOver,
        'infoAdditional': value.infoAdditional,
        'events': ((value.events as Array<any>).map(ModelProductionDetailsEventFormValuesDtoToJSON)),
    };
}

