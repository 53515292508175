/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantResponseDto } from './ParticipantResponseDto';
import {
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoFromJSONTyped,
    ParticipantResponseDtoToJSON,
} from './ParticipantResponseDto';
import type { ProjectModelParticipantsByProjectRoleResponseDto } from './ProjectModelParticipantsByProjectRoleResponseDto';
import {
    ProjectModelParticipantsByProjectRoleResponseDtoFromJSON,
    ProjectModelParticipantsByProjectRoleResponseDtoFromJSONTyped,
    ProjectModelParticipantsByProjectRoleResponseDtoToJSON,
} from './ProjectModelParticipantsByProjectRoleResponseDto';

/**
 * 
 * @export
 * @interface ProjectParticipantResponseDto
 */
export interface ProjectParticipantResponseDto {
    /**
     * 
     * @type {Array<ProjectModelParticipantsByProjectRoleResponseDto>}
     * @memberof ProjectParticipantResponseDto
     */
    models?: Array<ProjectModelParticipantsByProjectRoleResponseDto>;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ProjectParticipantResponseDto
     */
    producer?: Array<ParticipantResponseDto>;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ProjectParticipantResponseDto
     */
    additional?: Array<ParticipantResponseDto>;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ProjectParticipantResponseDto
     */
    external?: Array<ParticipantResponseDto>;
}

/**
 * Check if a given object implements the ProjectParticipantResponseDto interface.
 */
export function instanceOfProjectParticipantResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectParticipantResponseDtoFromJSON(json: any): ProjectParticipantResponseDto {
    return ProjectParticipantResponseDtoFromJSONTyped(json, false);
}

export function ProjectParticipantResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectParticipantResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(ProjectModelParticipantsByProjectRoleResponseDtoFromJSON)),
        'producer': !exists(json, 'producer') ? undefined : ((json['producer'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
        'additional': !exists(json, 'additional') ? undefined : ((json['additional'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
        'external': !exists(json, 'external') ? undefined : ((json['external'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
    };
}

export function ProjectParticipantResponseDtoToJSON(value?: ProjectParticipantResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(ProjectModelParticipantsByProjectRoleResponseDtoToJSON)),
        'producer': value.producer === undefined ? undefined : ((value.producer as Array<any>).map(ParticipantResponseDtoToJSON)),
        'additional': value.additional === undefined ? undefined : ((value.additional as Array<any>).map(ParticipantResponseDtoToJSON)),
        'external': value.external === undefined ? undefined : ((value.external as Array<any>).map(ParticipantResponseDtoToJSON)),
    };
}

