/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobHistoryFormValuesDto } from './JobHistoryFormValuesDto';
import {
    JobHistoryFormValuesDtoFromJSON,
    JobHistoryFormValuesDtoFromJSONTyped,
    JobHistoryFormValuesDtoToJSON,
} from './JobHistoryFormValuesDto';

/**
 * 
 * @export
 * @interface JobHistoriesFormValuesDto
 */
export interface JobHistoriesFormValuesDto {
    /**
     * 
     * @type {Array<JobHistoryFormValuesDto>}
     * @memberof JobHistoriesFormValuesDto
     */
    jobHistories?: Array<JobHistoryFormValuesDto>;
}

/**
 * Check if a given object implements the JobHistoriesFormValuesDto interface.
 */
export function instanceOfJobHistoriesFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobHistoriesFormValuesDtoFromJSON(json: any): JobHistoriesFormValuesDto {
    return JobHistoriesFormValuesDtoFromJSONTyped(json, false);
}

export function JobHistoriesFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobHistoriesFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobHistories': !exists(json, 'jobHistories') ? undefined : ((json['jobHistories'] as Array<any>).map(JobHistoryFormValuesDtoFromJSON)),
    };
}

export function JobHistoriesFormValuesDtoToJSON(value?: JobHistoriesFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobHistories': value.jobHistories === undefined ? undefined : ((value.jobHistories as Array<any>).map(JobHistoryFormValuesDtoToJSON)),
    };
}

