/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelAgencyFormValuesDto } from './ModelAgencyFormValuesDto';
import {
    ModelAgencyFormValuesDtoFromJSON,
    ModelAgencyFormValuesDtoFromJSONTyped,
    ModelAgencyFormValuesDtoToJSON,
} from './ModelAgencyFormValuesDto';

/**
 * 
 * @export
 * @interface ModelAgencyListFormValuesDto
 */
export interface ModelAgencyListFormValuesDto {
    /**
     * 
     * @type {Array<ModelAgencyFormValuesDto>}
     * @memberof ModelAgencyListFormValuesDto
     */
    modelAgencyFormValuesDtoList?: Array<ModelAgencyFormValuesDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAgencyListFormValuesDto
     */
    statusOptions?: Array<string>;
}

/**
 * Check if a given object implements the ModelAgencyListFormValuesDto interface.
 */
export function instanceOfModelAgencyListFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelAgencyListFormValuesDtoFromJSON(json: any): ModelAgencyListFormValuesDto {
    return ModelAgencyListFormValuesDtoFromJSONTyped(json, false);
}

export function ModelAgencyListFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelAgencyListFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelAgencyFormValuesDtoList': !exists(json, 'modelAgencyFormValuesDtoList') ? undefined : ((json['modelAgencyFormValuesDtoList'] as Array<any>).map(ModelAgencyFormValuesDtoFromJSON)),
        'statusOptions': !exists(json, 'statusOptions') ? undefined : json['statusOptions'],
    };
}

export function ModelAgencyListFormValuesDtoToJSON(value?: ModelAgencyListFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelAgencyFormValuesDtoList': value.modelAgencyFormValuesDtoList === undefined ? undefined : ((value.modelAgencyFormValuesDtoList as Array<any>).map(ModelAgencyFormValuesDtoToJSON)),
        'statusOptions': value.statusOptions,
    };
}

