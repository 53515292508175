/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactInfoFormValuesDto
 */
export interface ContactInfoFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    skype?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoFormValuesDto
     */
    fax?: string;
}

/**
 * Check if a given object implements the ContactInfoFormValuesDto interface.
 */
export function instanceOfContactInfoFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactInfoFormValuesDtoFromJSON(json: any): ContactInfoFormValuesDto {
    return ContactInfoFormValuesDtoFromJSONTyped(json, false);
}

export function ContactInfoFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactInfoFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'skype': !exists(json, 'skype') ? undefined : json['skype'],
        'facebook': !exists(json, 'facebook') ? undefined : json['facebook'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'twitter': !exists(json, 'twitter') ? undefined : json['twitter'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
    };
}

export function ContactInfoFormValuesDtoToJSON(value?: ContactInfoFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile': value.mobile,
        'email': value.email,
        'phone': value.phone,
        'website': value.website,
        'skype': value.skype,
        'facebook': value.facebook,
        'instagram': value.instagram,
        'twitter': value.twitter,
        'fax': value.fax,
    };
}

