/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingModel } from './BookingModel';
import {
    BookingModelFromJSON,
    BookingModelFromJSONTyped,
    BookingModelToJSON,
} from './BookingModel';
import type { DetailsCasting } from './DetailsCasting';
import {
    DetailsCastingFromJSON,
    DetailsCastingFromJSONTyped,
    DetailsCastingToJSON,
} from './DetailsCasting';
import type { ModelId } from './ModelId';
import {
    ModelIdFromJSON,
    ModelIdFromJSONTyped,
    ModelIdToJSON,
} from './ModelId';
import type { ProjectModelBilling } from './ProjectModelBilling';
import {
    ProjectModelBillingFromJSON,
    ProjectModelBillingFromJSONTyped,
    ProjectModelBillingToJSON,
} from './ProjectModelBilling';
import type { ProjectModelCasting } from './ProjectModelCasting';
import {
    ProjectModelCastingFromJSON,
    ProjectModelCastingFromJSONTyped,
    ProjectModelCastingToJSON,
} from './ProjectModelCasting';
import type { ProjectModelProductionDetails } from './ProjectModelProductionDetails';
import {
    ProjectModelProductionDetailsFromJSON,
    ProjectModelProductionDetailsFromJSONTyped,
    ProjectModelProductionDetailsToJSON,
} from './ProjectModelProductionDetails';
import type { RoleDetails } from './RoleDetails';
import {
    RoleDetailsFromJSON,
    RoleDetailsFromJSONTyped,
    RoleDetailsToJSON,
} from './RoleDetails';
import type { TravelCosts } from './TravelCosts';
import {
    TravelCostsFromJSON,
    TravelCostsFromJSONTyped,
    TravelCostsToJSON,
} from './TravelCosts';

/**
 * 
 * @export
 * @interface ProjectModel
 */
export interface ProjectModel {
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    projectModelId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    lastName: string;
    /**
     * 
     * @type {ModelId}
     * @memberof ProjectModel
     */
    modelId: ModelId;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    status?: ProjectModelStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectModel
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModel
     */
    salary?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    buyoutType?: ProjectModelBuyoutTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectModel
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    externalNote?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectModel
     */
    addedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    roleName?: string;
    /**
     * 
     * @type {ProjectModelProductionDetails}
     * @memberof ProjectModel
     */
    productionDetails?: ProjectModelProductionDetails;
    /**
     * 
     * @type {BookingModel}
     * @memberof ProjectModel
     */
    booking?: BookingModel;
    /**
     * 
     * @type {ProjectModelCasting}
     * @memberof ProjectModel
     */
    casting?: ProjectModelCasting;
    /**
     * 
     * @type {RoleDetails}
     * @memberof ProjectModel
     */
    roleDetails?: RoleDetails;
    /**
     * 
     * @type {DetailsCasting}
     * @memberof ProjectModel
     */
    detailsCasting?: DetailsCasting;
    /**
     * 
     * @type {ProjectModelBilling}
     * @memberof ProjectModel
     */
    billing?: ProjectModelBilling;
    /**
     * 
     * @type {Array<TravelCosts>}
     * @memberof ProjectModel
     */
    travelCosts?: Array<TravelCosts>;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    oauthId?: string;
}


/**
 * @export
 */
export const ProjectModelStatusEnum = {
    Rejected: 'REJECTED',
    Shortlist: 'SHORTLIST',
    OptRequested: 'OPT_REQUESTED',
    RejectedByModel: 'REJECTED_BY_MODEL',
    Optioned: 'OPTIONED',
    Casting: 'CASTING',
    RejectedByCustomer: 'REJECTED_BY_CUSTOMER',
    Selected: 'SELECTED'
} as const;
export type ProjectModelStatusEnum = typeof ProjectModelStatusEnum[keyof typeof ProjectModelStatusEnum];

/**
 * @export
 */
export const ProjectModelBuyoutTypeEnum = {
    Incl: 'INCL',
    Flat: 'FLAT',
    Percentage: 'PERCENTAGE'
} as const;
export type ProjectModelBuyoutTypeEnum = typeof ProjectModelBuyoutTypeEnum[keyof typeof ProjectModelBuyoutTypeEnum];


/**
 * Check if a given object implements the ProjectModel interface.
 */
export function instanceOfProjectModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectModelId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function ProjectModelFromJSON(json: any): ProjectModel {
    return ProjectModelFromJSONTyped(json, false);
}

export function ProjectModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectModelId': json['projectModelId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'modelId': ModelIdFromJSON(json['modelId']),
        'projectId': json['projectId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'salary': !exists(json, 'salary') ? undefined : json['salary'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'externalNote': !exists(json, 'externalNote') ? undefined : json['externalNote'],
        'addedAt': !exists(json, 'addedAt') ? undefined : (new Date(json['addedAt'])),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'productionDetails': !exists(json, 'productionDetails') ? undefined : ProjectModelProductionDetailsFromJSON(json['productionDetails']),
        'booking': !exists(json, 'booking') ? undefined : BookingModelFromJSON(json['booking']),
        'casting': !exists(json, 'casting') ? undefined : ProjectModelCastingFromJSON(json['casting']),
        'roleDetails': !exists(json, 'roleDetails') ? undefined : RoleDetailsFromJSON(json['roleDetails']),
        'detailsCasting': !exists(json, 'detailsCasting') ? undefined : DetailsCastingFromJSON(json['detailsCasting']),
        'billing': !exists(json, 'billing') ? undefined : ProjectModelBillingFromJSON(json['billing']),
        'travelCosts': !exists(json, 'travelCosts') ? undefined : ((json['travelCosts'] as Array<any>).map(TravelCostsFromJSON)),
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ProjectModelToJSON(value?: ProjectModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectModelId': value.projectModelId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'modelId': ModelIdToJSON(value.modelId),
        'projectId': value.projectId,
        'image': value.image,
        'mobile': value.mobile,
        'phone': value.phone,
        'email': value.email,
        'status': value.status,
        'dailyRate': value.dailyRate,
        'salary': value.salary,
        'note': value.note,
        'buyoutType': value.buyoutType,
        'buyoutValue': value.buyoutValue,
        'externalNote': value.externalNote,
        'addedAt': value.addedAt === undefined ? undefined : (value.addedAt.toISOString()),
        'roleId': value.roleId,
        'roleName': value.roleName,
        'productionDetails': ProjectModelProductionDetailsToJSON(value.productionDetails),
        'booking': BookingModelToJSON(value.booking),
        'casting': ProjectModelCastingToJSON(value.casting),
        'roleDetails': RoleDetailsToJSON(value.roleDetails),
        'detailsCasting': DetailsCastingToJSON(value.detailsCasting),
        'billing': ProjectModelBillingToJSON(value.billing),
        'travelCosts': value.travelCosts === undefined ? undefined : ((value.travelCosts as Array<any>).map(TravelCostsToJSON)),
        'oauthId': value.oauthId,
    };
}

