/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActingExperienceDto } from './ActingExperienceDto';
import {
    ActingExperienceDtoFromJSON,
    ActingExperienceDtoFromJSONTyped,
    ActingExperienceDtoToJSON,
} from './ActingExperienceDto';
import type { AddressDto } from './AddressDto';
import {
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
} from './AddressDto';
import type { BahnCardDto } from './BahnCardDto';
import {
    BahnCardDtoFromJSON,
    BahnCardDtoFromJSONTyped,
    BahnCardDtoToJSON,
} from './BahnCardDto';
import type { BankAccountInformationDto } from './BankAccountInformationDto';
import {
    BankAccountInformationDtoFromJSON,
    BankAccountInformationDtoFromJSONTyped,
    BankAccountInformationDtoToJSON,
} from './BankAccountInformationDto';
import type { BodyAccessoriesDto } from './BodyAccessoriesDto';
import {
    BodyAccessoriesDtoFromJSON,
    BodyAccessoriesDtoFromJSONTyped,
    BodyAccessoriesDtoToJSON,
} from './BodyAccessoriesDto';
import type { BodyDetailsDto } from './BodyDetailsDto';
import {
    BodyDetailsDtoFromJSON,
    BodyDetailsDtoFromJSONTyped,
    BodyDetailsDtoToJSON,
} from './BodyDetailsDto';
import type { ColorsAndTypesDto } from './ColorsAndTypesDto';
import {
    ColorsAndTypesDtoFromJSON,
    ColorsAndTypesDtoFromJSONTyped,
    ColorsAndTypesDtoToJSON,
} from './ColorsAndTypesDto';
import type { ContactDto } from './ContactDto';
import {
    ContactDtoFromJSON,
    ContactDtoFromJSONTyped,
    ContactDtoToJSON,
} from './ContactDto';
import type { DanceDto } from './DanceDto';
import {
    DanceDtoFromJSON,
    DanceDtoFromJSONTyped,
    DanceDtoToJSON,
} from './DanceDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { IdentificationDto } from './IdentificationDto';
import {
    IdentificationDtoFromJSON,
    IdentificationDtoFromJSONTyped,
    IdentificationDtoToJSON,
} from './IdentificationDto';
import type { InsuranceDto } from './InsuranceDto';
import {
    InsuranceDtoFromJSON,
    InsuranceDtoFromJSONTyped,
    InsuranceDtoToJSON,
} from './InsuranceDto';
import type { JobDto } from './JobDto';
import {
    JobDtoFromJSON,
    JobDtoFromJSONTyped,
    JobDtoToJSON,
} from './JobDto';
import type { ModelingExperienceDto } from './ModelingExperienceDto';
import {
    ModelingExperienceDtoFromJSON,
    ModelingExperienceDtoFromJSONTyped,
    ModelingExperienceDtoToJSON,
} from './ModelingExperienceDto';
import type { NameDto } from './NameDto';
import {
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import type { Specials } from './Specials';
import {
    SpecialsFromJSON,
    SpecialsFromJSONTyped,
    SpecialsToJSON,
} from './Specials';
import type { SpokenLanguageDto } from './SpokenLanguageDto';
import {
    SpokenLanguageDtoFromJSON,
    SpokenLanguageDtoFromJSONTyped,
    SpokenLanguageDtoToJSON,
} from './SpokenLanguageDto';
import type { SportDto } from './SportDto';
import {
    SportDtoFromJSON,
    SportDtoFromJSONTyped,
    SportDtoToJSON,
} from './SportDto';
import type { TaxInformationDto } from './TaxInformationDto';
import {
    TaxInformationDtoFromJSON,
    TaxInformationDtoFromJSONTyped,
    TaxInformationDtoToJSON,
} from './TaxInformationDto';

/**
 * 
 * @export
 * @interface ModelApplicationRequestDto
 */
export interface ModelApplicationRequestDto {
    /**
     * 
     * @type {Date}
     * @memberof ModelApplicationRequestDto
     */
    date?: Date;
    /**
     * 
     * @type {EmailDto}
     * @memberof ModelApplicationRequestDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {NameDto}
     * @memberof ModelApplicationRequestDto
     */
    name?: NameDto;
    /**
     * 
     * @type {string}
     * @memberof ModelApplicationRequestDto
     */
    sex?: ModelApplicationRequestDtoSexEnum;
    /**
     * 
     * @type {ContactDto}
     * @memberof ModelApplicationRequestDto
     */
    contactInfos?: ContactDto;
    /**
     * 
     * @type {AddressDto}
     * @memberof ModelApplicationRequestDto
     */
    livingAddress?: AddressDto;
    /**
     * 
     * @type {AddressDto}
     * @memberof ModelApplicationRequestDto
     */
    notifyAddress?: AddressDto;
    /**
     * 
     * @type {IdentificationDto}
     * @memberof ModelApplicationRequestDto
     */
    identification?: IdentificationDto;
    /**
     * 
     * @type {Array<SpokenLanguageDto>}
     * @memberof ModelApplicationRequestDto
     */
    spokenLanguages?: Array<SpokenLanguageDto>;
    /**
     * 
     * @type {Specials}
     * @memberof ModelApplicationRequestDto
     */
    specials?: Specials;
    /**
     * 
     * @type {string}
     * @memberof ModelApplicationRequestDto
     */
    diet?: ModelApplicationRequestDtoDietEnum;
    /**
     * 
     * @type {JobDto}
     * @memberof ModelApplicationRequestDto
     */
    job?: JobDto;
    /**
     * 
     * @type {BankAccountInformationDto}
     * @memberof ModelApplicationRequestDto
     */
    bankAccountInformation?: BankAccountInformationDto;
    /**
     * 
     * @type {InsuranceDto}
     * @memberof ModelApplicationRequestDto
     */
    insurance?: InsuranceDto;
    /**
     * 
     * @type {TaxInformationDto}
     * @memberof ModelApplicationRequestDto
     */
    taxInformation?: TaxInformationDto;
    /**
     * 
     * @type {ColorsAndTypesDto}
     * @memberof ModelApplicationRequestDto
     */
    colorsAndTypes?: ColorsAndTypesDto;
    /**
     * 
     * @type {BodyAccessoriesDto}
     * @memberof ModelApplicationRequestDto
     */
    bodyAccessories?: BodyAccessoriesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelApplicationRequestDto
     */
    instruments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelApplicationRequestDto
     */
    additionalAgencies?: Array<string>;
    /**
     * 
     * @type {Array<DanceDto>}
     * @memberof ModelApplicationRequestDto
     */
    dances?: Array<DanceDto>;
    /**
     * 
     * @type {Array<SportDto>}
     * @memberof ModelApplicationRequestDto
     */
    sports?: Array<SportDto>;
    /**
     * 
     * @type {BodyDetailsDto}
     * @memberof ModelApplicationRequestDto
     */
    bodyDetails?: BodyDetailsDto;
    /**
     * 
     * @type {ActingExperienceDto}
     * @memberof ModelApplicationRequestDto
     */
    actingExperience?: ActingExperienceDto;
    /**
     * 
     * @type {ModelingExperienceDto}
     * @memberof ModelApplicationRequestDto
     */
    modelExperience?: ModelingExperienceDto;
    /**
     * 
     * @type {string}
     * @memberof ModelApplicationRequestDto
     */
    parentAgency?: string;
    /**
     * 
     * @type {BahnCardDto}
     * @memberof ModelApplicationRequestDto
     */
    bahnCard?: BahnCardDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelApplicationRequestDto
     */
    driversLicense?: Array<ModelApplicationRequestDtoDriversLicenseEnum>;
}


/**
 * @export
 */
export const ModelApplicationRequestDtoSexEnum = {
    Female: 'FEMALE',
    Male: 'MALE',
    Diverse: 'DIVERSE'
} as const;
export type ModelApplicationRequestDtoSexEnum = typeof ModelApplicationRequestDtoSexEnum[keyof typeof ModelApplicationRequestDtoSexEnum];

/**
 * @export
 */
export const ModelApplicationRequestDtoDietEnum = {
    Regular: 'REGULAR',
    Vegetarian: 'VEGETARIAN',
    Vegan: 'VEGAN'
} as const;
export type ModelApplicationRequestDtoDietEnum = typeof ModelApplicationRequestDtoDietEnum[keyof typeof ModelApplicationRequestDtoDietEnum];

/**
 * @export
 */
export const ModelApplicationRequestDtoDriversLicenseEnum = {
    Car: 'CAR',
    Truck: 'TRUCK',
    Airplane: 'AIRPLANE',
    Bus: 'BUS',
    Boat: 'BOAT',
    Motorcycle: 'MOTORCYCLE'
} as const;
export type ModelApplicationRequestDtoDriversLicenseEnum = typeof ModelApplicationRequestDtoDriversLicenseEnum[keyof typeof ModelApplicationRequestDtoDriversLicenseEnum];


/**
 * Check if a given object implements the ModelApplicationRequestDto interface.
 */
export function instanceOfModelApplicationRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelApplicationRequestDtoFromJSON(json: any): ModelApplicationRequestDto {
    return ModelApplicationRequestDtoFromJSONTyped(json, false);
}

export function ModelApplicationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelApplicationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'name': !exists(json, 'name') ? undefined : NameDtoFromJSON(json['name']),
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'contactInfos': !exists(json, 'contactInfos') ? undefined : ContactDtoFromJSON(json['contactInfos']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : AddressDtoFromJSON(json['livingAddress']),
        'notifyAddress': !exists(json, 'notifyAddress') ? undefined : AddressDtoFromJSON(json['notifyAddress']),
        'identification': !exists(json, 'identification') ? undefined : IdentificationDtoFromJSON(json['identification']),
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : ((json['spokenLanguages'] as Array<any>).map(SpokenLanguageDtoFromJSON)),
        'specials': !exists(json, 'specials') ? undefined : SpecialsFromJSON(json['specials']),
        'diet': !exists(json, 'diet') ? undefined : json['diet'],
        'job': !exists(json, 'job') ? undefined : JobDtoFromJSON(json['job']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationDtoFromJSON(json['bankAccountInformation']),
        'insurance': !exists(json, 'insurance') ? undefined : InsuranceDtoFromJSON(json['insurance']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationDtoFromJSON(json['taxInformation']),
        'colorsAndTypes': !exists(json, 'colorsAndTypes') ? undefined : ColorsAndTypesDtoFromJSON(json['colorsAndTypes']),
        'bodyAccessories': !exists(json, 'bodyAccessories') ? undefined : BodyAccessoriesDtoFromJSON(json['bodyAccessories']),
        'instruments': !exists(json, 'instruments') ? undefined : json['instruments'],
        'additionalAgencies': !exists(json, 'additionalAgencies') ? undefined : json['additionalAgencies'],
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(DanceDtoFromJSON)),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportDtoFromJSON)),
        'bodyDetails': !exists(json, 'bodyDetails') ? undefined : BodyDetailsDtoFromJSON(json['bodyDetails']),
        'actingExperience': !exists(json, 'actingExperience') ? undefined : ActingExperienceDtoFromJSON(json['actingExperience']),
        'modelExperience': !exists(json, 'modelExperience') ? undefined : ModelingExperienceDtoFromJSON(json['modelExperience']),
        'parentAgency': !exists(json, 'parentAgency') ? undefined : json['parentAgency'],
        'bahnCard': !exists(json, 'bahnCard') ? undefined : BahnCardDtoFromJSON(json['bahnCard']),
        'driversLicense': !exists(json, 'driversLicense') ? undefined : json['driversLicense'],
    };
}

export function ModelApplicationRequestDtoToJSON(value?: ModelApplicationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'email': EmailDtoToJSON(value.email),
        'name': NameDtoToJSON(value.name),
        'sex': value.sex,
        'contactInfos': ContactDtoToJSON(value.contactInfos),
        'livingAddress': AddressDtoToJSON(value.livingAddress),
        'notifyAddress': AddressDtoToJSON(value.notifyAddress),
        'identification': IdentificationDtoToJSON(value.identification),
        'spokenLanguages': value.spokenLanguages === undefined ? undefined : ((value.spokenLanguages as Array<any>).map(SpokenLanguageDtoToJSON)),
        'specials': SpecialsToJSON(value.specials),
        'diet': value.diet,
        'job': JobDtoToJSON(value.job),
        'bankAccountInformation': BankAccountInformationDtoToJSON(value.bankAccountInformation),
        'insurance': InsuranceDtoToJSON(value.insurance),
        'taxInformation': TaxInformationDtoToJSON(value.taxInformation),
        'colorsAndTypes': ColorsAndTypesDtoToJSON(value.colorsAndTypes),
        'bodyAccessories': BodyAccessoriesDtoToJSON(value.bodyAccessories),
        'instruments': value.instruments,
        'additionalAgencies': value.additionalAgencies,
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(DanceDtoToJSON)),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportDtoToJSON)),
        'bodyDetails': BodyDetailsDtoToJSON(value.bodyDetails),
        'actingExperience': ActingExperienceDtoToJSON(value.actingExperience),
        'modelExperience': ModelingExperienceDtoToJSON(value.modelExperience),
        'parentAgency': value.parentAgency,
        'bahnCard': BahnCardDtoToJSON(value.bahnCard),
        'driversLicense': value.driversLicense,
    };
}

