/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AvailableFormValuesDto
 */
export interface AvailableFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    availableSexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    availableCountries?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    availableCities?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    ethnicityOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    dietOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    skinTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    skinColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    eyeColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    hairColorOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    hairColorNuanceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    hairLengthOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    hairStyleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    piercingOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    tattooOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    instrumentTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    danceTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    sportTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    positionOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    driverLicenseOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    spokenLanguageTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    costReasonOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    categoryTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    specialsOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    usageTypesOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    subUsageTypesOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    professionsOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    roleCategoriesOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    industryFieldOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    activityFieldOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    transportationOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    extraExpensesOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    modelProvisionsOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    bahncardTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    bahncardClassOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    confectionSizeFemaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    confectionSizeMaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    shoeSizeEuOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    shoeSizeEuFemaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    shoeSizeEuMaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    shoeSizeUsOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    braCircumferenceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    braCupSizeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    legLengthOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    headCircumferenceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    pantSizeFemaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    pantSizeMaleOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    timeToPaymentOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    billingOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AvailableFormValuesDto
     */
    durationOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the AvailableFormValuesDto interface.
 */
export function instanceOfAvailableFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AvailableFormValuesDtoFromJSON(json: any): AvailableFormValuesDto {
    return AvailableFormValuesDtoFromJSONTyped(json, false);
}

export function AvailableFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableSexes': !exists(json, 'availableSexes') ? undefined : ((json['availableSexes'] as Array<any>).map(AdminValueFromJSON)),
        'availableCountries': !exists(json, 'availableCountries') ? undefined : ((json['availableCountries'] as Array<any>).map(AdminValueFromJSON)),
        'availableCities': !exists(json, 'availableCities') ? undefined : ((json['availableCities'] as Array<any>).map(AdminValueFromJSON)),
        'ethnicityOptions': !exists(json, 'ethnicityOptions') ? undefined : ((json['ethnicityOptions'] as Array<any>).map(AdminValueFromJSON)),
        'dietOptions': !exists(json, 'dietOptions') ? undefined : ((json['dietOptions'] as Array<any>).map(AdminValueFromJSON)),
        'skinTypeOptions': !exists(json, 'skinTypeOptions') ? undefined : ((json['skinTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'skinColorOptions': !exists(json, 'skinColorOptions') ? undefined : ((json['skinColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'eyeColorOptions': !exists(json, 'eyeColorOptions') ? undefined : ((json['eyeColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairColorOptions': !exists(json, 'hairColorOptions') ? undefined : ((json['hairColorOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairColorNuanceOptions': !exists(json, 'hairColorNuanceOptions') ? undefined : ((json['hairColorNuanceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairLengthOptions': !exists(json, 'hairLengthOptions') ? undefined : ((json['hairLengthOptions'] as Array<any>).map(AdminValueFromJSON)),
        'hairStyleOptions': !exists(json, 'hairStyleOptions') ? undefined : ((json['hairStyleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'piercingOptions': !exists(json, 'piercingOptions') ? undefined : ((json['piercingOptions'] as Array<any>).map(AdminValueFromJSON)),
        'tattooOptions': !exists(json, 'tattooOptions') ? undefined : ((json['tattooOptions'] as Array<any>).map(AdminValueFromJSON)),
        'instrumentTypeOptions': !exists(json, 'instrumentTypeOptions') ? undefined : ((json['instrumentTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'danceTypeOptions': !exists(json, 'danceTypeOptions') ? undefined : ((json['danceTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'sportTypeOptions': !exists(json, 'sportTypeOptions') ? undefined : ((json['sportTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'positionOptions': !exists(json, 'positionOptions') ? undefined : ((json['positionOptions'] as Array<any>).map(AdminValueFromJSON)),
        'driverLicenseOptions': !exists(json, 'driverLicenseOptions') ? undefined : ((json['driverLicenseOptions'] as Array<any>).map(AdminValueFromJSON)),
        'spokenLanguageTypeOptions': !exists(json, 'spokenLanguageTypeOptions') ? undefined : ((json['spokenLanguageTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'costReasonOptions': !exists(json, 'costReasonOptions') ? undefined : ((json['costReasonOptions'] as Array<any>).map(AdminValueFromJSON)),
        'categoryTypeOptions': !exists(json, 'categoryTypeOptions') ? undefined : ((json['categoryTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'specialsOptions': !exists(json, 'specialsOptions') ? undefined : ((json['specialsOptions'] as Array<any>).map(AdminValueFromJSON)),
        'usageTypesOptions': !exists(json, 'usageTypesOptions') ? undefined : ((json['usageTypesOptions'] as Array<any>).map(AdminValueFromJSON)),
        'subUsageTypesOptions': !exists(json, 'subUsageTypesOptions') ? undefined : ((json['subUsageTypesOptions'] as Array<any>).map(AdminValueFromJSON)),
        'professionsOptions': !exists(json, 'professionsOptions') ? undefined : ((json['professionsOptions'] as Array<any>).map(AdminValueFromJSON)),
        'roleCategoriesOptions': !exists(json, 'roleCategoriesOptions') ? undefined : ((json['roleCategoriesOptions'] as Array<any>).map(AdminValueFromJSON)),
        'industryFieldOptions': !exists(json, 'industryFieldOptions') ? undefined : ((json['industryFieldOptions'] as Array<any>).map(AdminValueFromJSON)),
        'activityFieldOptions': !exists(json, 'activityFieldOptions') ? undefined : ((json['activityFieldOptions'] as Array<any>).map(AdminValueFromJSON)),
        'transportationOptions': !exists(json, 'transportationOptions') ? undefined : ((json['transportationOptions'] as Array<any>).map(AdminValueFromJSON)),
        'extraExpensesOptions': !exists(json, 'extraExpensesOptions') ? undefined : ((json['extraExpensesOptions'] as Array<any>).map(AdminValueFromJSON)),
        'modelProvisionsOptions': !exists(json, 'modelProvisionsOptions') ? undefined : ((json['modelProvisionsOptions'] as Array<any>).map(AdminValueFromJSON)),
        'bahncardTypeOptions': !exists(json, 'bahncardTypeOptions') ? undefined : ((json['bahncardTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'bahncardClassOptions': !exists(json, 'bahncardClassOptions') ? undefined : ((json['bahncardClassOptions'] as Array<any>).map(AdminValueFromJSON)),
        'confectionSizeFemaleOptions': !exists(json, 'confectionSizeFemaleOptions') ? undefined : ((json['confectionSizeFemaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'confectionSizeMaleOptions': !exists(json, 'confectionSizeMaleOptions') ? undefined : ((json['confectionSizeMaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeEuOptions': !exists(json, 'shoeSizeEuOptions') ? undefined : ((json['shoeSizeEuOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeEuFemaleOptions': !exists(json, 'shoeSizeEuFemaleOptions') ? undefined : ((json['shoeSizeEuFemaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeEuMaleOptions': !exists(json, 'shoeSizeEuMaleOptions') ? undefined : ((json['shoeSizeEuMaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeUsOptions': !exists(json, 'shoeSizeUsOptions') ? undefined : ((json['shoeSizeUsOptions'] as Array<any>).map(AdminValueFromJSON)),
        'braCircumferenceOptions': !exists(json, 'braCircumferenceOptions') ? undefined : ((json['braCircumferenceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'braCupSizeOptions': !exists(json, 'braCupSizeOptions') ? undefined : ((json['braCupSizeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'legLengthOptions': !exists(json, 'legLengthOptions') ? undefined : ((json['legLengthOptions'] as Array<any>).map(AdminValueFromJSON)),
        'headCircumferenceOptions': !exists(json, 'headCircumferenceOptions') ? undefined : ((json['headCircumferenceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'pantSizeFemaleOptions': !exists(json, 'pantSizeFemaleOptions') ? undefined : ((json['pantSizeFemaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'pantSizeMaleOptions': !exists(json, 'pantSizeMaleOptions') ? undefined : ((json['pantSizeMaleOptions'] as Array<any>).map(AdminValueFromJSON)),
        'timeToPaymentOptions': !exists(json, 'timeToPaymentOptions') ? undefined : ((json['timeToPaymentOptions'] as Array<any>).map(AdminValueFromJSON)),
        'billingOptions': !exists(json, 'billingOptions') ? undefined : ((json['billingOptions'] as Array<any>).map(AdminValueFromJSON)),
        'durationOptions': !exists(json, 'durationOptions') ? undefined : ((json['durationOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function AvailableFormValuesDtoToJSON(value?: AvailableFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableSexes': value.availableSexes === undefined ? undefined : ((value.availableSexes as Array<any>).map(AdminValueToJSON)),
        'availableCountries': value.availableCountries === undefined ? undefined : ((value.availableCountries as Array<any>).map(AdminValueToJSON)),
        'availableCities': value.availableCities === undefined ? undefined : ((value.availableCities as Array<any>).map(AdminValueToJSON)),
        'ethnicityOptions': value.ethnicityOptions === undefined ? undefined : ((value.ethnicityOptions as Array<any>).map(AdminValueToJSON)),
        'dietOptions': value.dietOptions === undefined ? undefined : ((value.dietOptions as Array<any>).map(AdminValueToJSON)),
        'skinTypeOptions': value.skinTypeOptions === undefined ? undefined : ((value.skinTypeOptions as Array<any>).map(AdminValueToJSON)),
        'skinColorOptions': value.skinColorOptions === undefined ? undefined : ((value.skinColorOptions as Array<any>).map(AdminValueToJSON)),
        'eyeColorOptions': value.eyeColorOptions === undefined ? undefined : ((value.eyeColorOptions as Array<any>).map(AdminValueToJSON)),
        'hairColorOptions': value.hairColorOptions === undefined ? undefined : ((value.hairColorOptions as Array<any>).map(AdminValueToJSON)),
        'hairColorNuanceOptions': value.hairColorNuanceOptions === undefined ? undefined : ((value.hairColorNuanceOptions as Array<any>).map(AdminValueToJSON)),
        'hairLengthOptions': value.hairLengthOptions === undefined ? undefined : ((value.hairLengthOptions as Array<any>).map(AdminValueToJSON)),
        'hairStyleOptions': value.hairStyleOptions === undefined ? undefined : ((value.hairStyleOptions as Array<any>).map(AdminValueToJSON)),
        'piercingOptions': value.piercingOptions === undefined ? undefined : ((value.piercingOptions as Array<any>).map(AdminValueToJSON)),
        'tattooOptions': value.tattooOptions === undefined ? undefined : ((value.tattooOptions as Array<any>).map(AdminValueToJSON)),
        'instrumentTypeOptions': value.instrumentTypeOptions === undefined ? undefined : ((value.instrumentTypeOptions as Array<any>).map(AdminValueToJSON)),
        'danceTypeOptions': value.danceTypeOptions === undefined ? undefined : ((value.danceTypeOptions as Array<any>).map(AdminValueToJSON)),
        'sportTypeOptions': value.sportTypeOptions === undefined ? undefined : ((value.sportTypeOptions as Array<any>).map(AdminValueToJSON)),
        'positionOptions': value.positionOptions === undefined ? undefined : ((value.positionOptions as Array<any>).map(AdminValueToJSON)),
        'driverLicenseOptions': value.driverLicenseOptions === undefined ? undefined : ((value.driverLicenseOptions as Array<any>).map(AdminValueToJSON)),
        'spokenLanguageTypeOptions': value.spokenLanguageTypeOptions === undefined ? undefined : ((value.spokenLanguageTypeOptions as Array<any>).map(AdminValueToJSON)),
        'costReasonOptions': value.costReasonOptions === undefined ? undefined : ((value.costReasonOptions as Array<any>).map(AdminValueToJSON)),
        'categoryTypeOptions': value.categoryTypeOptions === undefined ? undefined : ((value.categoryTypeOptions as Array<any>).map(AdminValueToJSON)),
        'specialsOptions': value.specialsOptions === undefined ? undefined : ((value.specialsOptions as Array<any>).map(AdminValueToJSON)),
        'usageTypesOptions': value.usageTypesOptions === undefined ? undefined : ((value.usageTypesOptions as Array<any>).map(AdminValueToJSON)),
        'subUsageTypesOptions': value.subUsageTypesOptions === undefined ? undefined : ((value.subUsageTypesOptions as Array<any>).map(AdminValueToJSON)),
        'professionsOptions': value.professionsOptions === undefined ? undefined : ((value.professionsOptions as Array<any>).map(AdminValueToJSON)),
        'roleCategoriesOptions': value.roleCategoriesOptions === undefined ? undefined : ((value.roleCategoriesOptions as Array<any>).map(AdminValueToJSON)),
        'industryFieldOptions': value.industryFieldOptions === undefined ? undefined : ((value.industryFieldOptions as Array<any>).map(AdminValueToJSON)),
        'activityFieldOptions': value.activityFieldOptions === undefined ? undefined : ((value.activityFieldOptions as Array<any>).map(AdminValueToJSON)),
        'transportationOptions': value.transportationOptions === undefined ? undefined : ((value.transportationOptions as Array<any>).map(AdminValueToJSON)),
        'extraExpensesOptions': value.extraExpensesOptions === undefined ? undefined : ((value.extraExpensesOptions as Array<any>).map(AdminValueToJSON)),
        'modelProvisionsOptions': value.modelProvisionsOptions === undefined ? undefined : ((value.modelProvisionsOptions as Array<any>).map(AdminValueToJSON)),
        'bahncardTypeOptions': value.bahncardTypeOptions === undefined ? undefined : ((value.bahncardTypeOptions as Array<any>).map(AdminValueToJSON)),
        'bahncardClassOptions': value.bahncardClassOptions === undefined ? undefined : ((value.bahncardClassOptions as Array<any>).map(AdminValueToJSON)),
        'confectionSizeFemaleOptions': value.confectionSizeFemaleOptions === undefined ? undefined : ((value.confectionSizeFemaleOptions as Array<any>).map(AdminValueToJSON)),
        'confectionSizeMaleOptions': value.confectionSizeMaleOptions === undefined ? undefined : ((value.confectionSizeMaleOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeEuOptions': value.shoeSizeEuOptions === undefined ? undefined : ((value.shoeSizeEuOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeEuFemaleOptions': value.shoeSizeEuFemaleOptions === undefined ? undefined : ((value.shoeSizeEuFemaleOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeEuMaleOptions': value.shoeSizeEuMaleOptions === undefined ? undefined : ((value.shoeSizeEuMaleOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeUsOptions': value.shoeSizeUsOptions === undefined ? undefined : ((value.shoeSizeUsOptions as Array<any>).map(AdminValueToJSON)),
        'braCircumferenceOptions': value.braCircumferenceOptions === undefined ? undefined : ((value.braCircumferenceOptions as Array<any>).map(AdminValueToJSON)),
        'braCupSizeOptions': value.braCupSizeOptions === undefined ? undefined : ((value.braCupSizeOptions as Array<any>).map(AdminValueToJSON)),
        'legLengthOptions': value.legLengthOptions === undefined ? undefined : ((value.legLengthOptions as Array<any>).map(AdminValueToJSON)),
        'headCircumferenceOptions': value.headCircumferenceOptions === undefined ? undefined : ((value.headCircumferenceOptions as Array<any>).map(AdminValueToJSON)),
        'pantSizeFemaleOptions': value.pantSizeFemaleOptions === undefined ? undefined : ((value.pantSizeFemaleOptions as Array<any>).map(AdminValueToJSON)),
        'pantSizeMaleOptions': value.pantSizeMaleOptions === undefined ? undefined : ((value.pantSizeMaleOptions as Array<any>).map(AdminValueToJSON)),
        'timeToPaymentOptions': value.timeToPaymentOptions === undefined ? undefined : ((value.timeToPaymentOptions as Array<any>).map(AdminValueToJSON)),
        'billingOptions': value.billingOptions === undefined ? undefined : ((value.billingOptions as Array<any>).map(AdminValueToJSON)),
        'durationOptions': value.durationOptions === undefined ? undefined : ((value.durationOptions as Array<any>).map(AdminValueToJSON)),
    };
}

