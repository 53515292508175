/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ModelCostFormValuesDto } from './ModelCostFormValuesDto';
import {
    ModelCostFormValuesDtoFromJSON,
    ModelCostFormValuesDtoFromJSONTyped,
    ModelCostFormValuesDtoToJSON,
} from './ModelCostFormValuesDto';

/**
 * 
 * @export
 * @interface ModelCostsFormValuesDto
 */
export interface ModelCostsFormValuesDto {
    /**
     * 
     * @type {Array<ModelCostFormValuesDto>}
     * @memberof ModelCostsFormValuesDto
     */
    modelCosts?: Array<ModelCostFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelCostsFormValuesDto
     */
    costReasonOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ModelCostsFormValuesDto interface.
 */
export function instanceOfModelCostsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelCostsFormValuesDtoFromJSON(json: any): ModelCostsFormValuesDto {
    return ModelCostsFormValuesDtoFromJSONTyped(json, false);
}

export function ModelCostsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelCostsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelCosts': !exists(json, 'modelCosts') ? undefined : ((json['modelCosts'] as Array<any>).map(ModelCostFormValuesDtoFromJSON)),
        'costReasonOptions': !exists(json, 'costReasonOptions') ? undefined : ((json['costReasonOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ModelCostsFormValuesDtoToJSON(value?: ModelCostsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelCosts': value.modelCosts === undefined ? undefined : ((value.modelCosts as Array<any>).map(ModelCostFormValuesDtoToJSON)),
        'costReasonOptions': value.costReasonOptions === undefined ? undefined : ((value.costReasonOptions as Array<any>).map(AdminValueToJSON)),
    };
}

