/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushSubscriptionKeyDto
 */
export interface PushSubscriptionKeyDto {
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionKeyDto
     */
    auth?: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionKeyDto
     */
    p256dh?: string;
}

/**
 * Check if a given object implements the PushSubscriptionKeyDto interface.
 */
export function instanceOfPushSubscriptionKeyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushSubscriptionKeyDtoFromJSON(json: any): PushSubscriptionKeyDto {
    return PushSubscriptionKeyDtoFromJSONTyped(json, false);
}

export function PushSubscriptionKeyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushSubscriptionKeyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
        'p256dh': !exists(json, 'p256dh') ? undefined : json['p256dh'],
    };
}

export function PushSubscriptionKeyDtoToJSON(value?: PushSubscriptionKeyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth': value.auth,
        'p256dh': value.p256dh,
    };
}

