/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UseCaseResult
 */
export interface UseCaseResult {
    /**
     * 
     * @type {string}
     * @memberof UseCaseResult
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseResult
     */
    result?: UseCaseResultResultEnum;
}


/**
 * @export
 */
export const UseCaseResultResultEnum = {
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;
export type UseCaseResultResultEnum = typeof UseCaseResultResultEnum[keyof typeof UseCaseResultResultEnum];


/**
 * Check if a given object implements the UseCaseResult interface.
 */
export function instanceOfUseCaseResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UseCaseResultFromJSON(json: any): UseCaseResult {
    return UseCaseResultFromJSONTyped(json, false);
}

export function UseCaseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UseCaseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function UseCaseResultToJSON(value?: UseCaseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'result': value.result,
    };
}

