import {Component, Input} from "@angular/core";
import {ParticipantResponseDto} from "@brody-bookings/api";

@Component({
    selector: 'app-conversation-participant-display',
    templateUrl: './participantDisplay.component.html'
})

export class ParticipantDisplayComponent {
    @Input() selectedParticipantList: ParticipantResponseDto[] = [];
    @Input() onRemoveParticipant!: (participant: ParticipantResponseDto) => void;
}