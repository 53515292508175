/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEmployeeNamesForProjectsDto } from './CustomerEmployeeNamesForProjectsDto';
import {
    CustomerEmployeeNamesForProjectsDtoFromJSON,
    CustomerEmployeeNamesForProjectsDtoFromJSONTyped,
    CustomerEmployeeNamesForProjectsDtoToJSON,
} from './CustomerEmployeeNamesForProjectsDto';
import type { CustomerNamesForProjectsDto } from './CustomerNamesForProjectsDto';
import {
    CustomerNamesForProjectsDtoFromJSON,
    CustomerNamesForProjectsDtoFromJSONTyped,
    CustomerNamesForProjectsDtoToJSON,
} from './CustomerNamesForProjectsDto';

/**
 * 
 * @export
 * @interface CustomerAndEmployeesForProjectsDto
 */
export interface CustomerAndEmployeesForProjectsDto {
    /**
     * 
     * @type {CustomerNamesForProjectsDto}
     * @memberof CustomerAndEmployeesForProjectsDto
     */
    customerNamesForProjectsDto?: CustomerNamesForProjectsDto;
    /**
     * 
     * @type {Array<CustomerEmployeeNamesForProjectsDto>}
     * @memberof CustomerAndEmployeesForProjectsDto
     */
    customerEmployeeNamesForProjectsDtoList?: Array<CustomerEmployeeNamesForProjectsDto>;
}

/**
 * Check if a given object implements the CustomerAndEmployeesForProjectsDto interface.
 */
export function instanceOfCustomerAndEmployeesForProjectsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerAndEmployeesForProjectsDtoFromJSON(json: any): CustomerAndEmployeesForProjectsDto {
    return CustomerAndEmployeesForProjectsDtoFromJSONTyped(json, false);
}

export function CustomerAndEmployeesForProjectsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAndEmployeesForProjectsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerNamesForProjectsDto': !exists(json, 'customerNamesForProjectsDto') ? undefined : CustomerNamesForProjectsDtoFromJSON(json['customerNamesForProjectsDto']),
        'customerEmployeeNamesForProjectsDtoList': !exists(json, 'customerEmployeeNamesForProjectsDtoList') ? undefined : ((json['customerEmployeeNamesForProjectsDtoList'] as Array<any>).map(CustomerEmployeeNamesForProjectsDtoFromJSON)),
    };
}

export function CustomerAndEmployeesForProjectsDtoToJSON(value?: CustomerAndEmployeesForProjectsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerNamesForProjectsDto': CustomerNamesForProjectsDtoToJSON(value.customerNamesForProjectsDto),
        'customerEmployeeNamesForProjectsDtoList': value.customerEmployeeNamesForProjectsDtoList === undefined ? undefined : ((value.customerEmployeeNamesForProjectsDtoList as Array<any>).map(CustomerEmployeeNamesForProjectsDtoToJSON)),
    };
}

