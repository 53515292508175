/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteMediaRequestDto
 */
export interface DeleteMediaRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteMediaRequestDto
     */
    filenames?: Array<string>;
}

/**
 * Check if a given object implements the DeleteMediaRequestDto interface.
 */
export function instanceOfDeleteMediaRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteMediaRequestDtoFromJSON(json: any): DeleteMediaRequestDto {
    return DeleteMediaRequestDtoFromJSONTyped(json, false);
}

export function DeleteMediaRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteMediaRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filenames': !exists(json, 'filenames') ? undefined : json['filenames'],
    };
}

export function DeleteMediaRequestDtoToJSON(value?: DeleteMediaRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filenames': value.filenames,
    };
}

