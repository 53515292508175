/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelPublicDataWishListItem } from './ModelPublicDataWishListItem';
import {
    ModelPublicDataWishListItemFromJSON,
    ModelPublicDataWishListItemFromJSONTyped,
    ModelPublicDataWishListItemToJSON,
} from './ModelPublicDataWishListItem';

/**
 * 
 * @export
 * @interface ModelPublicDataWishListDto
 */
export interface ModelPublicDataWishListDto {
    /**
     * 
     * @type {Array<ModelPublicDataWishListItem>}
     * @memberof ModelPublicDataWishListDto
     */
    wishListedModels?: Array<ModelPublicDataWishListItem>;
}

/**
 * Check if a given object implements the ModelPublicDataWishListDto interface.
 */
export function instanceOfModelPublicDataWishListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataWishListDtoFromJSON(json: any): ModelPublicDataWishListDto {
    return ModelPublicDataWishListDtoFromJSONTyped(json, false);
}

export function ModelPublicDataWishListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataWishListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wishListedModels': !exists(json, 'wishListedModels') ? undefined : ((json['wishListedModels'] as Array<any>).map(ModelPublicDataWishListItemFromJSON)),
    };
}

export function ModelPublicDataWishListDtoToJSON(value?: ModelPublicDataWishListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wishListedModels': value.wishListedModels === undefined ? undefined : ((value.wishListedModels as Array<any>).map(ModelPublicDataWishListItemToJSON)),
    };
}

