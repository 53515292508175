import { Injectable } from '@angular/core';
import {Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  private showErrorModalSubject = new Subject<string>();

  showErrorModal(message: string): void {
    this.showErrorModalSubject.next(message);
  }

  get showErrorModal$(): Observable<string> {
    return this.showErrorModalSubject.asObservable();
  }
}
