/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingProducerAddresseeFormValues
 */
export interface BookingProducerAddresseeFormValues {
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    addressee?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    addresseeId?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    customerStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    customerZipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    customerCity?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    companySuffix?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProducerAddresseeFormValues
     */
    phone?: string;
}

/**
 * Check if a given object implements the BookingProducerAddresseeFormValues interface.
 */
export function instanceOfBookingProducerAddresseeFormValues(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingProducerAddresseeFormValuesFromJSON(json: any): BookingProducerAddresseeFormValues {
    return BookingProducerAddresseeFormValuesFromJSONTyped(json, false);
}

export function BookingProducerAddresseeFormValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingProducerAddresseeFormValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressee': !exists(json, 'addressee') ? undefined : json['addressee'],
        'addresseeId': !exists(json, 'addresseeId') ? undefined : json['addresseeId'],
        'customerStreet': !exists(json, 'customerStreet') ? undefined : json['customerStreet'],
        'customerZipCode': !exists(json, 'customerZipCode') ? undefined : json['customerZipCode'],
        'customerCity': !exists(json, 'customerCity') ? undefined : json['customerCity'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'companySuffix': !exists(json, 'companySuffix') ? undefined : json['companySuffix'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function BookingProducerAddresseeFormValuesToJSON(value?: BookingProducerAddresseeFormValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressee': value.addressee,
        'addresseeId': value.addresseeId,
        'customerStreet': value.customerStreet,
        'customerZipCode': value.customerZipCode,
        'customerCity': value.customerCity,
        'mobile': value.mobile,
        'company': value.company,
        'companySuffix': value.companySuffix,
        'phone': value.phone,
    };
}

