/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelJobHistoryItemDto
 */
export interface ModelJobHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof ModelJobHistoryItemDto
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelJobHistoryItemDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelJobHistoryItemDto
     */
    dateFinished?: string;
}

/**
 * Check if a given object implements the ModelJobHistoryItemDto interface.
 */
export function instanceOfModelJobHistoryItemDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelJobHistoryItemDtoFromJSON(json: any): ModelJobHistoryItemDto {
    return ModelJobHistoryItemDtoFromJSONTyped(json, false);
}

export function ModelJobHistoryItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelJobHistoryItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'dateFinished': !exists(json, 'dateFinished') ? undefined : json['dateFinished'],
    };
}

export function ModelJobHistoryItemDtoToJSON(value?: ModelJobHistoryItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectName': value.projectName,
        'roleName': value.roleName,
        'dateFinished': value.dateFinished,
    };
}

