/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyBookerContactFormValuesDto } from './AgencyBookerContactFormValuesDto';
import {
    AgencyBookerContactFormValuesDtoFromJSON,
    AgencyBookerContactFormValuesDtoFromJSONTyped,
    AgencyBookerContactFormValuesDtoToJSON,
} from './AgencyBookerContactFormValuesDto';
import type { AgencyBookerSexFormValuesDto } from './AgencyBookerSexFormValuesDto';
import {
    AgencyBookerSexFormValuesDtoFromJSON,
    AgencyBookerSexFormValuesDtoFromJSONTyped,
    AgencyBookerSexFormValuesDtoToJSON,
} from './AgencyBookerSexFormValuesDto';

/**
 * 
 * @export
 * @interface AgencyBookerFormValuesDto
 */
export interface AgencyBookerFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerLastName?: string;
    /**
     * 
     * @type {AgencyBookerContactFormValuesDto}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerContact?: AgencyBookerContactFormValuesDto;
    /**
     * 
     * @type {AgencyBookerSexFormValuesDto}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerSex?: AgencyBookerSexFormValuesDto;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerPosition?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerBirthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    bookerOtherInformation?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBookerFormValuesDto
     */
    oauthUserId?: string;
}

/**
 * Check if a given object implements the AgencyBookerFormValuesDto interface.
 */
export function instanceOfAgencyBookerFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBookerFormValuesDtoFromJSON(json: any): AgencyBookerFormValuesDto {
    return AgencyBookerFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyBookerFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBookerFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
        'bookerContact': !exists(json, 'bookerContact') ? undefined : AgencyBookerContactFormValuesDtoFromJSON(json['bookerContact']),
        'bookerSex': !exists(json, 'bookerSex') ? undefined : AgencyBookerSexFormValuesDtoFromJSON(json['bookerSex']),
        'bookerPosition': !exists(json, 'bookerPosition') ? undefined : json['bookerPosition'],
        'bookerBirthday': !exists(json, 'bookerBirthday') ? undefined : (new Date(json['bookerBirthday'])),
        'bookerTitle': !exists(json, 'bookerTitle') ? undefined : json['bookerTitle'],
        'bookerOtherInformation': !exists(json, 'bookerOtherInformation') ? undefined : json['bookerOtherInformation'],
        'oauthUserId': !exists(json, 'oauthUserId') ? undefined : json['oauthUserId'],
    };
}

export function AgencyBookerFormValuesDtoToJSON(value?: AgencyBookerFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookerId': value.bookerId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
        'bookerContact': AgencyBookerContactFormValuesDtoToJSON(value.bookerContact),
        'bookerSex': AgencyBookerSexFormValuesDtoToJSON(value.bookerSex),
        'bookerPosition': value.bookerPosition,
        'bookerBirthday': value.bookerBirthday === undefined ? undefined : (value.bookerBirthday.toISOString().substr(0,10)),
        'bookerTitle': value.bookerTitle,
        'bookerOtherInformation': value.bookerOtherInformation,
        'oauthUserId': value.oauthUserId,
    };
}

