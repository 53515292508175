import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';

import { GlobalProgressBarComponent } from './global-progress-bar.component';
import {GlobalProgressBarService} from "../../../service/global-progress-bar.service";

@NgModule({
    declarations: [GlobalProgressBarComponent],
    exports: [GlobalProgressBarComponent],
    providers: [GlobalProgressBarService],
    imports: [CommonModule, ProgressBarModule]
})
export class GlobalProgressBarModule {}
