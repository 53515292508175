/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface EmployeeSexFormValuesDto
 */
export interface EmployeeSexFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof EmployeeSexFormValuesDto
     */
    sexAdminValue?: AdminValue;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof EmployeeSexFormValuesDto
     */
    sexOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the EmployeeSexFormValuesDto interface.
 */
export function instanceOfEmployeeSexFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeSexFormValuesDtoFromJSON(json: any): EmployeeSexFormValuesDto {
    return EmployeeSexFormValuesDtoFromJSONTyped(json, false);
}

export function EmployeeSexFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeSexFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sexAdminValue': !exists(json, 'sexAdminValue') ? undefined : AdminValueFromJSON(json['sexAdminValue']),
        'sexOptions': !exists(json, 'sexOptions') ? undefined : ((json['sexOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function EmployeeSexFormValuesDtoToJSON(value?: EmployeeSexFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sexAdminValue': AdminValueToJSON(value.sexAdminValue),
        'sexOptions': value.sexOptions === undefined ? undefined : ((value.sexOptions as Array<any>).map(AdminValueToJSON)),
    };
}

