import { TaskManagerTranslations } from '../../demo/service/translation.service';

export function createTaskManagerMenu(
    menuTranslations: TaskManagerTranslations
    ): any[] {

    return [
        {
            label: menuTranslations.label,
            items: [
                {
                    label: menuTranslations.projects.label,
                    icon: 'pi pi-fw pi-box',
                    routerLink: ['/taskmanager/projects'],
                },
                {
                    label: menuTranslations.bills.label,
                    icon: 'pi pi-fw pi-money-bill',
                    routerLink: ['/taskmanager/bills']
                },
                {
                    label: menuTranslations.update_portfolio.label,
                    icon: 'pi pi-fw pi-image',
                    routerLink: ['/taskmanager/update-portfolio']
                }
            ]
        }
    ];
}
