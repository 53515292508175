/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Specials
 */
export interface Specials {
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    nude: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    halfNude: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    fur: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    smoking: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    catwalk: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    moderation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    speaker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    tv: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Specials
     */
    lingerie: boolean;
}

/**
 * Check if a given object implements the Specials interface.
 */
export function instanceOfSpecials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nude" in value;
    isInstance = isInstance && "halfNude" in value;
    isInstance = isInstance && "fur" in value;
    isInstance = isInstance && "smoking" in value;
    isInstance = isInstance && "catwalk" in value;
    isInstance = isInstance && "moderation" in value;
    isInstance = isInstance && "speaker" in value;
    isInstance = isInstance && "tv" in value;
    isInstance = isInstance && "lingerie" in value;

    return isInstance;
}

export function SpecialsFromJSON(json: any): Specials {
    return SpecialsFromJSONTyped(json, false);
}

export function SpecialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Specials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nude': json['nude'],
        'halfNude': json['halfNude'],
        'fur': json['fur'],
        'smoking': json['smoking'],
        'catwalk': json['catwalk'],
        'moderation': json['moderation'],
        'speaker': json['speaker'],
        'tv': json['tv'],
        'lingerie': json['lingerie'],
    };
}

export function SpecialsToJSON(value?: Specials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nude': value.nude,
        'halfNude': value.halfNude,
        'fur': value.fur,
        'smoking': value.smoking,
        'catwalk': value.catwalk,
        'moderation': value.moderation,
        'speaker': value.speaker,
        'tv': value.tv,
        'lingerie': value.lingerie,
    };
}

