/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageResponseDto
 */
export interface MessageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    senderId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    senderName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    videoUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageResponseDto
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof MessageResponseDto
     */
    formattedTimeStamp?: string;
}

/**
 * Check if a given object implements the MessageResponseDto interface.
 */
export function instanceOfMessageResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MessageResponseDtoFromJSON(json: any): MessageResponseDto {
    return MessageResponseDtoFromJSONTyped(json, false);
}

export function MessageResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'senderId': !exists(json, 'senderId') ? undefined : json['senderId'],
        'senderName': !exists(json, 'senderName') ? undefined : json['senderName'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'timeStamp': !exists(json, 'timeStamp') ? undefined : (new Date(json['timeStamp'])),
        'formattedTimeStamp': !exists(json, 'formattedTimeStamp') ? undefined : json['formattedTimeStamp'],
    };
}

export function MessageResponseDtoToJSON(value?: MessageResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageId': value.messageId,
        'senderId': value.senderId,
        'senderName': value.senderName,
        'message': value.message,
        'imageUrl': value.imageUrl,
        'videoUrl': value.videoUrl,
        'timeStamp': value.timeStamp === undefined ? undefined : (value.timeStamp.toISOString()),
        'formattedTimeStamp': value.formattedTimeStamp,
    };
}

