/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectMotif } from './ProjectMotif';
import {
    ProjectMotifFromJSON,
    ProjectMotifFromJSONTyped,
    ProjectMotifToJSON,
} from './ProjectMotif';

/**
 * 
 * @export
 * @interface ProjectLocation
 */
export interface ProjectLocation {
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationContact?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    locationId?: string;
    /**
     * 
     * @type {Array<ProjectMotif>}
     * @memberof ProjectLocation
     */
    motifs?: Array<ProjectMotif>;
}

/**
 * Check if a given object implements the ProjectLocation interface.
 */
export function instanceOfProjectLocation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectLocationFromJSON(json: any): ProjectLocation {
    return ProjectLocationFromJSONTyped(json, false);
}

export function ProjectLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
        'locationAddress': !exists(json, 'locationAddress') ? undefined : json['locationAddress'],
        'locationInfo': !exists(json, 'locationInfo') ? undefined : json['locationInfo'],
        'locationContact': !exists(json, 'locationContact') ? undefined : json['locationContact'],
        'locationMobile': !exists(json, 'locationMobile') ? undefined : json['locationMobile'],
        'locationPhone': !exists(json, 'locationPhone') ? undefined : json['locationPhone'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'motifs': !exists(json, 'motifs') ? undefined : ((json['motifs'] as Array<any>).map(ProjectMotifFromJSON)),
    };
}

export function ProjectLocationToJSON(value?: ProjectLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationName': value.locationName,
        'locationAddress': value.locationAddress,
        'locationInfo': value.locationInfo,
        'locationContact': value.locationContact,
        'locationMobile': value.locationMobile,
        'locationPhone': value.locationPhone,
        'locationId': value.locationId,
        'motifs': value.motifs === undefined ? undefined : ((value.motifs as Array<any>).map(ProjectMotifToJSON)),
    };
}

