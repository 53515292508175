/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChargeableProjectFormValuesDto
 */
export interface ChargeableProjectFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ChargeableProjectFormValuesDto
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProjectFormValuesDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProjectFormValuesDto
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeableProjectFormValuesDto
     */
    roleId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProjectFormValuesDto
     */
    productionDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ChargeableProjectFormValuesDto
     */
    paid?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProjectFormValuesDto
     */
    datePaid?: Date;
    /**
     * 
     * @type {number}
     * @memberof ChargeableProjectFormValuesDto
     */
    billId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ChargeableProjectFormValuesDto
     */
    billCreated?: Date;
}

/**
 * Check if a given object implements the ChargeableProjectFormValuesDto interface.
 */
export function instanceOfChargeableProjectFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChargeableProjectFormValuesDtoFromJSON(json: any): ChargeableProjectFormValuesDto {
    return ChargeableProjectFormValuesDtoFromJSONTyped(json, false);
}

export function ChargeableProjectFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeableProjectFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'productionDate': !exists(json, 'productionDate') ? undefined : (new Date(json['productionDate'])),
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'datePaid': !exists(json, 'datePaid') ? undefined : (new Date(json['datePaid'])),
        'billId': !exists(json, 'billId') ? undefined : json['billId'],
        'billCreated': !exists(json, 'billCreated') ? undefined : (new Date(json['billCreated'])),
    };
}

export function ChargeableProjectFormValuesDtoToJSON(value?: ChargeableProjectFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectName': value.projectName,
        'projectId': value.projectId,
        'roleName': value.roleName,
        'roleId': value.roleId,
        'productionDate': value.productionDate === undefined ? undefined : (value.productionDate.toISOString().substr(0,10)),
        'paid': value.paid,
        'datePaid': value.datePaid === undefined ? undefined : (value.datePaid.toISOString().substr(0,10)),
        'billId': value.billId,
        'billCreated': value.billCreated === undefined ? undefined : (value.billCreated.toISOString().substr(0,10)),
    };
}

