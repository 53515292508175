/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActingExperience
 */
export interface ActingExperience {
    /**
     * 
     * @type {string}
     * @memberof ActingExperience
     */
    level: ActingExperienceLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ActingExperience
     */
    experiences: string;
}


/**
 * @export
 */
export const ActingExperienceLevelEnum = {
    Professional: 'PROFESSIONAL',
    SemiProfessional: 'SEMI_PROFESSIONAL',
    Commercials: 'COMMERCIALS'
} as const;
export type ActingExperienceLevelEnum = typeof ActingExperienceLevelEnum[keyof typeof ActingExperienceLevelEnum];


/**
 * Check if a given object implements the ActingExperience interface.
 */
export function instanceOfActingExperience(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "experiences" in value;

    return isInstance;
}

export function ActingExperienceFromJSON(json: any): ActingExperience {
    return ActingExperienceFromJSONTyped(json, false);
}

export function ActingExperienceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActingExperience {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'experiences': json['experiences'],
    };
}

export function ActingExperienceToJSON(value?: ActingExperience | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'experiences': value.experiences,
    };
}

