/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface DietFormValuesDto
 */
export interface DietFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof DietFormValuesDto
     */
    type?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof DietFormValuesDto
     */
    allergies?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof DietFormValuesDto
     */
    dietOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the DietFormValuesDto interface.
 */
export function instanceOfDietFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DietFormValuesDtoFromJSON(json: any): DietFormValuesDto {
    return DietFormValuesDtoFromJSONTyped(json, false);
}

export function DietFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DietFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AdminValueFromJSON(json['type']),
        'allergies': !exists(json, 'allergies') ? undefined : json['allergies'],
        'dietOptions': !exists(json, 'dietOptions') ? undefined : ((json['dietOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function DietFormValuesDtoToJSON(value?: DietFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AdminValueToJSON(value.type),
        'allergies': value.allergies,
        'dietOptions': value.dietOptions === undefined ? undefined : ((value.dietOptions as Array<any>).map(AdminValueToJSON)),
    };
}

