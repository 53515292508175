/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectCastingFormValuesDto
 */
export interface ProjectCastingFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectCastingFormValuesDto
     */
    link?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectCastingFormValuesDto
     */
    date?: Date;
}

/**
 * Check if a given object implements the ProjectCastingFormValuesDto interface.
 */
export function instanceOfProjectCastingFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectCastingFormValuesDtoFromJSON(json: any): ProjectCastingFormValuesDto {
    return ProjectCastingFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectCastingFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCastingFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': !exists(json, 'link') ? undefined : json['link'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function ProjectCastingFormValuesDtoToJSON(value?: ProjectCastingFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
    };
}

