/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageDto } from './MessageDto';
import {
    MessageDtoFromJSON,
    MessageDtoFromJSONTyped,
    MessageDtoToJSON,
} from './MessageDto';

/**
 * 
 * @export
 * @interface MessageListDto
 */
export interface MessageListDto {
    /**
     * 
     * @type {Array<MessageDto>}
     * @memberof MessageListDto
     */
    messages?: Array<MessageDto>;
}

/**
 * Check if a given object implements the MessageListDto interface.
 */
export function instanceOfMessageListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MessageListDtoFromJSON(json: any): MessageListDto {
    return MessageListDtoFromJSONTyped(json, false);
}

export function MessageListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageDtoFromJSON)),
    };
}

export function MessageListDtoToJSON(value?: MessageListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageDtoToJSON)),
    };
}

