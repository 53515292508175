/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeChargeableProjectPaidOnRequestDto
 */
export interface ChangeChargeableProjectPaidOnRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeChargeableProjectPaidOnRequestDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeChargeableProjectPaidOnRequestDto
     */
    roleId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeChargeableProjectPaidOnRequestDto
     */
    paid?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ChangeChargeableProjectPaidOnRequestDto
     */
    datePaid?: Date;
}

/**
 * Check if a given object implements the ChangeChargeableProjectPaidOnRequestDto interface.
 */
export function instanceOfChangeChargeableProjectPaidOnRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeChargeableProjectPaidOnRequestDtoFromJSON(json: any): ChangeChargeableProjectPaidOnRequestDto {
    return ChangeChargeableProjectPaidOnRequestDtoFromJSONTyped(json, false);
}

export function ChangeChargeableProjectPaidOnRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeChargeableProjectPaidOnRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'datePaid': !exists(json, 'datePaid') ? undefined : (new Date(json['datePaid'])),
    };
}

export function ChangeChargeableProjectPaidOnRequestDtoToJSON(value?: ChangeChargeableProjectPaidOnRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'roleId': value.roleId,
        'paid': value.paid,
        'datePaid': value.datePaid === undefined ? undefined : (value.datePaid.toISOString().substr(0,10)),
    };
}

