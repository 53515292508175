/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyId } from './AgencyId';
import {
    AgencyIdFromJSON,
    AgencyIdFromJSONTyped,
    AgencyIdToJSON,
} from './AgencyId';
import type { AgencyName } from './AgencyName';
import {
    AgencyNameFromJSON,
    AgencyNameFromJSONTyped,
    AgencyNameToJSON,
} from './AgencyName';

/**
 * 
 * @export
 * @interface AgencyTableItemDto
 */
export interface AgencyTableItemDto {
    /**
     * 
     * @type {AgencyId}
     * @memberof AgencyTableItemDto
     */
    agencyId: AgencyId;
    /**
     * 
     * @type {AgencyName}
     * @memberof AgencyTableItemDto
     */
    agencyName?: AgencyName;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    lastEditedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyTableItemDto
     */
    url?: string;
}

/**
 * Check if a given object implements the AgencyTableItemDto interface.
 */
export function instanceOfAgencyTableItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyId" in value;

    return isInstance;
}

export function AgencyTableItemDtoFromJSON(json: any): AgencyTableItemDto {
    return AgencyTableItemDtoFromJSONTyped(json, false);
}

export function AgencyTableItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyTableItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': AgencyIdFromJSON(json['agencyId']),
        'agencyName': !exists(json, 'agencyName') ? undefined : AgencyNameFromJSON(json['agencyName']),
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : json['lastEditedAt'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function AgencyTableItemDtoToJSON(value?: AgencyTableItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': AgencyIdToJSON(value.agencyId),
        'agencyName': AgencyNameToJSON(value.agencyName),
        'lastEditedAt': value.lastEditedAt,
        'city': value.city,
        'zipCode': value.zipCode,
        'phone': value.phone,
        'fax': value.fax,
        'url': value.url,
    };
}

