/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectProducerFormValueDto } from './ProjectProducerFormValueDto';
import {
    ProjectProducerFormValueDtoFromJSON,
    ProjectProducerFormValueDtoFromJSONTyped,
    ProjectProducerFormValueDtoToJSON,
} from './ProjectProducerFormValueDto';

/**
 * 
 * @export
 * @interface ProjectCreateRequestDto
 */
export interface ProjectCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateRequestDto
     */
    workingTitle: string;
    /**
     * 
     * @type {ProjectProducerFormValueDto}
     * @memberof ProjectCreateRequestDto
     */
    producer?: ProjectProducerFormValueDto;
}

/**
 * Check if a given object implements the ProjectCreateRequestDto interface.
 */
export function instanceOfProjectCreateRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workingTitle" in value;

    return isInstance;
}

export function ProjectCreateRequestDtoFromJSON(json: any): ProjectCreateRequestDto {
    return ProjectCreateRequestDtoFromJSONTyped(json, false);
}

export function ProjectCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workingTitle': json['workingTitle'],
        'producer': !exists(json, 'producer') ? undefined : ProjectProducerFormValueDtoFromJSON(json['producer']),
    };
}

export function ProjectCreateRequestDtoToJSON(value?: ProjectCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workingTitle': value.workingTitle,
        'producer': ProjectProducerFormValueDtoToJSON(value.producer),
    };
}

