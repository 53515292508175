/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelId } from './ModelId';
import {
    ModelIdFromJSON,
    ModelIdFromJSONTyped,
    ModelIdToJSON,
} from './ModelId';

/**
 * 
 * @export
 * @interface ModelPublicDataWishListItem
 */
export interface ModelPublicDataWishListItem {
    /**
     * 
     * @type {ModelId}
     * @memberof ModelPublicDataWishListItem
     */
    modelId?: ModelId;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataWishListItem
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataWishListItem
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataWishListItem
     */
    imageAltUrl?: string;
}

/**
 * Check if a given object implements the ModelPublicDataWishListItem interface.
 */
export function instanceOfModelPublicDataWishListItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataWishListItemFromJSON(json: any): ModelPublicDataWishListItem {
    return ModelPublicDataWishListItemFromJSONTyped(json, false);
}

export function ModelPublicDataWishListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataWishListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : ModelIdFromJSON(json['modelId']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'imageAltUrl': !exists(json, 'imageAltUrl') ? undefined : json['imageAltUrl'],
    };
}

export function ModelPublicDataWishListItemToJSON(value?: ModelPublicDataWishListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': ModelIdToJSON(value.modelId),
        'firstName': value.firstName,
        'imageUrl': value.imageUrl,
        'imageAltUrl': value.imageAltUrl,
    };
}

