/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageResponseDto } from './MessageResponseDto';
import {
    MessageResponseDtoFromJSON,
    MessageResponseDtoFromJSONTyped,
    MessageResponseDtoToJSON,
} from './MessageResponseDto';
import type { ParticipantResponseDto } from './ParticipantResponseDto';
import {
    ParticipantResponseDtoFromJSON,
    ParticipantResponseDtoFromJSONTyped,
    ParticipantResponseDtoToJSON,
} from './ParticipantResponseDto';

/**
 * 
 * @export
 * @interface ConversationResponseDto
 */
export interface ConversationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationResponseDto
     */
    conversationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationResponseDto
     */
    conversationTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationResponseDto
     */
    isGroupChat?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationResponseDto
     */
    projectId?: string;
    /**
     * 
     * @type {Array<ParticipantResponseDto>}
     * @memberof ConversationResponseDto
     */
    participants?: Array<ParticipantResponseDto>;
    /**
     * 
     * @type {Array<MessageResponseDto>}
     * @memberof ConversationResponseDto
     */
    messages?: Array<MessageResponseDto>;
    /**
     * 
     * @type {Date}
     * @memberof ConversationResponseDto
     */
    lastEditedAt?: Date;
}

/**
 * Check if a given object implements the ConversationResponseDto interface.
 */
export function instanceOfConversationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationResponseDtoFromJSON(json: any): ConversationResponseDto {
    return ConversationResponseDtoFromJSONTyped(json, false);
}

export function ConversationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationId': !exists(json, 'conversationId') ? undefined : json['conversationId'],
        'conversationTitle': !exists(json, 'conversationTitle') ? undefined : json['conversationTitle'],
        'isGroupChat': !exists(json, 'isGroupChat') ? undefined : json['isGroupChat'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(ParticipantResponseDtoFromJSON)),
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageResponseDtoFromJSON)),
        'lastEditedAt': !exists(json, 'lastEditedAt') ? undefined : (new Date(json['lastEditedAt'])),
    };
}

export function ConversationResponseDtoToJSON(value?: ConversationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationId': value.conversationId,
        'conversationTitle': value.conversationTitle,
        'isGroupChat': value.isGroupChat,
        'projectId': value.projectId,
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(ParticipantResponseDtoToJSON)),
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageResponseDtoToJSON)),
        'lastEditedAt': value.lastEditedAt === undefined ? undefined : (value.lastEditedAt.toISOString()),
    };
}

