/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectBillingInvoiceFormValuesDto
 */
export interface ProjectBillingInvoiceFormValuesDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    identification?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    invoiceStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    dueDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    ifPaymentReceived?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    paymentReceived?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectBillingInvoiceFormValuesDto
     */
    invoiceStatusOptions?: Array<string>;
}

/**
 * Check if a given object implements the ProjectBillingInvoiceFormValuesDto interface.
 */
export function instanceOfProjectBillingInvoiceFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectBillingInvoiceFormValuesDtoFromJSON(json: any): ProjectBillingInvoiceFormValuesDto {
    return ProjectBillingInvoiceFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectBillingInvoiceFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBillingInvoiceFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identification': !exists(json, 'identification') ? undefined : json['identification'],
        'invoiceStatus': !exists(json, 'invoiceStatus') ? undefined : json['invoiceStatus'],
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'ifPaymentReceived': !exists(json, 'ifPaymentReceived') ? undefined : json['ifPaymentReceived'],
        'paymentReceived': !exists(json, 'paymentReceived') ? undefined : (new Date(json['paymentReceived'])),
        'invoiceStatusOptions': !exists(json, 'invoiceStatusOptions') ? undefined : json['invoiceStatusOptions'],
    };
}

export function ProjectBillingInvoiceFormValuesDtoToJSON(value?: ProjectBillingInvoiceFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identification': value.identification,
        'invoiceStatus': value.invoiceStatus,
        'dueDate': value.dueDate,
        'ifPaymentReceived': value.ifPaymentReceived,
        'paymentReceived': value.paymentReceived === undefined ? undefined : (value.paymentReceived.toISOString().substr(0,10)),
        'invoiceStatusOptions': value.invoiceStatusOptions,
    };
}

