/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { SportFormValuesDto } from './SportFormValuesDto';
import {
    SportFormValuesDtoFromJSON,
    SportFormValuesDtoFromJSONTyped,
    SportFormValuesDtoToJSON,
} from './SportFormValuesDto';

/**
 * 
 * @export
 * @interface SportsFormValuesDto
 */
export interface SportsFormValuesDto {
    /**
     * 
     * @type {Array<SportFormValuesDto>}
     * @memberof SportsFormValuesDto
     */
    sports?: Array<SportFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof SportsFormValuesDto
     */
    sportOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SportsFormValuesDto
     */
    sportLevelOptions?: Array<string>;
}

/**
 * Check if a given object implements the SportsFormValuesDto interface.
 */
export function instanceOfSportsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SportsFormValuesDtoFromJSON(json: any): SportsFormValuesDto {
    return SportsFormValuesDtoFromJSONTyped(json, false);
}

export function SportsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SportsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(SportFormValuesDtoFromJSON)),
        'sportOptions': !exists(json, 'sportOptions') ? undefined : ((json['sportOptions'] as Array<any>).map(AdminValueFromJSON)),
        'sportLevelOptions': !exists(json, 'sportLevelOptions') ? undefined : json['sportLevelOptions'],
    };
}

export function SportsFormValuesDtoToJSON(value?: SportsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(SportFormValuesDtoToJSON)),
        'sportOptions': value.sportOptions === undefined ? undefined : ((value.sportOptions as Array<any>).map(AdminValueToJSON)),
        'sportLevelOptions': value.sportLevelOptions,
    };
}

