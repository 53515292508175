/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface BodyDetailsFormValuesDto
 */
export interface BodyDetailsFormValuesDto {
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    confectionSizeMin?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    confectionSizeMax?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    shoeSizeEu?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    shoeSizeUs?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    braCircumference?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    braCupSize?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    legLength?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    headCircumference?: AdminValue;
    /**
     * 
     * @type {AdminValue}
     * @memberof BodyDetailsFormValuesDto
     */
    pantSize?: AdminValue;
    /**
     * 
     * @type {boolean}
     * @memberof BodyDetailsFormValuesDto
     */
    isPregnant?: boolean;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    confectionSizeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    shoeSizeEuOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    shoeSizeUsOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    braCircumferenceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    braCupSizeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    legLengthOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    headCircumferenceOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof BodyDetailsFormValuesDto
     */
    pantSizeOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the BodyDetailsFormValuesDto interface.
 */
export function instanceOfBodyDetailsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BodyDetailsFormValuesDtoFromJSON(json: any): BodyDetailsFormValuesDto {
    return BodyDetailsFormValuesDtoFromJSONTyped(json, false);
}

export function BodyDetailsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyDetailsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confectionSizeMin': !exists(json, 'confectionSizeMin') ? undefined : AdminValueFromJSON(json['confectionSizeMin']),
        'confectionSizeMax': !exists(json, 'confectionSizeMax') ? undefined : AdminValueFromJSON(json['confectionSizeMax']),
        'shoeSizeEu': !exists(json, 'shoeSizeEu') ? undefined : AdminValueFromJSON(json['shoeSizeEu']),
        'shoeSizeUs': !exists(json, 'shoeSizeUs') ? undefined : AdminValueFromJSON(json['shoeSizeUs']),
        'braCircumference': !exists(json, 'braCircumference') ? undefined : AdminValueFromJSON(json['braCircumference']),
        'braCupSize': !exists(json, 'braCupSize') ? undefined : AdminValueFromJSON(json['braCupSize']),
        'legLength': !exists(json, 'legLength') ? undefined : AdminValueFromJSON(json['legLength']),
        'headCircumference': !exists(json, 'headCircumference') ? undefined : AdminValueFromJSON(json['headCircumference']),
        'pantSize': !exists(json, 'pantSize') ? undefined : AdminValueFromJSON(json['pantSize']),
        'isPregnant': !exists(json, 'isPregnant') ? undefined : json['isPregnant'],
        'confectionSizeOptions': !exists(json, 'confectionSizeOptions') ? undefined : ((json['confectionSizeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeEuOptions': !exists(json, 'shoeSizeEuOptions') ? undefined : ((json['shoeSizeEuOptions'] as Array<any>).map(AdminValueFromJSON)),
        'shoeSizeUsOptions': !exists(json, 'shoeSizeUsOptions') ? undefined : ((json['shoeSizeUsOptions'] as Array<any>).map(AdminValueFromJSON)),
        'braCircumferenceOptions': !exists(json, 'braCircumferenceOptions') ? undefined : ((json['braCircumferenceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'braCupSizeOptions': !exists(json, 'braCupSizeOptions') ? undefined : ((json['braCupSizeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'legLengthOptions': !exists(json, 'legLengthOptions') ? undefined : ((json['legLengthOptions'] as Array<any>).map(AdminValueFromJSON)),
        'headCircumferenceOptions': !exists(json, 'headCircumferenceOptions') ? undefined : ((json['headCircumferenceOptions'] as Array<any>).map(AdminValueFromJSON)),
        'pantSizeOptions': !exists(json, 'pantSizeOptions') ? undefined : ((json['pantSizeOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function BodyDetailsFormValuesDtoToJSON(value?: BodyDetailsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confectionSizeMin': AdminValueToJSON(value.confectionSizeMin),
        'confectionSizeMax': AdminValueToJSON(value.confectionSizeMax),
        'shoeSizeEu': AdminValueToJSON(value.shoeSizeEu),
        'shoeSizeUs': AdminValueToJSON(value.shoeSizeUs),
        'braCircumference': AdminValueToJSON(value.braCircumference),
        'braCupSize': AdminValueToJSON(value.braCupSize),
        'legLength': AdminValueToJSON(value.legLength),
        'headCircumference': AdminValueToJSON(value.headCircumference),
        'pantSize': AdminValueToJSON(value.pantSize),
        'isPregnant': value.isPregnant,
        'confectionSizeOptions': value.confectionSizeOptions === undefined ? undefined : ((value.confectionSizeOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeEuOptions': value.shoeSizeEuOptions === undefined ? undefined : ((value.shoeSizeEuOptions as Array<any>).map(AdminValueToJSON)),
        'shoeSizeUsOptions': value.shoeSizeUsOptions === undefined ? undefined : ((value.shoeSizeUsOptions as Array<any>).map(AdminValueToJSON)),
        'braCircumferenceOptions': value.braCircumferenceOptions === undefined ? undefined : ((value.braCircumferenceOptions as Array<any>).map(AdminValueToJSON)),
        'braCupSizeOptions': value.braCupSizeOptions === undefined ? undefined : ((value.braCupSizeOptions as Array<any>).map(AdminValueToJSON)),
        'legLengthOptions': value.legLengthOptions === undefined ? undefined : ((value.legLengthOptions as Array<any>).map(AdminValueToJSON)),
        'headCircumferenceOptions': value.headCircumferenceOptions === undefined ? undefined : ((value.headCircumferenceOptions as Array<any>).map(AdminValueToJSON)),
        'pantSizeOptions': value.pantSizeOptions === undefined ? undefined : ((value.pantSizeOptions as Array<any>).map(AdminValueToJSON)),
    };
}

