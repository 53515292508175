import {Component} from "@angular/core";
import {ErrorModalService} from "../../service/errorModal.service";

@Component({
    selector: "app-error-modal",
    templateUrl: "./error.modal.html"
})
export class ErrorModalComponent {
    display: boolean = false;
    errorText: string = "";

    constructor(
        private errorModalService: ErrorModalService
    ) {
        this.errorModalService.showErrorModal$.subscribe(message => {
            this.showError(message);
        });
    }

    public showError(message: string): void {
        this.errorText = message;
        this.display = true;
    }

}
