/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectLocationFormValuesDto } from './ProjectLocationFormValuesDto';
import {
    ProjectLocationFormValuesDtoFromJSON,
    ProjectLocationFormValuesDtoFromJSONTyped,
    ProjectLocationFormValuesDtoToJSON,
} from './ProjectLocationFormValuesDto';
import type { ProjectMotifFormValuesDto } from './ProjectMotifFormValuesDto';
import {
    ProjectMotifFormValuesDtoFromJSON,
    ProjectMotifFormValuesDtoFromJSONTyped,
    ProjectMotifFormValuesDtoToJSON,
} from './ProjectMotifFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectLocationsAndMotifsFormValuesDto
 */
export interface ProjectLocationsAndMotifsFormValuesDto {
    /**
     * 
     * @type {Array<ProjectLocationFormValuesDto>}
     * @memberof ProjectLocationsAndMotifsFormValuesDto
     */
    locations?: Array<ProjectLocationFormValuesDto>;
    /**
     * 
     * @type {Array<ProjectMotifFormValuesDto>}
     * @memberof ProjectLocationsAndMotifsFormValuesDto
     */
    motifs?: Array<ProjectMotifFormValuesDto>;
}

/**
 * Check if a given object implements the ProjectLocationsAndMotifsFormValuesDto interface.
 */
export function instanceOfProjectLocationsAndMotifsFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectLocationsAndMotifsFormValuesDtoFromJSON(json: any): ProjectLocationsAndMotifsFormValuesDto {
    return ProjectLocationsAndMotifsFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectLocationsAndMotifsFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectLocationsAndMotifsFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(ProjectLocationFormValuesDtoFromJSON)),
        'motifs': !exists(json, 'motifs') ? undefined : ((json['motifs'] as Array<any>).map(ProjectMotifFormValuesDtoFromJSON)),
    };
}

export function ProjectLocationsAndMotifsFormValuesDtoToJSON(value?: ProjectLocationsAndMotifsFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(ProjectLocationFormValuesDtoToJSON)),
        'motifs': value.motifs === undefined ? undefined : ((value.motifs as Array<any>).map(ProjectMotifFormValuesDtoToJSON)),
    };
}

