/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UploadedVideoResult } from './UploadedVideoResult';
import {
    UploadedVideoResultFromJSON,
    UploadedVideoResultFromJSONTyped,
    UploadedVideoResultToJSON,
} from './UploadedVideoResult';

/**
 * 
 * @export
 * @interface VideoUploadResult
 */
export interface VideoUploadResult {
    /**
     * 
     * @type {Array<UploadedVideoResult>}
     * @memberof VideoUploadResult
     */
    videos?: Array<UploadedVideoResult>;
    /**
     * 
     * @type {string}
     * @memberof VideoUploadResult
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the VideoUploadResult interface.
 */
export function instanceOfVideoUploadResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VideoUploadResultFromJSON(json: any): VideoUploadResult {
    return VideoUploadResultFromJSONTyped(json, false);
}

export function VideoUploadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoUploadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videos': !exists(json, 'videos') ? undefined : ((json['videos'] as Array<any>).map(UploadedVideoResultFromJSON)),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function VideoUploadResultToJSON(value?: VideoUploadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videos': value.videos === undefined ? undefined : ((value.videos as Array<any>).map(UploadedVideoResultToJSON)),
        'errorMessage': value.errorMessage,
    };
}

