/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelPublicDataOverviewFilterDtoGeneral
 */
export interface ModelPublicDataOverviewFilterDtoGeneral {
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDtoGeneral
     */
    nationality?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ModelPublicDataOverviewFilterDtoGeneral
     */
    sex?: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof ModelPublicDataOverviewFilterDtoGeneral
     */
    zipDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelPublicDataOverviewFilterDtoGeneral
     */
    zipCode?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ModelPublicDataOverviewFilterDtoGeneral
     */
    age?: Array<number>;
}

/**
 * Check if a given object implements the ModelPublicDataOverviewFilterDtoGeneral interface.
 */
export function instanceOfModelPublicDataOverviewFilterDtoGeneral(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelPublicDataOverviewFilterDtoGeneralFromJSON(json: any): ModelPublicDataOverviewFilterDtoGeneral {
    return ModelPublicDataOverviewFilterDtoGeneralFromJSONTyped(json, false);
}

export function ModelPublicDataOverviewFilterDtoGeneralFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPublicDataOverviewFilterDtoGeneral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'zipDistance': !exists(json, 'zipDistance') ? undefined : json['zipDistance'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'age': !exists(json, 'age') ? undefined : json['age'],
    };
}

export function ModelPublicDataOverviewFilterDtoGeneralToJSON(value?: ModelPublicDataOverviewFilterDtoGeneral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nationality': value.nationality,
        'sex': value.sex,
        'zipDistance': value.zipDistance,
        'zipCode': value.zipCode,
        'age': value.age,
    };
}

