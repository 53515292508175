/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxInformationDto
 */
export interface TaxInformationDto {
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    foreignTaxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    taxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    taxIdentificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    taxOffice: string;
    /**
     * 
     * @type {string}
     * @memberof TaxInformationDto
     */
    vatId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxInformationDto
     */
    liableToVat: boolean;
}

/**
 * Check if a given object implements the TaxInformationDto interface.
 */
export function instanceOfTaxInformationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "foreignTaxNumber" in value;
    isInstance = isInstance && "taxNumber" in value;
    isInstance = isInstance && "taxIdentificationNumber" in value;
    isInstance = isInstance && "taxOffice" in value;
    isInstance = isInstance && "vatId" in value;
    isInstance = isInstance && "liableToVat" in value;

    return isInstance;
}

export function TaxInformationDtoFromJSON(json: any): TaxInformationDto {
    return TaxInformationDtoFromJSONTyped(json, false);
}

export function TaxInformationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxInformationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': json['country'],
        'foreignTaxNumber': json['foreignTaxNumber'],
        'taxNumber': json['taxNumber'],
        'taxIdentificationNumber': json['taxIdentificationNumber'],
        'taxOffice': json['taxOffice'],
        'vatId': json['vatId'],
        'liableToVat': json['liableToVat'],
    };
}

export function TaxInformationDtoToJSON(value?: TaxInformationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'foreignTaxNumber': value.foreignTaxNumber,
        'taxNumber': value.taxNumber,
        'taxIdentificationNumber': value.taxIdentificationNumber,
        'taxOffice': value.taxOffice,
        'vatId': value.vatId,
        'liableToVat': value.liableToVat,
    };
}

