/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateBookerUserCommand,
  CreateCustomerEmployeeUserCommand,
  CreateModelUserCommand,
  OauthUserCreatedResponseDto,
  User,
  UserDataResponseDto,
  UserInformationRequestDto,
} from '../models';
import {
    CreateBookerUserCommandFromJSON,
    CreateBookerUserCommandToJSON,
    CreateCustomerEmployeeUserCommandFromJSON,
    CreateCustomerEmployeeUserCommandToJSON,
    CreateModelUserCommandFromJSON,
    CreateModelUserCommandToJSON,
    OauthUserCreatedResponseDtoFromJSON,
    OauthUserCreatedResponseDtoToJSON,
    UserFromJSON,
    UserToJSON,
    UserDataResponseDtoFromJSON,
    UserDataResponseDtoToJSON,
    UserInformationRequestDtoFromJSON,
    UserInformationRequestDtoToJSON,
} from '../models';

export interface ChangePasswordRequest {
    id: string;
    body: string;
}

export interface CreateBookerUserRequest {
    createBookerUserCommand: CreateBookerUserCommand;
}

export interface CreateCustomerEmployeeUserRequest {
    createCustomerEmployeeUserCommand: CreateCustomerEmployeeUserCommand;
}

export interface CreateModelUserRequest {
    createModelUserCommand: CreateModelUserCommand;
}

export interface DeleteUserRequest {
    id: string;
}

export interface GetUserDataByOauthUsernameRequest {
    oAuthUsername: string;
}

export interface SaveUserRequest {
    userInformationRequestDto: UserInformationRequestDto;
}

/**
 * 
 */
export class AuthenticationControllerApi extends runtime.BaseAPI {

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePassword.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/{id}/password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createBookerUserRaw(requestParameters: CreateBookerUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OauthUserCreatedResponseDto>> {
        if (requestParameters.createBookerUserCommand === null || requestParameters.createBookerUserCommand === undefined) {
            throw new runtime.RequiredError('createBookerUserCommand','Required parameter requestParameters.createBookerUserCommand was null or undefined when calling createBookerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/create-booker-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBookerUserCommandToJSON(requestParameters.createBookerUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OauthUserCreatedResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async createBookerUser(requestParameters: CreateBookerUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OauthUserCreatedResponseDto> {
        const response = await this.createBookerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCustomerEmployeeUserRaw(requestParameters: CreateCustomerEmployeeUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OauthUserCreatedResponseDto>> {
        if (requestParameters.createCustomerEmployeeUserCommand === null || requestParameters.createCustomerEmployeeUserCommand === undefined) {
            throw new runtime.RequiredError('createCustomerEmployeeUserCommand','Required parameter requestParameters.createCustomerEmployeeUserCommand was null or undefined when calling createCustomerEmployeeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/create-customer-employee-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomerEmployeeUserCommandToJSON(requestParameters.createCustomerEmployeeUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OauthUserCreatedResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async createCustomerEmployeeUser(requestParameters: CreateCustomerEmployeeUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OauthUserCreatedResponseDto> {
        const response = await this.createCustomerEmployeeUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createModelUserRaw(requestParameters: CreateModelUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OauthUserCreatedResponseDto>> {
        if (requestParameters.createModelUserCommand === null || requestParameters.createModelUserCommand === undefined) {
            throw new runtime.RequiredError('createModelUserCommand','Required parameter requestParameters.createModelUserCommand was null or undefined when calling createModelUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/create-model-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateModelUserCommandToJSON(requestParameters.createModelUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OauthUserCreatedResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async createModelUser(requestParameters: CreateModelUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OauthUserCreatedResponseDto> {
        const response = await this.createModelUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllBookersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDataResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/all-bookers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDataResponseDtoFromJSON));
    }

    /**
     */
    async getAllBookers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDataResponseDto>> {
        const response = await this.getAllBookersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserDataByOauthUsernameRaw(requestParameters: GetUserDataByOauthUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataResponseDto>> {
        if (requestParameters.oAuthUsername === null || requestParameters.oAuthUsername === undefined) {
            throw new runtime.RequiredError('oAuthUsername','Required parameter requestParameters.oAuthUsername was null or undefined when calling getUserDataByOauthUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/user-data/{oAuthUsername}`.replace(`{${"oAuthUsername"}}`, encodeURIComponent(String(requestParameters.oAuthUsername))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDataResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getUserDataByOauthUsername(requestParameters: GetUserDataByOauthUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataResponseDto> {
        const response = await this.getUserDataByOauthUsernameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveUserRaw(requestParameters: SaveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userInformationRequestDto === null || requestParameters.userInformationRequestDto === undefined) {
            throw new runtime.RequiredError('userInformationRequestDto','Required parameter requestParameters.userInformationRequestDto was null or undefined when calling saveUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInformationRequestDtoToJSON(requestParameters.userInformationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async saveUser(requestParameters: SaveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.saveUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
