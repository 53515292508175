/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelMasterDataSearchItemDto } from './ModelMasterDataSearchItemDto';
import {
    ModelMasterDataSearchItemDtoFromJSON,
    ModelMasterDataSearchItemDtoFromJSONTyped,
    ModelMasterDataSearchItemDtoToJSON,
} from './ModelMasterDataSearchItemDto';

/**
 * 
 * @export
 * @interface ModelMasterDataSearchItemsDto
 */
export interface ModelMasterDataSearchItemsDto {
    /**
     * 
     * @type {Array<ModelMasterDataSearchItemDto>}
     * @memberof ModelMasterDataSearchItemsDto
     */
    modelMasterDataSearchItems: Array<ModelMasterDataSearchItemDto>;
}

/**
 * Check if a given object implements the ModelMasterDataSearchItemsDto interface.
 */
export function instanceOfModelMasterDataSearchItemsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelMasterDataSearchItems" in value;

    return isInstance;
}

export function ModelMasterDataSearchItemsDtoFromJSON(json: any): ModelMasterDataSearchItemsDto {
    return ModelMasterDataSearchItemsDtoFromJSONTyped(json, false);
}

export function ModelMasterDataSearchItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataSearchItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelMasterDataSearchItems': ((json['modelMasterDataSearchItems'] as Array<any>).map(ModelMasterDataSearchItemDtoFromJSON)),
    };
}

export function ModelMasterDataSearchItemsDtoToJSON(value?: ModelMasterDataSearchItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelMasterDataSearchItems': ((value.modelMasterDataSearchItems as Array<any>).map(ModelMasterDataSearchItemDtoToJSON)),
    };
}

