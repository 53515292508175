/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { ServiceProviderTableItemsDto } from './ServiceProviderTableItemsDto';
import {
    ServiceProviderTableItemsDtoFromJSON,
    ServiceProviderTableItemsDtoFromJSONTyped,
    ServiceProviderTableItemsDtoToJSON,
} from './ServiceProviderTableItemsDto';

/**
 * 
 * @export
 * @interface ServiceProviderTableDto
 */
export interface ServiceProviderTableDto {
    /**
     * 
     * @type {Array<ServiceProviderTableItemsDto>}
     * @memberof ServiceProviderTableDto
     */
    serviceProviders?: Array<ServiceProviderTableItemsDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ServiceProviderTableDto
     */
    cities?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ServiceProviderTableDto interface.
 */
export function instanceOfServiceProviderTableDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceProviderTableDtoFromJSON(json: any): ServiceProviderTableDto {
    return ServiceProviderTableDtoFromJSONTyped(json, false);
}

export function ServiceProviderTableDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderTableDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceProviders': !exists(json, 'serviceProviders') ? undefined : ((json['serviceProviders'] as Array<any>).map(ServiceProviderTableItemsDtoFromJSON)),
        'cities': !exists(json, 'cities') ? undefined : ((json['cities'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ServiceProviderTableDtoToJSON(value?: ServiceProviderTableDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceProviders': value.serviceProviders === undefined ? undefined : ((value.serviceProviders as Array<any>).map(ServiceProviderTableItemsDtoToJSON)),
        'cities': value.cities === undefined ? undefined : ((value.cities as Array<any>).map(AdminValueToJSON)),
    };
}

