/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModelCalenderFormValuesDto,
} from '../models';
import {
    ModelCalenderFormValuesDtoFromJSON,
    ModelCalenderFormValuesDtoToJSON,
} from '../models';

export interface GetFormValues1Request {
    lang: string;
    filter?: Array<string>;
}

/**
 * 
 */
export class CalendarControllerApi extends runtime.BaseAPI {

    /**
     */
    async getFormValues1Raw(requestParameters: GetFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelCalenderFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getFormValues1.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model-master-data/calendar/formValues/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelCalenderFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFormValues1(requestParameters: GetFormValues1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelCalenderFormValuesDto> {
        const response = await this.getFormValues1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
