/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SportDto
 */
export interface SportDto {
    /**
     * 
     * @type {string}
     * @memberof SportDto
     */
    name: SportDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SportDto
     */
    level: SportDtoLevelEnum;
}


/**
 * @export
 */
export const SportDtoNameEnum = {
    Archery: 'ARCHERY',
    Athletics: 'ATHLETICS',
    Badminton: 'BADMINTON',
    Baseball: 'BASEBALL',
    Basketball: 'BASKETBALL',
    BeachVolleyball: 'BEACH_VOLLEYBALL',
    Biathlon: 'BIATHLON',
    Bmx: 'BMX',
    Bobsleigh: 'BOBSLEIGH',
    Boxing: 'BOXING',
    Canoeing: 'CANOEING',
    Climbing: 'CLIMBING',
    CrossCountrySkiing: 'CROSS_COUNTRY_SKIING',
    Curling: 'CURLING',
    Cycling: 'CYCLING',
    Diving: 'DIVING',
    Equestrian: 'EQUESTRIAN',
    Fencing: 'FENCING',
    FigureSkating: 'FIGURE_SKATING',
    Football: 'FOOTBALL',
    FreestyleSkiing: 'FREESTYLE_SKIING',
    Golf: 'GOLF',
    Gymnastics: 'GYMNASTICS',
    Handball: 'HANDBALL',
    Hockey: 'HOCKEY',
    HorseRacing: 'HORSE_RACING',
    IceHockey: 'ICE_HOCKEY',
    Judo: 'JUDO',
    Karate: 'KARATE',
    Kayaking: 'KAYAKING',
    Luge: 'LUGE',
    ModernPentathlon: 'MODERN_PENTATHLON',
    Motorsport: 'MOTORSPORT',
    MountainBiking: 'MOUNTAIN_BIKING',
    NordicCombined: 'NORDIC_COMBINED',
    Rowing: 'ROWING',
    Rugby: 'RUGBY',
    Sailing: 'SAILING',
    Shooting: 'SHOOTING',
    ShortTrackSpeedSkating: 'SHORT_TRACK_SPEED_SKATING',
    Skateboarding: 'SKATEBOARDING',
    Skating: 'SKATING',
    SkiJumping: 'SKI_JUMPING',
    Snowboarding: 'SNOWBOARDING',
    Soccer: 'SOCCER',
    Softball: 'SOFTBALL',
    SpeedSkating: 'SPEED_SKATING',
    Swimming: 'SWIMMING',
    SynchronizedSwimming: 'SYNCHRONIZED_SWIMMING',
    TableTennis: 'TABLE_TENNIS',
    Taekwondo: 'TAEKWONDO',
    Tennis: 'TENNIS',
    Trampoline: 'TRAMPOLINE',
    Triathlon: 'TRIATHLON',
    Volleyball: 'VOLLEYBALL',
    WaterPolo: 'WATER_POLO',
    Weightlifting: 'WEIGHTLIFTING',
    Wrestling: 'WRESTLING'
} as const;
export type SportDtoNameEnum = typeof SportDtoNameEnum[keyof typeof SportDtoNameEnum];

/**
 * @export
 */
export const SportDtoLevelEnum = {
    Professional: 'PROFESSIONAL',
    SemiProfessional: 'SEMI_PROFESSIONAL',
    Regular: 'REGULAR',
    Sometimes: 'SOMETIMES'
} as const;
export type SportDtoLevelEnum = typeof SportDtoLevelEnum[keyof typeof SportDtoLevelEnum];


/**
 * Check if a given object implements the SportDto interface.
 */
export function instanceOfSportDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "level" in value;

    return isInstance;
}

export function SportDtoFromJSON(json: any): SportDto {
    return SportDtoFromJSONTyped(json, false);
}

export function SportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'level': json['level'],
    };
}

export function SportDtoToJSON(value?: SportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'level': value.level,
    };
}

