/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AgencyBankInformationFormValuesDto
 */
export interface AgencyBankInformationFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    bankIdentificationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    bankName?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof AgencyBankInformationFormValuesDto
     */
    country?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    accountHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    accountNumber?: string;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof AgencyBankInformationFormValuesDto
     */
    countryOptions?: Array<AdminValue>;
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    bic?: string;
    /**
     * 
     * @type {string}
     * @memberof AgencyBankInformationFormValuesDto
     */
    iban?: string;
}

/**
 * Check if a given object implements the AgencyBankInformationFormValuesDto interface.
 */
export function instanceOfAgencyBankInformationFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgencyBankInformationFormValuesDtoFromJSON(json: any): AgencyBankInformationFormValuesDto {
    return AgencyBankInformationFormValuesDtoFromJSONTyped(json, false);
}

export function AgencyBankInformationFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgencyBankInformationFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankIdentificationNumber': !exists(json, 'bankIdentificationNumber') ? undefined : json['bankIdentificationNumber'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
        'accountHolder': !exists(json, 'accountHolder') ? undefined : json['accountHolder'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'countryOptions': !exists(json, 'countryOptions') ? undefined : ((json['countryOptions'] as Array<any>).map(AdminValueFromJSON)),
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
    };
}

export function AgencyBankInformationFormValuesDtoToJSON(value?: AgencyBankInformationFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankIdentificationNumber': value.bankIdentificationNumber,
        'bankName': value.bankName,
        'country': AdminValueToJSON(value.country),
        'accountHolder': value.accountHolder,
        'accountNumber': value.accountNumber,
        'countryOptions': value.countryOptions === undefined ? undefined : ((value.countryOptions as Array<any>).map(AdminValueToJSON)),
        'bic': value.bic,
        'iban': value.iban,
    };
}

