/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentificationDto
 */
export interface IdentificationDto {
    /**
     * 
     * @type {string}
     * @memberof IdentificationDto
     */
    nationality: string;
    /**
     * 
     * @type {Date}
     * @memberof IdentificationDto
     */
    dateOfBirth: Date;
    /**
     * 
     * @type {string}
     * @memberof IdentificationDto
     */
    passportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof IdentificationDto
     */
    authority: string;
    /**
     * 
     * @type {Date}
     * @memberof IdentificationDto
     */
    passportValidUntil: Date;
    /**
     * 
     * @type {string}
     * @memberof IdentificationDto
     */
    ethnicityFather: string;
    /**
     * 
     * @type {string}
     * @memberof IdentificationDto
     */
    ethnicityMother: string;
}

/**
 * Check if a given object implements the IdentificationDto interface.
 */
export function instanceOfIdentificationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nationality" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "passportNumber" in value;
    isInstance = isInstance && "authority" in value;
    isInstance = isInstance && "passportValidUntil" in value;
    isInstance = isInstance && "ethnicityFather" in value;
    isInstance = isInstance && "ethnicityMother" in value;

    return isInstance;
}

export function IdentificationDtoFromJSON(json: any): IdentificationDto {
    return IdentificationDtoFromJSONTyped(json, false);
}

export function IdentificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nationality': json['nationality'],
        'dateOfBirth': (new Date(json['dateOfBirth'])),
        'passportNumber': json['passportNumber'],
        'authority': json['authority'],
        'passportValidUntil': (new Date(json['passportValidUntil'])),
        'ethnicityFather': json['ethnicityFather'],
        'ethnicityMother': json['ethnicityMother'],
    };
}

export function IdentificationDtoToJSON(value?: IdentificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nationality': value.nationality,
        'dateOfBirth': (value.dateOfBirth.toISOString().substr(0,10)),
        'passportNumber': value.passportNumber,
        'authority': value.authority,
        'passportValidUntil': (value.passportValidUntil.toISOString().substr(0,10)),
        'ethnicityFather': value.ethnicityFather,
        'ethnicityMother': value.ethnicityMother,
    };
}

