/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpokenLanguageFormValuesDto
 */
export interface SpokenLanguageFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof SpokenLanguageFormValuesDto
     */
    spokenLanguage: string;
    /**
     * 
     * @type {string}
     * @memberof SpokenLanguageFormValuesDto
     */
    level: string;
}

/**
 * Check if a given object implements the SpokenLanguageFormValuesDto interface.
 */
export function instanceOfSpokenLanguageFormValuesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spokenLanguage" in value;
    isInstance = isInstance && "level" in value;

    return isInstance;
}

export function SpokenLanguageFormValuesDtoFromJSON(json: any): SpokenLanguageFormValuesDto {
    return SpokenLanguageFormValuesDtoFromJSONTyped(json, false);
}

export function SpokenLanguageFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpokenLanguageFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spokenLanguage': json['spokenLanguage'],
        'level': json['level'],
    };
}

export function SpokenLanguageFormValuesDtoToJSON(value?: SpokenLanguageFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spokenLanguage': value.spokenLanguage,
        'level': value.level,
    };
}

