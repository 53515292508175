import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ParticipantResponseDto} from "@brody-bookings/api";
import {Subscription} from "rxjs";
import {ConversationService} from "../../../service/conversation.service";

@Component({
    selector: 'app-conversation-model-list',
    templateUrl: './modelSearchList.component.html',
    styleUrls: ['./modelSearchList.style.css']
})

export class ModelSearchListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    modelParticipantList: ParticipantResponseDto[] = []

    @Input() onModelSelection!: (model: ParticipantResponseDto) => void
    @Input() selectedParticipants!: ParticipantResponseDto[]

    constructor(
        private conversationService: ConversationService
    ){}

    ngOnInit(): void {
        this.conversationService.fetchModelParticipants().subscribe((participantResponseDtoList: ParticipantResponseDto[]) => {
            this.modelParticipantList = participantResponseDtoList
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}