/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtraCostsListFormValuesDto } from './ExtraCostsListFormValuesDto';
import {
    ExtraCostsListFormValuesDtoFromJSON,
    ExtraCostsListFormValuesDtoFromJSONTyped,
    ExtraCostsListFormValuesDtoToJSON,
} from './ExtraCostsListFormValuesDto';
import type { ProjectBillingAddresseeFormValuesDto } from './ProjectBillingAddresseeFormValuesDto';
import {
    ProjectBillingAddresseeFormValuesDtoFromJSON,
    ProjectBillingAddresseeFormValuesDtoFromJSONTyped,
    ProjectBillingAddresseeFormValuesDtoToJSON,
} from './ProjectBillingAddresseeFormValuesDto';
import type { ProjectBillingInvoiceFormValuesDto } from './ProjectBillingInvoiceFormValuesDto';
import {
    ProjectBillingInvoiceFormValuesDtoFromJSON,
    ProjectBillingInvoiceFormValuesDtoFromJSONTyped,
    ProjectBillingInvoiceFormValuesDtoToJSON,
} from './ProjectBillingInvoiceFormValuesDto';
import type { ProjectBillingModelsSumFormValuesDto } from './ProjectBillingModelsSumFormValuesDto';
import {
    ProjectBillingModelsSumFormValuesDtoFromJSON,
    ProjectBillingModelsSumFormValuesDtoFromJSONTyped,
    ProjectBillingModelsSumFormValuesDtoToJSON,
} from './ProjectBillingModelsSumFormValuesDto';
import type { ProjectBillingSumFormValuesDto } from './ProjectBillingSumFormValuesDto';
import {
    ProjectBillingSumFormValuesDtoFromJSON,
    ProjectBillingSumFormValuesDtoFromJSONTyped,
    ProjectBillingSumFormValuesDtoToJSON,
} from './ProjectBillingSumFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectBillingFormValuesDto
 */
export interface ProjectBillingFormValuesDto {
    /**
     * 
     * @type {ProjectBillingModelsSumFormValuesDto}
     * @memberof ProjectBillingFormValuesDto
     */
    totalSumModels?: ProjectBillingModelsSumFormValuesDto;
    /**
     * 
     * @type {ProjectBillingAddresseeFormValuesDto}
     * @memberof ProjectBillingFormValuesDto
     */
    addressee?: ProjectBillingAddresseeFormValuesDto;
    /**
     * 
     * @type {ProjectBillingInvoiceFormValuesDto}
     * @memberof ProjectBillingFormValuesDto
     */
    invoice?: ProjectBillingInvoiceFormValuesDto;
    /**
     * 
     * @type {ProjectBillingSumFormValuesDto}
     * @memberof ProjectBillingFormValuesDto
     */
    totalSum?: ProjectBillingSumFormValuesDto;
    /**
     * 
     * @type {ExtraCostsListFormValuesDto}
     * @memberof ProjectBillingFormValuesDto
     */
    extraCosts?: ExtraCostsListFormValuesDto;
}

/**
 * Check if a given object implements the ProjectBillingFormValuesDto interface.
 */
export function instanceOfProjectBillingFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectBillingFormValuesDtoFromJSON(json: any): ProjectBillingFormValuesDto {
    return ProjectBillingFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectBillingFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBillingFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalSumModels': !exists(json, 'totalSumModels') ? undefined : ProjectBillingModelsSumFormValuesDtoFromJSON(json['totalSumModels']),
        'addressee': !exists(json, 'addressee') ? undefined : ProjectBillingAddresseeFormValuesDtoFromJSON(json['addressee']),
        'invoice': !exists(json, 'invoice') ? undefined : ProjectBillingInvoiceFormValuesDtoFromJSON(json['invoice']),
        'totalSum': !exists(json, 'totalSum') ? undefined : ProjectBillingSumFormValuesDtoFromJSON(json['totalSum']),
        'extraCosts': !exists(json, 'extraCosts') ? undefined : ExtraCostsListFormValuesDtoFromJSON(json['extraCosts']),
    };
}

export function ProjectBillingFormValuesDtoToJSON(value?: ProjectBillingFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalSumModels': ProjectBillingModelsSumFormValuesDtoToJSON(value.totalSumModels),
        'addressee': ProjectBillingAddresseeFormValuesDtoToJSON(value.addressee),
        'invoice': ProjectBillingInvoiceFormValuesDtoToJSON(value.invoice),
        'totalSum': ProjectBillingSumFormValuesDtoToJSON(value.totalSum),
        'extraCosts': ExtraCostsListFormValuesDtoToJSON(value.extraCosts),
    };
}

