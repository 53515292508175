/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BankAccountInformationFormValuesDto } from './BankAccountInformationFormValuesDto';
import {
    BankAccountInformationFormValuesDtoFromJSON,
    BankAccountInformationFormValuesDtoFromJSONTyped,
    BankAccountInformationFormValuesDtoToJSON,
} from './BankAccountInformationFormValuesDto';
import type { ConditionsFormValuesDto } from './ConditionsFormValuesDto';
import {
    ConditionsFormValuesDtoFromJSON,
    ConditionsFormValuesDtoFromJSONTyped,
    ConditionsFormValuesDtoToJSON,
} from './ConditionsFormValuesDto';
import type { ContactInfoFormValuesDto } from './ContactInfoFormValuesDto';
import {
    ContactInfoFormValuesDtoFromJSON,
    ContactInfoFormValuesDtoFromJSONTyped,
    ContactInfoFormValuesDtoToJSON,
} from './ContactInfoFormValuesDto';
import type { CustomerAddressFormValuesDto } from './CustomerAddressFormValuesDto';
import {
    CustomerAddressFormValuesDtoFromJSON,
    CustomerAddressFormValuesDtoFromJSONTyped,
    CustomerAddressFormValuesDtoToJSON,
} from './CustomerAddressFormValuesDto';
import type { CustomerNameFormValuesDto } from './CustomerNameFormValuesDto';
import {
    CustomerNameFormValuesDtoFromJSON,
    CustomerNameFormValuesDtoFromJSONTyped,
    CustomerNameFormValuesDtoToJSON,
} from './CustomerNameFormValuesDto';
import type { EmployeesFormValuesDto } from './EmployeesFormValuesDto';
import {
    EmployeesFormValuesDtoFromJSON,
    EmployeesFormValuesDtoFromJSONTyped,
    EmployeesFormValuesDtoToJSON,
} from './EmployeesFormValuesDto';
import type { IndustryFieldFormValuesDto } from './IndustryFieldFormValuesDto';
import {
    IndustryFieldFormValuesDtoFromJSON,
    IndustryFieldFormValuesDtoFromJSONTyped,
    IndustryFieldFormValuesDtoToJSON,
} from './IndustryFieldFormValuesDto';
import type { JobHistoriesFormValuesDto } from './JobHistoriesFormValuesDto';
import {
    JobHistoriesFormValuesDtoFromJSON,
    JobHistoriesFormValuesDtoFromJSONTyped,
    JobHistoriesFormValuesDtoToJSON,
} from './JobHistoriesFormValuesDto';
import type { ProtocolsFormValuesDto } from './ProtocolsFormValuesDto';
import {
    ProtocolsFormValuesDtoFromJSON,
    ProtocolsFormValuesDtoFromJSONTyped,
    ProtocolsFormValuesDtoToJSON,
} from './ProtocolsFormValuesDto';
import type { TaxInformationFormValuesDto } from './TaxInformationFormValuesDto';
import {
    TaxInformationFormValuesDtoFromJSON,
    TaxInformationFormValuesDtoFromJSONTyped,
    TaxInformationFormValuesDtoToJSON,
} from './TaxInformationFormValuesDto';

/**
 * 
 * @export
 * @interface CustomerMasterDataFormValuesDto
 */
export interface CustomerMasterDataFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerMasterDataFormValuesDto
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMasterDataFormValuesDto
     */
    email?: string;
    /**
     * 
     * @type {CustomerNameFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    customerName?: CustomerNameFormValuesDto;
    /**
     * 
     * @type {ContactInfoFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    contactInfo?: ContactInfoFormValuesDto;
    /**
     * 
     * @type {CustomerAddressFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    livingAddress?: CustomerAddressFormValuesDto;
    /**
     * 
     * @type {CustomerAddressFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    billingAddress?: CustomerAddressFormValuesDto;
    /**
     * 
     * @type {BankAccountInformationFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    bankAccountInformation?: BankAccountInformationFormValuesDto;
    /**
     * 
     * @type {ConditionsFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    conditions?: ConditionsFormValuesDto;
    /**
     * 
     * @type {TaxInformationFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    taxInformation?: TaxInformationFormValuesDto;
    /**
     * 
     * @type {EmployeesFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    employees?: EmployeesFormValuesDto;
    /**
     * 
     * @type {IndustryFieldFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    industryField?: IndustryFieldFormValuesDto;
    /**
     * 
     * @type {ProtocolsFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    protocol?: ProtocolsFormValuesDto;
    /**
     * 
     * @type {JobHistoriesFormValuesDto}
     * @memberof CustomerMasterDataFormValuesDto
     */
    jobHistory?: JobHistoriesFormValuesDto;
}

/**
 * Check if a given object implements the CustomerMasterDataFormValuesDto interface.
 */
export function instanceOfCustomerMasterDataFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerMasterDataFormValuesDtoFromJSON(json: any): CustomerMasterDataFormValuesDto {
    return CustomerMasterDataFormValuesDtoFromJSONTyped(json, false);
}

export function CustomerMasterDataFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerMasterDataFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'customerName': !exists(json, 'customerName') ? undefined : CustomerNameFormValuesDtoFromJSON(json['customerName']),
        'contactInfo': !exists(json, 'contactInfo') ? undefined : ContactInfoFormValuesDtoFromJSON(json['contactInfo']),
        'livingAddress': !exists(json, 'livingAddress') ? undefined : CustomerAddressFormValuesDtoFromJSON(json['livingAddress']),
        'billingAddress': !exists(json, 'billingAddress') ? undefined : CustomerAddressFormValuesDtoFromJSON(json['billingAddress']),
        'bankAccountInformation': !exists(json, 'bankAccountInformation') ? undefined : BankAccountInformationFormValuesDtoFromJSON(json['bankAccountInformation']),
        'conditions': !exists(json, 'conditions') ? undefined : ConditionsFormValuesDtoFromJSON(json['conditions']),
        'taxInformation': !exists(json, 'taxInformation') ? undefined : TaxInformationFormValuesDtoFromJSON(json['taxInformation']),
        'employees': !exists(json, 'employees') ? undefined : EmployeesFormValuesDtoFromJSON(json['employees']),
        'industryField': !exists(json, 'industryField') ? undefined : IndustryFieldFormValuesDtoFromJSON(json['industryField']),
        'protocol': !exists(json, 'protocol') ? undefined : ProtocolsFormValuesDtoFromJSON(json['protocol']),
        'jobHistory': !exists(json, 'jobHistory') ? undefined : JobHistoriesFormValuesDtoFromJSON(json['jobHistory']),
    };
}

export function CustomerMasterDataFormValuesDtoToJSON(value?: CustomerMasterDataFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'email': value.email,
        'customerName': CustomerNameFormValuesDtoToJSON(value.customerName),
        'contactInfo': ContactInfoFormValuesDtoToJSON(value.contactInfo),
        'livingAddress': CustomerAddressFormValuesDtoToJSON(value.livingAddress),
        'billingAddress': CustomerAddressFormValuesDtoToJSON(value.billingAddress),
        'bankAccountInformation': BankAccountInformationFormValuesDtoToJSON(value.bankAccountInformation),
        'conditions': ConditionsFormValuesDtoToJSON(value.conditions),
        'taxInformation': TaxInformationFormValuesDtoToJSON(value.taxInformation),
        'employees': EmployeesFormValuesDtoToJSON(value.employees),
        'industryField': IndustryFieldFormValuesDtoToJSON(value.industryField),
        'protocol': ProtocolsFormValuesDtoToJSON(value.protocol),
        'jobHistory': JobHistoriesFormValuesDtoToJSON(value.jobHistory),
    };
}

