/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ChangeLivingAddressRequestDto
 */
export interface ChangeLivingAddressRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeLivingAddressRequestDto
     */
    street?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof ChangeLivingAddressRequestDto
     */
    city?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof ChangeLivingAddressRequestDto
     */
    zipCode?: string;
    /**
     * 
     * @type {AdminValue}
     * @memberof ChangeLivingAddressRequestDto
     */
    country?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof ChangeLivingAddressRequestDto
     */
    co?: string;
}

/**
 * Check if a given object implements the ChangeLivingAddressRequestDto interface.
 */
export function instanceOfChangeLivingAddressRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeLivingAddressRequestDtoFromJSON(json: any): ChangeLivingAddressRequestDto {
    return ChangeLivingAddressRequestDtoFromJSONTyped(json, false);
}

export function ChangeLivingAddressRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeLivingAddressRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : AdminValueFromJSON(json['city']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'country': !exists(json, 'country') ? undefined : AdminValueFromJSON(json['country']),
        'co': !exists(json, 'co') ? undefined : json['co'],
    };
}

export function ChangeLivingAddressRequestDtoToJSON(value?: ChangeLivingAddressRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'city': AdminValueToJSON(value.city),
        'zipCode': value.zipCode,
        'country': AdminValueToJSON(value.country),
        'co': value.co,
    };
}

