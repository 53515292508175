/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsageTypeFormValueDto
 */
export interface UsageTypeFormValueDto {
    /**
     * 
     * @type {string}
     * @memberof UsageTypeFormValueDto
     */
    usageType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsageTypeFormValueDto
     */
    usageSubType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsageTypeFormValueDto
     */
    usageTypeProfession?: string;
}

/**
 * Check if a given object implements the UsageTypeFormValueDto interface.
 */
export function instanceOfUsageTypeFormValueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UsageTypeFormValueDtoFromJSON(json: any): UsageTypeFormValueDto {
    return UsageTypeFormValueDtoFromJSONTyped(json, false);
}

export function UsageTypeFormValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageTypeFormValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usageType': !exists(json, 'usageType') ? undefined : json['usageType'],
        'usageSubType': !exists(json, 'usageSubType') ? undefined : json['usageSubType'],
        'usageTypeProfession': !exists(json, 'usageTypeProfession') ? undefined : json['usageTypeProfession'],
    };
}

export function UsageTypeFormValueDtoToJSON(value?: UsageTypeFormValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usageType': value.usageType,
        'usageSubType': value.usageSubType,
        'usageTypeProfession': value.usageTypeProfession,
    };
}

