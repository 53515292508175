/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { TravelCostFormValuesDto } from './TravelCostFormValuesDto';
import {
    TravelCostFormValuesDtoFromJSON,
    TravelCostFormValuesDtoFromJSONTyped,
    TravelCostFormValuesDtoToJSON,
} from './TravelCostFormValuesDto';

/**
 * 
 * @export
 * @interface TravelCostListFormValuesDto
 */
export interface TravelCostListFormValuesDto {
    /**
     * 
     * @type {Array<TravelCostFormValuesDto>}
     * @memberof TravelCostListFormValuesDto
     */
    travelCosts?: Array<TravelCostFormValuesDto>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof TravelCostListFormValuesDto
     */
    transportationMeansOptions?: Array<AdminValue>;
}

/**
 * Check if a given object implements the TravelCostListFormValuesDto interface.
 */
export function instanceOfTravelCostListFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TravelCostListFormValuesDtoFromJSON(json: any): TravelCostListFormValuesDto {
    return TravelCostListFormValuesDtoFromJSONTyped(json, false);
}

export function TravelCostListFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelCostListFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelCosts': !exists(json, 'travelCosts') ? undefined : ((json['travelCosts'] as Array<any>).map(TravelCostFormValuesDtoFromJSON)),
        'transportationMeansOptions': !exists(json, 'transportationMeansOptions') ? undefined : ((json['transportationMeansOptions'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function TravelCostListFormValuesDtoToJSON(value?: TravelCostListFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelCosts': value.travelCosts === undefined ? undefined : ((value.travelCosts as Array<any>).map(TravelCostFormValuesDtoToJSON)),
        'transportationMeansOptions': value.transportationMeansOptions === undefined ? undefined : ((value.transportationMeansOptions as Array<any>).map(AdminValueToJSON)),
    };
}

