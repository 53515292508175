/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProtocolEntry
 */
export interface ProtocolEntry {
    /**
     * 
     * @type {string}
     * @memberof ProtocolEntry
     */
    entry?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProtocolEntry
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the ProtocolEntry interface.
 */
export function instanceOfProtocolEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProtocolEntryFromJSON(json: any): ProtocolEntry {
    return ProtocolEntryFromJSONTyped(json, false);
}

export function ProtocolEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : json['entry'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function ProtocolEntryToJSON(value?: ProtocolEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': value.entry,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

