/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceDto
 */
export interface InsuranceDto {
    /**
     * 
     * @type {string}
     * @memberof InsuranceDto
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDto
     */
    healthInsurance: string;
}

/**
 * Check if a given object implements the InsuranceDto interface.
 */
export function instanceOfInsuranceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "socialSecurityNumber" in value;
    isInstance = isInstance && "healthInsurance" in value;

    return isInstance;
}

export function InsuranceDtoFromJSON(json: any): InsuranceDto {
    return InsuranceDtoFromJSONTyped(json, false);
}

export function InsuranceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'socialSecurityNumber': json['socialSecurityNumber'],
        'healthInsurance': json['healthInsurance'],
    };
}

export function InsuranceDtoToJSON(value?: InsuranceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'socialSecurityNumber': value.socialSecurityNumber,
        'healthInsurance': value.healthInsurance,
    };
}

