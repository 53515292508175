/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingProducer } from './BookingProducer';
import {
    BookingProducerFromJSON,
    BookingProducerFromJSONTyped,
    BookingProducerToJSON,
} from './BookingProducer';
import type { Budget } from './Budget';
import {
    BudgetFromJSON,
    BudgetFromJSONTyped,
    BudgetToJSON,
} from './Budget';
import type { BuyOutPerModel } from './BuyOutPerModel';
import {
    BuyOutPerModelFromJSON,
    BuyOutPerModelFromJSONTyped,
    BuyOutPerModelToJSON,
} from './BuyOutPerModel';
import type { Executor } from './Executor';
import {
    ExecutorFromJSON,
    ExecutorFromJSONTyped,
    ExecutorToJSON,
} from './Executor';
import type { ExternalParticipant } from './ExternalParticipant';
import {
    ExternalParticipantFromJSON,
    ExternalParticipantFromJSONTyped,
    ExternalParticipantToJSON,
} from './ExternalParticipant';
import type { FinalCustomer } from './FinalCustomer';
import {
    FinalCustomerFromJSON,
    FinalCustomerFromJSONTyped,
    FinalCustomerToJSON,
} from './FinalCustomer';
import type { ProductionDetails } from './ProductionDetails';
import {
    ProductionDetailsFromJSON,
    ProductionDetailsFromJSONTyped,
    ProductionDetailsToJSON,
} from './ProductionDetails';
import type { ProjectAdditionalParticipant } from './ProjectAdditionalParticipant';
import {
    ProjectAdditionalParticipantFromJSON,
    ProjectAdditionalParticipantFromJSONTyped,
    ProjectAdditionalParticipantToJSON,
} from './ProjectAdditionalParticipant';
import type { ProjectBilling } from './ProjectBilling';
import {
    ProjectBillingFromJSON,
    ProjectBillingFromJSONTyped,
    ProjectBillingToJSON,
} from './ProjectBilling';
import type { ProjectCasting } from './ProjectCasting';
import {
    ProjectCastingFromJSON,
    ProjectCastingFromJSONTyped,
    ProjectCastingToJSON,
} from './ProjectCasting';
import type { ProjectCreator } from './ProjectCreator';
import {
    ProjectCreatorFromJSON,
    ProjectCreatorFromJSONTyped,
    ProjectCreatorToJSON,
} from './ProjectCreator';
import type { ProjectId } from './ProjectId';
import {
    ProjectIdFromJSON,
    ProjectIdFromJSONTyped,
    ProjectIdToJSON,
} from './ProjectId';
import type { ProjectLocation } from './ProjectLocation';
import {
    ProjectLocationFromJSON,
    ProjectLocationFromJSONTyped,
    ProjectLocationToJSON,
} from './ProjectLocation';
import type { ProjectModel } from './ProjectModel';
import {
    ProjectModelFromJSON,
    ProjectModelFromJSONTyped,
    ProjectModelToJSON,
} from './ProjectModel';
import type { ProjectMotif } from './ProjectMotif';
import {
    ProjectMotifFromJSON,
    ProjectMotifFromJSONTyped,
    ProjectMotifToJSON,
} from './ProjectMotif';
import type { ProjectName } from './ProjectName';
import {
    ProjectNameFromJSON,
    ProjectNameFromJSONTyped,
    ProjectNameToJSON,
} from './ProjectName';
import type { ProjectProducer } from './ProjectProducer';
import {
    ProjectProducerFromJSON,
    ProjectProducerFromJSONTyped,
    ProjectProducerToJSON,
} from './ProjectProducer';
import type { ProjectRole } from './ProjectRole';
import {
    ProjectRoleFromJSON,
    ProjectRoleFromJSONTyped,
    ProjectRoleToJSON,
} from './ProjectRole';
import type { Protocol } from './Protocol';
import {
    ProtocolFromJSON,
    ProtocolFromJSONTyped,
    ProtocolToJSON,
} from './Protocol';
import type { SelectedUsageType } from './SelectedUsageType';
import {
    SelectedUsageTypeFromJSON,
    SelectedUsageTypeFromJSONTyped,
    SelectedUsageTypeToJSON,
} from './SelectedUsageType';
import type { TaskManagerInfos } from './TaskManagerInfos';
import {
    TaskManagerInfosFromJSON,
    TaskManagerInfosFromJSONTyped,
    TaskManagerInfosToJSON,
} from './TaskManagerInfos';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {ProjectId}
     * @memberof Project
     */
    id: ProjectId;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    brodyProjectReferenceId?: number;
    /**
     * 
     * @type {ProjectName}
     * @memberof Project
     */
    name: ProjectName;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    status?: ProjectStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    projectDocReceived?: boolean;
    /**
     * 
     * @type {ProjectCreator}
     * @memberof Project
     */
    creator: ProjectCreator;
    /**
     * 
     * @type {ProjectProducer}
     * @memberof Project
     */
    producer?: ProjectProducer;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    lastEditedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    lastWatchedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    lastEditor: string;
    /**
     * 
     * @type {FinalCustomer}
     * @memberof Project
     */
    finalCustomer?: FinalCustomer;
    /**
     * 
     * @type {ProductionDetails}
     * @memberof Project
     */
    productionDetails?: ProductionDetails;
    /**
     * 
     * @type {Budget}
     * @memberof Project
     */
    budget?: Budget;
    /**
     * 
     * @type {SelectedUsageType}
     * @memberof Project
     */
    usageType?: SelectedUsageType;
    /**
     * 
     * @type {Executor}
     * @memberof Project
     */
    executor?: Executor;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    buyOut?: string;
    /**
     * 
     * @type {ProjectCasting}
     * @memberof Project
     */
    casting?: ProjectCasting;
    /**
     * 
     * @type {Array<ProjectAdditionalParticipant>}
     * @memberof Project
     */
    additionalParticipantList?: Array<ProjectAdditionalParticipant>;
    /**
     * 
     * @type {Array<ExternalParticipant>}
     * @memberof Project
     */
    externalParticipantList?: Array<ExternalParticipant>;
    /**
     * 
     * @type {Array<ProjectRole>}
     * @memberof Project
     */
    roles?: Array<ProjectRole>;
    /**
     * 
     * @type {Array<ProjectModel>}
     * @memberof Project
     */
    models?: Array<ProjectModel>;
    /**
     * 
     * @type {BookingProducer}
     * @memberof Project
     */
    bookingProducer?: BookingProducer;
    /**
     * 
     * @type {Array<Protocol>}
     * @memberof Project
     */
    protocol?: Array<Protocol>;
    /**
     * 
     * @type {Array<ProjectLocation>}
     * @memberof Project
     */
    projectLocations?: Array<ProjectLocation>;
    /**
     * 
     * @type {Array<ProjectMotif>}
     * @memberof Project
     */
    projectMotifs?: Array<ProjectMotif>;
    /**
     * 
     * @type {Array<BuyOutPerModel>}
     * @memberof Project
     */
    buyOutPerModelList?: Array<BuyOutPerModel>;
    /**
     * 
     * @type {ProjectBilling}
     * @memberof Project
     */
    billing?: ProjectBilling;
    /**
     * 
     * @type {TaskManagerInfos}
     * @memberof Project
     */
    taskManagerInfos?: TaskManagerInfos;
}


/**
 * @export
 */
export const ProjectStatusEnum = {
    Active: 'ACTIVE',
    Stopped: 'STOPPED',
    Finished: 'FINISHED'
} as const;
export type ProjectStatusEnum = typeof ProjectStatusEnum[keyof typeof ProjectStatusEnum];


/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastEditedAt" in value;
    isInstance = isInstance && "lastWatchedBy" in value;
    isInstance = isInstance && "lastEditor" in value;

    return isInstance;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': ProjectIdFromJSON(json['id']),
        'brodyProjectReferenceId': !exists(json, 'brodyProjectReferenceId') ? undefined : json['brodyProjectReferenceId'],
        'name': ProjectNameFromJSON(json['name']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'projectDocReceived': !exists(json, 'projectDocReceived') ? undefined : json['projectDocReceived'],
        'creator': ProjectCreatorFromJSON(json['creator']),
        'producer': !exists(json, 'producer') ? undefined : ProjectProducerFromJSON(json['producer']),
        'createdAt': (new Date(json['createdAt'])),
        'lastEditedAt': (new Date(json['lastEditedAt'])),
        'lastWatchedBy': json['lastWatchedBy'],
        'lastEditor': json['lastEditor'],
        'finalCustomer': !exists(json, 'finalCustomer') ? undefined : FinalCustomerFromJSON(json['finalCustomer']),
        'productionDetails': !exists(json, 'productionDetails') ? undefined : ProductionDetailsFromJSON(json['productionDetails']),
        'budget': !exists(json, 'budget') ? undefined : BudgetFromJSON(json['budget']),
        'usageType': !exists(json, 'usageType') ? undefined : SelectedUsageTypeFromJSON(json['usageType']),
        'executor': !exists(json, 'executor') ? undefined : ExecutorFromJSON(json['executor']),
        'buyOut': !exists(json, 'buyOut') ? undefined : json['buyOut'],
        'casting': !exists(json, 'casting') ? undefined : ProjectCastingFromJSON(json['casting']),
        'additionalParticipantList': !exists(json, 'additionalParticipantList') ? undefined : ((json['additionalParticipantList'] as Array<any>).map(ProjectAdditionalParticipantFromJSON)),
        'externalParticipantList': !exists(json, 'externalParticipantList') ? undefined : ((json['externalParticipantList'] as Array<any>).map(ExternalParticipantFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(ProjectRoleFromJSON)),
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(ProjectModelFromJSON)),
        'bookingProducer': !exists(json, 'bookingProducer') ? undefined : BookingProducerFromJSON(json['bookingProducer']),
        'protocol': !exists(json, 'protocol') ? undefined : ((json['protocol'] as Array<any>).map(ProtocolFromJSON)),
        'projectLocations': !exists(json, 'projectLocations') ? undefined : ((json['projectLocations'] as Array<any>).map(ProjectLocationFromJSON)),
        'projectMotifs': !exists(json, 'projectMotifs') ? undefined : ((json['projectMotifs'] as Array<any>).map(ProjectMotifFromJSON)),
        'buyOutPerModelList': !exists(json, 'buyOutPerModelList') ? undefined : ((json['buyOutPerModelList'] as Array<any>).map(BuyOutPerModelFromJSON)),
        'billing': !exists(json, 'billing') ? undefined : ProjectBillingFromJSON(json['billing']),
        'taskManagerInfos': !exists(json, 'taskManagerInfos') ? undefined : TaskManagerInfosFromJSON(json['taskManagerInfos']),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': ProjectIdToJSON(value.id),
        'brodyProjectReferenceId': value.brodyProjectReferenceId,
        'name': ProjectNameToJSON(value.name),
        'status': value.status,
        'projectDocReceived': value.projectDocReceived,
        'creator': ProjectCreatorToJSON(value.creator),
        'producer': ProjectProducerToJSON(value.producer),
        'createdAt': (value.createdAt.toISOString()),
        'lastEditedAt': (value.lastEditedAt.toISOString()),
        'lastWatchedBy': value.lastWatchedBy,
        'lastEditor': value.lastEditor,
        'finalCustomer': FinalCustomerToJSON(value.finalCustomer),
        'productionDetails': ProductionDetailsToJSON(value.productionDetails),
        'budget': BudgetToJSON(value.budget),
        'usageType': SelectedUsageTypeToJSON(value.usageType),
        'executor': ExecutorToJSON(value.executor),
        'buyOut': value.buyOut,
        'casting': ProjectCastingToJSON(value.casting),
        'additionalParticipantList': value.additionalParticipantList === undefined ? undefined : ((value.additionalParticipantList as Array<any>).map(ProjectAdditionalParticipantToJSON)),
        'externalParticipantList': value.externalParticipantList === undefined ? undefined : ((value.externalParticipantList as Array<any>).map(ExternalParticipantToJSON)),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(ProjectRoleToJSON)),
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(ProjectModelToJSON)),
        'bookingProducer': BookingProducerToJSON(value.bookingProducer),
        'protocol': value.protocol === undefined ? undefined : ((value.protocol as Array<any>).map(ProtocolToJSON)),
        'projectLocations': value.projectLocations === undefined ? undefined : ((value.projectLocations as Array<any>).map(ProjectLocationToJSON)),
        'projectMotifs': value.projectMotifs === undefined ? undefined : ((value.projectMotifs as Array<any>).map(ProjectMotifToJSON)),
        'buyOutPerModelList': value.buyOutPerModelList === undefined ? undefined : ((value.buyOutPerModelList as Array<any>).map(BuyOutPerModelToJSON)),
        'billing': ProjectBillingToJSON(value.billing),
        'taskManagerInfos': TaskManagerInfosToJSON(value.taskManagerInfos),
    };
}

