import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { AuthenticationService } from "./layout/service/app.auth.service";

@Injectable()
export class FinalInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Retrieve the token from local storage or state management service
        const authToken = localStorage.getItem('accessToken');

        if (authToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`
                }
            });
        }

        return next.handle(request);
    }

    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     return next.handle(req).pipe(
    //         tap((event: HttpEvent<any>) => {
    //             if (event instanceof  HttpErrorResponse) {
    //                 //this.errorHandlerService.handleError(event);
    //                 console.log(event)
    //             }
    //         }));
    // }
}
