/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ModelApplicationDto
 */
export interface ModelApplicationDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    sex?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    availableCountries?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    sportTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    driverLicenseOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    skinTypes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    skinColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    eyeColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    hairColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    hairLengths?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    hairStyles?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    bahncardTypeOptions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelApplicationDto
     */
    musicalInstruments?: Array<AdminValue>;
}

/**
 * Check if a given object implements the ModelApplicationDto interface.
 */
export function instanceOfModelApplicationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelApplicationDtoFromJSON(json: any): ModelApplicationDto {
    return ModelApplicationDtoFromJSONTyped(json, false);
}

export function ModelApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelApplicationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sex': !exists(json, 'sex') ? undefined : ((json['sex'] as Array<any>).map(AdminValueFromJSON)),
        'availableCountries': !exists(json, 'availableCountries') ? undefined : ((json['availableCountries'] as Array<any>).map(AdminValueFromJSON)),
        'sportTypeOptions': !exists(json, 'sportTypeOptions') ? undefined : ((json['sportTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'driverLicenseOptions': !exists(json, 'driverLicenseOptions') ? undefined : ((json['driverLicenseOptions'] as Array<any>).map(AdminValueFromJSON)),
        'skinTypes': !exists(json, 'skinTypes') ? undefined : ((json['skinTypes'] as Array<any>).map(AdminValueFromJSON)),
        'skinColors': !exists(json, 'skinColors') ? undefined : ((json['skinColors'] as Array<any>).map(AdminValueFromJSON)),
        'eyeColors': !exists(json, 'eyeColors') ? undefined : ((json['eyeColors'] as Array<any>).map(AdminValueFromJSON)),
        'hairColors': !exists(json, 'hairColors') ? undefined : ((json['hairColors'] as Array<any>).map(AdminValueFromJSON)),
        'hairLengths': !exists(json, 'hairLengths') ? undefined : ((json['hairLengths'] as Array<any>).map(AdminValueFromJSON)),
        'hairStyles': !exists(json, 'hairStyles') ? undefined : ((json['hairStyles'] as Array<any>).map(AdminValueFromJSON)),
        'bahncardTypeOptions': !exists(json, 'bahncardTypeOptions') ? undefined : ((json['bahncardTypeOptions'] as Array<any>).map(AdminValueFromJSON)),
        'musicalInstruments': !exists(json, 'musicalInstruments') ? undefined : ((json['musicalInstruments'] as Array<any>).map(AdminValueFromJSON)),
    };
}

export function ModelApplicationDtoToJSON(value?: ModelApplicationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sex': value.sex === undefined ? undefined : ((value.sex as Array<any>).map(AdminValueToJSON)),
        'availableCountries': value.availableCountries === undefined ? undefined : ((value.availableCountries as Array<any>).map(AdminValueToJSON)),
        'sportTypeOptions': value.sportTypeOptions === undefined ? undefined : ((value.sportTypeOptions as Array<any>).map(AdminValueToJSON)),
        'driverLicenseOptions': value.driverLicenseOptions === undefined ? undefined : ((value.driverLicenseOptions as Array<any>).map(AdminValueToJSON)),
        'skinTypes': value.skinTypes === undefined ? undefined : ((value.skinTypes as Array<any>).map(AdminValueToJSON)),
        'skinColors': value.skinColors === undefined ? undefined : ((value.skinColors as Array<any>).map(AdminValueToJSON)),
        'eyeColors': value.eyeColors === undefined ? undefined : ((value.eyeColors as Array<any>).map(AdminValueToJSON)),
        'hairColors': value.hairColors === undefined ? undefined : ((value.hairColors as Array<any>).map(AdminValueToJSON)),
        'hairLengths': value.hairLengths === undefined ? undefined : ((value.hairLengths as Array<any>).map(AdminValueToJSON)),
        'hairStyles': value.hairStyles === undefined ? undefined : ((value.hairStyles as Array<any>).map(AdminValueToJSON)),
        'bahncardTypeOptions': value.bahncardTypeOptions === undefined ? undefined : ((value.bahncardTypeOptions as Array<any>).map(AdminValueToJSON)),
        'musicalInstruments': value.musicalInstruments === undefined ? undefined : ((value.musicalInstruments as Array<any>).map(AdminValueToJSON)),
    };
}

