/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelId } from './ModelId';
import {
    ModelIdFromJSON,
    ModelIdFromJSONTyped,
    ModelIdToJSON,
} from './ModelId';
import type { SelectedUsageType } from './SelectedUsageType';
import {
    SelectedUsageTypeFromJSON,
    SelectedUsageTypeFromJSONTyped,
    SelectedUsageTypeToJSON,
} from './SelectedUsageType';

/**
 * 
 * @export
 * @interface BuyOutPerModel
 */
export interface BuyOutPerModel {
    /**
     * 
     * @type {ModelId}
     * @memberof BuyOutPerModel
     */
    modelId: ModelId;
    /**
     * 
     * @type {string}
     * @memberof BuyOutPerModel
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyOutPerModel
     */
    modelFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof BuyOutPerModel
     */
    modelLastName: string;
    /**
     * 
     * @type {Date}
     * @memberof BuyOutPerModel
     */
    date: Date;
    /**
     * 
     * @type {SelectedUsageType}
     * @memberof BuyOutPerModel
     */
    selectedUsageType: SelectedUsageType;
    /**
     * 
     * @type {string}
     * @memberof BuyOutPerModel
     */
    description: string;
}

/**
 * Check if a given object implements the BuyOutPerModel interface.
 */
export function instanceOfBuyOutPerModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "modelFirstName" in value;
    isInstance = isInstance && "modelLastName" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "selectedUsageType" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function BuyOutPerModelFromJSON(json: any): BuyOutPerModel {
    return BuyOutPerModelFromJSONTyped(json, false);
}

export function BuyOutPerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyOutPerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': ModelIdFromJSON(json['modelId']),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'modelFirstName': json['modelFirstName'],
        'modelLastName': json['modelLastName'],
        'date': (new Date(json['date'])),
        'selectedUsageType': SelectedUsageTypeFromJSON(json['selectedUsageType']),
        'description': json['description'],
    };
}

export function BuyOutPerModelToJSON(value?: BuyOutPerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': ModelIdToJSON(value.modelId),
        'roleId': value.roleId,
        'modelFirstName': value.modelFirstName,
        'modelLastName': value.modelLastName,
        'date': (value.date.toISOString().substr(0,10)),
        'selectedUsageType': SelectedUsageTypeToJSON(value.selectedUsageType),
        'description': value.description,
    };
}

