/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobFormValuesDto
 */
export interface JobFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof JobFormValuesDto
     */
    currentJob?: string;
    /**
     * 
     * @type {string}
     * @memberof JobFormValuesDto
     */
    learnedJob?: string;
}

/**
 * Check if a given object implements the JobFormValuesDto interface.
 */
export function instanceOfJobFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobFormValuesDtoFromJSON(json: any): JobFormValuesDto {
    return JobFormValuesDtoFromJSONTyped(json, false);
}

export function JobFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentJob': !exists(json, 'currentJob') ? undefined : json['currentJob'],
        'learnedJob': !exists(json, 'learnedJob') ? undefined : json['learnedJob'],
    };
}

export function JobFormValuesDtoToJSON(value?: JobFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentJob': value.currentJob,
        'learnedJob': value.learnedJob,
    };
}

