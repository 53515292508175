<div class="search-list-box">
<p-listbox [options]="customerNames" [filter]="true" (onChange)="onSelectCustomer($event.value)">
    <ng-template let-customer pTemplate="item">
        <h6>{{customer.customerName}}</h6>
    </ng-template>
</p-listbox>
</div>
<div *ngIf="showEmployeeList" class="search-list-box">
    <p-listbox [options]="customerParticipantList" [filter]="true"
               (onChange)="onSelectEmployee($event.value)">
        <ng-template let-employee pTemplate="item">
            <h6>{{employee.participantFirstName}} {{employee.participantLastName}}</h6>
        </ng-template>
    </p-listbox>
</div>
