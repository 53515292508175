/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCustomerEmployeeUserCommand
 */
export interface CreateCustomerEmployeeUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    role?: CreateCustomerEmployeeUserCommandRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerEmployeeUserCommand
     */
    customerId?: string;
}


/**
 * @export
 */
export const CreateCustomerEmployeeUserCommandRoleEnum = {
    Admin: 'Admin',
    Booker: 'Booker',
    BookerAdmin: 'BookerAdmin',
    JuniorBooker: 'JuniorBooker',
    Model: 'Model',
    Customer: 'Customer',
    ExternalParticipant: 'ExternalParticipant'
} as const;
export type CreateCustomerEmployeeUserCommandRoleEnum = typeof CreateCustomerEmployeeUserCommandRoleEnum[keyof typeof CreateCustomerEmployeeUserCommandRoleEnum];


/**
 * Check if a given object implements the CreateCustomerEmployeeUserCommand interface.
 */
export function instanceOfCreateCustomerEmployeeUserCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function CreateCustomerEmployeeUserCommandFromJSON(json: any): CreateCustomerEmployeeUserCommand {
    return CreateCustomerEmployeeUserCommandFromJSONTyped(json, false);
}

export function CreateCustomerEmployeeUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerEmployeeUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
    };
}

export function CreateCustomerEmployeeUserCommandToJSON(value?: CreateCustomerEmployeeUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'role': value.role,
        'employeeId': value.employeeId,
        'customerId': value.customerId,
    };
}

