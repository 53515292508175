/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailFormValuesDto
 */
export interface EmailFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof EmailFormValuesDto
     */
    email?: string;
}

/**
 * Check if a given object implements the EmailFormValuesDto interface.
 */
export function instanceOfEmailFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailFormValuesDtoFromJSON(json: any): EmailFormValuesDto {
    return EmailFormValuesDtoFromJSONTyped(json, false);
}

export function EmailFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function EmailFormValuesDtoToJSON(value?: EmailFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

