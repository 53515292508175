/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletedMediaResult
 */
export interface DeletedMediaResult {
    /**
     * 
     * @type {string}
     * @memberof DeletedMediaResult
     */
    status?: DeletedMediaResultStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DeletedMediaResult
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedMediaResult
     */
    fileName?: string;
}


/**
 * @export
 */
export const DeletedMediaResultStatusEnum = {
    Success: 'Success',
    Failure: 'Failure'
} as const;
export type DeletedMediaResultStatusEnum = typeof DeletedMediaResultStatusEnum[keyof typeof DeletedMediaResultStatusEnum];


/**
 * Check if a given object implements the DeletedMediaResult interface.
 */
export function instanceOfDeletedMediaResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeletedMediaResultFromJSON(json: any): DeletedMediaResult {
    return DeletedMediaResultFromJSONTyped(json, false);
}

export function DeletedMediaResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletedMediaResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function DeletedMediaResultToJSON(value?: DeletedMediaResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
        'fileName': value.fileName,
    };
}

